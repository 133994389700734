require.config({
    paths: {
        'sdk': uiBuilderSDKPath + '/sdk',
    }
});

define(['sdk'], BackendlessUI => BackendlessUI.startApp());

define('./functions/206a4a5e45efaff98b863be918e683dc/code.js', () => { 

BackendlessUI.Functions.Custom['fn_206a4a5e45efaff98b863be918e683dc'] = async function fn_206a4a5e45efaff98b863be918e683dc(Page_Data) {
  await (async function(Page_Data) {
  	document.addEventListener('click', e => {

  	  const classes = e.target.className.split(" ");

  	  let checkBoxClicked = false;

  	  for (let i = 0; i < classes.length; i++) {
  	    if (classes[i].substring(0, 3) === "jss") {
  	      checkBoxClicked = true;
  	    }
  	  }

  	  if (!(classes.includes("actionBlock") || classes.includes("actionContent") || classes.includes("actionButton") || classes.includes("actionText") || classes.includes("actionIcon") || classes.includes("bl-checkbox-label"))) {
  	    Page_Data.isUserRecordActionDrawerOpen = false;
  	    Page_Data.isDeviceRecordActionDrawerOpen = false;
  	    Page_Data.isCurfewRecordActionDrawerOpen = false;
  	    Page_Data.isOrganizationRecordActionDrawerOpen = false;
  	    Page_Data.isViewResidentActionDrawerOpen = false;
  	  }

  	  if (!(classes.includes("menuBlock") || classes.includes("menuContent") || classes.includes("menuButton") || classes.includes("menuText") || classes.includes("menuIcon") || classes.includes("menuPicture") || classes.includes("bl-checkbox-label"))) {
  	    Page_Data.isUserMenuOpen = false;
  	  }

  	  if (!(classes.includes("filterBlock") || classes.includes("filterContent") || classes.includes("filterButton") || classes.includes("filterText") || classes.includes("filterIcon") || classes.includes("bl-checkbox-label")) && !checkBoxClicked) {
  	    Page_Data.isResidentRecordFilterDrawerOpen = false;
  	    Page_Data.isDeviceRecordFilterDrawerOpen = false;
  	  }
  	})
  })(Page_Data);

}

})
define('./functions/256db7f8ee15af9d778314d97f563562/code.js', () => { 

BackendlessUI.Functions.Custom['fn_256db7f8ee15af9d778314d97f563562'] = async function fn_256db7f8ee15af9d778314d97f563562(text) {
return (await (async function(text) {
	const replaceSpecialCharacters = text.replaceAll(/[^a-zA-Z0-9 ]/g, "");

	return replaceSpecialCharacters
})(text))
}

})
define('./functions/2dc3266507a474ba2f5a291d2361a812/code.js', () => { 

BackendlessUI.Functions.Custom['fn_2dc3266507a474ba2f5a291d2361a812'] = async function fn_2dc3266507a474ba2f5a291d2361a812(zipCode) {
var bareNumber, text;


  bareNumber = (await (async function(zipCode) {
  	return zipCode.replace(/[^0-9]+/g, "")
  })(zipCode));
  if (bareNumber.length > 5) {
    return (bareNumber.slice(0, 5));
  } else {
    return bareNumber;
  }

return 
}

})
define('./functions/36435bc8e7f437df45a6682ebdbf7388/code.js', () => { 

BackendlessUI.Functions.Custom['fn_36435bc8e7f437df45a6682ebdbf7388'] = function fn_36435bc8e7f437df45a6682ebdbf7388(email) {
return ((new RegExp('^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,}$', '')).test(email))
}

})
define('./functions/3c26f63ed44ba06a52e69b84db7a8b7b/code.js', () => { 

BackendlessUI.Functions.Custom['fn_3c26f63ed44ba06a52e69b84db7a8b7b'] = function fn_3c26f63ed44ba06a52e69b84db7a8b7b(dob) {
var days, difference, dob, today;


  today = (new Date((new Date())).getTime());
  dob = (new Date(dob).getTime());
  difference = today - dob;
  days = 1000 * 3600 * 24;

return (Math.floor((difference / days) / 365))
}

})
define('./functions/3c37569726d2e37c1909af054741f424/code.js', () => { 

BackendlessUI.Functions.Custom['fn_3c37569726d2e37c1909af054741f424'] = async function fn_3c37569726d2e37c1909af054741f424(id, variant, previousValue) {
var bareNumber;


  bareNumber = (await (async function(phoneNumber) {
  	return phoneNumber.replace(/[^0-9]+/g, "")
  })(id));
  if (variant == 'remove') {
    return bareNumber;
  } else if (variant == 'create') {
    if (bareNumber.length <= 2) {
      return bareNumber;
    } else if (bareNumber.length > 2) {
      return ([bareNumber.slice(0, 2),'-',bareNumber.slice(2, 9)].join(''));
    }
  }

return 
}

})
define('./functions/419a5da59f8ecf4150695a570f593a75/code.js', () => { 

BackendlessUI.Functions.Custom['fn_419a5da59f8ecf4150695a570f593a75'] = function fn_419a5da59f8ecf4150695a570f593a75(militaryTime) {
var hour;


  if (militaryTime == 12) {
    return '12:00 PM';
  } else if (militaryTime == 24) {
    return '12:00 AM';
  } else {
    if (militaryTime == 1 || militaryTime == 13) {
      hour = 1;
    } else if (militaryTime == 2 || militaryTime == 14) {
      hour = 2;
    } else if (militaryTime == 3 || militaryTime == 15) {
      hour = 3;
    } else if (militaryTime == 4 || militaryTime == 16) {
      hour = 4;
    } else if (militaryTime == 5 || militaryTime == 17) {
      hour = 5;
    } else if (militaryTime == 6 || militaryTime == 18) {
      hour = 6;
    } else if (militaryTime == 7 || militaryTime == 19) {
      hour = 7;
    } else if (militaryTime == 8 || militaryTime == 20) {
      hour = 8;
    } else if (militaryTime == 9 || militaryTime == 21) {
      hour = 9;
    } else if (militaryTime == 10 || militaryTime == 22) {
      hour = 10;
    } else if (militaryTime == 11 || militaryTime == 23) {
      hour = 11;
    }
    if (hour) {
      if (militaryTime <= 11) {
        return (String(hour) + String(':00 AM'));
      } else if (militaryTime >= 13) {
        return (String(hour) + String(':00 PM'));
      }
    }
  }

return 
}

})
define('./functions/4d0b6f63e09985a12656f99b68cbc84d/code.js', () => { 

BackendlessUI.Functions.Custom['fn_4d0b6f63e09985a12656f99b68cbc84d'] = function fn_4d0b6f63e09985a12656f99b68cbc84d(webAddress) {
return ((new RegExp('^((ftp|http|https):\\/\\/)?(www.)?(?!.*(ftp|http|https|www.))[a-zA-Z0-9_-]+(\\.[a-zA-Z]+)+((\\/)[\\w#]+)*(\\/\\w+\\?[a-zA-Z0-9_]+=\\w+(&[a-zA-Z0-9_]+=\\w+)*)?\\/?$', '')).test(webAddress))
}

})
define('./functions/5da19a0685ee9e7b97339f9e3dd69c3b/code.js', () => { 

BackendlessUI.Functions.Custom['fn_5da19a0685ee9e7b97339f9e3dd69c3b'] = async function fn_5da19a0685ee9e7b97339f9e3dd69c3b(variant, email, orgName, changedValue, selectedFilterArray, userRole, pillar) {
var defaultClause, filterArray, attribute, listOfAttributes;

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  filterArray = [];
  if (variant == 'resident' && userRole == 'admin') {
    defaultClause = ['orgName = \'',orgName,'\''].join('');
    if (changedValue && !(await BackendlessUI.Functions.Custom['fn_a7ad4c1fd5d18ca6bff3eb611aeee2ac'](changedValue))) {
      if (selectedFilterArray && selectedFilterArray.length != 0) {
        for (var attribute_index in selectedFilterArray) {
          attribute = selectedFilterArray[attribute_index];
          addItemToList(filterArray, ([defaultClause,' and nameInUse LIKE \'%',changedValue,'%\' and ',attribute].join('')));
        }
        return (filterArray.join(' or '));
      } else {
        return ([defaultClause,' and nameInUse LIKE \'%',changedValue,'%\''].join(''));
      }
    } else {
      if (selectedFilterArray && selectedFilterArray.length != 0) {
        filterArray = [];
        for (var attribute_index2 in selectedFilterArray) {
          attribute = selectedFilterArray[attribute_index2];
          addItemToList(filterArray, ([defaultClause,' and ',attribute].join('')));
        }
        return (filterArray.join(' or '));
      } else {
        return defaultClause;
      }
    }
  } else if (variant == 'resident') {
    defaultClause = ['managerEmail = \'',email,'\' and orgName = \'',orgName,'\''].join('');
    if (changedValue && !(await BackendlessUI.Functions.Custom['fn_a7ad4c1fd5d18ca6bff3eb611aeee2ac'](changedValue))) {
      if (selectedFilterArray && selectedFilterArray.length != 0) {
        for (var attribute_index3 in selectedFilterArray) {
          attribute = selectedFilterArray[attribute_index3];
          addItemToList(filterArray, ([defaultClause,' and nameInUse LIKE \'%',changedValue,'%\' and ',attribute].join('')));
        }
        return (filterArray.join(' or '));
      } else {
        return ([defaultClause,' and nameInUse LIKE \'%',changedValue,'%\''].join(''));
      }
    } else {
      if (selectedFilterArray && selectedFilterArray.length != 0) {
        filterArray = [];
        for (var attribute_index4 in selectedFilterArray) {
          attribute = selectedFilterArray[attribute_index4];
          addItemToList(filterArray, ([defaultClause,' and ',attribute].join('')));
        }
        return (filterArray.join(' or '));
      } else {
        return defaultClause;
      }
    }
  } else if (variant == 'device') {
    defaultClause = ['organizationId.orgName = \'',orgName,'\' and deviceIMEI is not null'].join('');
    if (changedValue && !(await BackendlessUI.Functions.Custom['fn_a7ad4c1fd5d18ca6bff3eb611aeee2ac'](changedValue))) {
      if (selectedFilterArray && selectedFilterArray.length != 0) {
        for (var attribute_index5 in selectedFilterArray) {
          attribute = selectedFilterArray[attribute_index5];
          addItemToList(filterArray, ([defaultClause,' and deviceIMEI LIKE \'%',changedValue,'%\' and ',attribute].join('')));
          addItemToList(filterArray, ([defaultClause,' and deviceStatus LIKE \'%',changedValue,'%\' and ',attribute].join('')));
          addItemToList(filterArray, ([defaultClause,' and residentId.nameInUse LIKE \'%',changedValue,'%\' and ',attribute].join('')));
        }
        return (filterArray.join(' or '));
      } else {
        return ([defaultClause,' and deviceIMEI LIKE \'%',changedValue,'%\' OR ',defaultClause,' and deviceStatus LIKE \'%',changedValue,'%\' OR ',defaultClause,' and residentId.nameInUse LIKE \'%',changedValue,'%\''].join(''));
      }
    } else {
      if (selectedFilterArray && selectedFilterArray.length != 0) {
        filterArray = [];
        for (var attribute_index6 in selectedFilterArray) {
          attribute = selectedFilterArray[attribute_index6];
          addItemToList(filterArray, ([defaultClause,' and ',attribute].join('')));
        }
        return (filterArray.join(' or '));
      } else {
        return defaultClause;
      }
    }
  } else if (variant == 'user') {
    if (userRole == 'super') {
      defaultClause = ['email LIKE \'%',changedValue,'%\' OR name LIKE \'%',changedValue,'%\' OR userRole LIKE \'%',changedValue,'%\' OR phoneNumber LIKE \'%',changedValue,'%\' OR status LIKE \'%',changedValue,'%\' OR orgName LIKE \'%',changedValue,'%\''].join('');
      if (!(await BackendlessUI.Functions.Custom['fn_a7ad4c1fd5d18ca6bff3eb611aeee2ac'](changedValue))) {
        return defaultClause;
      } else {
        return '';
      }
    } else if (userRole == 'admin') {
      listOfAttributes = ['name', 'userRole', 'phoneNumber', 'status', 'inviteStatus', 'email'];
      defaultClause = ['orgName = \'',orgName,'\' and email != \'',email,'\''].join('');
      if (!(await BackendlessUI.Functions.Custom['fn_a7ad4c1fd5d18ca6bff3eb611aeee2ac'](changedValue))) {
        for (var attribute_index7 in listOfAttributes) {
          attribute = listOfAttributes[attribute_index7];
          addItemToList(filterArray, ([defaultClause,' and ',attribute,' LIKE \'%',changedValue,'%\''].join('')));
        }
        return (filterArray.join(' or '));
      } else {
        return defaultClause;
      }
    }
  } else if (variant == 'organization') {
    defaultClause = ['orgName LIKE \'%',changedValue,'%\' OR billingEmail LIKE \'%',changedValue,'%\' OR billingName LIKE \'%',changedValue,'%\' OR billingPhone LIKE \'%',changedValue,'%\''].join('');
    if (!(await BackendlessUI.Functions.Custom['fn_a7ad4c1fd5d18ca6bff3eb611aeee2ac'](changedValue))) {
      return defaultClause;
    } else {
      return '';
    }
  } else if (variant == 'pillars') {
    defaultClause = ['organizationId.orgName = \'',orgName,'\' and pillar = \'',pillar,'\''].join('');
    if (defaultClause) {
      return defaultClause;
    } else {
      return '';
    }
  } else if (variant == 'curfew') {
    defaultClause = ['organizationId.orgName = \'',orgName,'\' and pillar = \'',pillar,'\''].join('');
    if (defaultClause) {
      return defaultClause;
    } else {
      return '';
    }
  }

return 
}

})
define('./functions/67c6f852cf5ce414b74b8f0b9401b852/code.js', () => { 

BackendlessUI.Functions.Custom['fn_67c6f852cf5ce414b74b8f0b9401b852'] = async function fn_67c6f852cf5ce414b74b8f0b9401b852(senderEmail, senderName, recipientEmail, template_id, dynamic_template_data) {
var error, sendGridResponse;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  try {
    sendGridResponse = (await Backendless.Request.post(`${Backendless.appPath}/services/Sendgrid/sendgrid`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send({ 'to': [({ [`email`]: recipientEmail })],'from': ({ [`name`]: senderName,[`email`]: senderEmail }),'template_id': template_id,'dynamic_template_data': dynamic_template_data }));
    if ((getObjectProperty(sendGridResponse, 'status')) == 'success') {
      return true;
    } else {
      return false;
    }

  } catch (error) {
    return error;

  }

return 
}

})
define('./functions/6d4580c12cbae4a32f38d054d2dc9e71/code.js', () => { 

BackendlessUI.Functions.Custom['fn_6d4580c12cbae4a32f38d054d2dc9e71'] = function fn_6d4580c12cbae4a32f38d054d2dc9e71(phoneNumber) {
var phoneNumber;

function getValueLength(value) {
 if (typeof value === 'number') {
   value = `${ value }`
 }

 return value.length
}


  if (phoneNumber.indexOf('+') !== -1) {
    phoneNumber = phoneNumber.slice(2, phoneNumber.length);
  } else {
    phoneNumber = phoneNumber;
  }
  if (((new RegExp('^[0-9+]+$', '')).test(phoneNumber)) && getValueLength(phoneNumber) == 10) {
    return true;
  } else {
    return false;
  }

return 
}

})
define('./functions/71a2dca5099d57aac701dfa99e2c9eb1/code.js', () => { 

BackendlessUI.Functions.Custom['fn_71a2dca5099d57aac701dfa99e2c9eb1'] = function fn_71a2dca5099d57aac701dfa99e2c9eb1(password) {
  if ((new RegExp('^(?=.*?[a-zA-Z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-_]).{8,}$', '')).test(password)) {
    return true;
  } else {
    return false;
  }

return 
}

})
define('./functions/77bfd63e533a9b4c5577e5fe31a4b5ca/code.js', () => { 

BackendlessUI.Functions.Custom['fn_77bfd63e533a9b4c5577e5fe31a4b5ca'] = async function fn_77bfd63e533a9b4c5577e5fe31a4b5ca(Page_Data, message, status) {
function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (!(getObjectProperty(Page_Data, 'hasToastError'))) {
    Page_Data['hasToastError'] = true;
    Page_Data['isToastVisible'] = true;
    Page_Data['toastMessage'] = message;
    Page_Data['toastClasses'] = ['toast'];
    await new Promise(r => setTimeout(r, 30 || 0));
    Page_Data['toastClasses'] = ['toast', (typeof status === 'string') ? status : '', 'show'];
    await new Promise(r => setTimeout(r, 4000 || 0));
    Page_Data['toastClasses'] = ['toast'];
    await new Promise(r => setTimeout(r, 500 || 0));
    delete Page_Data['toastClasses'];
    delete Page_Data['toastMessage'];
    Page_Data['hasToastError'] = false;
    Page_Data['isToastVisible'] = false;
  }

}

})
define('./functions/7e3d62cb9ecfca7bbe1d85a6a421c66f/code.js', () => { 

BackendlessUI.Functions.Custom['fn_7e3d62cb9ecfca7bbe1d85a6a421c66f'] = function fn_7e3d62cb9ecfca7bbe1d85a6a421c66f(pillarStart) {
var days, difference, pillarStart, today;


  today = (new Date((new Date())).getTime());
  pillarStart = (new Date(pillarStart).getTime());
  difference = today - pillarStart;
  days = 1000 * 60 * 60 * 24;

return (Math.ceil(difference / days))
}

})
define('./functions/85382ee7628e1f2abdf9560ba62487cc/code.js', () => { 

BackendlessUI.Functions.Custom['fn_85382ee7628e1f2abdf9560ba62487cc'] = function fn_85382ee7628e1f2abdf9560ba62487cc(Page_Data) {
  Page_Data['isUserMenuOpen'] = false;
  Page_Data['isResidentRecordActionDrawerOpen'] = false;
  Page_Data['isResidentRecordFilterDrawerOpen'] = false;
  Page_Data['isDeviceRecordActionDrawerOpen'] = false;
  Page_Data['isDeviceRecordFilterDrawerOpen'] = false;
  Page_Data['isUserRecordActionDrawerOpen'] = false;
  Page_Data['isUserRecordFilterDrawerOpen'] = false;

}

})
define('./functions/86e80235f72a49fc4fa8cc92d83c4b9e/code.js', () => { 

BackendlessUI.Functions.Custom['fn_86e80235f72a49fc4fa8cc92d83c4b9e'] = function fn_86e80235f72a49fc4fa8cc92d83c4b9e(Page_Data) {
  Page_Data['changedValueResident'] = undefined;
  Page_Data['changedValueUser'] = undefined;
  Page_Data['changedValueDevice'] = undefined;
  Page_Data['noResultsScreen'] = false;
  Page_Data['pageEnter'] = false;
  Page_Data['isViewResidentDetailsOpen'] = undefined;
  Page_Data['isViewDetailsOpen'] = undefined;
  Page_Data['residentDetailsSelection'] = undefined;
  Page_Data['curfewPillarSelectionBar'] = '1';
  Page_Data['supportGuideSelection'] = 'admin';

return 
}

})
define('./functions/8d46c0c781a168eb2601b8313797f5df/code.js', () => { 

BackendlessUI.Functions.Custom['fn_8d46c0c781a168eb2601b8313797f5df'] = async function fn_8d46c0c781a168eb2601b8313797f5df(date, variant) {
var bareNumber, text;


  bareNumber = (await (async function(date) {
  	return date.replace(/[^0-9]+/g, "")
  })(date));
  if (variant == 'remove') {
    return bareNumber;
  } else if (variant == 'create') {
    if (date.length == 3 && date.slice(-1) == '/' || date.length == 4 && date.charAt(2) == '/' && bareNumber.length == 2) {
      return (String(bareNumber) + String('/'));
    } else if (bareNumber.length <= 2) {
      return bareNumber;
    } else if (date.length == 6 && date.slice(-1) == '/' && bareNumber.length == 4 || date.length == 7 && date.charAt(5) == '/' && bareNumber.length == 4) {
      return ([bareNumber.slice(0, 2),'/',bareNumber.slice(2, bareNumber.length),'/'].join(''));
    } else if (bareNumber.length == 3 || bareNumber.length == 4) {
      return ([bareNumber.slice(0, 2),'/',bareNumber.slice(2, bareNumber.length)].join(''));
    } else if (false) {
    } else if (false) {
    } else if (false) {
    } else if (bareNumber.length >= 5) {
      return ([bareNumber.slice(0, 2),'/',bareNumber.slice(2, 4),'/',bareNumber.slice(4, 8)].join(''));
    } else if (false) {
    }
  }

return 
}

})
define('./functions/966857bb865419826be74c3759378f6e/code.js', () => { 

BackendlessUI.Functions.Custom['fn_966857bb865419826be74c3759378f6e'] = async function fn_966857bb865419826be74c3759378f6e() {
return (await Backendless.Request.post(`${Backendless.appPath}/services/HeadwindMDM/getUserToken`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send({ 'login': 'admin','password': 'CB0EB539825D53A373B702FB000EE02F' }))
}

})
define('./functions/9807a958b9f47818617415e4438b5e47/code.js', () => { 

BackendlessUI.Functions.Custom['fn_9807a958b9f47818617415e4438b5e47'] = function fn_9807a958b9f47818617415e4438b5e47(string, num) {
function getValueLength(value) {
 if (typeof value === 'number') {
   value = `${ value }`
 }

 return value.length
}


  if (string && getValueLength(string) > num) {
    return (String(string.slice(0, num)) + String('...'));
  } else {
    return string;
  }

return 
}

})
define('./functions/9b44aef25264690eed0615df9af44168/code.js', () => { 

BackendlessUI.Functions.Custom['fn_9b44aef25264690eed0615df9af44168'] = async function fn_9b44aef25264690eed0615df9af44168(Page_Data, group) {
var deviceId, qrCodeKey, deviceTableResponse, headwindResponse, configurationId, headwindData, newToken, organization;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function generateUUID() {
   const chr4 = () => Math.random().toString(16).slice(-4);
   const chr8 = () => `${chr4()}${chr4()}`;
   const short = () => chr8();
   const long = () => `${chr8()}-${chr4()}-${chr4()}-${chr4()}-${chr8()}${chr4()}`;

   return { short, long, }
}

/**
 * Describe this function...
 */
async function createQRCode() {
  if (getObjectProperty(Page_Data, 'currentUser.organizationId.headwindData')) {
    headwindData = (JSON.parse((getObjectProperty(Page_Data, 'currentUser.organizationId.headwindData'))));
    if ((getObjectProperty(Page_Data, 'residentPillarRadio')) == '1') {
      qrCodeKey = (getObjectProperty((JSON.parse((getObjectProperty(Page_Data, 'currentUser.organizationId.headwindData')))), 'qrCodeKeyOne'));
      configurationId = 6;
    } else if ((getObjectProperty(Page_Data, 'residentPillarRadio')) == '2') {
      qrCodeKey = (getObjectProperty((JSON.parse((getObjectProperty(Page_Data, 'currentUser.organizationId.headwindData')))), 'qrCodeKeyTwo'));
      configurationId = 13;
    } else if ((getObjectProperty(Page_Data, 'residentPillarRadio')) == '3') {
      qrCodeKey = (getObjectProperty((JSON.parse((getObjectProperty(Page_Data, 'currentUser.organizationId.headwindData')))), 'qrCodeKeyThree'));
      configurationId = 14;
    } else if ((getObjectProperty(Page_Data, 'residentPillarRadio')) == '4') {
      qrCodeKey = (getObjectProperty((JSON.parse((getObjectProperty(Page_Data, 'currentUser.organizationId.headwindData')))), 'qrCodeKeyFour'));
      configurationId = 15;
    }
    if (qrCodeKey) {
      if (!(getObjectProperty(Page_Data, 'selectedItemResident.deviceId'))) {
        deviceId = (generateUUID().long());
        if (newToken) {
          headwindResponse = (await Backendless.Request.put(`${Backendless.appPath}/services/HeadwindMDM/createDevice`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send({ 'number': deviceId,'token': newToken,'configurationId': configurationId,'groups': [({ [`id`]: '2',[`name`]: 'CyberDive' })] }));
        } else {
          headwindResponse = (await Backendless.Request.put(`${Backendless.appPath}/services/HeadwindMDM/createDevice`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send({ 'number': deviceId,'token': (getObjectProperty(headwindData, 'token')),'configurationId': configurationId,'groups': [({ [`id`]: '2',[`name`]: 'CyberDive' })] }));
        }
        if ((getObjectProperty(headwindResponse, 'status')) == 'OK') {
          deviceTableResponse = (await Backendless.Data.of('Devices').save( ({ [`deviceId`]: deviceId,[`deviceStatus`]: 'ONBOARDPENDING' }) ));
          await Backendless.Data.of('Residents').setRelation((getObjectProperty(Page_Data, 'selectedItemResident.objectId')), 'deviceId', [deviceTableResponse]);
          await Backendless.Data.of('Devices').setRelation(deviceTableResponse, 'organizationId', [(getObjectProperty(Page_Data, 'currentUser.organizationId.objectId'))]);
          await Backendless.Data.of('Devices').setRelation(deviceTableResponse, 'residentId', [(getObjectProperty(Page_Data, 'selectedItemResident.objectId'))]);
        } else {
          throw (new Error(''))
        }
      } else {
        deviceId = (getObjectProperty(Page_Data, 'selectedItemResident.deviceId'));
      }
      if (deviceId) {
        Page_Data['QRCodeImage'] = (['https://mdm.aquax.co/rest/public/qr/',qrCodeKey,'?group=',group,'&create=0&deviceId=',deviceId].join(''));
        Page_Data['refreshUser'] = true;
        Page_Data['refreshResidentTable'] = true;
        Page_Data['refreshResident'] = true;
        Page_Data['getQRCodeError'] = false;
        Page_Data['isLoadingQRCode'] = false;
        Page_Data['isQRSuccessful'] = true;
      }
    }
  } else {
    Page_Data['getQRCodeError'] = true;
    await BackendlessUI.Functions.Custom['fn_77bfd63e533a9b4c5577e5fe31a4b5ca'](Page_Data, 'failure', 'Error displaying QR code.');
  }
}

/**
 * Describe this function...
 */
async function refreshMDMTokenInBackend() {
  try {
    newToken = (getObjectProperty((await Backendless.Request.post(`${Backendless.appPath}/services/HeadwindMDM/getUserToken`).set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send()), 'id_token'));
    var organization_list = (await Backendless.Data.of('Organizations').find(Backendless.DataQueryBuilder.create().setPageSize(50)));
    for (var organization_index in organization_list) {
      organization = organization_list[organization_index];
      if (getObjectProperty(organization, 'headwindData')) {
        headwindData = (JSON.parse((getObjectProperty(organization, 'headwindData'))));
      } else {
        headwindData = ({ [`token`]: undefined });
      }
      headwindData['token'] = newToken;
      await Backendless.Data.of('Organizations').save( ({ [`objectId`]: (getObjectProperty(organization, 'objectId')),[`headwindData`]: (JSON.stringify(headwindData)) }) );
    }
    await createQRCode();

  } catch (error) {
    Page_Data['isLoadingQRCode'] = false;
    Page_Data['getQRCodeError'] = true;
    await BackendlessUI.Functions.Custom['fn_77bfd63e533a9b4c5577e5fe31a4b5ca'](Page_Data, 'Error displaying QR code.', 'failure');

  }
}


  Page_Data['getQRCodeError'] = false;
  Page_Data['isLoadingQRCode'] = true;
  try {
    await createQRCode();

  } catch (error) {
    await refreshMDMTokenInBackend();

  }

return 
}

})
define('./functions/a3492c0a3c3e3c5e02e3736d07768491/code.js', () => { 

BackendlessUI.Functions.Custom['fn_a3492c0a3c3e3c5e02e3736d07768491'] = function fn_a3492c0a3c3e3c5e02e3736d07768491(taxId) {
function getValueLength(value) {
 if (typeof value === 'number') {
   value = `${ value }`
 }

 return value.length
}


  if (((new RegExp('^[0-9-]+$', '')).test(taxId)) && getValueLength(taxId) == 10) {
    return true;
  } else {
    return false;
  }

return 
}

})
define('./functions/a65e994797048d593245ed62a80fb31f/code.js', () => { 

BackendlessUI.Functions.Custom['fn_a65e994797048d593245ed62a80fb31f'] = function fn_a65e994797048d593245ed62a80fb31f(emailType, linkItems) {
function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (emailType == 'invite') {
    if ((function () { return BackendlessUI.isProd })()) {
      return (['https://app.aquax.co/?page=Register&userEmail=',(getObjectProperty(linkItems, 'user_email')),'&adminEmail=',(getObjectProperty(linkItems, 'admin_email'))].join(''));
    } else {
      return (['https://app.aquax.co/api/files/ui-builder/containers/default/index.html?page=Register&userEmail=',(getObjectProperty(linkItems, 'user_email')),'&adminEmail=',(getObjectProperty(linkItems, 'admin_email'))].join(''));
    }
  } else if (emailType == 'created') {
    if ((function () { return BackendlessUI.isProd })()) {
      return 'https://app.aquax.co/?page=Login';
    } else {
      return 'https://app.aquax.co/api/files/ui-builder/containers/default/index.html?page=Login';
    }
  } else if (emailType == 'forgotPassword') {
    if ((function () { return BackendlessUI.isProd })()) {
      return (['https://app.aquax.co/?page=NewPassword&userEmail=',(getObjectProperty(linkItems, 'user_email')),'&key=',(getObjectProperty(linkItems, 'key'))].join(''));
    } else {
      return (['https://app.aquax.co/api/files/ui-builder/containers/default/index.html?page=NewPassword&userEmail=',(getObjectProperty(linkItems, 'user_email')),'&key=',(getObjectProperty(linkItems, 'key'))].join(''));
    }
  }

return 
}

})
define('./functions/a7ad4c1fd5d18ca6bff3eb611aeee2ac/code.js', () => { 

BackendlessUI.Functions.Custom['fn_a7ad4c1fd5d18ca6bff3eb611aeee2ac'] = function fn_a7ad4c1fd5d18ca6bff3eb611aeee2ac(string) {
return ((new RegExp('^\\s*$', '')).test(string))
}

})
define('./functions/c16fa4da6fdc2a0ab06c37eb5691c2e2/code.js', () => { 

BackendlessUI.Functions.Custom['fn_c16fa4da6fdc2a0ab06c37eb5691c2e2'] = function fn_c16fa4da6fdc2a0ab06c37eb5691c2e2(zip) {
var organizationApiResponse;

function getValueLength(value) {
 if (typeof value === 'number') {
   value = `${ value }`
 }

 return value.length
}


  if (((new RegExp('^\\d+$', '')).test(zip)) && getValueLength(zip) == 5) {
    return true;
  } else {
    return false;
  }

return 
}

})
define('./functions/d48a6d3a366b0ef43b6e0b4bab07fa46/code.js', () => { 

BackendlessUI.Functions.Custom['fn_d48a6d3a366b0ef43b6e0b4bab07fa46'] = function fn_d48a6d3a366b0ef43b6e0b4bab07fa46(Page_Data) {
var contact;


  Page_Data['residentContactFullNameOne'] = undefined;
  Page_Data['residentContactCellPhoneOne'] = undefined;
  Page_Data['residentContactHomePhoneOne'] = undefined;
  Page_Data['residentContactFullNameTwo'] = undefined;
  Page_Data['residentContactCellPhoneTwo'] = undefined;
  Page_Data['residentContactHomePhoneTwo'] = undefined;
  Page_Data['residentContactFullNameThree'] = undefined;
  Page_Data['residentContactCellPhoneThree'] = undefined;
  Page_Data['residentContactHomePhoneThree'] = undefined;
  Page_Data['residentContactFullNameFour'] = undefined;
  Page_Data['residentContactCellPhoneFour'] = undefined;
  Page_Data['residentContactHomePhoneFour'] = undefined;
  Page_Data['residentContactFullNameFive'] = undefined;
  Page_Data['residentContactCellPhoneFive'] = undefined;
  Page_Data['residentContactHomePhoneFive'] = undefined;

return 
}

})
define('./functions/d897f147d4c500e22bcf6e99a55e0d31/code.js', () => { 

BackendlessUI.Functions.Custom['fn_d897f147d4c500e22bcf6e99a55e0d31'] = function fn_d897f147d4c500e22bcf6e99a55e0d31(PAGE_DATA) {
  PAGE_DATA['curfewTableCount'] = undefined;
  PAGE_DATA['userTableCount'] = undefined;
  PAGE_DATA['residentTableCount'] = undefined;
  PAGE_DATA['orgTableCount'] = undefined;

return 
}

})
define('./functions/f282814277b3c0aa13f33d3b2cda111b/code.js', () => { 

BackendlessUI.Functions.Custom['fn_f282814277b3c0aa13f33d3b2cda111b'] = async function fn_f282814277b3c0aa13f33d3b2cda111b(phoneNumber, variant) {
var bareNumber;


  bareNumber = (await (async function(phoneNumber) {
  	return phoneNumber.replace(/[^0-9]+/g, "")
  })(phoneNumber));
  if (variant == 'remove') {
    return bareNumber;
  } else if (variant == 'create') {
    if (phoneNumber.length == 5) {
      return bareNumber;
    } else if (bareNumber.length == 3) {
      return (['(',bareNumber,')-'].join(''));
    } else if (bareNumber.length > 3 && bareNumber.length <= 6) {
      return (['(',bareNumber.slice(0, 3),')-',bareNumber.slice(3, bareNumber.length)].join(''));
    } else if (bareNumber.length > 6) {
      return (['(',bareNumber.slice(0, 3),')-',bareNumber.slice(3, 6),'-',bareNumber.slice(6, 10)].join(''));
    } else {
      return bareNumber;
    }
  }

return 
}

})
define('./pages/404/components/902c9641de4ec9e73427e7192a118d14/bundle.js', [], () => ({
  /* content */

  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('', undefined);

  },
  /* handler:onClick *//* content */
}));

define('./pages/Dashboard/components/page/bundle.js', [], () => ({
  /* content */
  /* handler:onBeforeEnter */
  async ['onBeforeEnter'](___arguments) {
    var activeOrganizations, item, navbarSelection, currentUser, toast;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

/**
 * Describe this function...
 */
async function setToast(toast) {
  await BackendlessUI.Functions.Custom['fn_77bfd63e533a9b4c5577e5fe31a4b5ca'](___arguments.context.pageData, (getObjectProperty(toast, 'message')), (getObjectProperty(toast, 'status')));
}

/**
 * Describe this function...
 */
async function getCurrentUserInfo() {
  try {
    ___arguments.context.pageData['currentUser'] = ((await Backendless.Data.of('Users').find(Backendless.DataQueryBuilder.create().setWhereClause((['email =','\'',(getObjectProperty(currentUser, 'email')),'\''].join(''))).setRelationsDepth(2).setRelationsPageSize(1).setPageSize(10)))[0]);

  } catch (error) {
    await BackendlessUI.Functions.Custom['fn_77bfd63e533a9b4c5577e5fe31a4b5ca'](___arguments.context.pageData, 'There was a problem loading the user information.', 'failure');

  }
}


  currentUser = (await Backendless.UserService.getCurrentUser(false));
  if (!currentUser) {
    (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('Login', undefined);
  } else if (currentUser && (getObjectProperty(currentUser, 'userRole')) == 'super') {
    (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('Management', undefined);
  } else {
    await getCurrentUserInfo();
  }
  navbarSelection = ((function(key){ try { return JSON.parse(localStorage.getItem(key)) } catch(e){ return null }})('navbarSelection'));
  ___arguments.context.pageData['isUserEditingProfile'] = false;
  await BackendlessUI.Functions.Custom['fn_86e80235f72a49fc4fa8cc92d83c4b9e'](___arguments.context.pageData);
  if (navbarSelection) {
    ___arguments.context.pageData['navbarSelection'] = navbarSelection;
  } else {
    ___arguments.context.pageData['navbarSelection'] = 'dashboard';
  }
  try {
    activeOrganizations = (await Promise.all((await Backendless.Data.of('Organizations').find(Backendless.DataQueryBuilder.create().setWhereClause('status = \'ACTIVE\'').setProperties(['orgName', 'objectId']).setPageSize(100))).map(async item => {; return ({ [`label`]: (getObjectProperty(item, 'orgName')),[`value`]: (getObjectProperty(item, 'orgName')) });})));
    ___arguments.context.pageData['organizationChoice'] = activeOrganizations;
    ___arguments.context.pageData['userRecordsOrganizationOptions'] = activeOrganizations;
    ___arguments.context.pageData['userRecordsUserRoleOptions'] = [({ [`label`]: 'Administrator',[`value`]: 'admin' }), ({ [`label`]: 'Case Manager',[`value`]: 'manager' })];

  } catch (error) {
    await BackendlessUI.Functions.Custom['fn_77bfd63e533a9b4c5577e5fe31a4b5ca'](___arguments.context.pageData, 'Error fetching organizations.', 'failure');

  }

  },
  /* handler:onBeforeEnter */
  /* handler:onEnter */
  async ['onEnter'](___arguments) {
      await BackendlessUI.Functions.Custom['fn_206a4a5e45efaff98b863be918e683dc'](___arguments.context.pageData);

  },
  /* handler:onEnter */
  /* content */
}))

define('./pages/Dashboard/components/0a228ff669212204054b6e79fa890cdd/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      ___arguments.context.pageData['isLogoutModalOpen'] = true;

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/Dashboard/components/31f87d7284997ae19e53ddda03321d3c/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'navbarSelection')) == 'dashboard') {
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('31f87d7284997ae19e53ddda03321d3c'))['color'] = '#3871ff';
  } else {
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('31f87d7284997ae19e53ddda03321d3c'))['color'] = '#000000';
  }

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./pages/Dashboard/components/4d9f094d1731f93894372453a4a37819/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'navbarSelection')) == 'dashboard') {
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('4d9f094d1731f93894372453a4a37819'))['color'] = '#3871ff';
  } else {
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('4d9f094d1731f93894372453a4a37819'))['color'] = '#484848';
  }

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./pages/Dashboard/components/22fbd7decedcd6416231d35b9934e0f7/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'navbarSelection')) == 'userData') {
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('22fbd7decedcd6416231d35b9934e0f7'))['color'] = '#3871ff';
  } else {
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('22fbd7decedcd6416231d35b9934e0f7'))['color'] = '#000000';
  }

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./pages/Dashboard/components/fd61b8a9c7af8609e9eaf255b7b7b189/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'navbarSelection')) == 'userData') {
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('fd61b8a9c7af8609e9eaf255b7b7b189'))['color'] = '#3871ff';
  } else {
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('fd61b8a9c7af8609e9eaf255b7b7b189'))['color'] = '#000000';
  }

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./pages/Dashboard/components/c0452a05df2e617fc2d1b532ad1f09e6/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
      ___arguments.context.pageData['navbarSelection'] = 'userData';
  localStorage.setItem('navbarSelection', JSON.stringify('userData'));
  await BackendlessUI.Functions.Custom['fn_86e80235f72a49fc4fa8cc92d83c4b9e'](___arguments.context.pageData);

  },
  /* handler:onClick */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'currentUser.userRole')) == 'admin') {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('c0452a05df2e617fc2d1b532ad1f09e6', true);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('c0452a05df2e617fc2d1b532ad1f09e6', false);
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./pages/Dashboard/components/4894157cc3396a8c3a8885ecb55a9aa8/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
      ___arguments.context.pageData['navbarSelection'] = 'dashboard';
  ___arguments.context.pageData['refreshResidentGraph'] = true;
  localStorage.setItem('navbarSelection', JSON.stringify('dashboard'));
  await BackendlessUI.Functions.Custom['fn_86e80235f72a49fc4fa8cc92d83c4b9e'](___arguments.context.pageData);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/Dashboard/components/65bce8ccd9dd264c6f3f58cd4e4e2d84/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    var currentUser;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'navbarSelection')) == 'userData' && !(getObjectProperty(___arguments.context.pageData, 'noResultsScreen'))) {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('65bce8ccd9dd264c6f3f58cd4e4e2d84', true);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('65bce8ccd9dd264c6f3f58cd4e4e2d84', false);
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./pages/Dashboard/components/6e5a3178017cc0c3fcedab916b8f3896/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    var currentUser;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  currentUser = (getObjectProperty(___arguments.context.pageData, 'currentUser'));
  if ((getObjectProperty(___arguments.context.pageData, 'navbarSelection')) == 'residentData' && !(getObjectProperty(___arguments.context.pageData, 'noResultsScreen')) && !(getObjectProperty(___arguments.context.pageData, 'isViewDetailsOpen'))) {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('6e5a3178017cc0c3fcedab916b8f3896', true);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('6e5a3178017cc0c3fcedab916b8f3896', false);
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./pages/Dashboard/components/51278f5d59b7d2def311de9cbd62d77f/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  async ['onStyleAssignment'](___arguments) {
    var currentUser;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

/**
 * Describe this function...
 */
async function getCurrentUserInfo() {
  try {
    ___arguments.context.pageData['currentUser'] = ((await Backendless.Data.of('Users').find(Backendless.DataQueryBuilder.create().setWhereClause((['email =','\'',(getObjectProperty(currentUser, 'email')),'\''].join(''))).setRelationsDepth(2).setRelationsPageSize(1).setPageSize(10)))[0]);

  } catch (error) {
    await BackendlessUI.Functions.Custom['fn_77bfd63e533a9b4c5577e5fe31a4b5ca'](___arguments.context.pageData, 'There was a problem loading the user information.', 'failure');

  }
}


  currentUser = (await Backendless.UserService.getCurrentUser(false));
  if (currentUser) {
    if (getObjectProperty(___arguments.context.pageData, 'refreshUser')) {
      await getCurrentUserInfo();
      ___arguments.context.pageData['refreshUser'] = undefined;
    }
  }

  },
  /* handler:onStyleAssignment */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    var classes;


  if ((function () { return BackendlessUI.isProd })()) {
    classes = ['dashboardPage', 'dashboard_display_none'];
  } else {
    classes = ['dashboardPage'];
  }

  return classes

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./pages/Dashboard/components/917563d4b8124614d5a39ca40df6a27d/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
      ___arguments.context.pageData['navbarSelection'] = 'deviceRecords';
  localStorage.setItem('navbarSelection', JSON.stringify('deviceRecords'));
  await BackendlessUI.Functions.Custom['fn_86e80235f72a49fc4fa8cc92d83c4b9e'](___arguments.context.pageData);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/Dashboard/components/57ca5db577a78642ae8f06f6423b9fe8/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
      ___arguments.context.pageData['navbarSelection'] = 'supportGuide';
  localStorage.setItem('navbarSelection', JSON.stringify('supportGuide'));
  await BackendlessUI.Functions.Custom['fn_86e80235f72a49fc4fa8cc92d83c4b9e'](___arguments.context.pageData);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/Dashboard/components/dff394caee5a55ae8ca5005a020ff013/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'navbarSelection')) == 'deviceRecords') {
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('dff394caee5a55ae8ca5005a020ff013'))['color'] = '#3871ff';
  } else {
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('dff394caee5a55ae8ca5005a020ff013'))['color'] = '#000000';
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./pages/Dashboard/components/2e66840e5c0ea8f51855a8936e4cf029/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'navbarSelection')) == 'deviceRecords') {
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('2e66840e5c0ea8f51855a8936e4cf029'))['color'] = '#3871ff';
  } else {
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('2e66840e5c0ea8f51855a8936e4cf029'))['color'] = '#000000';
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./pages/Dashboard/components/6a9cbef46aafb135f7fc7e0bd8f8d785/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'navbarSelection')) == 'supportGuide') {
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('6a9cbef46aafb135f7fc7e0bd8f8d785'))['color'] = '#3871ff';
  } else {
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('6a9cbef46aafb135f7fc7e0bd8f8d785'))['color'] = '#000000';
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./pages/Dashboard/components/c8497b1a481b2f277e03e80eccf6eea9/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'navbarSelection')) == 'supportGuide') {
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('c8497b1a481b2f277e03e80eccf6eea9'))['color'] = '#3871ff';
  } else {
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('c8497b1a481b2f277e03e80eccf6eea9'))['color'] = '#000000';
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./pages/Dashboard/components/5a2910f29c23c8c8f991b0db299ec6e5/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'navbarSelection')) == 'curfew') {
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('5a2910f29c23c8c8f991b0db299ec6e5'))['color'] = '#3871ff';
  } else {
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('5a2910f29c23c8c8f991b0db299ec6e5'))['color'] = '#000000';
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./pages/Dashboard/components/3894d01d324783cf74c1e3522c313696/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'navbarSelection')) == 'curfew') {
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('3894d01d324783cf74c1e3522c313696'))['color'] = '#3871ff';
  } else {
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('3894d01d324783cf74c1e3522c313696'))['color'] = '#000000';
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./pages/Dashboard/components/afef63b4c582819c8f7a33f139433606/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
      ___arguments.context.pageData['navbarSelection'] = 'curfew';
  localStorage.setItem('navbarSelection', JSON.stringify('curfew'));
  await BackendlessUI.Functions.Custom['fn_86e80235f72a49fc4fa8cc92d83c4b9e'](___arguments.context.pageData);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/Dashboard/components/f25656fc6fccd2c1732f6216cb8984db/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    var currentUser;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  currentUser = (getObjectProperty(___arguments.context.pageData, 'currentUser'));
  if ((getObjectProperty(___arguments.context.pageData, 'navbarSelection')) == 'curfew' && !(getObjectProperty(___arguments.context.pageData, 'noResultsScreen')) && !(getObjectProperty(___arguments.context.pageData, 'residentDetailsItem'))) {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('f25656fc6fccd2c1732f6216cb8984db', true);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('f25656fc6fccd2c1732f6216cb8984db', false);
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./pages/Dashboard/components/775a50b3c048a36a9453b5a432ed8e1a/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'currentUser')) {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('775a50b3c048a36a9453b5a432ed8e1a', true);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('775a50b3c048a36a9453b5a432ed8e1a', false);
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./pages/Dashboard/components/08dc45693d9ee7f544ab002bd6cf7c82/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    var currentUser;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  currentUser = (getObjectProperty(___arguments.context.pageData, 'currentUser'));
  if ((getObjectProperty(___arguments.context.pageData, 'navbarSelection')) == 'deviceRecords' && !(getObjectProperty(___arguments.context.pageData, 'noResultsScreen')) && !(getObjectProperty(___arguments.context.pageData, 'isViewResidentDetailsOpen'))) {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('08dc45693d9ee7f544ab002bd6cf7c82', true);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('08dc45693d9ee7f544ab002bd6cf7c82', false);
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./pages/Dashboard/components/3ffbfa061eb6962af60b836dfca4e15d/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'isSupportDrawerOneOpen')) {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('3ffbfa061eb6962af60b836dfca4e15d', true);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('3ffbfa061eb6962af60b836dfca4e15d', false);
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./pages/Dashboard/components/799abf183752a5d17c5e6ce2c3eb381f/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'isSupportDrawerOneOpen')) {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('799abf183752a5d17c5e6ce2c3eb381f', false);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('799abf183752a5d17c5e6ce2c3eb381f', true);
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./pages/Dashboard/components/d1ee767a225b09c8be56b395c7158a5b/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'isSupportDrawerOneOpen')) {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('d1ee767a225b09c8be56b395c7158a5b', true);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('d1ee767a225b09c8be56b395c7158a5b', false);
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./pages/Dashboard/components/5e1eda18457d1214140137363c96422d/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'isSupportDrawerOneOpen')) {
    ___arguments.context.pageData['isSupportDrawerOneOpen'] = false;
  } else {
    ___arguments.context.pageData['isSupportDrawerOneOpen'] = true;
  }

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/Dashboard/components/d6c3b549b345eb750d61e05da694d8f6/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      ___arguments.context.pageData['supportGuideSelection'] = 'admin';

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/Dashboard/components/d14cc726548d75559a5f88d25078b35f/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      ___arguments.context.pageData['supportGuideSelection'] = 'manager';

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/Dashboard/components/f4e3431a8ee5f175dafc9ca1c95e09ef/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    var classes;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  classes = [];
  if ((getObjectProperty(___arguments.context.pageData, 'supportGuideSelection')) == 'admin') {
    addItemToList(classes, 'selected_box_bar');
  }

  return classes

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./pages/Dashboard/components/9bfc106e638c9404219eca1d442c5f7a/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    var classes;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  classes = [];
  if ((getObjectProperty(___arguments.context.pageData, 'supportGuideSelection')) == 'admin') {
    addItemToList(classes, 'selected_box_text');
  }

  return classes

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./pages/Dashboard/components/b654715969d283f96f591c87c71d1aee/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    var classes;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  classes = [];
  if ((getObjectProperty(___arguments.context.pageData, 'supportGuideSelection')) == 'manager') {
    addItemToList(classes, 'selected_box_bar');
  }

  return classes

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./pages/Dashboard/components/a34f8d73b6e88304bf151a46865e54ef/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    var classes;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  classes = [];
  if ((getObjectProperty(___arguments.context.pageData, 'supportGuideSelection')) == 'manager') {
    addItemToList(classes, 'selected_box_text');
  }

  return classes

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./pages/Dashboard/components/9632a44ca7adb2c6b6f8be192ea6af28/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    var currentUser;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  currentUser = (getObjectProperty(___arguments.context.pageData, 'currentUser'));
  if ((getObjectProperty(___arguments.context.pageData, 'navbarSelection')) == 'supportGuide' && !(getObjectProperty(___arguments.context.pageData, 'noResultsScreen')) && !(getObjectProperty(___arguments.context.pageData, 'residentDetailsItem'))) {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('9632a44ca7adb2c6b6f8be192ea6af28', true);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('9632a44ca7adb2c6b6f8be192ea6af28', false);
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./pages/Dashboard/components/f22ffaf8518d876867984e9a50eb1a1d/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'supportGuideSelection')) == 'admin') {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('f22ffaf8518d876867984e9a50eb1a1d', true);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('f22ffaf8518d876867984e9a50eb1a1d', false);
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./pages/Dashboard/components/2c3c38c5109a029b53d41f141b471bfc/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'supportGuideSelection')) == 'manager') {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('2c3c38c5109a029b53d41f141b471bfc', true);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('2c3c38c5109a029b53d41f141b471bfc', false);
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./pages/Dashboard/components/29f332ccc3a2fc6843efbb0cbeb33cf6/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'pillarOneResidentsCount')) {
    if ((getObjectProperty(___arguments.context.pageData, 'pillarOneResidentsCount')) == 1) {
      return (String(getObjectProperty(___arguments.context.pageData, 'pillarOneResidentsCount')) + String(' Resident'));
    } else {
      return (String(getObjectProperty(___arguments.context.pageData, 'pillarOneResidentsCount')) + String(' Residents'));
    }
  } else {
    return '0 Residents';
  }

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/Dashboard/components/59729869aeceb984843641a85a674b7a/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'pillarTwoResidentsCount')) {
    if ((getObjectProperty(___arguments.context.pageData, 'pillarTwoResidentsCount')) == 1) {
      return (String(getObjectProperty(___arguments.context.pageData, 'pillarTwoResidentsCount')) + String(' Resident'));
    } else {
      return (String(getObjectProperty(___arguments.context.pageData, 'pillarTwoResidentsCount')) + String(' Residents'));
    }
  } else {
    return '0 Residents';
  }

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/Dashboard/components/a329d0fb651e45ea3c9eb81d8726ccd2/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'pillarThreeResidentsCount')) {
    if ((getObjectProperty(___arguments.context.pageData, 'pillarThreeResidentsCount')) == 1) {
      return (String(getObjectProperty(___arguments.context.pageData, 'pillarThreeResidentsCount')) + String(' Resident'));
    } else {
      return (String(getObjectProperty(___arguments.context.pageData, 'pillarThreeResidentsCount')) + String(' Residents'));
    }
  } else {
    return '0 Residents';
  }

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/Dashboard/components/018bb38a954e9c2b586eaf580634b101/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'pillarFourResidentsCount')) {
    if ((getObjectProperty(___arguments.context.pageData, 'pillarFourResidentsCount')) == 1) {
      return (String(getObjectProperty(___arguments.context.pageData, 'pillarFourResidentsCount')) + String(' Resident'));
    } else {
      return (String(getObjectProperty(___arguments.context.pageData, 'pillarFourResidentsCount')) + String(' Residents'));
    }
  } else {
    return '0 Residents';
  }

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/Dashboard/components/7eefdfc25b9b19ae5b2ad66965727d87/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'pillarOneResidentsCount')) + (getObjectProperty(___arguments.context.pageData, 'pillarTwoResidentsCount')) + (getObjectProperty(___arguments.context.pageData, 'pillarThreeResidentsCount')) + (getObjectProperty(___arguments.context.pageData, 'pillarFourResidentsCount')) > 0) {
    if ((getObjectProperty(___arguments.context.pageData, 'pillarOneResidentsCount')) + (getObjectProperty(___arguments.context.pageData, 'pillarTwoResidentsCount')) + (getObjectProperty(___arguments.context.pageData, 'pillarThreeResidentsCount')) + (getObjectProperty(___arguments.context.pageData, 'pillarFourResidentsCount')) == 1) {
      return (['(',(getObjectProperty(___arguments.context.pageData, 'pillarOneResidentsCount')) + (getObjectProperty(___arguments.context.pageData, 'pillarTwoResidentsCount')) + (getObjectProperty(___arguments.context.pageData, 'pillarThreeResidentsCount')) + (getObjectProperty(___arguments.context.pageData, 'pillarFourResidentsCount')),' Record)'].join(''));
    } else {
      return (['(',(getObjectProperty(___arguments.context.pageData, 'pillarOneResidentsCount')) + (getObjectProperty(___arguments.context.pageData, 'pillarTwoResidentsCount')) + (getObjectProperty(___arguments.context.pageData, 'pillarThreeResidentsCount')) + (getObjectProperty(___arguments.context.pageData, 'pillarFourResidentsCount')),' Records)'].join(''));
    }
  } else {
    return '0 Records';
  }

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/Dashboard/components/98ec45086304fe081a214845c4788f7a/bundle.js', [], () => ({
  /* content */
  /* handler:datasetsLogic */
  ['datasetsLogic'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return [({ [`label`]: 'Pillar 1',[`data`]: [(getObjectProperty(___arguments.context.pageData, 'pillarOneResidentsCount'))],[`borderColor`]: '#8657B5',[`backgroundColor`]: '#8657B5' }), ({ [`label`]: 'Pillar 2',[`data`]: [(getObjectProperty(___arguments.context.pageData, 'pillarTwoResidentsCount'))],[`borderColor`]: '#2EC8EE',[`backgroundColor`]: '#2EC8EE' }), ({ [`label`]: 'Pillar 3',[`data`]: [(getObjectProperty(___arguments.context.pageData, 'pillarThreeResidentsCount'))],[`borderColor`]: '#FE378C',[`backgroundColor`]: '#FE378C' }), ({ [`label`]: 'Pillar 4',[`data`]: [(getObjectProperty(___arguments.context.pageData, 'pillarFourResidentsCount'))],[`borderColor`]: '#FFB000',[`backgroundColor`]: '#FFB000' })]

  },
  /* handler:datasetsLogic */
  /* content */
}))

define('./pages/Dashboard/components/4d7b07aad852cb1763476d1ecfcd6cb4/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    var currentUser;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'navbarSelection')) == 'dashboard' && !(getObjectProperty(___arguments.context.pageData, 'noResultsScreen'))) {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('4d7b07aad852cb1763476d1ecfcd6cb4', true);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('4d7b07aad852cb1763476d1ecfcd6cb4', false);
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./pages/Dashboard/components/1e1db38d74c56d4fd1a1c9474e99e766/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
      ___arguments.context.pageData['navbarSelection'] = 'residentData';
  localStorage.setItem('navbarSelection', JSON.stringify('residentData'));
  await BackendlessUI.Functions.Custom['fn_86e80235f72a49fc4fa8cc92d83c4b9e'](___arguments.context.pageData);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/Dashboard/components/f5f409b1236aaefd1bd57f616ff7b32f/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'navbarSelection')) == 'residentData') {
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('f5f409b1236aaefd1bd57f616ff7b32f'))['color'] = '#3871ff';
  } else {
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('f5f409b1236aaefd1bd57f616ff7b32f'))['color'] = '#000000';
  }

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./pages/Dashboard/components/b4cae29ff09f11336acc3683bd1a1d62/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'navbarSelection')) == 'residentData') {
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('b4cae29ff09f11336acc3683bd1a1d62'))['color'] = '#3871ff';
  } else {
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('b4cae29ff09f11336acc3683bd1a1d62'))['color'] = '#000000';
  }

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./pages/Dashboard/components/fedb9bcdf95f668b90482e7eb2be2e26/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  async ['onStyleAssignment'](___arguments) {
    var pillarFourResidents, pillarThreeResidents, pillarTwoResidents, pillarOneResidents, resident;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  pillarOneResidents = [];
  pillarTwoResidents = [];
  pillarThreeResidents = [];
  pillarFourResidents = [];
  if (!(getObjectProperty(___arguments.context.pageData, 'refreshGraphForFirstTime'))) {
    ___arguments.context.pageData['refreshResidentGraph'] = true;
    await new Promise(r => setTimeout(r, 100 || 0));
    ___arguments.context.pageData['refreshGraphForFirstTime'] = true;
  }
  if (getObjectProperty(___arguments.context.pageData, 'refreshResidentGraph')) {
    var resident_list = (await Backendless.Data.of('Residents').find(Backendless.DataQueryBuilder.create().setWhereClause((['organizationId.orgName = \'',(getObjectProperty(___arguments.context.pageData, 'currentUser.organizationId.orgName')),'\''].join(''))).setProperties(['organizationId.orgName', 'pillar']).setRelationsDepth(2).setPageSize(100)));
    for (var resident_index in resident_list) {
      resident = resident_list[resident_index];
      if ((getObjectProperty(resident, 'pillar')) == '1') {
        addItemToList(pillarOneResidents, resident);
      } else if ((getObjectProperty(resident, 'pillar')) == '2') {
        addItemToList(pillarTwoResidents, resident);
      } else if ((getObjectProperty(resident, 'pillar')) == '3') {
        addItemToList(pillarThreeResidents, resident);
      } else if ((getObjectProperty(resident, 'pillar')) == '4') {
        addItemToList(pillarFourResidents, resident);
      }
    }
    ___arguments.context.pageData['pillarOneResidentsCount'] = (pillarOneResidents.length);
    ___arguments.context.pageData['pillarTwoResidentsCount'] = (pillarTwoResidents.length);
    ___arguments.context.pageData['pillarThreeResidentsCount'] = (pillarThreeResidents.length);
    ___arguments.context.pageData['pillarFourResidentsCount'] = (pillarFourResidents.length);
    ___arguments.context.pageData['refreshResidentGraph'] = false;
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./pages/ForgotPassword/components/page/bundle.js', [], () => ({
  /* content */
  /* handler:onBeforeEnter */
  async ['onBeforeEnter'](___arguments) {
    var currentUser, error;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

/**
 * Describe this function...
 */
async function setError(error) {
  if (error) {
    ___arguments.context.appData['toastState'] = ({ [`status`]: (getObjectProperty(error, 'status')),[`message`]: (getObjectProperty(error, 'message')) });
  }
}


  currentUser = (await Backendless.UserService.getCurrentUser(false));
  ___arguments.context.pageData['pageVariant'] = 'resetPassword';
  if ((getObjectProperty(___arguments.context.pageData, 'error')) == 'login') {
    await setError(({ [`status`]: 'failure',[`message`]: 'There was an error logging into your dashboard.' }));
  }
  if (currentUser) {
    if ((getObjectProperty(currentUser, 'userRole')) == 'super') {
      (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('Management', undefined);
    } else {
      (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('Dashboard', undefined);
    }
  }

  },
  /* handler:onBeforeEnter */
  /* content */
}))

define('./pages/ForgotPassword/components/db5546014b7940ad8771a98d85f83036/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var isLoading, button, emailInput, uniqueID, user, toastError;

/**
 * Describe this function...
 */
async function isButtonLoading(isLoading) {
  ___arguments.context.pageData['isButtonLoading'] = isLoading;
  button = ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('db5546014b7940ad8771a98d85f83036'));
  button['label'] = (isLoading ? 'Please wait...' : 'Continue');
}

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function generateUUID() {
   const chr4 = () => Math.random().toString(16).slice(-4);
   const chr8 = () => `${chr4()}${chr4()}`;
   const short = () => chr8();
   const long = () => `${chr8()}-${chr4()}-${chr4()}-${chr4()}-${chr8()}${chr4()}`;

   return { short, long, }
}

/**
 * Describe this function...
 */
async function setError(toastError) {
  await isButtonLoading(false);
  await BackendlessUI.Functions.Custom['fn_77bfd63e533a9b4c5577e5fe31a4b5ca'](___arguments.context.pageData, (getObjectProperty(toastError, 'message')), (getObjectProperty(toastError, 'status')));
}


  await isButtonLoading(true);
  emailInput = (getObjectProperty(___arguments.context.pageData, 'email'));
  user = (
      await (async function(userIdentity){
        var userColumns = await Backendless.UserService.describeUserClass()
        var identityColumn = userColumns.find(column => column.identity)
        var whereClause = `${identityColumn.name} = '${userIdentity}'`
        var query = Backendless.DataQueryBuilder.create().setWhereClause(whereClause)
        var users = await Backendless.Data.of(Backendless.User).find(query)

        return users[0]
     })(emailInput)
  );
  if (!(await BackendlessUI.Functions.Custom['fn_36435bc8e7f437df45a6682ebdbf7388'](emailInput))) {
    await setError(({ [`status`]: 'failure',[`message`]: 'Please enter a valid email.' }));
    return ;
  } else if (!user) {
    await setError(({ [`status`]: 'failure',[`message`]: 'Email doesn\'t match our records.' }));
    return ;
  } else {
    try {
      uniqueID = (generateUUID().long());
      await Backendless.Cache.put(emailInput, ({ [`key`]: uniqueID }), 7200);
      await Backendless.Cache.expireIn(emailInput, 7200);
      await BackendlessUI.Functions.Custom['fn_67c6f852cf5ce414b74b8f0b9401b852']('engineers@cyberdive.co', 'Cyber Dive Team', emailInput, 'd-f5785c08d0cc40c9812079f53ef167f6', ({ [`link`]: (await BackendlessUI.Functions.Custom['fn_a65e994797048d593245ed62a80fb31f']('forgotPassword', ({ [`user_email`]: emailInput,[`key`]: uniqueID }))) }));
      await isButtonLoading(false);
      ___arguments.context.pageData['pageVariant'] = 'success';

    } catch (error) {
      await setError(({ [`status`]: 'failure',[`message`]: 'There was an error sending the password recovery email.' }));

    } finally {
      await isButtonLoading(false);

    }
  }

  },
  /* handler:onClick */
  /* handler:onStyleAssignment */
  async ['onStyleAssignment'](___arguments) {
    var isDisabled, emailInput, isButtonLoading;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  emailInput = (getObjectProperty(___arguments.context.pageData, 'email'));
  isButtonLoading = (getObjectProperty(___arguments.context.pageData, 'isButtonLoading'));
  if (isButtonLoading) {
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('db5546014b7940ad8771a98d85f83036'))['background-color'] = 'rgba(56, 113, 255, 0.38)';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('db5546014b7940ad8771a98d85f83036'))['color'] = 'rgba(56, 113, 255, 0.5)';
    ___arguments.context.pageData['isSubmitButtonDisabled'] = true;
  } else {
    isDisabled = false;
    if (!emailInput || (await BackendlessUI.Functions.Custom['fn_a7ad4c1fd5d18ca6bff3eb611aeee2ac'](emailInput))) {
      isDisabled = true;
    }
    if (isDisabled) {
      ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('db5546014b7940ad8771a98d85f83036'))['background-color'] = 'rgba(56, 113, 255, 0.38)';
      ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('db5546014b7940ad8771a98d85f83036'))['color'] = 'rgba(56, 113, 255, 0.5)';
      ___arguments.context.pageData['isSubmitButtonDisabled'] = true;
    } else {
      ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('db5546014b7940ad8771a98d85f83036'))['background-color'] = '#3871FF';
      ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('db5546014b7940ad8771a98d85f83036'))['color'] = '#FFFFFF';
      ___arguments.context.pageData['isSubmitButtonDisabled'] = false;
    }
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./pages/ForgotPassword/components/98819e5abc884848ea0f2d26ffd3d031/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'pageVariant')) == 'resetPassword') {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('98819e5abc884848ea0f2d26ffd3d031', true);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('98819e5abc884848ea0f2d26ffd3d031', false);
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./pages/ForgotPassword/components/79857a9dfa0154cb4f77a2477d3ac66f/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'pageVariant')) == 'success') {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('79857a9dfa0154cb4f77a2477d3ac66f', true);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('79857a9dfa0154cb4f77a2477d3ac66f', false);
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./pages/ForgotPassword/components/9401959f40491e17a85632bd153e50fc/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('Login', undefined);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/Login/components/page/bundle.js', [], () => ({
  /* content */
  /* handler:onBeforeEnter */
  async ['onBeforeEnter'](___arguments) {
    var currentUser, error;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

/**
 * Describe this function...
 */
async function setError(error) {
  if (error) {
    ___arguments.context.appData['toastState'] = ({ [`status`]: (getObjectProperty(error, 'status')),[`message`]: (getObjectProperty(error, 'message')) });
  }
}


  ___arguments.context.pageData['viewingPassword'] = undefined;
  currentUser = (await Backendless.UserService.getCurrentUser(false));
  if ((getObjectProperty(___arguments.context.pageData, 'error')) == 'login') {
    await setError(({ [`status`]: 'failure',[`message`]: 'There was an error logging into your dashboard.' }));
  }
  if (currentUser) {
    if ((getObjectProperty(currentUser, 'userRole')) == 'super') {
      (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('Management', undefined);
    } else {
      (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('Dashboard', undefined);
    }
  }

  },
  /* handler:onBeforeEnter */
  /* content */
}))

define('./pages/Login/components/6d7b09fc8c04707fa6291e69b80c05d1/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('ForgotPassword', undefined);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/Login/components/db5546014b7940ad8771a98d85f83036/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var username, isLoading, button, toastError, currentUser, updatedUser, loginAttempts, user, password;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

/**
 * Describe this function...
 */
async function isDeactivated() {
  if ((getObjectProperty((
      await (async function(userIdentity){
        var userColumns = await Backendless.UserService.describeUserClass()
        var identityColumn = userColumns.find(column => column.identity)
        var whereClause = `${identityColumn.name} = '${userIdentity}'`
        var query = Backendless.DataQueryBuilder.create().setWhereClause(whereClause)
        var users = await Backendless.Data.of(Backendless.User).find(query)

        return users[0]
     })(username)
  ), 'status')) == 'DEACTIVATED') {
    return true;
  }
}

/**
 * Describe this function...
 */
async function isButtonLoading(isLoading) {
  ___arguments.context.pageData['isButtonLoading'] = isLoading;
  button = ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('db5546014b7940ad8771a98d85f83036'));
  button['label'] = (isLoading ? 'Please wait...' : 'LOG IN');
}

/**
 * Describe this function...
 */
async function setError(toastError) {
  await isButtonLoading(false);
  await BackendlessUI.Functions.Custom['fn_77bfd63e533a9b4c5577e5fe31a4b5ca'](___arguments.context.pageData, (getObjectProperty(toastError, 'message')), (getObjectProperty(toastError, 'status')));
}

/**
 * Describe this function...
 */
async function login() {
  try {
    currentUser = (await Backendless.UserService.login( username, password, true  ));
    if (currentUser) {
      updatedUser = (await Backendless.UserService.update( new Backendless.User(({ [`objectId`]: (getObjectProperty(user, 'objectId')),[`loginAttempts`]: 0 })) ));
    }

  } catch (error) {
    loginAttempts = 1 + loginAttempts;
    if (loginAttempts == 2) {
      await setError(({ [`status`]: 'failure',[`message`]: 'Failed to login. You have 1 attempt left.' }));
    } else {
      await setError(({ [`status`]: 'failure',[`message`]: ['Failed to login. You have ',3 - loginAttempts,' attempts left.'].join('') }));
    }
    if (3 - loginAttempts == 0) {
      await Backendless.UserService.update( new Backendless.User(({ [`objectId`]: (getObjectProperty(user, 'objectId')),[`loginAttempts`]: loginAttempts,[`status`]: 'LOCKED' })) );
    } else {
      await Backendless.UserService.update( new Backendless.User(({ [`objectId`]: (getObjectProperty(user, 'objectId')),[`loginAttempts`]: loginAttempts })) );
    }
    await isButtonLoading(false);

  }
}


  await isButtonLoading(true);
  username = (getObjectProperty(___arguments.context.pageData, 'username'));
  password = (getObjectProperty(___arguments.context.pageData, 'password'));
  if (username && password) {
    try {
      user = (
          await (async function(userIdentity){
            var userColumns = await Backendless.UserService.describeUserClass()
            var identityColumn = userColumns.find(column => column.identity)
            var whereClause = `${identityColumn.name} = '${userIdentity}'`
            var query = Backendless.DataQueryBuilder.create().setWhereClause(whereClause)
            var users = await Backendless.Data.of(Backendless.User).find(query)

            return users[0]
         })(username)
      );
      if (user) {
        loginAttempts = (getObjectProperty(user, 'loginAttempts'));
        if (loginAttempts >= 3) {
          await setError(({ [`status`]: 'failure',[`message`]: 'This account has been locked. Please contact customer support.' }));
        } else {
          if (await isDeactivated()) {
            await setError(({ [`status`]: 'failure',[`message`]: 'This account has been deactivated. Please contact support or your admin.' }));
          } else {
            await login();
            if (updatedUser) {
              if ((getObjectProperty(currentUser, 'userRole')) == 'super') {
                (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('Management', undefined);
              } else {
                (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('Dashboard', ({ [`testing`]: 'hello' }));
              }
            }
          }
        }
      } else {
        await setError(({ [`status`]: 'failure',[`message`]: 'Failed to login. Incorrect user name or password.' }));
      }

    } catch (error) {
      await setError(({ [`status`]: 'failure',[`message`]: 'Failed to login. Incorrect user name or password.' }));

    }
  } else {
    await setError(({ [`status`]: 'failure',[`message`]: 'Please enter your email and password to login.' }));
  }

  },
  /* handler:onClick */
  /* handler:onStyleAssignment */
  async ['onStyleAssignment'](___arguments) {
    var isDisabled, password, username, isButtonLoading;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  username = (getObjectProperty(___arguments.context.pageData, 'username'));
  password = (getObjectProperty(___arguments.context.pageData, 'password'));
  isButtonLoading = (getObjectProperty(___arguments.context.pageData, 'isButtonLoading'));
  if (isButtonLoading) {
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('db5546014b7940ad8771a98d85f83036'))['background-color'] = 'rgba(56, 113, 255, 0.38)';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('db5546014b7940ad8771a98d85f83036'))['color'] = 'rgba(56, 113, 255, 0.5)';
    ___arguments.context.pageData['isLoginButtonDisabled'] = true;
  } else {
    isDisabled = false;
    if (!username || (await BackendlessUI.Functions.Custom['fn_a7ad4c1fd5d18ca6bff3eb611aeee2ac'](username))) {
      isDisabled = true;
    } else if (!password || (await BackendlessUI.Functions.Custom['fn_a7ad4c1fd5d18ca6bff3eb611aeee2ac'](password))) {
      isDisabled = true;
    }
    if (isDisabled) {
      ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('db5546014b7940ad8771a98d85f83036'))['background-color'] = 'rgba(56, 113, 255, 0.38)';
      ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('db5546014b7940ad8771a98d85f83036'))['color'] = 'rgba(56, 113, 255, 0.5)';
      ___arguments.context.pageData['isLoginButtonDisabled'] = true;
    } else {
      ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('db5546014b7940ad8771a98d85f83036'))['background-color'] = '#3871FF';
      ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('db5546014b7940ad8771a98d85f83036'))['color'] = '#FFFFFF';
      ___arguments.context.pageData['isLoginButtonDisabled'] = false;
    }
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./pages/Login/components/d416e33637324fbaab9151cf89fd0f22/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'viewingPassword')) {
    ___arguments.context.pageData['viewingPassword'] = false;
  } else {
    ___arguments.context.pageData['viewingPassword'] = true;
  }

  },
  /* handler:onClick */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'viewingPassword')) {
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('08a637cb5f6979e7ff4329cdbf129917'))['color'] = '#3871FF';
    ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('b4edd272bd7fb7f6f22eae3525232662'))['type'] = 'input';
  } else {
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('08a637cb5f6979e7ff4329cdbf129917'))['color'] = 'rgb(0,0,0,0.38)';
    ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('b4edd272bd7fb7f6f22eae3525232662'))['type'] = 'password';
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./pages/Management/components/page/bundle.js', [], () => ({
  /* content */
  /* handler:onBeforeEnter */
  async ['onBeforeEnter'](___arguments) {
    var activeOrganizations, currentUser, item;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  currentUser = (await Backendless.UserService.getCurrentUser(false));
  if (!currentUser) {
    (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('Login', undefined);
  } else {
    ___arguments.context.pageData['currentUser'] = currentUser;
  }
  if ((getObjectProperty(___arguments.context.pageData, 'currentUser.userRole')) != 'super') {
    (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('Dashboard', undefined);
  }
  ___arguments.context.pageData['toastState'] = ({  });
  ___arguments.context.pageData['isUserMenuOpen'] = false;
  ___arguments.context.pageData['navbarSelection'] = 'organizations';
  ___arguments.context.pageData['selectedItem'] = null;
  ___arguments.context.pageData['isDeactivatingOrganziation'] = false;
  ___arguments.context.pageData['isFiltering'] = true;
  ___arguments.context.pageData['isButtonLoading'] = false;
  ___arguments.context.pageData['selectedItemArrayOrg'] = [];
  ___arguments.context.pageData['selectedItemArrayUser'] = [];
  ___arguments.context.pageData['userRecordsUserRoleOptions'] = [({ [`label`]: 'Administrator',[`value`]: 'admin' }), ({ [`label`]: 'Case Manager',[`value`]: 'manager' })];
  ___arguments.context.pageData['organizationRecordsStateOptions'] = (await Promise.all(('AL AK AZ AR CA CO CT DE FL GA HI ID IL IN IA KS KY LA ME MD MA MI MN MS MO MT NE NV NH NJ NM NY NC ND OH OK OR PA RI SC SD TN TX UT VT VA WA WV WI WY'.split(' ')).map(async item => {; return ({ [`label`]: item,[`value`]: item });})));
  try {
    activeOrganizations = (await Promise.all((await Backendless.Data.of('Organizations').find(Backendless.DataQueryBuilder.create().setWhereClause('status = \'ACTIVE\'').setProperties(['orgName', 'objectId']).setPageSize(100))).map(async item => {; return ({ [`label`]: (getObjectProperty(item, 'orgName')),[`value`]: (getObjectProperty(item, 'objectId')) });})));
    ___arguments.context.pageData['organizationOptions'] = activeOrganizations;

  } catch (error) {
    await BackendlessUI.Functions.Custom['fn_77bfd63e533a9b4c5577e5fe31a4b5ca'](___arguments.context.pageData, 'Error fetching organizations.', 'failure');

  }

  },
  /* handler:onBeforeEnter */
  /* handler:onEnter */
  async ['onEnter'](___arguments) {
      await BackendlessUI.Functions.Custom['fn_206a4a5e45efaff98b863be918e683dc'](___arguments.context.pageData);
  await Backendless.Messaging.publish('default', 'Hello this is a test', new Backendless.PublishOptions({ 'headers': Object.assign({  }, {})  }), new Backendless.DeliveryOptions({ 'pushSinglecast': ['dz0DuCQ7TBe1GUyM5BEgFJ:APA91bFmYxRefB2cMPLoy18DLfxQtfjt6YwA0a6rUSVoJS32ohkQUaoARCAtO7FYfAZzYTqR4IVF3NldXHpZwL0sjhvbzgtwssXdZ5DWBZfXxqP7oX2fKAYVpEzvHEEVmWgHANVh6f0C']  }));

  },
  /* handler:onEnter */
  /* content */
}))

define('./pages/Management/components/0a228ff669212204054b6e79fa890cdd/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      ___arguments.context.pageData['isLogoutModalOpen'] = true;

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/Management/components/31f87d7284997ae19e53ddda03321d3c/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'navbarSelection')) == 'organizations') {
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('31f87d7284997ae19e53ddda03321d3c'))['color'] = '#3871ff';
  } else {
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('31f87d7284997ae19e53ddda03321d3c'))['color'] = '#000000';
  }

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./pages/Management/components/4d9f094d1731f93894372453a4a37819/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'navbarSelection')) == 'organizations') {
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('4d9f094d1731f93894372453a4a37819'))['color'] = '#3871ff';
  } else {
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('4d9f094d1731f93894372453a4a37819'))['color'] = '#484848';
  }

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./pages/Management/components/22fbd7decedcd6416231d35b9934e0f7/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'navbarSelection')) == 'userData') {
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('22fbd7decedcd6416231d35b9934e0f7'))['color'] = '#3871ff';
  } else {
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('22fbd7decedcd6416231d35b9934e0f7'))['color'] = '#000000';
  }

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./pages/Management/components/fd61b8a9c7af8609e9eaf255b7b7b189/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'navbarSelection')) == 'userData') {
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('fd61b8a9c7af8609e9eaf255b7b7b189'))['color'] = '#3871ff';
  } else {
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('fd61b8a9c7af8609e9eaf255b7b7b189'))['color'] = '#000000';
  }

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./pages/Management/components/c0452a05df2e617fc2d1b532ad1f09e6/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
      ___arguments.context.pageData['navbarSelection'] = 'userData';
  await BackendlessUI.Functions.Custom['fn_86e80235f72a49fc4fa8cc92d83c4b9e'](___arguments.context.pageData);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/Management/components/4894157cc3396a8c3a8885ecb55a9aa8/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
      ___arguments.context.pageData['navbarSelection'] = 'organizations';
  await BackendlessUI.Functions.Custom['fn_86e80235f72a49fc4fa8cc92d83c4b9e'](___arguments.context.pageData);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/Management/components/1122cbfb4556cb323d49f3d8f3b55023/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (!(getObjectProperty(___arguments.context.pageData, 'isViewDetailsOpen')) && (getObjectProperty(___arguments.context.pageData, 'navbarSelection')) == 'organizations' && !(getObjectProperty(___arguments.context.pageData, 'noResultsScreen'))) {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('1122cbfb4556cb323d49f3d8f3b55023', true);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('1122cbfb4556cb323d49f3d8f3b55023', false);
  }

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./pages/Management/components/39677fcdcabe89bcccbf7579ed510819/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (!(getObjectProperty(___arguments.context.pageData, 'isViewDetailsOpen')) && (getObjectProperty(___arguments.context.pageData, 'navbarSelection')) == 'userData' && !(getObjectProperty(___arguments.context.pageData, 'noResultsScreen'))) {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('39677fcdcabe89bcccbf7579ed510819', true);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('39677fcdcabe89bcccbf7579ed510819', false);
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./pages/Management/components/06286e4131dfb48ef4cb2c126707d922/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    var isActionDrawerOpen;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  isActionDrawerOpen = (getObjectProperty(___arguments.context.pageData, 'isOrganizationRecordActionDrawerOpen'));
  if (isActionDrawerOpen) {
    ___arguments.context.pageData['isOrganizationRecordActionDrawerOpen'] = false;
    ___arguments.context.pageData['userClickedOrgAction'] = false;
  } else {
    ___arguments.context.pageData['isOrganizationRecordActionDrawerOpen'] = true;
    ___arguments.context.pageData['userClickedOrgAction'] = true;
  }

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/Management/components/b46596fe564be412cdfe918acca19025/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    var tableCount, changedValue;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'orgTableCount')) {
    return (['Showing ',(getObjectProperty(___arguments.context.pageData, 'orgTableCount')),' records'].join(''));
  } else if ((getObjectProperty(___arguments.context.pageData, 'orgTableCount')) == 0) {
    return 'Showing 0 records';
  }

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/Management/components/3a48b32dc94fef52abe22bd7670fd1ca/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    var isActionDrawerOpen, selectedItemArrayOrg;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  isActionDrawerOpen = (getObjectProperty(___arguments.context.pageData, 'isOrganizationRecordActionDrawerOpen'));
  if (isActionDrawerOpen) {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('3a48b32dc94fef52abe22bd7670fd1ca', true);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('3a48b32dc94fef52abe22bd7670fd1ca', false);
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./pages/Management/components/63974e9996643282be822cc7c0f367f5/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    var isSame, item, selectedItemArrayOrg;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  selectedItemArrayOrg = (getObjectProperty(___arguments.context.pageData, 'selectedItemArrayOrg'));
  if (selectedItemArrayOrg && selectedItemArrayOrg.length == 1) {
    if ((getObjectProperty((selectedItemArrayOrg[0]), 'inviteStatus')) != 'PENDING' && (getObjectProperty((selectedItemArrayOrg[0]), 'status')) == 'ACTIVE') {
      ___arguments.context.pageData['isConfirmationModalOpen'] = true;
      ___arguments.context.pageData['confirmationOption'] = 'deactivateOrganization';
    }
  } else if (selectedItemArrayOrg && selectedItemArrayOrg.length > 1) {
    isSame = true;
    for (var item_index in selectedItemArrayOrg) {
      item = selectedItemArrayOrg[item_index];
      if ((getObjectProperty(item, 'status')) != 'ACTIVE' || (getObjectProperty(item, 'inviteStatus')) == 'PENDING') {
        isSame = false;
      }
    }
    if (isSame) {
      ___arguments.context.pageData['isConfirmationModalOpen'] = true;
      ___arguments.context.pageData['confirmationOption'] = 'deactivateOrganization';
    }
  }

  },
  /* handler:onClick */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    var isSame, item, selectedItemArrayOrg;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  selectedItemArrayOrg = (getObjectProperty(___arguments.context.pageData, 'selectedItemArrayOrg'));
  if (selectedItemArrayOrg && selectedItemArrayOrg.length == 1) {
    if ((getObjectProperty((selectedItemArrayOrg[0]), 'inviteStatus')) != 'PENDING' && (getObjectProperty((selectedItemArrayOrg[0]), 'status')) == 'ACTIVE') {
      ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('cfe1c86275f5c1a1f558c8f17e3fa924'))['color'] = '#000000';
      ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('1a085dd96d45d2ad038a041a812103d4'))['color'] = '#000000';
    }
  } else if (selectedItemArrayOrg && selectedItemArrayOrg.length > 1) {
    isSame = true;
    for (var item_index in selectedItemArrayOrg) {
      item = selectedItemArrayOrg[item_index];
      if ((getObjectProperty(item, 'status')) != 'ACTIVE' || (getObjectProperty(item, 'inviteStatus')) == 'PENDING') {
        isSame = false;
      }
    }
    if (isSame) {
      ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('cfe1c86275f5c1a1f558c8f17e3fa924'))['color'] = '#000000';
      ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('1a085dd96d45d2ad038a041a812103d4'))['color'] = '#000000';
    }
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./pages/Management/components/b9984198ef0b752fc61177cbcb933da2/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    var isSame, item, selectedItemArrayOrg;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  selectedItemArrayOrg = (getObjectProperty(___arguments.context.pageData, 'selectedItemArrayOrg'));
  if (selectedItemArrayOrg && selectedItemArrayOrg.length == 1) {
    if ((getObjectProperty((selectedItemArrayOrg[0]), 'status')) == 'DEACTIVATED') {
      ___arguments.context.pageData['isConfirmationModalOpen'] = true;
      ___arguments.context.pageData['confirmationOption'] = 'activateOrganization';
    }
  } else if (selectedItemArrayOrg && selectedItemArrayOrg.length > 1) {
    isSame = true;
    for (var item_index in selectedItemArrayOrg) {
      item = selectedItemArrayOrg[item_index];
      if ((getObjectProperty(item, 'status')) != 'DEACTIVATED') {
        isSame = false;
      }
    }
    if (isSame) {
      ___arguments.context.pageData['isConfirmationModalOpen'] = true;
      ___arguments.context.pageData['confirmationOption'] = 'activateOrganization';
    }
  }

  },
  /* handler:onClick */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    var isSame, item, selectedItemArrayOrg;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  selectedItemArrayOrg = (getObjectProperty(___arguments.context.pageData, 'selectedItemArrayOrg'));
  if (selectedItemArrayOrg && selectedItemArrayOrg.length == 1) {
    if ((getObjectProperty((selectedItemArrayOrg[0]), 'status')) == 'DEACTIVATED') {
      ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('7cfbefef18f8002664a9d737468f8cdf'))['color'] = '#000000';
      ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('33b6678431f7453372d42f6910c97a94'))['color'] = '#000000';
    }
  } else if (selectedItemArrayOrg && selectedItemArrayOrg.length > 1) {
    isSame = true;
    for (var item_index in selectedItemArrayOrg) {
      item = selectedItemArrayOrg[item_index];
      if ((getObjectProperty(item, 'status')) != 'DEACTIVATED') {
        isSame = false;
      }
    }
    if (isSame) {
      ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('7cfbefef18f8002664a9d737468f8cdf'))['color'] = '#000000';
      ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('33b6678431f7453372d42f6910c97a94'))['color'] = '#000000';
    } else {
    }
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./pages/Management/components/9c187fee8dcfe2fcaaded09e38f39983/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var item, selectedItem, menuOptionSelection, currentUser;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function subsequenceFromStartLast(sequence, at1) {
  var start = at1;
  var end = sequence.length - 1 + 1;
  return sequence.slice(start, end);
}


  selectedItem = (getObjectProperty(___arguments.context.pageData, 'selectedItemArrayOrg'))[0];
  currentUser = (getObjectProperty(___arguments.context.pageData, 'currentUser'));
  menuOptionSelection = (getObjectProperty(___arguments.context.pageData, 'menuOptionSelection'));
  if ((getObjectProperty(___arguments.context.pageData, 'selectedItemArrayOrg')) && (getObjectProperty(___arguments.context.pageData, 'selectedItemArrayOrg')).length == 1) {
    ___arguments.context.pageData['scrollToTopSelectedItem'] = true;
    ___arguments.context.pageData['recordTableVariant'] = 'organization';
    ___arguments.context.pageData['isSelectedItemModalOpen'] = true;
    ___arguments.context.pageData['selectedImage'] = undefined;
    ___arguments.context.pageData['selectedItemOrgName'] = (getObjectProperty(selectedItem, 'orgName'));
    ___arguments.context.pageData['selectedItemOrgBillingName'] = (getObjectProperty(selectedItem, 'billingName'));
    ___arguments.context.pageData['selectedItemOrgBillingEmail'] = undefined;
    ___arguments.context.pageData['selectedItemOrgBillingPhone'] = (await BackendlessUI.Functions.Custom['fn_f282814277b3c0aa13f33d3b2cda111b']((subsequenceFromStartLast((getObjectProperty(selectedItem, 'billingPhone')), 2)), 'create'));
    ___arguments.context.pageData['selectedItemOrgTaxId'] = (getObjectProperty(selectedItem, 'taxId'));
    ___arguments.context.pageData['selectedItemOrgWebsite'] = undefined;
    ___arguments.context.pageData['selectedItemOrgAddress'] = undefined;
    ___arguments.context.pageData['selectedItemOrgCity'] = undefined;
    ___arguments.context.pageData['orgStateValue'] = undefined;
    ___arguments.context.pageData['selectedItemOrgZipCode'] = (getObjectProperty(selectedItem, 'zipCode'));
    ___arguments.context.pageData['isEditOrgButtonDisabled'] = ({ [`orgName`]: true,[`address`]: true,[`website`]: true,[`taxId`]: true,[`billingName`]: true,[`billingPhone`]: true,[`billingEmail`]: true,[`state`]: true,[`zipCode`]: true,[`city`]: true,[`selectedImage`]: true });
  }

  },
  /* handler:onClick */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    var selectedItemArrayOrg;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  selectedItemArrayOrg = (getObjectProperty(___arguments.context.pageData, 'selectedItemArrayOrg'));
  if (selectedItemArrayOrg && selectedItemArrayOrg.length == 1) {
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('22df31328093aff17c17e6d40868fb0f'))['color'] = '#000000';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('bb486b34e31214071d7a2de9df1db3bf'))['color'] = '#000000';
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./pages/Management/components/e5494c6b73a1dd11216ebdea401ee5bf/bundle.js', [], () => ({
  /* content */
  /* handler:onPageChange */
  async ['onPageChange'](___arguments) {
    var changedValue;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  ___arguments.context.pageData['isTableLoading'] = true;
  (function (componentUid, listItems) { ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('e53fd57a686d1999fca1b9448b583382', []);
  changedValue = (getObjectProperty(___arguments.context.pageData, 'changedValueOrg'));
  (function (componentUid, listItems) { ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('e53fd57a686d1999fca1b9448b583382', (await Backendless.Data.of('Organizations').find(Backendless.DataQueryBuilder.create().setWhereClause((await BackendlessUI.Functions.Custom['fn_5da19a0685ee9e7b97339f9e3dd69c3b']('organization', null, null, changedValue, null, null, null))).setSortBy('created asc').setPageSize(10).setOffset((10 * ___arguments['currentPage'] - 10)))));
  if (!(getObjectProperty(___arguments.context.pageData, 'pageEnter'))) {
    ___arguments.context.pageData['pageEnter'] = true;
    ___arguments.context.pageData['orgTableCount'] = (await Backendless.Data.of('Organizations').getObjectCount(Backendless.DataQueryBuilder.create().setWhereClause((await BackendlessUI.Functions.Custom['fn_5da19a0685ee9e7b97339f9e3dd69c3b']('organization', null, null, changedValue, null, null, null)))));
    if ((getObjectProperty(___arguments.context.pageData, 'orgTableCount')) == 0) {
      ___arguments.context.pageData['noResultsScreen'] = true;
    }
  }
  ___arguments.context.pageData['isTableLoading'] = false;

  },
  /* handler:onPageChange */
  /* handler:PageCountLogic */
  ['PageCountLogic'](___arguments) {
    var orgSearchCount, changedValue, currentUser;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'orgTableCount')) {
    if ((getObjectProperty(___arguments.context.pageData, 'orgTableCount')) > 10) {
      return (Math.ceil((getObjectProperty(___arguments.context.pageData, 'orgTableCount')) / 10));
    } else {
      return 1;
    }
  }

  },
  /* handler:PageCountLogic */
  /* content */
}))

define('./pages/Management/components/7a8b1208417a657feb319f73f7167d9e/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var item;

/**
 * Describe this function...
 */
async function clearInputs() {
  ___arguments.context.pageData['imageName'] = undefined;
  ___arguments.context.pageData['organizationName'] = undefined;
  ___arguments.context.pageData['logoPicture'] = undefined;
  ___arguments.context.pageData['streetAddress'] = undefined;
  ___arguments.context.pageData['city'] = undefined;
  ___arguments.context.pageData['state'] = undefined;
  ___arguments.context.pageData['zipCode'] = undefined;
  ___arguments.context.pageData['websiteLink'] = undefined;
  ___arguments.context.pageData['billingEmail'] = undefined;
  ___arguments.context.pageData['billingPhone'] = undefined;
  ___arguments.context.pageData['billingName'] = undefined;
  ___arguments.context.pageData['taxId'] = undefined;
  ___arguments.context.pageData['addOrganizationStep'] = 1;
  ___arguments.context.pageData['orgNameError'] = false;
  ___arguments.context.pageData['websiteError'] = false;
  ___arguments.context.pageData['zipCodeError'] = false;
  ___arguments.context.pageData['taxIdError'] = undefined;
  ___arguments.context.pageData['billingEmailError'] = false;
  ___arguments.context.pageData['billingPhoneError'] = false;
}


  ___arguments.context.pageData['isAddRecordModalOpen'] = true;
  ___arguments.context.pageData['addOrganizationStep'] = 1;
  ___arguments.context.pageData['recordTableVariant'] = 'organization';
  await clearInputs();
  (function (componentUid, options) { ___arguments.context.getComponentByUid(componentUid).options = options })('07058b5df43c69258fa2ecb64e023e34', (await Promise.all(('AK,AL,AR,AZ,CA,CO,CT,DE,FL,GA,HI,IA,ID,IL,IN,KS,KY,LA,MA,MD,ME,MI,MN,MO,MS,MT,NC,ND,NE,NH,NJ,NM,NV,NY,OH,OK,OR,PA,RI,SC,SD,TN,TX,UT,VA,VT,WA,WI,WV,WY'.split(',')).map(async item => {; return ({ [`value`]: item,[`label`]: item });}))));

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/Management/components/fbc785d3931ac5a38061bbf35ad558b3/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  async ['onChange'](___arguments) {
    var orgSearchResults, changedValued, currentUser, changedValue;


  ___arguments.context.pageData['isTableLoading'] = true;
  ___arguments.context.pageData['changedValueOrg'] = ___arguments.value;
  (function (componentUid, listItems) { ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('e53fd57a686d1999fca1b9448b583382', []);
  (function (componentUid, listItems) { ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('e53fd57a686d1999fca1b9448b583382', (await Backendless.Data.of('Organizations').find(Backendless.DataQueryBuilder.create().setWhereClause((await BackendlessUI.Functions.Custom['fn_5da19a0685ee9e7b97339f9e3dd69c3b']('organization', null, null, ___arguments.value, null, null))).setSortBy('created asc').setPageSize(10))));
  ___arguments.context.pageData['orgTableCount'] = (await Backendless.Data.of('Organizations').getObjectCount(Backendless.DataQueryBuilder.create().setWhereClause((await BackendlessUI.Functions.Custom['fn_5da19a0685ee9e7b97339f9e3dd69c3b']('organization', null, null, ___arguments.value, null, null)))));
  ___arguments.context.pageData['isTableLoading'] = false;

  },
  /* handler:onChange */
  /* content */
}))

define('./pages/Management/components/bedeb0f286cae93f0a72f21a81ee8fe8/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ([((new Date((getObjectProperty(___arguments.context.getComponentDataStoreByUid('f3b59b0135057a666ac52e26fa9563e2'), 'created')))).toLocaleDateString()),', ',((new Date((getObjectProperty(___arguments.context.getComponentDataStoreByUid('f3b59b0135057a666ac52e26fa9563e2'), 'created')))).toLocaleTimeString())].join(''))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/Management/components/d0f3b942abb79f4deb1d0d598a791a1f/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (getObjectProperty(___arguments.context.getComponentDataStoreByUid('f3b59b0135057a666ac52e26fa9563e2'), 'billingEmail'))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/Management/components/be85ed0c5b8edf53ef1649a64cfbffba/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (await BackendlessUI.Functions.Custom['fn_9807a958b9f47818617415e4438b5e47']((getObjectProperty(___arguments.context.getComponentDataStoreByUid('f3b59b0135057a666ac52e26fa9563e2'), 'orgName')), 16))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/Management/components/a471323c4b73e22b26e974c6c8cc75d3/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    var isTableLoading;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  isTableLoading = (getObjectProperty(___arguments.context.pageData, 'isTableLoading'));
  if (isTableLoading) {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('a471323c4b73e22b26e974c6c8cc75d3', true);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('a471323c4b73e22b26e974c6c8cc75d3', false);
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./pages/Management/components/f3b59b0135057a666ac52e26fa9563e2/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      ___arguments.context.pageData['selectedItemOrganization'] = ___arguments.context.dataModel;

  },
  /* handler:onClick */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'selectedItemOrganization.objectId')) == (getObjectProperty(___arguments.context.dataModel, 'objectId')) && (getObjectProperty(___arguments.context.pageData, 'refreshSelectedItem'))) {
    if ((getObjectProperty(___arguments.context.pageData, 'navbarSelection')) == 'organizations') {
      ___arguments.context.pageData['selectedItemOrganization'] = ___arguments.context.dataModel;
    }
    ___arguments.context.pageData['refreshSelectedItem'] = false;
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./pages/Management/components/115cc499ccdccd29354b9dbfe8ea46c7/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  async ['onStyleAssignment'](___arguments) {
    var refreshOrgTable, changedValue;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

/**
 * Describe this function...
 */
async function refreshOrgTable2() {
  ___arguments.context.pageData['isTableLoading'] = true;
  (function (componentUid, listItems) { ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('e53fd57a686d1999fca1b9448b583382', []);
  changedValue = (getObjectProperty(refreshOrgTable, 'changedValueOrg'));
  (function (componentUid, listItems) { ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('e53fd57a686d1999fca1b9448b583382', (await Backendless.Data.of('Organizations').find(Backendless.DataQueryBuilder.create().setWhereClause((await BackendlessUI.Functions.Custom['fn_5da19a0685ee9e7b97339f9e3dd69c3b']('organization', null, null, changedValue, null, null, null))).setSortBy('created asc').setPageSize(10))));
  ___arguments.context.pageData['orgTableCount'] = (await Backendless.Data.of('Organizations').getObjectCount(Backendless.DataQueryBuilder.create().setWhereClause((await BackendlessUI.Functions.Custom['fn_5da19a0685ee9e7b97339f9e3dd69c3b']('organization', null, null, changedValue, null, null, null)))));
  ___arguments.context.pageData['selectedItemArrayOrg'] = [];
  ___arguments.context.pageData['isTableLoading'] = false;
  ___arguments.context.pageData['refreshOrgTable'] = undefined;
}


  refreshOrgTable = (getObjectProperty(___arguments.context.pageData, 'refreshOrgTable'));
  if (refreshOrgTable) {
    await refreshOrgTable2();
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./pages/Management/components/51278f5d59b7d2def311de9cbd62d77f/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    var classes;


  if ((function () { return BackendlessUI.isProd })()) {
    classes = ['managementPage', 'management_display_none'];
  } else {
    classes = ['managementPage'];
  }

  return classes

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./pages/Management/components/ef0369450001da75ed6a3fab82b4b1e9/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    var isTableLoading, orgTableCount, navbarSelection, residentTableCount, userTableCount, curfewTableCount;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  orgTableCount = (getObjectProperty(___arguments.context.pageData, 'orgTableCount'));
  isTableLoading = (getObjectProperty(___arguments.context.pageData, 'isTableLoading'));
  if (orgTableCount == 0 && !isTableLoading) {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('ef0369450001da75ed6a3fab82b4b1e9', true);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('ef0369450001da75ed6a3fab82b4b1e9', false);
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./pages/Management/components/e043a5d6c6ad06ef7644c761d53f1a8c/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
    var text;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function subsequenceFromStartLast(sequence, at1) {
  var start = at1;
  var end = sequence.length - 1 + 1;
  return sequence.slice(start, end);
}



  return (String('+1') + String((await BackendlessUI.Functions.Custom['fn_f282814277b3c0aa13f33d3b2cda111b']((subsequenceFromStartLast((getObjectProperty(___arguments.context.getComponentDataStoreByUid('f3b59b0135057a666ac52e26fa9563e2'), 'billingPhone')), 2)), 'create'))))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/Management/components/775a50b3c048a36a9453b5a432ed8e1a/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    
  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./pages/Management/components/20eaf23d912d475ec8cdcb052d526fab/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      ___arguments.context.pageData['selectedItemDetailsSelection'] = 'details';
  ___arguments.context.pageData['isViewDetailsOpen'] = true;

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/Management/components/670e853979052a938b2655a1be3e1735/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    var classesList;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('f3b59b0135057a666ac52e26fa9563e2'), 'status')) == 'ACTIVE') {
    classesList = ['active_status_block'];
  } else if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('f3b59b0135057a666ac52e26fa9563e2'), 'status')) == 'DEACTIVATED') {
    classesList = ['deactivated_status_block'];
  }

  return classesList

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./pages/Management/components/ef76a121df79136d15f133152da6c91b/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    var classesList;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('f3b59b0135057a666ac52e26fa9563e2'), 'status')) == 'ACTIVE') {
    classesList = ['active_status_block_icon'];
  } else if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('f3b59b0135057a666ac52e26fa9563e2'), 'status')) == 'DEACTIVATED') {
    classesList = ['deactivated_status_block_icon'];
  }

  return classesList

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./pages/Management/components/de28962d3afa2b78ca3fcf3c63245d95/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    var classesList;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('f3b59b0135057a666ac52e26fa9563e2'), 'status')) == 'ACTIVE') {
    classesList = ['active_status_block_text'];
  } else if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('f3b59b0135057a666ac52e26fa9563e2'), 'status')) == 'DEACTIVATED') {
    classesList = ['deactivated_status_block_text'];
  }

  return classesList

  },
  /* handler:onClassListAssignment */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    var classesList;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('f3b59b0135057a666ac52e26fa9563e2'), 'status')) == 'ACTIVE') {
    return 'Active';
  } else if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('f3b59b0135057a666ac52e26fa9563e2'), 'status')) == 'DEACTIVATED') {
    return 'Deactivated';
  }

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/Management/components/e61bc9e3525fa93317a17a33e51780ef/bundle.js', [], () => ({
  /* content */
  /* handler:onSourceUrlAssignment */
  ['onSourceUrlAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.getComponentDataStoreByUid('f3b59b0135057a666ac52e26fa9563e2'), 'logo')) {
    return (getObjectProperty(___arguments.context.getComponentDataStoreByUid('f3b59b0135057a666ac52e26fa9563e2'), 'logo'));
  } else {
    return 'https://app.aquax.co/api/files/cyberdive_photos/cd-icon-default-photo.svg';
  }

  },
  /* handler:onSourceUrlAssignment */
  /* content */
}))

define('./pages/NewPassword/components/page/bundle.js', [], () => ({
  /* content */
  /* handler:onBeforeEnter */
  async ['onBeforeEnter'](___arguments) {
    var user, userObject;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  ___arguments.context.pageData['viewingPassword'] = undefined;
  ___arguments.context.pageData['viewingConfirmPassword'] = undefined;
  userObject = (await Backendless.Cache.get((String(getObjectProperty(___arguments.context.pageData, 'userEmail')))));
  if (userObject == null) {
    ___arguments.context.pageData['pageVariant'] = 'expired';
  } else {
    user = (
        await (async function(userIdentity){
          var userColumns = await Backendless.UserService.describeUserClass()
          var identityColumn = userColumns.find(column => column.identity)
          var whereClause = `${identityColumn.name} = '${userIdentity}'`
          var query = Backendless.DataQueryBuilder.create().setWhereClause(whereClause)
          var users = await Backendless.Data.of(Backendless.User).find(query)

          return users[0]
       })((getObjectProperty(___arguments.context.pageData, 'userEmail')))
    );
    if ((getObjectProperty(___arguments.context.pageData, 'key')) == (getObjectProperty(userObject, 'key'))) {
      ___arguments.context.pageData['pageVariant'] = 'newPassword';
      ___arguments.context.pageData['currentUser'] = user;
    } else {
      ___arguments.context.pageData['pageVariant'] = 'expired';
    }
  }

  },
  /* handler:onBeforeEnter */
  /* content */
}))

define('./pages/NewPassword/components/fdc847332f171793625ca41f662e6956/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var toastError, currentUser, updatedUser, password, confirmPassword, isLoading, button;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

/**
 * Describe this function...
 */
async function setError(toastError) {
  await isButtonLoading(false);
  await BackendlessUI.Functions.Custom['fn_77bfd63e533a9b4c5577e5fe31a4b5ca'](___arguments.context.pageData, (getObjectProperty(toastError, 'message')), (getObjectProperty(toastError, 'status')));
}

/**
 * Describe this function...
 */
async function isButtonLoading(isLoading) {
  ___arguments.context.pageData['isButtonLoading'] = isLoading;
  button = ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('fdc847332f171793625ca41f662e6956'));
  button['label'] = (isLoading ? 'Please wait...' : 'Continue');
}


  await isButtonLoading(true);
  password = (getObjectProperty(___arguments.context.pageData, 'password'));
  confirmPassword = (getObjectProperty(___arguments.context.pageData, 'confirmPassword'));
  currentUser = (getObjectProperty(___arguments.context.pageData, 'currentUser'));
  if (password == confirmPassword) {
    try {
      updatedUser = (await Backendless.Request.post(`${Backendless.appPath}/services/UpdateUser/UserUpdate`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send(JSON.stringify(({ [`objectId`]: (getObjectProperty(currentUser, 'objectId')),[`password`]: password }))));
      if (updatedUser) {
        await Backendless.Cache.remove((String(getObjectProperty(currentUser, 'email'))));
        await BackendlessUI.Functions.Custom['fn_67c6f852cf5ce414b74b8f0b9401b852']('engineers@cyberdive.co', 'Cyber Dive Team', (getObjectProperty(___arguments.context.pageData, 'email')), 'd-69dbf2f59c624797bf4ad5d59c36ea09', null);
        ___arguments.context.pageData['pageVariant'] = 'success';
        await setError(({ [`status`]: 'success',[`message`]: 'We\'ve successfully updated your password!' }));
      }

    } catch (error) {
      await setError(({ [`status`]: 'failure',[`message`]: 'There was an error updating your password' }));

    }
  } else {
    await setError(({ [`status`]: 'failure',[`message`]: 'The passwords do not match.' }));
  }

  },
  /* handler:onClick */
  /* handler:onStyleAssignment */
  async ['onStyleAssignment'](___arguments) {
    var isDisabled, confirmPassword, password, isButtonLoading;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  isButtonLoading = (getObjectProperty(___arguments.context.pageData, 'isButtonLoading'));
  password = (getObjectProperty(___arguments.context.pageData, 'password'));
  confirmPassword = (getObjectProperty(___arguments.context.pageData, 'confirmPassword'));
  if (isButtonLoading) {
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('fdc847332f171793625ca41f662e6956'))['background-color'] = 'rgba(56, 113, 255, 0.38)';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('fdc847332f171793625ca41f662e6956'))['color'] = 'rgba(56, 113, 255, 0.5)';
    ___arguments.context.pageData['isButtonDisabled'] = true;
  } else {
    isDisabled = false;
    if (!password || (await BackendlessUI.Functions.Custom['fn_a7ad4c1fd5d18ca6bff3eb611aeee2ac'](password))) {
      isDisabled = true;
    } else if (!confirmPassword || (await BackendlessUI.Functions.Custom['fn_a7ad4c1fd5d18ca6bff3eb611aeee2ac'](confirmPassword))) {
      isDisabled = true;
    } else if (!(await BackendlessUI.Functions.Custom['fn_71a2dca5099d57aac701dfa99e2c9eb1'](password)) || !(await BackendlessUI.Functions.Custom['fn_71a2dca5099d57aac701dfa99e2c9eb1'](confirmPassword))) {
      isDisabled = true;
    }
    if (isDisabled) {
      ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('fdc847332f171793625ca41f662e6956'))['background-color'] = 'rgba(56, 113, 255, 0.38)';
      ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('fdc847332f171793625ca41f662e6956'))['color'] = 'rgba(56, 113, 255, 0.5)';
      ___arguments.context.pageData['isButtonDisabled'] = true;
    } else {
      ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('fdc847332f171793625ca41f662e6956'))['background-color'] = '#3871FF';
      ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('fdc847332f171793625ca41f662e6956'))['color'] = 'white';
      ___arguments.context.pageData['isButtonDisabled'] = false;
    }
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./pages/NewPassword/components/ade165fcabdbac285279b0dd8e363e95/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'pageVariant')) == 'expired') {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('ade165fcabdbac285279b0dd8e363e95', true);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('ade165fcabdbac285279b0dd8e363e95', false);
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./pages/NewPassword/components/a5adb18b63cd1dc47eec93f4be386bb5/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    var pageVariant;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'pageVariant')) == 'newPassword') {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('a5adb18b63cd1dc47eec93f4be386bb5', true);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('a5adb18b63cd1dc47eec93f4be386bb5', false);
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./pages/NewPassword/components/53be4648d79c18bc31f4209ca48eae71/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'pageVariant')) == 'success') {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('53be4648d79c18bc31f4209ca48eae71', true);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('53be4648d79c18bc31f4209ca48eae71', false);
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./pages/NewPassword/components/22ac9618ba2e155a90170d48fbef65b4/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  async ['onChange'](___arguments) {
      ___arguments.context.pageData['passwordError'] = false;
  ___arguments.context.pageData['password'] = ___arguments.value;
  if (!(await BackendlessUI.Functions.Custom['fn_71a2dca5099d57aac701dfa99e2c9eb1'](___arguments.value))) {
    ___arguments.context.pageData['passwordError'] = true;
  }

  },
  /* handler:onChange */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    var classesList;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function removeItemInList(l, i, c) {   const index = c ? l.findIndex(el => el[c] === i[c]) : l.indexOf(i);  if (index >= 0) { l.splice(index, 1); }  return l;}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  classesList = ['input_grey_border'];
  if (getObjectProperty(___arguments.context.pageData, 'passwordError')) {
    removeItemInList(classesList, 'input_grey_border', '');
    addItemToList(classesList, 'input_blue_border');
  }

  return classesList

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./pages/NewPassword/components/64fb55f900a3d61be948bb25ef6ece53/bundle.js', [], () => ({
  /* content */
  /* handler:onValueAssignment */
  ['onValueAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'currentUser')) {
    return (getObjectProperty((getObjectProperty(___arguments.context.pageData, 'currentUser')), 'email'));
  }

  },
  /* handler:onValueAssignment */
  /* content */
}))

define('./pages/NewPassword/components/0f0d2b23fb0df0c4edbe2033d97de7f4/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('Login', undefined);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/NewPassword/components/66ca704c1dff615153b36d0743f6e62b/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'passwordError')) {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('66ca704c1dff615153b36d0743f6e62b', true);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('66ca704c1dff615153b36d0743f6e62b', false);
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./pages/NewPassword/components/4ddbddbab81614c773a6fcaabdb65958/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  ['onChange'](___arguments) {
      ___arguments.context.pageData['confirmPassword'] = ___arguments.value;

  },
  /* handler:onChange */
  /* content */
}))

define('./pages/NewPassword/components/c4626f55741d68db8cc8a93c52ffad48/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (url, isExternal, params) { BackendlessUI.Navigator.openWebPage(url, isExternal, params); })('https://mail.google.com/mail/u/0/?f=1&source=mailto&to=support@aquax.co&fs=1&tf=cm', true, undefined);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/NewPassword/components/dd718e27dc3bfea66fae7d1fe6172c84/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'viewingPassword')) {
    ___arguments.context.pageData['viewingPassword'] = false;
  } else {
    ___arguments.context.pageData['viewingPassword'] = true;
  }

  },
  /* handler:onClick */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'viewingPassword')) {
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('de9f3f69076fc27bcd0278c6e52f04e5'))['color'] = '#3871FF';
    ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('22ac9618ba2e155a90170d48fbef65b4'))['type'] = 'input';
  } else {
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('de9f3f69076fc27bcd0278c6e52f04e5'))['color'] = 'rgb(0,0,0,0.38)';
    ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('22ac9618ba2e155a90170d48fbef65b4'))['type'] = 'password';
  }

  },
  /* handler:onStyleAssignment */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    var classesList;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function removeItemInList(l, i, c) {   const index = c ? l.findIndex(el => el[c] === i[c]) : l.indexOf(i);  if (index >= 0) { l.splice(index, 1); }  return l;}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  classesList = ['view_password'];
  if (getObjectProperty(___arguments.context.pageData, 'passwordError')) {
    removeItemInList(classesList, 'view_password', '');
    addItemToList(classesList, 'view_password_blue');
  }

  return classesList

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./pages/NewPassword/components/e1e9c0ce198bb3cc4d16cf66fce1221b/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'viewingConfirmPassword')) {
    ___arguments.context.pageData['viewingConfirmPassword'] = false;
  } else {
    ___arguments.context.pageData['viewingConfirmPassword'] = true;
  }

  },
  /* handler:onClick */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'viewingConfirmPassword')) {
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('89458375a97832ea417a7883db22a27a'))['color'] = '#3871FF';
    ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('4ddbddbab81614c773a6fcaabdb65958'))['type'] = 'input';
  } else {
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('89458375a97832ea417a7883db22a27a'))['color'] = 'rgb(0,0,0,0.38)';
    ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('4ddbddbab81614c773a6fcaabdb65958'))['type'] = 'password';
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./pages/Register/components/page/bundle.js', [], () => ({
  /* content */
  /* handler:onBeforeEnter */
  async ['onBeforeEnter'](___arguments) {
    var user, userObject;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  ___arguments.context.pageData['viewingPassword'] = undefined;
  ___arguments.context.pageData['viewingCofirmPassword'] = undefined;
  userObject = (await Backendless.Cache.get((String(getObjectProperty(___arguments.context.pageData, 'userEmail')))));
  if (userObject == null) {
    ___arguments.context.pageData['pageVariant'] = 'expiredLink';
  } else {
    user = (
        await (async function(userIdentity){
          var userColumns = await Backendless.UserService.describeUserClass()
          var identityColumn = userColumns.find(column => column.identity)
          var whereClause = `${identityColumn.name} = '${userIdentity}'`
          var query = Backendless.DataQueryBuilder.create().setWhereClause(whereClause)
          var users = await Backendless.Data.of(Backendless.User).find(query)

          return users[0]
       })((getObjectProperty(___arguments.context.pageData, 'userEmail')))
    );
    if (!user) {
      ___arguments.context.pageData['pageVariant'] = 'expiredLink';
    } else if (user && (getObjectProperty(user, 'status')) == 'ACTIVE') {
      ___arguments.context.pageData['pageVariant'] = 'expiredLink';
    } else {
      if (getObjectProperty(___arguments.context.pageData, 'adminEmail')) {
        ___arguments.context.pageData['orgAdmin'] = ((await Backendless.Data.of('Users').find(Backendless.DataQueryBuilder.create().setWhereClause((['email = \'',(getObjectProperty(___arguments.context.pageData, 'adminEmail')),'\''].join(''))).setRelationsDepth(2).setPageSize(10)))[0]);
      }
      ___arguments.context.pageData['orgName'] = (getObjectProperty(((await Backendless.Data.of('Users').find(Backendless.DataQueryBuilder.create().setWhereClause((['email = \'',(getObjectProperty(___arguments.context.pageData, 'userEmail')),'\''].join(''))).setRelationsDepth(2).setPageSize(10)))[0]), 'organizationId.orgName'));
      ___arguments.context.pageData['pageVariant'] = 'createAccount';
      ___arguments.context.pageData['currentUser'] = user;
    }
  }

  },
  /* handler:onBeforeEnter */
  /* content */
}))

define('./pages/Register/components/9069acce85badb2f6183b315aae3420f/bundle.js', [], () => ({
  /* content */
  /* handler:onValueAssignment */
  ['onValueAssignment'](___arguments) {
    var currentUser;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  currentUser = (getObjectProperty(___arguments.context.pageData, 'currentUser'));
  if (currentUser) {
    return (getObjectProperty(currentUser, 'name'));
  }

  },
  /* handler:onValueAssignment */
  /* handler:onChange */
  ['onChange'](___arguments) {
      ___arguments.context.pageData['fullName'] = ___arguments.value;

  },
  /* handler:onChange */
  /* content */
}))

define('./pages/Register/components/eb74efff1db954116c7a19b50075eafb/bundle.js', [], () => ({
  /* content */
  /* handler:onValueAssignment */
  ['onValueAssignment'](___arguments) {
    var currentUser;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  currentUser = (getObjectProperty(___arguments.context.pageData, 'currentUser'));
  if (currentUser) {
    return (getObjectProperty(currentUser, 'email'));
  }

  },
  /* handler:onValueAssignment */
  /* handler:onChange */
  ['onChange'](___arguments) {
      ___arguments.context.pageData['userName'] = ___arguments.value;

  },
  /* handler:onChange */
  /* content */
}))

define('./pages/Register/components/ee9455ef0cb3db71904419e15b732014/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  async ['onChange'](___arguments) {
      ___arguments.context.pageData['contactError'] = false;
  ___arguments.context.pageData['phoneNumber'] = (await BackendlessUI.Functions.Custom['fn_f282814277b3c0aa13f33d3b2cda111b'](___arguments.value, 'create'));

  },
  /* handler:onChange */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    var classesList;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function removeItemInList(l, i, c) {   const index = c ? l.findIndex(el => el[c] === i[c]) : l.indexOf(i);  if (index >= 0) { l.splice(index, 1); }  return l;}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  classesList = ['input_grey_border'];
  if (getObjectProperty(___arguments.context.pageData, 'contactError')) {
    removeItemInList(classesList, 'input_grey_border', '');
    addItemToList(classesList, 'input_blue_border');
  }

  return classesList

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./pages/Register/components/7bb3ffc7a9794e36e9654b41704ab002/bundle.js', [], () => ({
  /* content */
  /* handler:onValueAssignment */
  ['onValueAssignment'](___arguments) {
    var currentUser;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  currentUser = (getObjectProperty(___arguments.context.pageData, 'currentUser'));
  if (currentUser) {
    if ((getObjectProperty(currentUser, 'userRole')) == 'admin') {
      return 'Admin Role';
    } else if ((getObjectProperty(currentUser, 'userRole')) == 'manager') {
      return 'Manager Role';
    }
  }

  },
  /* handler:onValueAssignment */
  /* content */
}))

define('./pages/Register/components/c5813152a625d7e94da6039ff70db7ec/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var currentUser, profilePicture, isLoading, buttonLogin, toastError, loggedInUser, password, updatedUserData, changedAttributes, index, phoneNumber, fullName, attribute, inputs, re_enterPassword, key, item, x, error;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function encodePath(path) {
  if(path.startsWith("http://") || path.startsWith("https://")) {
    return path
  }

  let decodedPath
  try {
    decodedPath = decodeURI(path)
  } finally {
    return (decodedPath || path).split("/").map(encodeURIComponent).join("/")
  }
}

/**
 * Describe this function...
 */
async function createPictureUrl() {
  try {
    profilePicture = (await Backendless.Files.upload(
        ((getObjectProperty(___arguments.context.pageData, 'profilePicture'))[0]),
        encodePath((['/profile_pictures/',(getObjectProperty(currentUser, 'objectId')),'/'].join(''))),
        false
      ).then(result => result.fileURL));

  } catch (error) {
    await setToast(({ [`status`]: 'failure',[`message`]: 'There was a problem uploading your profile picture.' }));

  }
}

/**
 * Describe this function...
 */
async function isButtonLoading(isLoading) {
  ___arguments.context.pageData['isButtonLoading'] = isLoading;
  buttonLogin = ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('c5813152a625d7e94da6039ff70db7ec'));
  buttonLogin['label'] = (isLoading ? 'Please wait...' : 'Continue');
}

/**
 * Describe this function...
 */
async function setToast(error) {
  await isButtonLoading(false);
  await BackendlessUI.Functions.Custom['fn_77bfd63e533a9b4c5577e5fe31a4b5ca'](___arguments.context.pageData, (getObjectProperty(error, 'message')), (getObjectProperty(error, 'status')));
}


  await isButtonLoading(true);
  key = String(getObjectProperty(___arguments.context.pageData, 'key'));
  currentUser = (getObjectProperty(___arguments.context.pageData, 'currentUser'));
  fullName = (getObjectProperty(___arguments.context.pageData, 'fullName'));
  phoneNumber = String('+1') + String((await BackendlessUI.Functions.Custom['fn_f282814277b3c0aa13f33d3b2cda111b']((getObjectProperty(___arguments.context.pageData, 'phoneNumber')), 'remove')));
  password = (getObjectProperty(___arguments.context.pageData, 'password'));
  re_enterPassword = (getObjectProperty(___arguments.context.pageData, 're-enterPassword'));
  inputs = [fullName, phoneNumber];
  index = 0;
  if (phoneNumber) {
    if (!(await BackendlessUI.Functions.Custom['fn_6d4580c12cbae4a32f38d054d2dc9e71'](phoneNumber))) {
      ___arguments.context.pageData['contactError'] = true;
      await isButtonLoading(false);
      return ;
    }
  }
  if (getObjectProperty(___arguments.context.pageData, 'profilePicture')) {
    await createPictureUrl();
    changedAttributes = ({ [`objectId`]: (getObjectProperty(currentUser, 'objectId')),[`inviteStatus`]: 'ACCEPTED',[`status`]: 'ACTIVE',[`password`]: password,[`profilePicture`]: profilePicture });
  } else {
    changedAttributes = ({ [`objectId`]: (getObjectProperty(currentUser, 'objectId')),[`inviteStatus`]: 'ACCEPTED',[`status`]: 'ACTIVE',[`password`]: password });
  }
  for (var attribute_index in inputs) {
    attribute = inputs[attribute_index];
    if (attribute) {
      if (fullName && index == 0 && fullName != (getObjectProperty(currentUser, 'name'))) {
        changedAttributes['name'] = fullName;
      } else if (phoneNumber && index == 1 && phoneNumber != (getObjectProperty(currentUser, 'phoneNumber'))) {
        changedAttributes['phoneNumber'] = phoneNumber;
      }
    }
    index = (typeof index == 'number' ? index : 0) + 1;
  }
  try {
    updatedUserData = (await Backendless.UserService.update( new Backendless.User(changedAttributes) ));
    if (updatedUserData) {
      await Backendless.Cache.remove((getObjectProperty(updatedUserData, 'email')));
      if ((getObjectProperty(currentUser, 'userRole')) == 'admin') {
        if (getObjectProperty(___arguments.context.pageData, 'orgAdmin')) {
          await BackendlessUI.Functions.Custom['fn_67c6f852cf5ce414b74b8f0b9401b852']('engineers@cyberdive.co', 'Cyber Dive Team', (getObjectProperty(updatedUserData, 'email')), 'd-1a8d6cec3f3648eaa5cd43fbf95f1af6', ({ [`organization_admin`]: (getObjectProperty(updatedUserData, 'name')),[`organization_name`]: (getObjectProperty(___arguments.context.pageData, 'orgName')),[`link`]: (await BackendlessUI.Functions.Custom['fn_a65e994797048d593245ed62a80fb31f']('created', null)) }));
          await BackendlessUI.Functions.Custom['fn_67c6f852cf5ce414b74b8f0b9401b852']('engineers@cyberdive.co', 'Cyber Dive Team', (getObjectProperty(___arguments.context.pageData, 'adminEmail')), 'd-2ae0a502c6574f78a7b7eb6898666ab2', ({ [`organization_admin`]: (getObjectProperty(___arguments.context.pageData, 'orgAdmin.name')),[`case_manager`]: (getObjectProperty(updatedUserData, 'name')),[`organization_name`]: (getObjectProperty(___arguments.context.pageData, 'orgName')) }));
        } else {
          await BackendlessUI.Functions.Custom['fn_67c6f852cf5ce414b74b8f0b9401b852']('engineers@cyberdive.co', 'Cyber Dive Team', (getObjectProperty(updatedUserData, 'email')), 'd-1a8d6cec3f3648eaa5cd43fbf95f1af6', ({ [`organization_admin`]: (getObjectProperty(updatedUserData, 'name')),[`organization_name`]: (getObjectProperty(___arguments.context.pageData, 'orgName')),[`link`]: (await BackendlessUI.Functions.Custom['fn_a65e994797048d593245ed62a80fb31f']('created', null)) }));
          await BackendlessUI.Functions.Custom['fn_67c6f852cf5ce414b74b8f0b9401b852']('engineers@cyberdive.co', 'Cyber Dive Team', 'engineers@cyberdive.co', 'd-c3d2e65bfd6042c3986bf58389cb2a75', ({ [`super_admin`]: 'Super Admin',[`organization_name`]: (getObjectProperty(___arguments.context.pageData, 'orgName')),[`organization_admin`]: (getObjectProperty(updatedUserData, 'name')) }));
        }
      } else if ((getObjectProperty(currentUser, 'userRole')) == 'manager') {
        await BackendlessUI.Functions.Custom['fn_67c6f852cf5ce414b74b8f0b9401b852']('engineers@cyberdive.co', 'Cyber Dive Team', (getObjectProperty(updatedUserData, 'email')), 'd-1a8d6cec3f3648eaa5cd43fbf95f1af6', ({ [`organization_admin`]: (getObjectProperty(updatedUserData, 'name')),[`organization_name`]: (getObjectProperty(___arguments.context.pageData, 'orgName')),[`link`]: (await BackendlessUI.Functions.Custom['fn_a65e994797048d593245ed62a80fb31f']('created', null)) }));
        await BackendlessUI.Functions.Custom['fn_67c6f852cf5ce414b74b8f0b9401b852']('engineers@cyberdive.co', 'Cyber Dive Team', (getObjectProperty(___arguments.context.pageData, 'adminEmail')), 'd-2ae0a502c6574f78a7b7eb6898666ab2', ({ [`organization_admin`]: (getObjectProperty(___arguments.context.pageData, 'orgAdmin.name')),[`case_manager`]: (getObjectProperty(updatedUserData, 'name')),[`organization_name`]: (getObjectProperty(___arguments.context.pageData, 'orgName')) }));
      }
      loggedInUser = (await Backendless.UserService.login( (getObjectProperty(updatedUserData, 'email')), password, true  ));
      if (loggedInUser) {
        ___arguments.context.pageData['pageVariant'] = 'success';
      } else {
        (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('Login', ({ [`error`]: 'login' }));
      }
    }

  } catch (error) {
    await setToast(({ [`status`]: 'failure',[`message`]: 'There was a problem registering your account.' }));
    await isButtonLoading(false);

  }
  await isButtonLoading(false);

  },
  /* handler:onClick */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    var phoneNumber, isButtonLoading, currentUser;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  currentUser = (getObjectProperty(___arguments.context.pageData, 'currentUser'));
  phoneNumber = (getObjectProperty(___arguments.context.pageData, 'phoneNumber'));
  isButtonLoading = (getObjectProperty(___arguments.context.pageData, 'isButtonLoading'));
  if (isButtonLoading) {
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('c5813152a625d7e94da6039ff70db7ec'))['background-color'] = 'rgba(56, 113, 255, 0.38)';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('c5813152a625d7e94da6039ff70db7ec'))['color'] = 'rgba(56, 113, 255, 0.5)';
    ___arguments.context.pageData['isDetailsButtonLoading'] = true;
  } else {
    if (!(typeof phoneNumber === 'undefined') && ((new RegExp('^\\s*$', '')).test(phoneNumber))) {
      ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('c5813152a625d7e94da6039ff70db7ec'))['background-color'] = 'rgba(56, 113, 255, 0.38)';
      ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('c5813152a625d7e94da6039ff70db7ec'))['color'] = 'rgba(56, 113, 255, 0.5)';
      ___arguments.context.pageData['isDetailsButtonLoading'] = true;
    } else {
      ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('c5813152a625d7e94da6039ff70db7ec'))['background-color'] = '#3871FF';
      ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('c5813152a625d7e94da6039ff70db7ec'))['color'] = 'white';
      ___arguments.context.pageData['isDetailsButtonLoading'] = false;
    }
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./pages/Register/components/f32e18e7a8496f192e2e041cb196e14d/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    var pageVariant;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  pageVariant = (getObjectProperty(___arguments.context.pageData, 'pageVariant'));
  if (pageVariant == 'details') {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('f32e18e7a8496f192e2e041cb196e14d', true);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('f32e18e7a8496f192e2e041cb196e14d', false);
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./pages/Register/components/882c2e26c4e401f874a90b9a44d194d9/bundle.js', [], () => ({
  /* content */
  /* handler:onValueAssignment */
  ['onValueAssignment'](___arguments) {
    var orgName;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  orgName = (getObjectProperty(___arguments.context.pageData, 'orgName'));
  if (orgName) {
    return (getObjectProperty(___arguments.context.pageData, 'orgName'));
  }

  },
  /* handler:onValueAssignment */
  /* content */
}))

define('./pages/Register/components/727122fedc7a43dd55a5e1912b1c6424/bundle.js', [], () => ({
  /* content */
  /* handler:onDisabledStateAssignment */
  ['onDisabledStateAssignment'](___arguments) {
    var confirmPassword, password, fullName;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  fullName = (getObjectProperty(___arguments.context.pageData, 'fullName'));
  password = (getObjectProperty(___arguments.context.pageData, 'password'));
  confirmPassword = (getObjectProperty(___arguments.context.pageData, 'confirmPassword'));
  if (!(typeof fullName === 'undefined') && ((new RegExp('^\\s*$', '')).test(fullName))) {
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('727122fedc7a43dd55a5e1912b1c6424'))['background-color'] = 'rgba(56, 113, 255, 0.38)';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('727122fedc7a43dd55a5e1912b1c6424'))['color'] = 'rgba(56, 113, 255, 0.5)';
    return true;
  } else if ((typeof password === 'undefined') || ((new RegExp('^\\s*$', '')).test(password))) {
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('727122fedc7a43dd55a5e1912b1c6424'))['background-color'] = 'rgba(56, 113, 255, 0.38)';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('727122fedc7a43dd55a5e1912b1c6424'))['color'] = 'rgba(56, 113, 255, 0.5)';
    return true;
  } else if ((typeof confirmPassword === 'undefined') || ((new RegExp('^\\s*$', '')).test(confirmPassword))) {
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('727122fedc7a43dd55a5e1912b1c6424'))['background-color'] = 'rgba(56, 113, 255, 0.38)';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('727122fedc7a43dd55a5e1912b1c6424'))['color'] = 'rgba(56, 113, 255, 0.5)';
    return true;
  } else {
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('727122fedc7a43dd55a5e1912b1c6424'))['background-color'] = '#3871FF';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('727122fedc7a43dd55a5e1912b1c6424'))['color'] = 'white';
    return false;
  }

  },
  /* handler:onDisabledStateAssignment */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var currentUser, password, confirmPassword, fullName, error;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function subsequenceFromStartLast(sequence, at1) {
  var start = at1;
  var end = sequence.length - 1 + 1;
  return sequence.slice(start, end);
}

/**
 * Describe this function...
 */
async function setToast(error) {
  await BackendlessUI.Functions.Custom['fn_77bfd63e533a9b4c5577e5fe31a4b5ca'](___arguments.context.pageData, (getObjectProperty(error, 'message')), (getObjectProperty(error, 'status')));
}


  currentUser = (getObjectProperty(___arguments.context.pageData, 'currentUser'));
  fullName = (getObjectProperty(___arguments.context.pageData, 'fullName'));
  password = (getObjectProperty(___arguments.context.pageData, 'password'));
  confirmPassword = (getObjectProperty(___arguments.context.pageData, 'confirmPassword'));
  if (password == confirmPassword) {
    if (!(await BackendlessUI.Functions.Custom['fn_71a2dca5099d57aac701dfa99e2c9eb1'](password))) {
      ___arguments.context.pageData['passwordError'] = true;
    } else {
      ___arguments.context.pageData['phoneNumber'] = (await BackendlessUI.Functions.Custom['fn_f282814277b3c0aa13f33d3b2cda111b']((subsequenceFromStartLast((getObjectProperty(currentUser, 'phoneNumber')), 2)), 'create'));
      ___arguments.context.pageData['pageVariant'] = 'details';
    }
  } else {
    await setToast(({ [`status`]: 'failure',[`message`]: 'The passwords do not match.' }));
  }

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/Register/components/eda9a85230075edcd876bc91c2bea2f4/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  async ['onChange'](___arguments) {
    var password;


  ___arguments.context.pageData['passwordError'] = false;
  ___arguments.context.pageData['password'] = ___arguments.value;
  if (!(await BackendlessUI.Functions.Custom['fn_71a2dca5099d57aac701dfa99e2c9eb1'](___arguments.value))) {
    ___arguments.context.pageData['passwordError'] = true;
  }

  },
  /* handler:onChange */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    var classesList;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function removeItemInList(l, i, c) {   const index = c ? l.findIndex(el => el[c] === i[c]) : l.indexOf(i);  if (index >= 0) { l.splice(index, 1); }  return l;}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  classesList = ['input_grey_border'];
  if (getObjectProperty(___arguments.context.pageData, 'passwordError')) {
    removeItemInList(classesList, 'input_grey_border', '');
    addItemToList(classesList, 'input_blue_border');
  }

  return classesList

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./pages/Register/components/40ecb9a8528c6955f3395e0d97f8749b/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  ['onChange'](___arguments) {
      ___arguments.context.pageData['confirmPassword'] = ___arguments.value;

  },
  /* handler:onChange */
  /* content */
}))

define('./pages/Register/components/856b5a2cc132c423f35e116180995679/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'passwordError')) {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('856b5a2cc132c423f35e116180995679', true);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('856b5a2cc132c423f35e116180995679', false);
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./pages/Register/components/5f26533173d433e2e07a557a73993d17/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'contactError')) {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('5f26533173d433e2e07a557a73993d17', true);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('5f26533173d433e2e07a557a73993d17', false);
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./pages/Register/components/e267796cb35203c40adc7cb715999aea/bundle.js', [], () => ({
  /* content */
  /* handler:onSourceUrlAssignment */
  ['onSourceUrlAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (getObjectProperty(___arguments.context.pageData, 'picture'))

  },
  /* handler:onSourceUrlAssignment */
  /* handler:onStyleAssignment */
  async ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function readBrowserFileAs(file, type) {
   const readers = { base64: "readAsDataURL", utf8: "readAsText", bytes: "readAsArrayBuffer" }

   return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader[readers[type]](file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = error => reject(error)
  })
}


  if (getObjectProperty(___arguments.context.pageData, 'profilePicture')) {
    ___arguments.context.pageData['picture'] = (await readBrowserFileAs(((getObjectProperty(___arguments.context.pageData, 'profilePicture'))[0]), 'base64'));
    ___arguments.context.pageData['imageName'] = (getObjectProperty(((getObjectProperty(___arguments.context.pageData, 'profilePicture'))[0]), 'name'));
  } else {
    ___arguments.context.pageData['picture'] = 'https://app.aquax.co/api/files/cyberdive_photos/cd-icon-default-photo.svg';
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./pages/Register/components/5bbd9d9eb26e9a2bf2ec6cb263f98c06/bundle.js', [], () => ({
  /* content */
  /* handler:onSelectFiles */
  ['onSelectFiles'](___arguments) {
      ___arguments.context.pageData['profilePicture'] = ___arguments.selectedFiles;
  ___arguments.context.pageData['hasUploadedPhoto'] = true;

  },
  /* handler:onSelectFiles */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'hasUploadedPhoto')) {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('5bbd9d9eb26e9a2bf2ec6cb263f98c06', false);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('5bbd9d9eb26e9a2bf2ec6cb263f98c06', true);
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./pages/Register/components/f4bb32b9d32257d2699ce53c6ec18823/bundle.js', [], () => ({
  /* content */
  /* handler:onValueAssignment */
  ['onValueAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (getObjectProperty(___arguments.context.pageData, 'imageName'))

  },
  /* handler:onValueAssignment */
  /* content */
}))

define('./pages/Register/components/6f8f85e203697f748146538515f6fbb3/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'hasUploadedPhoto')) {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('6f8f85e203697f748146538515f6fbb3', true);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('6f8f85e203697f748146538515f6fbb3', false);
  }

  },
  /* handler:onStyleAssignment */
  /* handler:onClick */
  ['onClick'](___arguments) {
      ___arguments.context.pageData['profilePicture'] = undefined;
  ___arguments.context.pageData['imageName'] = undefined;
  ___arguments.context.pageData['hasUploadedPhoto'] = false;

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/Register/components/78b93de5b385f65153796f5fc3affe4c/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    var pageVariant;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  pageVariant = (getObjectProperty(___arguments.context.pageData, 'pageVariant'));
  if (pageVariant == 'success') {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('78b93de5b385f65153796f5fc3affe4c', true);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('78b93de5b385f65153796f5fc3affe4c', false);
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./pages/Register/components/36f95f0724e9b9d6422effed44359092/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('Dashboard', undefined);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/Register/components/d275aaf4ebd64fbfbe490acf97bb5442/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    var pageVariant;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  pageVariant = (getObjectProperty(___arguments.context.pageData, 'pageVariant'));
  if (pageVariant == 'expiredLink') {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('d275aaf4ebd64fbfbe490acf97bb5442', true);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('d275aaf4ebd64fbfbe490acf97bb5442', false);
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./pages/Register/components/fedbf4bbf26d51d50a8b40512ba16e82/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (url, isExternal, params) { BackendlessUI.Navigator.openWebPage(url, isExternal, params); })('https://mail.google.com/mail/u/0/?f=1&source=mailto&to=support@aquax.co&fs=1&tf=cm', true, undefined);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/Register/components/c4835269178f1549371e0a543d293886/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'viewingPassword')) {
    ___arguments.context.pageData['viewingPassword'] = false;
  } else {
    ___arguments.context.pageData['viewingPassword'] = true;
  }

  },
  /* handler:onClick */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'viewingPassword')) {
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('610d142419973fd3db3672f232c51f11'))['color'] = '#3871FF';
    ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('eda9a85230075edcd876bc91c2bea2f4'))['type'] = 'input';
  } else {
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('610d142419973fd3db3672f232c51f11'))['color'] = 'rgb(0,0,0,0.38)';
    ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('eda9a85230075edcd876bc91c2bea2f4'))['type'] = 'password';
  }

  },
  /* handler:onStyleAssignment */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    var classesList;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function removeItemInList(l, i, c) {   const index = c ? l.findIndex(el => el[c] === i[c]) : l.indexOf(i);  if (index >= 0) { l.splice(index, 1); }  return l;}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  classesList = ['view_password'];
  if (getObjectProperty(___arguments.context.pageData, 'passwordError')) {
    removeItemInList(classesList, 'view_password', '');
    addItemToList(classesList, 'view_password_blue');
  }

  return classesList

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./pages/Register/components/7668585de0d4ccc17b8c3db584490098/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'viewingConfirmPassword')) {
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('83881cec1a77fc345aed4a011bfc4e31'))['color'] = '#3871FF';
    ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('40ecb9a8528c6955f3395e0d97f8749b'))['type'] = 'input';
  } else {
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('83881cec1a77fc345aed4a011bfc4e31'))['color'] = 'rgb(0,0,0,0.38)';
    ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('40ecb9a8528c6955f3395e0d97f8749b'))['type'] = 'password';
  }

  },
  /* handler:onStyleAssignment */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'viewingConfirmPassword')) {
    ___arguments.context.pageData['viewingConfirmPassword'] = false;
  } else {
    ___arguments.context.pageData['viewingConfirmPassword'] = true;
  }

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/Register/components/9991e77e90765b2192493e93f1ed6e25/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    var pageVariant;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  pageVariant = (getObjectProperty(___arguments.context.pageData, 'pageVariant'));
  if (pageVariant == 'createAccount') {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('9991e77e90765b2192493e93f1ed6e25', true);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('9991e77e90765b2192493e93f1ed6e25', false);
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./pages/Register/components/007b961899db9acd1ec89c2d56cece42/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (['Welcome! Complete your account set up to join ',(getObjectProperty(___arguments.context.pageData, 'orgName')),'.'].join(''))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/Register/components/4d03c5560f58408d43a9919f3ea4ecc6/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (['You can now access ',(getObjectProperty(___arguments.context.pageData, 'orgName')),' Aqua X portal.'].join(''))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./components/reusable/r_827189cea4d96ee5531fade4feaedd05/logic/a1566914f8d492f5dcbfc4db2f145327/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    var classes;

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./components/reusable/r_80e9fa4ab06e9a4dd2dc5b29f63e46bc/logic/cd26f0fd4c3c193325fd3083c64b9c86/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    var classesList;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  classesList = ['modal'];
  if (getObjectProperty(___arguments.context.pageData, 'isSuccessModalOpen')) {
    addItemToList(classesList, 'open');
  }

  return classesList

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./components/reusable/r_80e9fa4ab06e9a4dd2dc5b29f63e46bc/logic/18edac382e932661b1f4cf0d70010a2c/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
    var userRole;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'navbarSelection')) == 'organizations') {
    return (String(getObjectProperty(___arguments.context.pageData, 'organizationName')) + String(' Added Successfully'));
  } else if ((getObjectProperty(___arguments.context.pageData, 'navbarSelection')) == 'userData') {
    if ((getObjectProperty(___arguments.context.pageData, 'userRoleSelection')) == 'admin') {
      userRole = 'Admin ';
    } else if ((getObjectProperty(___arguments.context.pageData, 'userRoleSelection')) == 'manager') {
      userRole = 'Case Manager ';
    }
    return ([userRole,' ',(await BackendlessUI.Functions.Custom['fn_9807a958b9f47818617415e4438b5e47']((getObjectProperty(___arguments.context.pageData, 'fullName')), 10)),' Successfully Added'].join(''));
  } else if ((getObjectProperty(___arguments.context.pageData, 'navbarSelection')) == 'residentData') {
    if (getObjectProperty(___arguments.context.pageData, 'isNewResident')) {
      return (String(getObjectProperty(___arguments.context.pageData, 'residentIntakeForm.personalDetails.nameInUse')) + String(' Added Successfully'));
    }
  } else if ((getObjectProperty(___arguments.context.pageData, 'navbarSelection')) == 'residentData') {
  }

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./components/reusable/r_80e9fa4ab06e9a4dd2dc5b29f63e46bc/logic/0bbf7201d5368d4cf11b85b10c9d782a/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'navbarSelection')) == 'organizations') {
    return 'The organization records table has been updated to reflect the new changes.';
  } else if ((getObjectProperty(___arguments.context.pageData, 'navbarSelection')) == 'userData') {
    return 'An invitation has been sent to their inbox.';
  } else if ((getObjectProperty(___arguments.context.pageData, 'navbarSelection')) == 'residentData') {
    if (getObjectProperty(___arguments.context.pageData, 'isNewResident')) {
      return 'You can manage the details from the resident records!';
    }
  }

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./components/reusable/r_80e9fa4ab06e9a4dd2dc5b29f63e46bc/logic/b761fa26a7c90fd56a9d4942db3752dd/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      ___arguments.context.pageData['isSuccessModalOpen'] = false;

  },
  /* handler:onClick */
  /* content */
}))

define('./components/reusable/r_80e9fa4ab06e9a4dd2dc5b29f63e46bc/logic/51855f267a91ac95cb8f23bead89706e/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      ___arguments.context.pageData['isSuccessModalOpen'] = false;

  },
  /* handler:onClick */
  /* content */
}))

define('./components/reusable/r_80e9fa4ab06e9a4dd2dc5b29f63e46bc/logic/56c244f2eb35c84da19a61ffbea305c5/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      ___arguments.context.pageData['isSuccessModalOpen'] = false;

  },
  /* handler:onClick */
  /* content */
}))

define('./components/reusable/r_ebc68c282ae4812c64225da604c336bb/logic/222153f2495c59f937e8e0b8564793f9/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    var classesList;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  classesList = ['modal'];
  if (getObjectProperty(___arguments.context.pageData, 'isConfirmationModalOpen')) {
    addItemToList(classesList, 'open');
  }

  return classesList

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./components/reusable/r_ebc68c282ae4812c64225da604c336bb/logic/9fd4a8dc0c547afd009b2cbfa8427434/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    /**
 * Describe this function...
 */
async function clearInputs() {
  ___arguments.context.pageData['isDeactivatingOrganization'] = undefined;
  ___arguments.context.pageData['isDeactivatingUser'] = undefined;
  ___arguments.context.pageData['isCancellingInvite'] = undefined;
  ___arguments.context.pageData['isResendingInvite'] = undefined;
}


  ___arguments.context.pageData['isConfirmationModalOpen'] = false;
  await clearInputs();

  },
  /* handler:onClick */
  /* content */
}))

define('./components/reusable/r_ebc68c282ae4812c64225da604c336bb/logic/ed3805d4409eac6f71e284448efe62f9/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    /**
 * Describe this function...
 */
async function clearInputs() {
  ___arguments.context.pageData['isDeactivatingOrganization'] = undefined;
  ___arguments.context.pageData['isDeactivatingUser'] = undefined;
  ___arguments.context.pageData['isCancellingInvite'] = undefined;
  ___arguments.context.pageData['isResendingInvite'] = undefined;
}


  ___arguments.context.pageData['isConfirmationModalOpen'] = false;
  await clearInputs();

  },
  /* handler:onClick */
  /* content */
}))

define('./components/reusable/r_ebc68c282ae4812c64225da604c336bb/logic/43782f368d547ff3517ad9de48364a8e/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    var isLoadingAction;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  isLoadingAction = (getObjectProperty(___arguments.context.pageData, 'isLoadingAction'));
  if (isLoadingAction) {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('43782f368d547ff3517ad9de48364a8e', true);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('43782f368d547ff3517ad9de48364a8e', false);
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./components/reusable/r_ebc68c282ae4812c64225da604c336bb/logic/06d7dd1c011e42a79e0bb26d0ef17ff3/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    var isLoadingAction;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  isLoadingAction = (getObjectProperty(___arguments.context.pageData, 'isLoadingAction'));
  if (isLoadingAction) {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('06d7dd1c011e42a79e0bb26d0ef17ff3', false);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('06d7dd1c011e42a79e0bb26d0ef17ff3', true);
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./components/reusable/r_ebc68c282ae4812c64225da604c336bb/logic/2705080c590236d4b2e4f8edb204a4ec/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    var confirmationOption;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  confirmationOption = (getObjectProperty(___arguments.context.pageData, 'confirmationOption'));
  if (confirmationOption == 'deactivateUser' || confirmationOption == 'deactivateOrganization' || confirmationOption == 'cancelUser') {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('2705080c590236d4b2e4f8edb204a4ec', true);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('2705080c590236d4b2e4f8edb204a4ec', false);
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./components/reusable/r_ebc68c282ae4812c64225da604c336bb/logic/d515bb036dac369987aba1977af812bd/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    var confirmationOption;


  ___arguments.context.pageData['isConfirmationModalOpen'] = false;

  },
  /* handler:onClick */
  /* handler:onLabelAssignment */
  ['onLabelAssignment'](___arguments) {
    var confirmationOption;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  confirmationOption = (getObjectProperty(___arguments.context.pageData, 'confirmationOption'));
  if (confirmationOption == 'saveResidentForm') {
    return 'Don\'t Save';
  } else {
    return 'Back';
  }

  },
  /* handler:onLabelAssignment */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    var classes, confirmationOption;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  confirmationOption = (getObjectProperty(___arguments.context.pageData, 'confirmationOption'));
  if (confirmationOption == 'saveResidentForm' || confirmationOption == 'activateUser' || confirmationOption == 'activateCurfew' || confirmationOption == 'resendUser' || confirmationOption == 'activateOrganization') {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('d515bb036dac369987aba1977af812bd', true);
    classes = ['red_button_outlined'];
  } else if (confirmationOption == 'deleteActiveCurfew') {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('d515bb036dac369987aba1977af812bd', false);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('d515bb036dac369987aba1977af812bd', true);
    classes = ['enabled_button_outlined'];
  }

  return classes

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./components/reusable/r_ebc68c282ae4812c64225da604c336bb/logic/a9d41a5b78c58c0d93fbc6c8bd52de1d/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var toast, newContactArray, item, confirmationOption, selectedItem, selectedItemArrayCurfew, curfewTableResponse, selectedItemArrayUser, sendGridResponse, currentUser, uniqueID, sendEmailResponse, selectedItemOrganization, userTableResponse, organizationTableResponse, user, updateUserResponse, usersOfOrganization;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

/**
 * Describe this function...
 */
async function setToast(toast) {
  ___arguments.context.pageData['isLoadingAction'] = false;
  await BackendlessUI.Functions.Custom['fn_77bfd63e533a9b4c5577e5fe31a4b5ca'](___arguments.context.pageData, (getObjectProperty(toast, 'message')), (getObjectProperty(toast, 'status')));
}

function generateUUID() {
   const chr4 = () => Math.random().toString(16).slice(-4);
   const chr8 = () => `${chr4()}${chr4()}`;
   const short = () => chr8();
   const long = () => `${chr8()}-${chr4()}-${chr4()}-${chr4()}-${chr8()}${chr4()}`;

   return { short, long, }
}

async function asyncListFilter(sourceList, callback) {
  const list = await Promise.all(sourceList.map(async source => ({
    source,
    value: await callback(source),
  })));

  const resultList = list.filter(item => item.value)

  return resultList.map(item => item.source)
}

/**
 * Describe this function...
 */
async function refreshUserTable() {
  ___arguments.context.pageData['refreshUserTable'] = ({ [`changedValueUser`]: (getObjectProperty(___arguments.context.pageData, 'changedValueUser')) });
}

/**
 * Describe this function...
 */
async function deactivateAllUsers() {
  try {
    usersOfOrganization = (await Backendless.Data.of('Users').find(Backendless.DataQueryBuilder.create().setWhereClause((['organizationId.objectId =','\'',(getObjectProperty(selectedItemOrganization, 'objectId')),'\''].join(''))).setRelationsDepth(2).setPageSize(10)));
    for (var user_index in usersOfOrganization) {
      user = usersOfOrganization[user_index];
      updateUserResponse = (await Backendless.UserService.update( new Backendless.User(({ [`objectId`]: (getObjectProperty(user, 'objectId')),[`status`]: 'DEACTIVATED' })) ));
      if (updateUserResponse) {
        await BackendlessUI.Functions.Custom['fn_67c6f852cf5ce414b74b8f0b9401b852']('engineers@cyberdive.co', 'Cyber Dive', (getObjectProperty(user, 'email')), 'd-a410c96816e6428ba745f8fbc404484c', ({ [`organization_name`]: (getObjectProperty(selectedItemOrganization, 'orgName')) }));
      }
    }

  } catch (error) {
    await setToast(({ [`status`]: 'failure',[`message`]: 'There was an issue deactivating the users.' }));

  }
}

/**
 * Describe this function...
 */
async function refreshCurfewTable() {
  ___arguments.context.pageData['refreshCurfewTable'] = ({ [`pillar`]: (getObjectProperty((selectedItemArrayCurfew[0]), 'pillar')) });
}


  selectedItemOrganization = (getObjectProperty(___arguments.context.pageData, 'selectedItemOrganization'));
  selectedItemArrayUser = (getObjectProperty(___arguments.context.pageData, 'selectedItemArrayUser'));
  selectedItemArrayCurfew = (getObjectProperty(___arguments.context.pageData, 'selectedItemArrayCurfew'));
  confirmationOption = (getObjectProperty(___arguments.context.pageData, 'confirmationOption'));
  currentUser = (getObjectProperty(___arguments.context.pageData, 'currentUser'));
  ___arguments.context.pageData['isLoadingAction'] = true;
  if (confirmationOption == 'deactivateOrganization') {
    try {
      organizationTableResponse = (await Backendless.Data.of('Organizations').save( ({ [`objectId`]: (getObjectProperty(selectedItemOrganization, 'objectId')),[`status`]: 'DEACTIVATED' }) ));
      await deactivateAllUsers();
      (getObjectProperty(___arguments.context.pageData, 'selectedItemOrganization'))['status'] = 'DEACTIVATED';
      ___arguments.context.pageData['refreshOrgTable'] = true;
      ___arguments.context.pageData['isOrganizationRecordActionDrawerOpen'] = false;
      ___arguments.context.pageData['isConfirmationModalOpen'] = false;
      ___arguments.context.pageData['selectedItemArrayOrg'] = false;
      await setToast(({ [`status`]: 'success',[`message`]: String(getObjectProperty(selectedItemOrganization, 'orgName')) + String(' successfully deactivated.') }));

    } catch (error) {
      await setToast(({ [`status`]: 'failure',[`message`]: 'Error deactivating the organization.' }));

    }
  } else if (confirmationOption == 'activateOrganization') {
    try {
      organizationTableResponse = (await Backendless.Data.of('Organizations').save( ({ [`objectId`]: (getObjectProperty(selectedItemOrganization, 'objectId')),[`status`]: 'ACTIVE' }) ));
      (getObjectProperty(___arguments.context.pageData, 'selectedItemOrganization'))['status'] = 'ACTIVE';
      ___arguments.context.pageData['refreshOrgTable'] = true;
      ___arguments.context.pageData['isConfirmationModalOpen'] = false;
      ___arguments.context.pageData['isOrganizationRecordActionDrawerOpen'] = false;
      ___arguments.context.pageData['selectedItemArrayOrg'] = false;
      await setToast(({ [`status`]: 'success',[`message`]: String(getObjectProperty(selectedItemOrganization, 'orgName')) + String(' successfully activated.') }));

    } catch (error) {
      await setToast(({ [`status`]: 'failure',[`message`]: 'Error activating the organization.' }));

    }
  } else if (confirmationOption == 'deactivateUser') {
    try {
      for (var selectedItem_index in selectedItemArrayUser) {
        selectedItem = selectedItemArrayUser[selectedItem_index];
        userTableResponse = (await Backendless.UserService.update( new Backendless.User(({ [`objectId`]: (getObjectProperty(selectedItem, 'objectId')),[`status`]: 'DEACTIVATED' })) ));
        if (getObjectProperty(userTableResponse, 'objectId')) {
          if ((getObjectProperty(currentUser, 'userRole')) == 'super') {
            if ((getObjectProperty(selectedItem, 'userRole')) == 'manager') {
              sendEmailResponse = (await BackendlessUI.Functions.Custom['fn_67c6f852cf5ce414b74b8f0b9401b852']('engineers@cyberdive.co', 'Cyber Dive', (getObjectProperty(selectedItem, 'email')), 'd-3088a12d29154a149afda7319b4aaa1f', ({ [`case_manager`]: (getObjectProperty(selectedItem, 'name')),[`organization_name`]: (getObjectProperty(selectedItem, 'orgName')) })));
            } else if ((getObjectProperty(selectedItem, 'userRole')) == 'admin') {
              sendEmailResponse = (await BackendlessUI.Functions.Custom['fn_67c6f852cf5ce414b74b8f0b9401b852']('engineers@cyberdive.co', 'Cyber Dive', (getObjectProperty(selectedItem, 'email')), 'd-1b4ba1b7ba7747d3a1e73426cc7c26f9', ({ [`organization_name`]: (getObjectProperty(selectedItem, 'orgName')),[`organization_admin`]: (getObjectProperty(selectedItem, 'name')) })));
            } else if ((getObjectProperty(selectedItem, 'userRole')) == 'super') {
              sendEmailResponse = true;
            }
          } else if ((getObjectProperty(currentUser, 'userRole')) == 'admin') {
            if ((getObjectProperty(selectedItem, 'userRole')) == 'manager') {
              sendEmailResponse = (await BackendlessUI.Functions.Custom['fn_67c6f852cf5ce414b74b8f0b9401b852']((getObjectProperty(currentUser, 'email')), (getObjectProperty(currentUser, 'name')), (getObjectProperty(selectedItem, 'email')), 'd-55de7fc773a0475f8b6bee559d3dbe2c', ({ [`case_manager`]: (getObjectProperty(selectedItem, 'name')),[`organization_name`]: (getObjectProperty(selectedItem, 'orgName')),[`organization_admin`]: (getObjectProperty(currentUser, 'name')) })));
            }
          }
        }
      }
      ___arguments.context.pageData['isUserRecordActionDrawerOpen'] = false;
      ___arguments.context.pageData['selectedItemArrayUser'] = false;
      ___arguments.context.pageData['isConfirmationModalOpen'] = false;
      await refreshUserTable();
      if (selectedItemArrayUser.length == 1) {
        await setToast(({ [`status`]: 'success',[`message`]: String('User successfully deactivated.') }));
      } else {
        await setToast(({ [`status`]: 'success',[`message`]: String((await (async function(userNumber) {
        	return `<strong>${userNumber} users</strong>`
        })((selectedItemArrayUser.length)))) + String(' successfully deactivated.') }));
      }

    } catch (error) {
      await setToast(({ [`status`]: 'failure',[`message`]: 'Issue deactivating user.' }));

    }
  } else if (confirmationOption == 'activateUser') {
    try {
      for (var selectedItem_index2 in selectedItemArrayUser) {
        selectedItem = selectedItemArrayUser[selectedItem_index2];
        userTableResponse = (await Backendless.UserService.update( new Backendless.User(({ [`objectId`]: (getObjectProperty(selectedItem, 'objectId')),[`status`]: 'ACTIVE' })) ));
        if (getObjectProperty(userTableResponse, 'objectId')) {
          if ((getObjectProperty(currentUser, 'userRole')) == 'super') {
            if ((getObjectProperty(selectedItem, 'userRole')) == 'manager') {
              sendEmailResponse = (await BackendlessUI.Functions.Custom['fn_67c6f852cf5ce414b74b8f0b9401b852']('engineers@cyberdive.co', 'Cyber Dive', (getObjectProperty(selectedItem, 'email')), 'd-251836f13fe34b33a164b7c57791abe5', ({ [`case_manager`]: (getObjectProperty(selectedItem, 'name')),[`organization_name`]: (getObjectProperty(selectedItem, 'orgName')) })));
            } else if ((getObjectProperty(selectedItem, 'userRole')) == 'admin') {
              sendEmailResponse = (await BackendlessUI.Functions.Custom['fn_67c6f852cf5ce414b74b8f0b9401b852']('engineers@cyberdive.co', 'Cyber Dive', (getObjectProperty(selectedItem, 'email')), 'd-20d900830c6740dfbe264ed215b3c5bd', ({ [`organization_name`]: (getObjectProperty(selectedItem, 'orgName')),[`organization_admin`]: (getObjectProperty(selectedItem, 'name')) })));
            }
          } else if ((getObjectProperty(currentUser, 'userRole')) == 'admin') {
            if ((getObjectProperty(selectedItem, 'userRole')) == 'manager') {
              sendEmailResponse = (await BackendlessUI.Functions.Custom['fn_67c6f852cf5ce414b74b8f0b9401b852']((getObjectProperty(currentUser, 'email')), (getObjectProperty(currentUser, 'name')), (getObjectProperty(selectedItem, 'email')), 'd-2387f288fb314359889799c9146a3f16', ({ [`case_manager`]: (getObjectProperty(selectedItem, 'name')),[`organization_admin`]: (getObjectProperty(currentUser, 'name')) })));
            }
          }
        }
      }
      ___arguments.context.pageData['isUserRecordActionDrawerOpen'] = false;
      ___arguments.context.pageData['isConfirmationModalOpen'] = false;
      ___arguments.context.pageData['selectedItemArrayUser'] = false;
      await refreshUserTable();
      if (selectedItemArrayUser.length == 1) {
        await setToast(({ [`status`]: 'success',[`message`]: String('User successfully activated.') }));
      } else {
        await setToast(({ [`status`]: 'success',[`message`]: String((await (async function(userNumber) {
        	return `<strong>${userNumber} users</strong>`
        })((selectedItemOrganization.length)))) + String(' successfully activated.') }));
      }

    } catch (error) {
      await setToast(({ [`status`]: 'failure',[`message`]: 'Issue activating user.' }));

    }
  } else if (confirmationOption == 'cancelUser') {
    try {
      for (var selectedItem_index3 in selectedItemArrayUser) {
        selectedItem = selectedItemArrayUser[selectedItem_index3];
        if ((getObjectProperty(selectedItem, 'userRole')) == 'manager') {
          sendEmailResponse = (await BackendlessUI.Functions.Custom['fn_67c6f852cf5ce414b74b8f0b9401b852']('engineers@cyberdive.co', 'Cyber Dive Team', (getObjectProperty(selectedItem, 'email')), 'd-07b9b52103aa4505994d7a9554d8e8c6', ({ [`case_manager`]: (getObjectProperty(selectedItem, 'name')),[`organization_admin`]: 'Cyber Dive Team' })));
        } else if ((getObjectProperty(selectedItem, 'userRole')) == 'admin') {
          sendEmailResponse = (await BackendlessUI.Functions.Custom['fn_67c6f852cf5ce414b74b8f0b9401b852']('engineers@cyberdive.co', 'Cyber Dive Team', (getObjectProperty(selectedItem, 'email')), 'd-f38f08cf334d4f3c80356947b37a2a12', ({ [`organization_admin`]: (getObjectProperty(selectedItem, 'name')) })));
        }
        await Backendless.Data.of(Backendless.User).remove({ objectId: (getObjectProperty(selectedItem, 'objectId')) });
      }
      ___arguments.context.pageData['isUserRecordActionDrawerOpen'] = false;
      ___arguments.context.pageData['isConfirmationModalOpen'] = false;
      ___arguments.context.pageData['selectedItemArrayUser'] = false;
      await refreshUserTable();
      if (selectedItemArrayUser.length == 1) {
        await setToast(({ [`status`]: 'success',[`message`]: String('Invitation successfully cancelled.') }));
      } else {
        await setToast(({ [`status`]: 'success',[`message`]: String((await (async function(userNumber) {
        	return `<strong>${userNumber} users</strong>`
        })((selectedItemArrayUser.length)))) + String(' successfully cancelled.') }));
      }

    } catch (error) {
      await setToast(({ [`status`]: 'failure',[`message`]: 'Issue cancelling invite.' }));

    }
  } else if (confirmationOption == 'resendUser') {
    for (var selectedItem_index4 in selectedItemArrayUser) {
      selectedItem = selectedItemArrayUser[selectedItem_index4];
      uniqueID = (generateUUID().long());
      await Backendless.Cache.put((getObjectProperty(selectedItem, 'email')), ({ [`key`]: uniqueID }), 7200);
      await Backendless.Cache.expireIn((getObjectProperty(selectedItem, 'email')), 7200);
      try {
        if ((getObjectProperty(currentUser, 'userRole')) == 'super') {
          if ((getObjectProperty(selectedItem, 'userRole')) == 'admin') {
            sendGridResponse = (await BackendlessUI.Functions.Custom['fn_67c6f852cf5ce414b74b8f0b9401b852']('engineers@cyberdive.co', 'Cyber Dive Team', (getObjectProperty(selectedItem, 'email')), 'd-f90fbbee97014b54bb155e790792e5bf', ({ [`organization_name`]: (getObjectProperty(selectedItem, 'orgName')),[`organization_admin`]: (getObjectProperty(selectedItem, 'name')),[`link`]: (await BackendlessUI.Functions.Custom['fn_a65e994797048d593245ed62a80fb31f']('invite', ({ [`user_email`]: (getObjectProperty(selectedItem, 'email')) }))) })));
          } else if ((getObjectProperty(selectedItem, 'userRole')) == 'manager') {
            sendGridResponse = (await BackendlessUI.Functions.Custom['fn_67c6f852cf5ce414b74b8f0b9401b852']('engineers@cyberdive.co', 'Cyber Dive Team', (getObjectProperty(selectedItem, 'email')), 'd-7ca2c090070c49319c4d0c3c0d03071f', ({ [`organization_name`]: (getObjectProperty(selectedItem, 'orgName')),[`organization_admin`]: 'Cyber Dive Team',[`case_manager`]: (getObjectProperty(selectedItem, 'name')),[`link`]: (await BackendlessUI.Functions.Custom['fn_a65e994797048d593245ed62a80fb31f']('invite', ({ [`user_email`]: (getObjectProperty(selectedItem, 'email')) }))) })));
          }
        } else if ((getObjectProperty(currentUser, 'userRole')) == 'admin') {
          if ((getObjectProperty(selectedItem, 'userRole')) == 'admin') {
            sendGridResponse = (await BackendlessUI.Functions.Custom['fn_67c6f852cf5ce414b74b8f0b9401b852']((getObjectProperty(currentUser, 'email')), (getObjectProperty(currentUser, 'name')), (getObjectProperty(selectedItem, 'email')), 'd-f90fbbee97014b54bb155e790792e5bf', ({ [`organization_name`]: (getObjectProperty(selectedItem, 'orgName')),[`organization_admin`]: (getObjectProperty(selectedItem, 'name')),[`link`]: (await BackendlessUI.Functions.Custom['fn_a65e994797048d593245ed62a80fb31f']('invite', ({ [`user_email`]: (getObjectProperty(selectedItem, 'email')),[`admin_email`]: (getObjectProperty(currentUser, 'email')) }))) })));
          } else if ((getObjectProperty(selectedItem, 'userRole')) == 'manager') {
            sendGridResponse = (await BackendlessUI.Functions.Custom['fn_67c6f852cf5ce414b74b8f0b9401b852']((getObjectProperty(currentUser, 'email')), (getObjectProperty(currentUser, 'name')), (getObjectProperty(selectedItem, 'email')), 'd-7ca2c090070c49319c4d0c3c0d03071f', ({ [`organization_name`]: (getObjectProperty(selectedItem, 'orgName')),[`organization_admin`]: (getObjectProperty(currentUser, 'name')),[`case_manager`]: (getObjectProperty(selectedItem, 'name')),[`link`]: (await BackendlessUI.Functions.Custom['fn_a65e994797048d593245ed62a80fb31f']('invite', ({ [`confirmation_key`]: uniqueID,[`user_email`]: (getObjectProperty(selectedItem, 'email')),[`admin_email`]: (getObjectProperty(currentUser, 'email')) }))) })));
          }
        }
        if (sendGridResponse) {
          await Backendless.UserService.update( new Backendless.User(({ [`inviteSent`]: (new Date()),[`objectId`]: (getObjectProperty(selectedItem, 'objectId')),[`inviteStatus`]: 'RESENT' })) );
        }

      } catch (error) {
        await setToast(({ [`status`]: 'failure',[`message`]: 'Problem resending the invite.' }));

      }
    }
    ___arguments.context.pageData['isUserRecordActionDrawerOpen'] = false;
    ___arguments.context.pageData['isConfirmationModalOpen'] = false;
    await refreshUserTable();
    if (selectedItemArrayUser.length == 1) {
      await setToast(({ [`status`]: 'success',[`message`]: String('Invite successfully resent.') }));
    } else {
      await setToast(({ [`status`]: 'success',[`message`]: String((await (async function(userNumber) {
      	return `<strong>${userNumber} organizations</strong>`
      })((selectedItemArrayUser.length)))) + String(' successfully resent.') }));
    }
  } else if (confirmationOption == 'deactivateCurfew') {
    try {
      for (var selectedItem_index5 in selectedItemArrayCurfew) {
        selectedItem = selectedItemArrayCurfew[selectedItem_index5];
        curfewTableResponse = (await Backendless.Data.of('Curfews').save( ({ [`objectId`]: (getObjectProperty(selectedItem, 'objectId')),[`status`]: 'DEACTIVATED' }) ));
      }
      ___arguments.context.pageData['isCurfewRecordActionDrawerOpen'] = false;
      ___arguments.context.pageData['isConfirmationModalOpen'] = false;
      ___arguments.context.pageData['selectedItemArrayCurfew'] = [];
      await refreshCurfewTable();
      await setToast(({ [`status`]: 'success',[`message`]: String('Curfew successfully deactivated.') }));

    } catch (error) {
      await setToast(({ [`status`]: 'failure',[`message`]: 'Issue deactivating curfew.' }));

    }
  } else if (confirmationOption == 'activateCurfew') {
    try {
      for (var selectedItem_index6 in selectedItemArrayCurfew) {
        selectedItem = selectedItemArrayCurfew[selectedItem_index6];
        curfewTableResponse = (await Backendless.Data.of('Curfews').save( ({ [`objectId`]: (getObjectProperty(selectedItem, 'objectId')),[`status`]: 'ACTIVE' }) ));
      }
      ___arguments.context.pageData['isConfirmationModalOpen'] = false;
      ___arguments.context.pageData['isCurfewRecordActionDrawerOpen'] = false;
      ___arguments.context.pageData['selectedItemArrayCurfew'] = [];
      await refreshCurfewTable();
      await setToast(({ [`status`]: 'success',[`message`]: String('Curfew successfully activated.') }));

    } catch (error) {
      await setToast(({ [`status`]: 'failure',[`message`]: 'Issue activating curfew.' }));

    }
  } else if (confirmationOption == 'deleteCurfew') {
    try {
      for (var selectedItem_index7 in selectedItemArrayCurfew) {
        selectedItem = selectedItemArrayCurfew[selectedItem_index7];
        await Backendless.Data.of('Curfews').remove( (getObjectProperty(selectedItem, 'objectId')) );
      }
      ___arguments.context.pageData['isCurfewRecordActionDrawerOpen'] = false;
      ___arguments.context.pageData['isConfirmationModalOpen'] = false;
      ___arguments.context.pageData['selectedItemArrayCurfew'] = [];
      await refreshCurfewTable();
      await setToast(({ [`status`]: 'success',[`message`]: String('Curfew successfully deleted.') }));

    } catch (error) {
      await setToast(({ [`status`]: 'failure',[`message`]: 'Issue deleting curfew.' }));

    }
  } else if (confirmationOption == 'deleteActiveCurfew') {
    ___arguments.context.pageData['isConfirmationModalOpen'] = false;
  } else if (confirmationOption == 'cancelIntake') {
    ___arguments.context.pageData['refreshResidentTable'] = true;
    ___arguments.context.pageData['isConfirmationModalOpen'] = false;
    ___arguments.context.pageData['isAddRecordModalOpen'] = false;
    if (getObjectProperty(___arguments.context.pageData, 'isNewResident')) {
      ___arguments.context.pageData['isSuccessModalOpen'] = true;
    }
    if ((getObjectProperty(___arguments.context.pageData, 'residentIntakeVariant')) == 'viewIntake') {
      ___arguments.context.pageData['residentIntakeForm'] = (JSON.parse((getObjectProperty((await Backendless.Data.of('Residents').findById((getObjectProperty(___arguments.context.pageData, 'selectedItemResident.objectId')), )), 'residentIntakeForm'))));
    }
  } else if (confirmationOption == 'deactivateResident') {
    try {
      (getObjectProperty(___arguments.context.pageData, 'residentIntakeForm'))['status'] = 'DEACTIVATED';
      await Backendless.Data.of('Residents').save( ({ [`objectId`]: (getObjectProperty(___arguments.context.pageData, 'selectedItemResident.objectId')),[`status`]: 'DEACTIVATED',[`residentIntakeForm`]: (getObjectProperty(___arguments.context.pageData, 'residentIntakeForm')) }) );
      ___arguments.context.pageData['refreshResidentTable'] = true;
      ___arguments.context.pageData['isConfirmationModalOpen'] = false;
      ___arguments.context.pageData['isAddRecordModalOpen'] = false;
      await setToast(({ [`status`]: 'success',[`message`]: String((await BackendlessUI.Functions.Custom['fn_9807a958b9f47818617415e4438b5e47']((getObjectProperty(___arguments.context.pageData, 'selectedItemResident.nameInUse')), 10))) + String(' has been deactivated') }));

    } catch (error) {
      await setToast(({ [`status`]: 'failure',[`message`]: 'Error deactivating resident' }));

    }
  } else if (confirmationOption == 'activateResident') {
    try {
      (getObjectProperty(___arguments.context.pageData, 'residentIntakeForm'))['status'] = 'ACTIVE';
      await Backendless.Data.of('Residents').save( ({ [`objectId`]: (getObjectProperty(___arguments.context.pageData, 'selectedItemResident.objectId')),[`status`]: 'ACTIVE',[`residentIntakeForm`]: (getObjectProperty(___arguments.context.pageData, 'residentIntakeForm')) }) );
      ___arguments.context.pageData['refreshResidentTable'] = true;
      ___arguments.context.pageData['isConfirmationModalOpen'] = false;
      ___arguments.context.pageData['isAddRecordModalOpen'] = false;
      await setToast(({ [`status`]: 'success',[`message`]: String((await BackendlessUI.Functions.Custom['fn_9807a958b9f47818617415e4438b5e47']((getObjectProperty(___arguments.context.pageData, 'selectedItemResident.nameInUse')), 10))) + String(' has been activated') }));

    } catch (error) {
      await setToast(({ [`status`]: 'failure',[`message`]: 'Error activating resident' }));

    }
  } else if (confirmationOption == 'deleteContact') {
    try {
      newContactArray = (await asyncListFilter((getObjectProperty(___arguments.context.pageData, 'residentIntakeForm.contactArray')), async (item) => {
         if ((getObjectProperty(___arguments.context.pageData, 'selectedItemContact')) != item) {
          return true;
        }


       return ;
      }));
      (getObjectProperty(___arguments.context.pageData, 'residentIntakeForm'))['contactArray'] = newContactArray;
      await Backendless.Data.of('Residents').save( ({ [`objectId`]: (getObjectProperty(___arguments.context.pageData, 'selectedItemResident.objectId')),[`status`]: 'ACTIVE',[`residentIntakeForm`]: (getObjectProperty(___arguments.context.pageData, 'residentIntakeForm')) }) );
      ___arguments.context.pageData['selectedItemContact'] = undefined;
      ___arguments.context.pageData['refreshResidentTable'] = true;
      ___arguments.context.pageData['isConfirmationModalOpen'] = false;
      ___arguments.context.pageData['isAddRecordModalOpen'] = false;
      await setToast(({ [`status`]: 'success',[`message`]: 'Successfully deleted contact' }));

    } catch (error) {
      await setToast(({ [`status`]: 'failure',[`message`]: 'Error deleting contact' }));

    }
  }
  ___arguments.context.pageData['isLoadingAction'] = false;

  },
  /* handler:onClick */
  /* handler:onLabelAssignment */
  ['onLabelAssignment'](___arguments) {
    var confirmationOption;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  confirmationOption = (getObjectProperty(___arguments.context.pageData, 'confirmationOption'));
  if (confirmationOption == 'deactivateResident' || confirmationOption == 'deactivateUser' || confirmationOption == 'deactivateCurfew' || confirmationOption == 'deactivateOrganization') {
    return 'Yes, Deactivate';
  } else if (confirmationOption == 'resendUser') {
    return 'Send Invite';
  } else if (confirmationOption == 'cancelUser' || confirmationOption == 'cancelIntake') {
    return 'Yes, Cancel';
  } else if (confirmationOption == 'deleteContact' || confirmationOption == 'deleteCurfew') {
    return 'Yes, Delete';
  } else if (confirmationOption == 'saveResidentForm') {
    return 'Save';
  } else if (confirmationOption == 'deleteActiveCurfew') {
    return 'Okay';
  } else {
    return 'Yes, Activate';
  }

  },
  /* handler:onLabelAssignment */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    var classes, confirmationOption;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  confirmationOption = (getObjectProperty(___arguments.context.pageData, 'confirmationOption'));
  if (confirmationOption == 'deleteContact' || confirmationOption == 'deactivateResident' || confirmationOption == 'cancelIntake' || confirmationOption == 'cancelUser' || confirmationOption == 'deleteCurfew' || confirmationOption == 'deactivateUser' || confirmationOption == 'deactivateCurfew' || confirmationOption == 'deactivateOrganization') {
    classes = ['red_button'];
  } else {
    classes = ['enabled_button'];
  }

  return classes

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./components/reusable/r_ebc68c282ae4812c64225da604c336bb/logic/ef9f2f4e57120713b6bebadc18471b12/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
    var confirmationOption, selectedItemArrayUser, selectedItemOrganization, selectedItemArrayCurfew;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  selectedItemArrayCurfew = (getObjectProperty(___arguments.context.pageData, 'selectedItemArrayCurfew'));
  selectedItemOrganization = (getObjectProperty(___arguments.context.pageData, 'selectedItemOrganization'));
  selectedItemArrayUser = (getObjectProperty(___arguments.context.pageData, 'selectedItemArrayUser'));
  confirmationOption = (getObjectProperty(___arguments.context.pageData, 'confirmationOption'));
  if (selectedItemArrayCurfew && selectedItemArrayCurfew.length == 1) {
    if (confirmationOption == 'deactivateCurfew') {
      return (['Are you sure you want to deactivate the ',(getObjectProperty((selectedItemArrayCurfew[0]), 'name')),' curfew?'].join(''));
    } else if (confirmationOption == 'activateCurfew') {
      return (['Are you sure you want to activate the ',(getObjectProperty((selectedItemArrayCurfew[0]), 'name')),' curfew?'].join(''));
    } else if (confirmationOption == 'deleteActiveCurfew') {
      return (['You can\'t delete an ',(await (async function() {
      	return "<strong>active</strong>"
      })()),' curfew. Please select a ',(await (async function() {
      	return "<strong>deactivated</strong>"
      })()),' curfew.'].join(''));
    } else if (confirmationOption == 'deleteCurfew') {
      return (['Are you sure you want to delete the ',(getObjectProperty((selectedItemArrayCurfew[0]), 'name')),' curfew?'].join(''));
    }
  } else if (selectedItemOrganization) {
    if (confirmationOption == 'deactivateOrganization') {
      return (['Are you sure you want to deactivate ',(getObjectProperty(selectedItemOrganization, 'orgName')),' Organization?'].join(''));
    } else if (confirmationOption == 'activateOrganization') {
      return (['Are you sure you want to activate ',(getObjectProperty(selectedItemOrganization, 'orgName')),' Organization?'].join(''));
    }
  } else if (selectedItemArrayUser && selectedItemArrayUser.length > 0) {
    if (selectedItemArrayUser.length == 1) {
      if (confirmationOption == 'deactivateUser') {
        return (['Are you sure you want to deactivate ',(getObjectProperty((selectedItemArrayUser[0]), 'name')),'\'s account?'].join(''));
      } else if (confirmationOption == 'activateUser') {
        return (['Are you sure you want to activate  ',(getObjectProperty((selectedItemArrayUser[0]), 'name')),'\'s account?'].join(''));
      } else if (confirmationOption == 'cancelUser') {
        return (['Are you sure you want to cancel ',(getObjectProperty((selectedItemArrayUser[0]), 'name')),'\'s invite?'].join(''));
      } else if (confirmationOption == 'resendUser') {
        return (['Are you sure you want to resend an invite to ',(getObjectProperty((selectedItemArrayUser[0]), 'name')),'?'].join(''));
      }
    } else {
      if (confirmationOption == 'deactivateUser') {
        return (String('Are you sure you want to deactivate '));
      } else if (confirmationOption == 'activateUser') {
        return (String('Are you sure you want to activate  '));
      } else if (confirmationOption == 'cancelUser') {
        return (String('Are you sure you want to cancel '));
      } else if (confirmationOption == 'resendUser') {
        return (String('Are you sure you want to resend an invite to '));
      }
    }
  } else if (confirmationOption == 'cancelIntake') {
    return (String('Are you sure you want to cancel?'));
  } else if (confirmationOption == 'deactivateResident') {
    return (['Are you sure you want to deactivate ',(getObjectProperty(___arguments.context.pageData, 'selectedItemResident.nameInUse')),'?'].join(''));
  } else if (confirmationOption == 'activateResident') {
    return (['Are you sure you want to activate ',(getObjectProperty(___arguments.context.pageData, 'selectedItemResident.nameInUse')),'?'].join(''));
  } else if (confirmationOption == 'deleteContact') {
    return (['Are you sure you want to delete ',(getObjectProperty(___arguments.context.pageData, 'selectedItemContact.fullName')),' as a contact?'].join(''));
  }

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./components/reusable/r_ebc68c282ae4812c64225da604c336bb/logic/887cc68e4bd1bf6dafeafcbc7edd4a9a/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    var confirmationOption;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'confirmationOption')) == 'cancelIntake') {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('887cc68e4bd1bf6dafeafcbc7edd4a9a', true);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('887cc68e4bd1bf6dafeafcbc7edd4a9a', false);
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./components/reusable/r_ebc68c282ae4812c64225da604c336bb/logic/ccab5a862b6c30289e83e1a305d5de96/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'recordTableVariant')) == 'resident') {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('ccab5a862b6c30289e83e1a305d5de96', true);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('ccab5a862b6c30289e83e1a305d5de96', false);
  }

  },
  /* handler:onStyleAssignment */
  /* handler:onSourceUrlAssignment */
  ['onSourceUrlAssignment'](___arguments) {
    var confirmationOption, selectedItemArrayUser, selectedItemOrganizations, selectedItemArrayCurfew;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  selectedItemArrayCurfew = (getObjectProperty(___arguments.context.pageData, 'selectedItemArrayCurfew'));
  selectedItemOrganizations = (getObjectProperty(___arguments.context.pageData, 'selectedItemOrganizations'));
  selectedItemArrayUser = (getObjectProperty(___arguments.context.pageData, 'selectedItemArrayUser'));
  confirmationOption = (getObjectProperty(___arguments.context.pageData, 'confirmationOption'));
  if (selectedItemArrayCurfew && selectedItemArrayCurfew.length == 1) {
    if (confirmationOption == 'deactivateCurfew') {
      return ;
    } else if (confirmationOption == 'activateCurfew') {
      return ;
    } else if (confirmationOption == 'deleteActiveCurfew') {
      return ;
    } else if (confirmationOption == 'deleteCurfew') {
      return ;
    }
  } else if (selectedItemOrganizations) {
    if (confirmationOption == 'deactivateOrganization') {
      return 'https://app.aquax.co/api/files/cyberdive_photos/cd-icon-deactivateOrganization.png';
    } else if (confirmationOption == 'activateOrganization') {
      return 'https://app.aquax.co/api/files/cyberdive_photos/cd-icon-activateOrganization.png';
    }
  } else if (selectedItemArrayUser && selectedItemArrayUser.length > 0) {
    if (selectedItemArrayUser.length == 1) {
      if (confirmationOption == 'deactivateUser') {
        return (['Are you sure you want to deactivate ',(getObjectProperty((selectedItemArrayUser[0]), 'name')),'\'s account?'].join(''));
      } else if (confirmationOption == 'activateUser') {
        return (['Are you sure you want to activate  ',(getObjectProperty((selectedItemArrayUser[0]), 'name')),'\'s account?'].join(''));
      } else if (confirmationOption == 'cancelUser') {
        return (['Are you sure you want to cancel ',(getObjectProperty((selectedItemArrayUser[0]), 'name')),'\'s invite?'].join(''));
      } else if (confirmationOption == 'resendUser') {
        return (['Are you sure you want to resend an invite to ',(getObjectProperty((selectedItemArrayUser[0]), 'name')),'?'].join(''));
      }
    } else {
      if (confirmationOption == 'deactivateUser') {
        return (String('Are you sure you want to deactivate '));
      } else if (confirmationOption == 'activateUser') {
        return (String('Are you sure you want to activate  '));
      } else if (confirmationOption == 'cancelUser') {
        return (String('Are you sure you want to cancel '));
      } else if (confirmationOption == 'resendUser') {
        return (String('Are you sure you want to resend an invite to '));
      }
    }
  } else if (confirmationOption == 'cancelIntake') {
    return 'https://app.aquax.co/api/files/cyberdive_photos/cd-icon-cancel.png';
  } else if (confirmationOption == 'deactivateResident') {
    return (['Are you sure you want to deactivate ',(getObjectProperty(___arguments.context.pageData, 'selectedItemResident.nameInUse')),'?'].join(''));
  } else if (confirmationOption == 'activateResident') {
    return (['Are you sure you want to activate ',(getObjectProperty(___arguments.context.pageData, 'selectedItemResident.nameInUse')),'?'].join(''));
  } else if (confirmationOption == 'deleteContact') {
    return (['Are you sure you want to delete ',(getObjectProperty(___arguments.context.pageData, 'selectedItemContact.fullName')),' as a contact?'].join(''));
  }

  },
  /* handler:onSourceUrlAssignment */
  /* content */
}))

define('./components/reusable/r_5871cdf8de1657653b530652a7730dc7/logic/cbd9bbf2cbaa79385815ce9a54c13ecc/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    var classesList;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  classesList = ['modal'];
  if (getObjectProperty(___arguments.context.pageData, 'isAddRecordModalOpen')) {
    addItemToList(classesList, 'open');
  }

  return classesList

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./components/reusable/r_5871cdf8de1657653b530652a7730dc7/logic/e048ee785396199ce19c31c9bb2b0a58/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'recordTableVariant')) == 'resident') {
    if ((getObjectProperty(___arguments.context.pageData, 'residentFormVariant')) == 'add') {
      return 'Add Resident';
    } else if ((getObjectProperty(___arguments.context.pageData, 'residentFormVariant')) == 'view') {
      return 'View Resident Details';
    }
  } else if ((getObjectProperty(___arguments.context.pageData, 'recordTableVariant')) == 'organization') {
    return 'Add Organization';
  } else if ((getObjectProperty(___arguments.context.pageData, 'recordTableVariant')) == 'user') {
    return 'Add User';
  } else if ((getObjectProperty(___arguments.context.pageData, 'recordTableVariant')) == 'curfew') {
    if ((getObjectProperty(___arguments.context.pageData, 'curfewTableVariant')) == 'edit') {
      return 'Edit Curfew';
    } else {
      return 'Add Curfew';
    }
  }

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./components/reusable/r_5871cdf8de1657653b530652a7730dc7/logic/44e507f12895338f26ed6555767f8967/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'recordTableVariant')) == 'resident') {
    ___arguments.context.pageData['confirmationOption'] = 'saveResidentForm';
    ___arguments.context.pageData['isConfirmationModalOpen'] = true;
  } else {
    ___arguments.context.pageData['isAddRecordModalOpen'] = false;
  }

  },
  /* handler:onClick */
  /* content */
}))

define('./components/reusable/r_5871cdf8de1657653b530652a7730dc7/logic/bc48a3167b8647683a338f75614c75eb/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    var currentUser;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  currentUser = (getObjectProperty(___arguments.context.pageData, 'currentUser'));
  if (currentUser) {
    if ((getObjectProperty(___arguments.context.pageData, 'recordTableVariant')) == 'user' && (getObjectProperty(currentUser, 'userRole')) != 'manager') {
      (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('bc48a3167b8647683a338f75614c75eb', true);
    } else {
      (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('bc48a3167b8647683a338f75614c75eb', false);
    }
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./components/reusable/r_5871cdf8de1657653b530652a7730dc7/logic/3a52d95329267b59265c221f82be5b57/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'recordTableVariant')) == 'organization') {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('3a52d95329267b59265c221f82be5b57', true);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('3a52d95329267b59265c221f82be5b57', false);
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./components/reusable/r_5871cdf8de1657653b530652a7730dc7/logic/b039316bd64b7269ddc23064d8acba97/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    var addOrganizationStep;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  addOrganizationStep = (getObjectProperty(___arguments.context.pageData, 'addOrganizationStep'));
  if (addOrganizationStep == 1) {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('b039316bd64b7269ddc23064d8acba97', true);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('b039316bd64b7269ddc23064d8acba97', false);
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./components/reusable/r_5871cdf8de1657653b530652a7730dc7/logic/f3814b44d39eac6208d15583a3b8a94b/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    var classesList;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  classesList = [];
  if (getObjectProperty(___arguments.context.pageData, 'orgNameError')) {
    addItemToList(classesList, 'input_red_border');
  }

  return classesList

  },
  /* handler:onClassListAssignment */
  /* handler:onChange */
  async ['onChange'](___arguments) {
      ___arguments.context.pageData['orgNameError'] = false;
  ___arguments.context.pageData['organizationName'] = (await BackendlessUI.Functions.Custom['fn_256db7f8ee15af9d778314d97f563562'](___arguments.value));

  },
  /* handler:onChange */
  /* content */
}))

define('./components/reusable/r_5871cdf8de1657653b530652a7730dc7/logic/6bfa60f74236e69dbdd147254f796cdd/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'orgNameError')) {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('6bfa60f74236e69dbdd147254f796cdd', true);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('6bfa60f74236e69dbdd147254f796cdd', false);
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./components/reusable/r_5871cdf8de1657653b530652a7730dc7/logic/774972b6c1ef2fa7a8faf0d7a0f81cc7/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  async ['onStyleAssignment'](___arguments) {
    var item, isAddRecordModalOpen;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  isAddRecordModalOpen = (getObjectProperty(___arguments.context.pageData, 'isAddRecordModalOpen'));
  if (isAddRecordModalOpen) {
    (function (componentUid, options) { ___arguments.context.getComponentByUid(componentUid).options = options })('774972b6c1ef2fa7a8faf0d7a0f81cc7', (await Promise.all(('AK,AL,AR,AZ,CA,CO,CT,DE,FL,GA,HI,IA,ID,IL,IN,KS,KY,LA,MA,MD,ME,MI,MN,MO,MS,MT,NC,ND,NE,NH,NJ,NM,NV,NY,OH,OK,OR,PA,RI,SC,SD,TN,TX,UT,VA,VT,WA,WI,WV,WY'.split(',')).map(async item => {; return ({ [`value`]: item,[`label`]: item });}))));
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./components/reusable/r_5871cdf8de1657653b530652a7730dc7/logic/a9937d75d08e11b1f4974eb4b87e045b/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  async ['onChange'](___arguments) {
      ___arguments.context.pageData['zipCodeError'] = false;
  ___arguments.context.pageData['zipCode'] = (await BackendlessUI.Functions.Custom['fn_2dc3266507a474ba2f5a291d2361a812'](___arguments.value));

  },
  /* handler:onChange */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    var classesList;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  classesList = [];
  if (getObjectProperty(___arguments.context.pageData, 'zipCodeError')) {
    addItemToList(classesList, 'input_red_border');
  }

  return classesList

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./components/reusable/r_5871cdf8de1657653b530652a7730dc7/logic/3cfea8ba4726fe56d1870fb4d7e4d6d9/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    var classesList;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  classesList = [];
  if (getObjectProperty(___arguments.context.pageData, 'websiteError')) {
    addItemToList(classesList, 'input_red_border');
  }

  return classesList

  },
  /* handler:onClassListAssignment */
  /* handler:onChange */
  ['onChange'](___arguments) {
      ___arguments.context.pageData['websiteError'] = false;

  },
  /* handler:onChange */
  /* content */
}))

define('./components/reusable/r_5871cdf8de1657653b530652a7730dc7/logic/386cad05e84b86f51db1a09a6612c9c2/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'websiteError')) {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('386cad05e84b86f51db1a09a6612c9c2', true);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('386cad05e84b86f51db1a09a6612c9c2', false);
  }

  },
  /* handler:onStyleAssignment */
  /* handler:onClick */
  ['onClick'](___arguments) {
      ___arguments.context.pageData['websiteError'] = false;

  },
  /* handler:onClick */
  /* content */
}))

define('./components/reusable/r_5871cdf8de1657653b530652a7730dc7/logic/40215aaee6db51337d51fab3f245bbdf/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var hasNoError, websiteLink, isDuplicateArray, zipCode, error;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

/**
 * Describe this function...
 */
async function addToast(error) {
  if (error) {
    ___arguments.context.appData['toastState'] = ({ [`status`]: (getObjectProperty(error, 'status')),[`message`]: (getObjectProperty(error, 'message')) });
  }
}


  isDuplicateArray = (await Backendless.Data.of('Organizations').find(Backendless.DataQueryBuilder.create().setWhereClause((['orgName = \'',(getObjectProperty(___arguments.context.pageData, 'organizationName')),'\''].join(''))).setPageSize(10)));
  zipCode = (getObjectProperty(___arguments.context.pageData, 'zipCode'));
  websiteLink = (getObjectProperty(___arguments.context.pageData, 'websiteLink'));
  hasNoError = true;
  if (!(await BackendlessUI.Functions.Custom['fn_c16fa4da6fdc2a0ab06c37eb5691c2e2'](zipCode))) {
    ___arguments.context.pageData['zipCodeError'] = true;
    hasNoError = false;
  }
  if (isDuplicateArray.length > 0) {
    ___arguments.context.pageData['orgNameError'] = true;
    hasNoError = false;
  }
  if (!(await BackendlessUI.Functions.Custom['fn_4d0b6f63e09985a12656f99b68cbc84d'](websiteLink))) {
    ___arguments.context.pageData['websiteError'] = true;
    hasNoError = false;
  }
  if (hasNoError) {
    ___arguments.context.pageData['addOrganizationStep'] = 2;
  }

  },
  /* handler:onClick */
  /* handler:onStyleAssignment */
  async ['onStyleAssignment'](___arguments) {
    var isDisabled, zipCode, state, city, streetAddress, websiteLink, orgName;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  orgName = (getObjectProperty(___arguments.context.pageData, 'organizationName'));
  streetAddress = (getObjectProperty(___arguments.context.pageData, 'streetAddress'));
  city = (getObjectProperty(___arguments.context.pageData, 'city'));
  state = (getObjectProperty(___arguments.context.pageData, 'state'));
  zipCode = (getObjectProperty(___arguments.context.pageData, 'zipCode'));
  websiteLink = (getObjectProperty(___arguments.context.pageData, 'websiteLink'));
  isDisabled = false;
  if (!orgName || (await BackendlessUI.Functions.Custom['fn_a7ad4c1fd5d18ca6bff3eb611aeee2ac'](orgName))) {
    isDisabled = true;
  } else if (!websiteLink || (await BackendlessUI.Functions.Custom['fn_a7ad4c1fd5d18ca6bff3eb611aeee2ac'](websiteLink))) {
    isDisabled = true;
  } else if (!streetAddress || (await BackendlessUI.Functions.Custom['fn_a7ad4c1fd5d18ca6bff3eb611aeee2ac'](streetAddress))) {
    isDisabled = true;
  } else if (!city || (await BackendlessUI.Functions.Custom['fn_a7ad4c1fd5d18ca6bff3eb611aeee2ac'](city))) {
    isDisabled = true;
  } else if (!state || (await BackendlessUI.Functions.Custom['fn_a7ad4c1fd5d18ca6bff3eb611aeee2ac'](state))) {
    isDisabled = true;
  } else if (!zipCode || (await BackendlessUI.Functions.Custom['fn_a7ad4c1fd5d18ca6bff3eb611aeee2ac'](zipCode))) {
    isDisabled = true;
  }
  ___arguments.context.pageData['isAddOrganizationNextStepDisabled'] = isDisabled;

  },
  /* handler:onStyleAssignment */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    var classesList;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  classesList = ['default_button'];
  if (getObjectProperty(___arguments.context.pageData, 'isAddOrganizationNextStepDisabled')) {
    addItemToList(classesList, 'disabled_button');
  } else {
    addItemToList(classesList, 'enabled_button');
  }

  return classesList

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./components/reusable/r_5871cdf8de1657653b530652a7730dc7/logic/c6c73f197d0d891cea24216f00449507/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      ___arguments.context.pageData['isAddRecordModalOpen'] = false;

  },
  /* handler:onClick */
  /* content */
}))

define('./components/reusable/r_5871cdf8de1657653b530652a7730dc7/logic/b9f4899578450d753c6357f95cb7bf3a/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    var addOrganizationStep;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  addOrganizationStep = (getObjectProperty(___arguments.context.pageData, 'addOrganizationStep'));
  if (addOrganizationStep == 2) {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('b9f4899578450d753c6357f95cb7bf3a', true);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('b9f4899578450d753c6357f95cb7bf3a', false);
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./components/reusable/r_5871cdf8de1657653b530652a7730dc7/logic/7c7e342e261f795150afa84348059c14/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    var classesList;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  classesList = [];
  if (getObjectProperty(___arguments.context.pageData, 'taxIdError')) {
    addItemToList(classesList, 'input_red_border');
  }

  return classesList

  },
  /* handler:onClassListAssignment */
  /* handler:onChange */
  async ['onChange'](___arguments) {
      ___arguments.context.pageData['taxIdError'] = false;
  ___arguments.context.pageData['taxId'] = (await BackendlessUI.Functions.Custom['fn_3c37569726d2e37c1909af054741f424'](___arguments.value, 'create', ___arguments.prevValue));

  },
  /* handler:onChange */
  /* content */
}))

define('./components/reusable/r_5871cdf8de1657653b530652a7730dc7/logic/f60ed163967646c86361e88c6a46fcee/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    var classesList;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  classesList = [];
  if (getObjectProperty(___arguments.context.pageData, 'billingPhoneError')) {
    addItemToList(classesList, 'input_red_border');
  }

  return classesList

  },
  /* handler:onClassListAssignment */
  /* handler:onChange */
  async ['onChange'](___arguments) {
      ___arguments.context.pageData['billingPhoneError'] = false;
  ___arguments.context.pageData['billingPhone'] = (await BackendlessUI.Functions.Custom['fn_f282814277b3c0aa13f33d3b2cda111b'](___arguments.value, 'create', null));

  },
  /* handler:onChange */
  /* content */
}))

define('./components/reusable/r_5871cdf8de1657653b530652a7730dc7/logic/5c1a7322c4bb9f5c8fcbc9f54a544bff/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    var classesList;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  classesList = [];
  if (getObjectProperty(___arguments.context.pageData, 'billingEmailError')) {
    addItemToList(classesList, 'input_red_border');
  }

  return classesList

  },
  /* handler:onClassListAssignment */
  /* handler:onChange */
  ['onChange'](___arguments) {
      ___arguments.context.pageData['billingEmailError'] = false;

  },
  /* handler:onChange */
  /* content */
}))

define('./components/reusable/r_5871cdf8de1657653b530652a7730dc7/logic/a3f47bb6832148cf10696b18a3689901/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'billingEmailError')) {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('a3f47bb6832148cf10696b18a3689901', true);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('a3f47bb6832148cf10696b18a3689901', false);
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./components/reusable/r_5871cdf8de1657653b530652a7730dc7/logic/f8a3449104eaa2ceb8518572e9dee2bb/bundle.js', [], () => ({
  /* content */
  /* handler:onValueAssignment */
  ['onValueAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'imageName')) {
    return (getObjectProperty(___arguments.context.pageData, 'imageName'));
  } else {
    return 'Upload Company Logo';
  }

  },
  /* handler:onValueAssignment */
  /* content */
}))

define('./components/reusable/r_5871cdf8de1657653b530652a7730dc7/logic/e685862779b9d3915c6fdad3a4e8ceae/bundle.js', [], () => ({
  /* content */
  /* handler:onSelectFiles */
  ['onSelectFiles'](___arguments) {
      ___arguments.context.pageData['logoPicture'] = ___arguments.selectedFiles;
  ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('e685862779b9d3915c6fdad3a4e8ceae'))['background-color'] = '#0bbd29';

  },
  /* handler:onSelectFiles */
  /* content */
}))

define('./components/reusable/r_5871cdf8de1657653b530652a7730dc7/logic/ecfeff0427e721d52f67aebf6712b621/bundle.js', [], () => ({
  /* content */
  /* handler:onSourceUrlAssignment */
  ['onSourceUrlAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (getObjectProperty(___arguments.context.pageData, 'orgPicture'))

  },
  /* handler:onSourceUrlAssignment */
  /* handler:onStyleAssignment */
  async ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function readBrowserFileAs(file, type) {
   const readers = { base64: "readAsDataURL", utf8: "readAsText", bytes: "readAsArrayBuffer" }

   return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader[readers[type]](file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = error => reject(error)
  })
}


  if (getObjectProperty(___arguments.context.pageData, 'logoPicture')) {
    ___arguments.context.pageData['orgPicture'] = (await readBrowserFileAs(((getObjectProperty(___arguments.context.pageData, 'logoPicture'))[0]), 'base64'));
    ___arguments.context.pageData['imageName'] = (getObjectProperty(((getObjectProperty(___arguments.context.pageData, 'logoPicture'))[0]), 'name'));
  } else {
    ___arguments.context.pageData['orgPicture'] = 'https://app.aquax.co/api/files/cyberdive_photos/cd-icon-default-photo.svg';
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./components/reusable/r_5871cdf8de1657653b530652a7730dc7/logic/5314c44a5d4ee9c39d630b378f9865cc/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var toast, addLogoResponse, organizationApiResponse, streetAddress, state, city, zipCode, taxId, websiteLink, orgName, billingPhone, billingName, billingEmail, hasNoErrors, duplicateTaxId, uniqueID, error, isLoading, buttonLogin, logoPicture;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

/**
 * Describe this function...
 */
async function addToast(toast) {
  await isButtonLoading(true);
  await BackendlessUI.Functions.Custom['fn_77bfd63e533a9b4c5577e5fe31a4b5ca'](___arguments.context.pageData, (getObjectProperty(toast, 'message')), (getObjectProperty(toast, 'status')));
}

/**
 * Describe this function...
 */
async function refreshOrgTable() {
  ___arguments.context.pageData['refreshOrgTable'] = ({ [`changedValue`]: (getObjectProperty(___arguments.context.pageData, 'changedValueOrg')) });
}

function generateUUID() {
   const chr4 = () => Math.random().toString(16).slice(-4);
   const chr8 = () => `${chr4()}${chr4()}`;
   const short = () => chr8();
   const long = () => `${chr8()}-${chr4()}-${chr4()}-${chr4()}-${chr8()}${chr4()}`;

   return { short, long, }
}

/**
 * Describe this function...
 */
async function isButtonLoading(isLoading) {
  ___arguments.context.pageData['isButtonLoading'] = isLoading;
  buttonLogin = ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('5314c44a5d4ee9c39d630b378f9865cc'));
  buttonLogin['label'] = (isLoading ? 'Please wait...' : 'Add Organization');
}

function encodePath(path) {
  if(path.startsWith("http://") || path.startsWith("https://")) {
    return path
  }

  let decodedPath
  try {
    decodedPath = decodeURI(path)
  } finally {
    return (decodedPath || path).split("/").map(encodeURIComponent).join("/")
  }
}

/**
 * Describe this function...
 */
async function createLogoUrl() {
  if (getObjectProperty(___arguments.context.pageData, 'logoPicture')) {
    if (organizationApiResponse) {
      try {
        logoPicture = (await Backendless.Files.upload(
            ((getObjectProperty(___arguments.context.pageData, 'logoPicture'))[0]),
            encodePath((['/organization_logos/',(getObjectProperty(organizationApiResponse, 'objectId')),'/'].join(''))),
            false
          ).then(result => result.fileURL));
        if (logoPicture) {
          addLogoResponse = (await Backendless.Data.of('Organizations').save( ({ [`logo`]: logoPicture,[`objectId`]: (getObjectProperty(organizationApiResponse, 'objectId')) }) ));
        }

      } catch (error) {
        await addToast(({ [`status`]: 'failure',[`message`]: 'Failed to add organization.' }));

      }
    }
  }
}


  await isButtonLoading(true);
  error = null;
  uniqueID = (generateUUID().long());
  orgName = (getObjectProperty(___arguments.context.pageData, 'organizationName'));
  streetAddress = (getObjectProperty(___arguments.context.pageData, 'streetAddress'));
  city = (getObjectProperty(___arguments.context.pageData, 'city'));
  state = (getObjectProperty(___arguments.context.pageData, 'state'));
  zipCode = (getObjectProperty(___arguments.context.pageData, 'zipCode'));
  websiteLink = (getObjectProperty(___arguments.context.pageData, 'websiteLink'));
  billingEmail = (getObjectProperty(___arguments.context.pageData, 'billingEmail'));
  billingPhone = String('+1') + String((await BackendlessUI.Functions.Custom['fn_f282814277b3c0aa13f33d3b2cda111b']((getObjectProperty(___arguments.context.pageData, 'billingPhone')), 'remove')));
  billingName = (getObjectProperty(___arguments.context.pageData, 'billingName'));
  taxId = (getObjectProperty(___arguments.context.pageData, 'taxId'));
  hasNoErrors = true;
  duplicateTaxId = (await Backendless.Data.of('Organizations').find(Backendless.DataQueryBuilder.create().setWhereClause((['taxId = \'',taxId,'\''].join(''))).setPageSize(10)));
  if (!(await BackendlessUI.Functions.Custom['fn_a3492c0a3c3e3c5e02e3736d07768491'](taxId))) {
    await isButtonLoading(false);
    ___arguments.context.pageData['taxIdError'] = 'invalid';
    hasNoErrors = false;
  }
  if (duplicateTaxId.length > 0) {
    await isButtonLoading(false);
    ___arguments.context.pageData['taxIdError'] = 'duplicate';
    hasNoErrors = false;
  }
  if (!(await BackendlessUI.Functions.Custom['fn_6d4580c12cbae4a32f38d054d2dc9e71'](billingPhone))) {
    await isButtonLoading(false);
    ___arguments.context.pageData['billingPhoneError'] = true;
    hasNoErrors = false;
  }
  if (!(await BackendlessUI.Functions.Custom['fn_36435bc8e7f437df45a6682ebdbf7388'](billingEmail))) {
    await isButtonLoading(false);
    ___arguments.context.pageData['billingEmailError'] = true;
    hasNoErrors = false;
  }
  if (hasNoErrors) {
    try {
      organizationApiResponse = (await Backendless.Data.of('Organizations').save( ({ [`billingEmail`]: billingEmail,[`billingName`]: billingName,[`billingPhone`]: billingPhone,[`orgName`]: orgName,[`websiteLink`]: websiteLink,[`taxId`]: taxId,[`zipCode`]: zipCode,[`city`]: city,[`state`]: state,[`address`]: streetAddress }) ));
      await createLogoUrl();
      if ((getObjectProperty(organizationApiResponse, 'objectId')) || (getObjectProperty(addLogoResponse, 'objectId'))) {
        ___arguments.context.pageData['noResultsScreen'] = false;
        await refreshOrgTable();
        await isButtonLoading(false);
        ___arguments.context.pageData['isAddRecordModalOpen'] = false;
        ___arguments.context.pageData['isSuccessModalOpen'] = true;
      }

    } catch (error) {
      await addToast(({ [`status`]: 'failure',[`message`]: 'Failed to add organization.' }));

    } finally {
      await isButtonLoading(false);

    }
  }

  },
  /* handler:onClick */
  /* handler:onStyleAssignment */
  async ['onStyleAssignment'](___arguments) {
    var isDisabled, isButtonLoading, taxId, billingName, billingPhone, billingEmail;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  billingEmail = (getObjectProperty(___arguments.context.pageData, 'billingEmail'));
  billingPhone = (getObjectProperty(___arguments.context.pageData, 'billingPhone'));
  billingName = (getObjectProperty(___arguments.context.pageData, 'billingName'));
  taxId = (getObjectProperty(___arguments.context.pageData, 'taxId'));
  isButtonLoading = (getObjectProperty(___arguments.context.pageData, 'isButtonLoading'));
  isDisabled = false;
  if (!billingEmail || (await BackendlessUI.Functions.Custom['fn_a7ad4c1fd5d18ca6bff3eb611aeee2ac'](billingEmail))) {
    isDisabled = true;
  } else if (!billingPhone || (await BackendlessUI.Functions.Custom['fn_a7ad4c1fd5d18ca6bff3eb611aeee2ac'](billingPhone))) {
    isDisabled = true;
  } else if (!billingName || (await BackendlessUI.Functions.Custom['fn_a7ad4c1fd5d18ca6bff3eb611aeee2ac'](billingName))) {
    isDisabled = true;
  } else if (!taxId || (await BackendlessUI.Functions.Custom['fn_a7ad4c1fd5d18ca6bff3eb611aeee2ac'](taxId))) {
    isDisabled = true;
  } else if (isButtonLoading) {
    isDisabled = true;
  }
  ___arguments.context.pageData['isAddOrganizationButtonDisabled'] = isDisabled;

  },
  /* handler:onStyleAssignment */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    var classesList;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  classesList = ['default_button'];
  if (getObjectProperty(___arguments.context.pageData, 'isAddOrganizationButtonDisabled')) {
    addItemToList(classesList, 'disabled_button');
  } else {
    addItemToList(classesList, 'enabled_button');
  }

  return classesList

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./components/reusable/r_5871cdf8de1657653b530652a7730dc7/logic/1e471ff7a513d4736cde40616e2229fd/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      ___arguments.context.pageData['addOrganizationStep'] = 1;

  },
  /* handler:onClick */
  /* content */
}))

define('./components/reusable/r_5871cdf8de1657653b530652a7730dc7/logic/5845b7d88c47e2b4dfe802853f2110f6/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    var currentUser;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  currentUser = (getObjectProperty(___arguments.context.pageData, 'currentUser'));
  if (currentUser) {
    if ((getObjectProperty(___arguments.context.pageData, 'recordTableVariant')) == 'user' && (getObjectProperty(currentUser, 'userRole')) != 'manager') {
      (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('5845b7d88c47e2b4dfe802853f2110f6', true);
    } else {
      (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('5845b7d88c47e2b4dfe802853f2110f6', false);
    }
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./components/reusable/r_5871cdf8de1657653b530652a7730dc7/logic/e92535079f2f12ea358c1b14d15a31f1/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    var classesList;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  classesList = [];
  if (getObjectProperty(___arguments.context.pageData, 'emailError')) {
    addItemToList(classesList, 'input_red_border');
  }

  return classesList

  },
  /* handler:onClassListAssignment */
  /* handler:onChange */
  ['onChange'](___arguments) {
      ___arguments.context.pageData['emailError'] = false;

  },
  /* handler:onChange */
  /* content */
}))

define('./components/reusable/r_5871cdf8de1657653b530652a7730dc7/logic/311811ac0f9f39d7514190d7944eb269/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'emailError')) {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('311811ac0f9f39d7514190d7944eb269', true);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('311811ac0f9f39d7514190d7944eb269', false);
  }

  },
  /* handler:onStyleAssignment */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    var emailError;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  emailError = (getObjectProperty(___arguments.context.pageData, 'emailError'));
  if (emailError == 'duplicate') {
    return 'This email is already being used by another account';
  } else if (emailError == 'invalid') {
    return 'Invalid email Address';
  }

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./components/reusable/r_5871cdf8de1657653b530652a7730dc7/logic/aac430fec1759947335d100829b572d1/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  async ['onChange'](___arguments) {
      ___arguments.context.pageData['phoneError'] = false;
  ___arguments.context.pageData['addUserPhoneNumber'] = (await BackendlessUI.Functions.Custom['fn_f282814277b3c0aa13f33d3b2cda111b'](___arguments.value, 'create', null));

  },
  /* handler:onChange */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    var classesList;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  classesList = [];
  if (getObjectProperty(___arguments.context.pageData, 'phoneError')) {
    addItemToList(classesList, 'input_red_border');
  }

  return classesList

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./components/reusable/r_5871cdf8de1657653b530652a7730dc7/logic/e616ee22d9465347030b21141602db71/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    var item;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'currentUser.userRole')) == 'super') {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('e616ee22d9465347030b21141602db71', true);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('e616ee22d9465347030b21141602db71', false);
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./components/reusable/r_5871cdf8de1657653b530652a7730dc7/logic/5afbcc44e0d579d5ca87a2311aa55d5d/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      ___arguments.context.pageData['userRoleSelection'] = 'admin';

  },
  /* handler:onClick */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    var userRoleSelection;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  userRoleSelection = (getObjectProperty(___arguments.context.pageData, 'userRoleSelection'));
  if (((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('5afbcc44e0d579d5ca87a2311aa55d5d')) && ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('16c13afe79579e5a07818be6bef96b5c'))) {
    if (userRoleSelection == 'admin') {
      ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('5afbcc44e0d579d5ca87a2311aa55d5d'))['background-color'] = '#eaf4fb';
      ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('16c13afe79579e5a07818be6bef96b5c'))['font-weight'] = 'bold';
      ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('16c13afe79579e5a07818be6bef96b5c'))['color'] = 'black';
    } else {
      ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('5afbcc44e0d579d5ca87a2311aa55d5d'))['background-color'] = '#FFFFFF';
      ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('16c13afe79579e5a07818be6bef96b5c'))['font-weight'] = 'normal';
      ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('16c13afe79579e5a07818be6bef96b5c'))['color'] = '#636262';
    }
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./components/reusable/r_5871cdf8de1657653b530652a7730dc7/logic/feac2eea00a41d097ee16fa7bb06324b/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      ___arguments.context.pageData['userRoleSelection'] = 'manager';

  },
  /* handler:onClick */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    var userRoleSelection;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  userRoleSelection = (getObjectProperty(___arguments.context.pageData, 'userRoleSelection'));
  if (((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('feac2eea00a41d097ee16fa7bb06324b')) && ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('095f1da3a2d509d3ce66535c8f535932'))) {
    if (userRoleSelection == 'manager') {
      ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('feac2eea00a41d097ee16fa7bb06324b'))['background-color'] = '#eaf4fb';
      ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('095f1da3a2d509d3ce66535c8f535932'))['font-weight'] = 'bold';
      ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('095f1da3a2d509d3ce66535c8f535932'))['color'] = 'black';
    } else {
      ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('feac2eea00a41d097ee16fa7bb06324b'))['background-color'] = '#FFFFFF';
      ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('095f1da3a2d509d3ce66535c8f535932'))['font-weight'] = 'normal';
      ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('095f1da3a2d509d3ce66535c8f535932'))['color'] = '#636262';
    }
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./components/reusable/r_5871cdf8de1657653b530652a7730dc7/logic/184ddb0e156d515f165f0036c2ad5a7c/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var organizationName, name2, organizationId, registeredUser, userRole, phoneNumber, randomPassword, email, uniqueID, sendGridResponse, userObjectId, hasNoErrors, alreadyExistingUser, currentUser, error, toast, isLoading, buttonLogin;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

/**
 * Describe this function...
 */
async function registerUser() {
  try {
    registeredUser = (await Backendless.UserService.register( new Backendless.User({ 'email': email,'password': randomPassword }) ));
    if (registeredUser) {
      await Backendless.UserService.update( new Backendless.User(({ [`name`]: name2,[`phoneNumber`]: phoneNumber,[`inviteStatus`]: 'PENDING',[`inviteSent`]: (new Date()),[`userRole`]: userRole,[`objectId`]: (getObjectProperty(registeredUser, 'objectId')) })) );
      await Backendless.Data.of('Users').setRelation(registeredUser, 'organizationId', [organizationId]);
    }

  } catch (error) {
    await addToast(({ [`status`]: 'failure',[`message`]: ['Invite sent to ',name2,' for ',organizationName,'failed.'].join('') }));

  }
}

function generateUUID() {
   const chr4 = () => Math.random().toString(16).slice(-4);
   const chr8 = () => `${chr4()}${chr4()}`;
   const short = () => chr8();
   const long = () => `${chr8()}-${chr4()}-${chr4()}-${chr4()}-${chr8()}${chr4()}`;

   return { short, long, }
}

/**
 * Describe this function...
 */
async function addToast(toast) {
  await isButtonLoading(false);
  await BackendlessUI.Functions.Custom['fn_77bfd63e533a9b4c5577e5fe31a4b5ca'](___arguments.context.pageData, (getObjectProperty(toast, 'message')), (getObjectProperty(toast, 'status')));
}

/**
 * Describe this function...
 */
async function refreshUserTable() {
  ___arguments.context.pageData['refreshUserTable'] = ({ [`changedValue`]: (getObjectProperty(___arguments.context.pageData, 'changedValueUser')) });
}

/**
 * Describe this function...
 */
async function sendAdminEmail() {
  try {
    if ((getObjectProperty(currentUser, 'userRole')) != 'super') {
      organizationName = (getObjectProperty(currentUser, 'organizationId.orgName'));
    } else {
      organizationName = (getObjectProperty(((await Backendless.Data.of('Organizations').find(Backendless.DataQueryBuilder.create().setWhereClause((['objectId =','\'',organizationId,'\''].join(''))).setPageSize(10)))[0]), 'orgName'));
    }
    if ((getObjectProperty(currentUser, 'userRole')) == 'super') {
      if (userRole == 'admin') {
        sendGridResponse = (await BackendlessUI.Functions.Custom['fn_67c6f852cf5ce414b74b8f0b9401b852']('engineers@cyberdive.co', 'Cyber Dive Team', email, 'd-f90fbbee97014b54bb155e790792e5bf', ({ [`organization_name`]: organizationName,[`organization_admin`]: name2,[`link`]: (await BackendlessUI.Functions.Custom['fn_a65e994797048d593245ed62a80fb31f']('invite', ({ [`user_email`]: email }))) })));
      } else if (userRole == 'manager') {
        sendGridResponse = (await BackendlessUI.Functions.Custom['fn_67c6f852cf5ce414b74b8f0b9401b852']('engineers@cyberdive.co', 'Cyber Dive Team', email, 'd-7ca2c090070c49319c4d0c3c0d03071f', ({ [`organization_name`]: organizationName,[`organization_admin`]: 'Cyber Dive Team',[`case_manager`]: name2,[`link`]: (await BackendlessUI.Functions.Custom['fn_a65e994797048d593245ed62a80fb31f']('invite', ({ [`user_email`]: email }))) })));
      }
    } else if ((getObjectProperty(currentUser, 'userRole')) == 'admin') {
      if (userRole == 'admin') {
        sendGridResponse = (await BackendlessUI.Functions.Custom['fn_67c6f852cf5ce414b74b8f0b9401b852']((getObjectProperty(currentUser, 'email')), (getObjectProperty(currentUser, 'name')), email, 'd-f90fbbee97014b54bb155e790792e5bf', ({ [`organization_name`]: organizationName,[`organization_admin`]: name2,[`link`]: (await BackendlessUI.Functions.Custom['fn_a65e994797048d593245ed62a80fb31f']('invite', ({ [`user_email`]: email,[`admin_email`]: (getObjectProperty(currentUser, 'email')) }))) })));
      } else if (userRole == 'manager') {
        sendGridResponse = (await BackendlessUI.Functions.Custom['fn_67c6f852cf5ce414b74b8f0b9401b852']((getObjectProperty(currentUser, 'email')), (getObjectProperty(currentUser, 'name')), email, 'd-7ca2c090070c49319c4d0c3c0d03071f', ({ [`organization_name`]: organizationName,[`organization_admin`]: (getObjectProperty(currentUser, 'name')),[`case_manager`]: name2,[`link`]: (await BackendlessUI.Functions.Custom['fn_a65e994797048d593245ed62a80fb31f']('invite', ({ [`user_email`]: email,[`admin_email`]: (getObjectProperty(currentUser, 'email')) }))) })));
      }
    }

  } catch (error) {
    await addToast(({ [`status`]: 'failure',[`message`]: ['Invitation to ',name2,' for ',organizationName,' failed to send. Please try again.'].join('') }));

  }
}

/**
 * Describe this function...
 */
async function isButtonLoading(isLoading) {
  ___arguments.context.pageData['isButtonLoading'] = isLoading;
  buttonLogin = ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('184ddb0e156d515f165f0036c2ad5a7c'));
  buttonLogin['label'] = (isLoading ? 'Please wait...' : 'Send Invite');
}


  await isButtonLoading(true);
  currentUser = (getObjectProperty(___arguments.context.pageData, 'currentUser'));
  error = null;
  uniqueID = (generateUUID().long());
  randomPassword = (generateUUID().short());
  email = (getObjectProperty(___arguments.context.pageData, 'email'));
  name2 = (getObjectProperty(___arguments.context.pageData, 'fullName'));
  phoneNumber = String('+1') + String((await BackendlessUI.Functions.Custom['fn_f282814277b3c0aa13f33d3b2cda111b']((getObjectProperty(___arguments.context.pageData, 'addUserPhoneNumber')), 'remove')));
  userRole = (getObjectProperty(___arguments.context.pageData, 'userRoleSelection'));
  alreadyExistingUser = (
      await (async function(userIdentity){
        var userColumns = await Backendless.UserService.describeUserClass()
        var identityColumn = userColumns.find(column => column.identity)
        var whereClause = `${identityColumn.name} = '${userIdentity}'`
        var query = Backendless.DataQueryBuilder.create().setWhereClause(whereClause)
        var users = await Backendless.Data.of(Backendless.User).find(query)

        return users[0]
     })(email)
  );
  hasNoErrors = true;
  if ((getObjectProperty(currentUser, 'userRole')) != 'super') {
    organizationId = (getObjectProperty(currentUser, 'organizationId.objectId'));
  } else {
    organizationId = (getObjectProperty(___arguments.context.pageData, 'organizationChoice'));
  }
  if (!(await BackendlessUI.Functions.Custom['fn_36435bc8e7f437df45a6682ebdbf7388'](email))) {
    ___arguments.context.pageData['emailError'] = 'invalid';
    await isButtonLoading(false);
    hasNoErrors = false;
  }
  if (alreadyExistingUser) {
    ___arguments.context.pageData['emailError'] = 'duplicate';
    await isButtonLoading(false);
    hasNoErrors = false;
  }
  if (!(await BackendlessUI.Functions.Custom['fn_6d4580c12cbae4a32f38d054d2dc9e71'](phoneNumber))) {
    ___arguments.context.pageData['phoneError'] = true;
    await isButtonLoading(false);
    hasNoErrors = false;
  }
  if (hasNoErrors) {
    try {
      await registerUser();
      if (getObjectProperty(registeredUser, 'objectId')) {
        userObjectId = (getObjectProperty(registeredUser, 'objectId'));
        await sendAdminEmail();
        if (sendGridResponse) {
          await Backendless.Cache.put(email, ({ [`key`]: uniqueID }), 7200);
          await Backendless.Cache.expireIn(email, 7200);
          ___arguments.context.pageData['noResultsScreen'] = false;
          await refreshUserTable();
          ___arguments.context.pageData['isAddRecordModalOpen'] = false;
          ___arguments.context.pageData['isEnrollingResident'] = undefined;
          ___arguments.context.pageData['isSuccessModalOpen'] = true;
        }
      }

    } catch (error) {
      await addToast(({ [`status`]: 'failure',[`message`]: ['Invitation to ',name2,' for ',organizationName,' failed to send. Please try again.'].join('') }));

    } finally {
      await isButtonLoading(false);

    }
  }

  },
  /* handler:onClick */
  /* handler:onStyleAssignment */
  async ['onStyleAssignment'](___arguments) {
    var isDisabled, isButtonLoading, organizationId, userRole, phoneNumber, name2, email;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  email = (getObjectProperty(___arguments.context.pageData, 'email'));
  name2 = (getObjectProperty(___arguments.context.pageData, 'fullName'));
  phoneNumber = (getObjectProperty(___arguments.context.pageData, 'addUserPhoneNumber'));
  userRole = (getObjectProperty(___arguments.context.pageData, 'userRoleSelection'));
  organizationId = (getObjectProperty(___arguments.context.pageData, 'organizationChoice'));
  isButtonLoading = (getObjectProperty(___arguments.context.pageData, 'isButtonLoading'));
  if (!email || (await BackendlessUI.Functions.Custom['fn_a7ad4c1fd5d18ca6bff3eb611aeee2ac'](email))) {
    isDisabled = true;
  } else if (!name2 || (await BackendlessUI.Functions.Custom['fn_a7ad4c1fd5d18ca6bff3eb611aeee2ac'](name2))) {
    isDisabled = true;
  } else if (!phoneNumber || (await BackendlessUI.Functions.Custom['fn_a7ad4c1fd5d18ca6bff3eb611aeee2ac'](phoneNumber))) {
    isDisabled = true;
  } else if (!userRole || (await BackendlessUI.Functions.Custom['fn_a7ad4c1fd5d18ca6bff3eb611aeee2ac'](userRole))) {
    isDisabled = true;
  } else if ((!organizationId || (await BackendlessUI.Functions.Custom['fn_a7ad4c1fd5d18ca6bff3eb611aeee2ac'](organizationId))) && (getObjectProperty(___arguments.context.pageData, 'currentUser.userRole')) == 'super') {
    isDisabled = true;
  } else if (isButtonLoading) {
    isDisabled = true;
  }
  ___arguments.context.pageData['isAddUserButtonDisabled'] = isDisabled;

  },
  /* handler:onStyleAssignment */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    var classesList;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  classesList = ['default_button'];
  if (getObjectProperty(___arguments.context.pageData, 'isAddUserButtonDisabled')) {
    addItemToList(classesList, 'disabled_button');
  } else {
    addItemToList(classesList, 'enabled_button');
  }

  return classesList

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./components/reusable/r_5871cdf8de1657653b530652a7730dc7/logic/c995827a0cc10d23e4e2dc4ab6b4c8cf/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      ___arguments.context.pageData['isAddRecordModalOpen'] = false;

  },
  /* handler:onClick */
  /* content */
}))

define('./components/reusable/r_5871cdf8de1657653b530652a7730dc7/logic/1f6d6e1442b6b2612c57d12a128489b3/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'recordTableVariant')) == 'organization') {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('1f6d6e1442b6b2612c57d12a128489b3', true);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('1f6d6e1442b6b2612c57d12a128489b3', false);
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./components/reusable/r_5871cdf8de1657653b530652a7730dc7/logic/618e6f24dcd9cd85ad14234cc82505ad/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    var classesList;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  classesList = [];
  if ((getObjectProperty(___arguments.context.pageData, 'addOrganizationStep')) == 1) {
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('618e6f24dcd9cd85ad14234cc82505ad'))['margin-left'] = '6px';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('d43c5a948d8c9b62e19129853c9325d2'))['font-weight'] = 'normal';
    addItemToList(classesList, 'grey_stepper');
  } else if ((getObjectProperty(___arguments.context.pageData, 'addOrganizationStep')) == 2) {
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('618e6f24dcd9cd85ad14234cc82505ad'))['margin-left'] = '0px';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('d43c5a948d8c9b62e19129853c9325d2'))['font-weight'] = 'bold';
    addItemToList(classesList, 'blue_stepper');
  }

  return classesList

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./components/reusable/r_5871cdf8de1657653b530652a7730dc7/logic/a26f750ce1fb9ac6e9da894d552ff08f/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    var classesList, classes, addResidentStep;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  classesList = [];
  if ((getObjectProperty(___arguments.context.pageData, 'addOrganizationStep')) == 1) {
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('a26f750ce1fb9ac6e9da894d552ff08f'))['margin-left'] = '0px';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('d4462fb259f239d65551a14c13d4397d'))['font-weight'] = 'bold';
    addItemToList(classesList, 'blue_stepper');
  } else if ((getObjectProperty(___arguments.context.pageData, 'addOrganizationStep')) == 2) {
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('a26f750ce1fb9ac6e9da894d552ff08f'))['margin-left'] = '6px';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('d4462fb259f239d65551a14c13d4397d'))['font-weight'] = 'normal';
    addItemToList(classesList, 'green_stepper');
  }

  return classesList

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./components/reusable/r_5871cdf8de1657653b530652a7730dc7/logic/d43c5a948d8c9b62e19129853c9325d2/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    var classesList;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  classesList = [];
  if ((getObjectProperty(___arguments.context.pageData, 'addOrganizationStep')) == 1) {
    addItemToList(classesList, 'stepper_text_small');
  } else if ((getObjectProperty(___arguments.context.pageData, 'addOrganizationStep')) == 2) {
    addItemToList(classesList, 'stepper_text_big');
  }

  return classesList

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./components/reusable/r_5871cdf8de1657653b530652a7730dc7/logic/d4462fb259f239d65551a14c13d4397d/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    var classesList;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  classesList = [];
  if ((getObjectProperty(___arguments.context.pageData, 'addOrganizationStep')) == 1) {
    addItemToList(classesList, 'stepper_text_big');
  } else if ((getObjectProperty(___arguments.context.pageData, 'addOrganizationStep')) == 2) {
    addItemToList(classesList, 'stepper_text_small');
  }

  return classesList

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./components/reusable/r_5871cdf8de1657653b530652a7730dc7/logic/8c82e30183f3f532731d8fa8c58c2345/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    var classes, addResidentStep;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  addResidentStep = (getObjectProperty(___arguments.context.pageData, 'addResidentStep'));
  classes = [];
  if (addResidentStep == 1) {
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('b11bd3a1c2c52298b195ba74d24c39af'))['font-weight'] = 'bold';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('8c82e30183f3f532731d8fa8c58c2345'))['margin-left'] = '0px';
    classes = ['blue_stepper'];
  } else if (addResidentStep > 1) {
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('b11bd3a1c2c52298b195ba74d24c39af'))['font-weight'] = 'normal';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('8c82e30183f3f532731d8fa8c58c2345'))['margin-left'] = '6px';
    classes = ['green_stepper'];
  }

  return classes

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./components/reusable/r_5871cdf8de1657653b530652a7730dc7/logic/22c7482da6ce3c6bdec39b460f93725f/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    var classes, addResidentStep;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  addResidentStep = (getObjectProperty(___arguments.context.pageData, 'addResidentStep'));
  classes = [];
  if (addResidentStep == 2) {
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('0accc624eb4b4d87cbdfdf72407f88b4'))['font-weight'] = 'bold';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('22c7482da6ce3c6bdec39b460f93725f'))['margin-left'] = '0px';
    classes = ['blue_stepper'];
  } else if (addResidentStep > 2) {
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('0accc624eb4b4d87cbdfdf72407f88b4'))['font-weight'] = 'normal';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('22c7482da6ce3c6bdec39b460f93725f'))['margin-left'] = '6px';
    classes = ['green_stepper'];
  } else {
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('0accc624eb4b4d87cbdfdf72407f88b4'))['font-weight'] = 'normal';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('22c7482da6ce3c6bdec39b460f93725f'))['margin-left'] = '6px';
    classes = ['grey_stepper'];
  }

  return classes

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./components/reusable/r_5871cdf8de1657653b530652a7730dc7/logic/ff235cd6137cfbba494ba22aac82562c/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    var classes, addResidentStep;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  addResidentStep = (getObjectProperty(___arguments.context.pageData, 'addResidentStep'));
  classes = [];
  if (addResidentStep == 3) {
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('3af72e358beb559b7c17a09ffc13e35a'))['font-weight'] = 'bold';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('ff235cd6137cfbba494ba22aac82562c'))['margin-left'] = '0px';
    classes = ['blue_stepper'];
  } else if (addResidentStep > 3) {
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('3af72e358beb559b7c17a09ffc13e35a'))['font-weight'] = 'normal';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('ff235cd6137cfbba494ba22aac82562c'))['margin-left'] = '6px';
    classes = ['green_stepper'];
  } else {
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('3af72e358beb559b7c17a09ffc13e35a'))['font-weight'] = 'normal';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('ff235cd6137cfbba494ba22aac82562c'))['margin-left'] = '6px';
    classes = ['grey_stepper'];
  }

  return classes

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./components/reusable/r_5871cdf8de1657653b530652a7730dc7/logic/16aa921a6069d849907fae139e8ef459/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    var classes, addResidentStep;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  addResidentStep = (getObjectProperty(___arguments.context.pageData, 'addResidentStep'));
  classes = [];
  if (addResidentStep == 4) {
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('c32fbad56df8ecbacf5bb6bbaf74a4a7'))['font-weight'] = 'bold';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('16aa921a6069d849907fae139e8ef459'))['margin-left'] = '0px';
    classes = ['blue_stepper'];
  } else if (addResidentStep > 4) {
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('c32fbad56df8ecbacf5bb6bbaf74a4a7'))['font-weight'] = 'normal';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('16aa921a6069d849907fae139e8ef459'))['margin-left'] = '6px';
    classes = ['green_stepper'];
  } else {
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('c32fbad56df8ecbacf5bb6bbaf74a4a7'))['font-weight'] = 'normal';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('16aa921a6069d849907fae139e8ef459'))['margin-left'] = '6px';
    classes = ['grey_stepper'];
  }

  return classes

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./components/reusable/r_5871cdf8de1657653b530652a7730dc7/logic/fbbdb23dc6cd3a3c12ad7045e1c536d7/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    var classes, addResidentStep;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  addResidentStep = (getObjectProperty(___arguments.context.pageData, 'addResidentStep'));
  classes = [];
  if (addResidentStep == 5) {
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('743136248bb87b35355f24256d6f88d2'))['font-weight'] = 'bold';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('fbbdb23dc6cd3a3c12ad7045e1c536d7'))['margin-left'] = '0px';
    classes = ['blue_stepper'];
  } else if (addResidentStep > 5) {
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('743136248bb87b35355f24256d6f88d2'))['font-weight'] = 'normal';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('fbbdb23dc6cd3a3c12ad7045e1c536d7'))['margin-left'] = '6px';
    classes = ['green_stepper'];
  } else {
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('743136248bb87b35355f24256d6f88d2'))['font-weight'] = 'normal';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('fbbdb23dc6cd3a3c12ad7045e1c536d7'))['margin-left'] = '6px';
    classes = ['grey_stepper'];
  }

  return classes

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./components/reusable/r_5871cdf8de1657653b530652a7730dc7/logic/eaf1e5c8083471359a71267ea7864d61/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'recordTableVariant')) == 'resident') {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('eaf1e5c8083471359a71267ea7864d61', true);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('eaf1e5c8083471359a71267ea7864d61', false);
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./components/reusable/r_5871cdf8de1657653b530652a7730dc7/logic/f67f92971e2c5a847e09c0cf5fa61317/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'resident')) {
    ___arguments.context.pageData['addResidentStep'] = 1;
    ___arguments.context.pageData['addResidentSection'] = 1;
    ___arguments.context.pageData['isTransitioningResidentSection'] = true;
  }

  },
  /* handler:onClick */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    var classes;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'resident')) {
    classes = [];
  } else {
    classes = ['default_cursor'];
  }

  return classes

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./components/reusable/r_5871cdf8de1657653b530652a7730dc7/logic/0409bee90ae6fd03444825aa4b9af0e7/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'resident')) {
    ___arguments.context.pageData['addResidentStep'] = 2;
    ___arguments.context.pageData['addResidentSection'] = 4;
    ___arguments.context.pageData['isTransitioningResidentSection'] = true;
  }

  },
  /* handler:onClick */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    var classes;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'resident')) {
    classes = [];
  } else {
    classes = ['default_cursor'];
  }

  return classes

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./components/reusable/r_5871cdf8de1657653b530652a7730dc7/logic/7dd9e2ab5eb9445ed10f2458d0560bfc/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'resident')) {
    ___arguments.context.pageData['addResidentStep'] = 3;
    ___arguments.context.pageData['addResidentSection'] = 5;
    ___arguments.context.pageData['isTransitioningResidentSection'] = true;
  }

  },
  /* handler:onClick */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    var classes;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'resident')) {
    classes = [];
  } else {
    classes = ['default_cursor'];
  }

  return classes

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./components/reusable/r_5871cdf8de1657653b530652a7730dc7/logic/0ac7eaf54ea20fed3cd51ca306ac6915/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'resident')) {
    ___arguments.context.pageData['addResidentStep'] = 4;
    ___arguments.context.pageData['addResidentSection'] = 10;
    ___arguments.context.pageData['isTransitioningResidentSection'] = true;
  }

  },
  /* handler:onClick */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    var classes;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'resident')) {
    classes = [];
  } else {
    classes = ['default_cursor'];
  }

  return classes

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./components/reusable/r_5871cdf8de1657653b530652a7730dc7/logic/d36d4542589e380b965103b1532d4e92/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    var classes, curfewStep;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  curfewStep = (getObjectProperty(___arguments.context.pageData, 'curfewStep'));
  classes = [];
  if (curfewStep == 1 || curfewStep == 3) {
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('7e6a70d6ae6940014d11876db466937b'))['font-weight'] = 'bold';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('d36d4542589e380b965103b1532d4e92'))['margin-left'] = '0px';
    classes = ['blue_stepper'];
  } else if (curfewStep == 2) {
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('7e6a70d6ae6940014d11876db466937b'))['font-weight'] = 'normal';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('d36d4542589e380b965103b1532d4e92'))['margin-left'] = '6px';
    classes = ['green_stepper'];
  }

  return classes

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./components/reusable/r_5871cdf8de1657653b530652a7730dc7/logic/654950db0c82fbd90775028de13e6e5c/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    var classes, curfewStep;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  curfewStep = (getObjectProperty(___arguments.context.pageData, 'curfewStep'));
  classes = [];
  if (curfewStep == 1 || curfewStep == 3) {
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('c5288c491266c4596d6e6574fb6194da'))['font-weight'] = 'normal';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('654950db0c82fbd90775028de13e6e5c'))['margin-left'] = '6px';
    classes = ['grey_stepper'];
  } else if (curfewStep == 2) {
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('c5288c491266c4596d6e6574fb6194da'))['font-weight'] = 'bold';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('654950db0c82fbd90775028de13e6e5c'))['margin-left'] = '0px';
    classes = ['blue_stepper'];
  }

  return classes

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./components/reusable/r_5871cdf8de1657653b530652a7730dc7/logic/d7555749d8df39f4aefd7449faa4dd4f/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'recordTableVariant')) == 'curfew') {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('d7555749d8df39f4aefd7449faa4dd4f', true);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('d7555749d8df39f4aefd7449faa4dd4f', false);
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./components/reusable/r_5871cdf8de1657653b530652a7730dc7/logic/6dd49041865c63d9a58bc5b48c45998f/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'recordTableVariant')) == 'curfew') {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('6dd49041865c63d9a58bc5b48c45998f', true);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('6dd49041865c63d9a58bc5b48c45998f', false);
  }

  },
  /* handler:onStyleAssignment */
  /* handler:onBeforeMount */
  ['onBeforeMount'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (!(getObjectProperty(___arguments.context.pageData, 'curfewPillarArray'))) {
    ___arguments.context.pageData['curfewPillarArray'] = [];
  }

  },
  /* handler:onBeforeMount */
  /* content */
}))

define('./components/reusable/r_5871cdf8de1657653b530652a7730dc7/logic/79a1f10906b97657154a0fcc9a9b872f/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    var addResidentStep, classes;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  addResidentStep = (getObjectProperty(___arguments.context.pageData, 'addResidentStep'));
  classes = [];
  if (addResidentStep == 6) {
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('8686c14d335a4572fb24be33c5b6d347'))['font-weight'] = 'bold';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('79a1f10906b97657154a0fcc9a9b872f'))['margin-left'] = '0px';
    classes = ['blue_stepper'];
  } else if (addResidentStep > 6) {
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('8686c14d335a4572fb24be33c5b6d347'))['font-weight'] = 'normal';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('79a1f10906b97657154a0fcc9a9b872f'))['margin-left'] = '6px';
    classes = ['green_stepper'];
  } else {
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('8686c14d335a4572fb24be33c5b6d347'))['font-weight'] = 'normal';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('79a1f10906b97657154a0fcc9a9b872f'))['margin-left'] = '6px';
    classes = ['grey_stepper'];
  }

  return classes

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./components/reusable/r_5871cdf8de1657653b530652a7730dc7/logic/9de1565a02d81a814a65b57fc24eee26/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var device, hasError, deviceId, toast;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

/**
 * Describe this function...
 */
async function addToast(toast) {
  await BackendlessUI.Functions.Custom['fn_77bfd63e533a9b4c5577e5fe31a4b5ca'](___arguments.context.pageData, (getObjectProperty(toast, 'message')), (getObjectProperty(toast, 'status')));
}


  if (getObjectProperty(___arguments.context.pageData, 'resident')) {
    ___arguments.context.pageData['addResidentStep'] = 6;
    ___arguments.context.pageData['addResidentSection'] = 13;
    ___arguments.context.pageData['isTransitioningResidentSection'] = true;
    deviceId = (await BackendlessUI.Functions.Custom['fn_9b44aef25264690eed0615df9af44168'](___arguments.context.pageData, '2'));
    if (deviceId) {
      while (!(getObjectProperty(___arguments.context.pageData, 'residentDetailsItem.deviceIMEI'))) {
        await new Promise(r => setTimeout(r, 2000 || 0));
        var device_list = (getObjectProperty((await Backendless.Request.post(`${Backendless.appPath}/services/HeadwindMDM/getDevices`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send({ 'group': '2','token': (getObjectProperty((JSON.parse((getObjectProperty(___arguments.context.pageData, 'currentUser.organizationId.headwindData')))), 'token')) })), 'data.devices.items'));
        for (var device_index in device_list) {
          device = device_list[device_index];
          if ((getObjectProperty(device, 'number')) == deviceId && (getObjectProperty(device, 'info.imei'))) {
            try {
              hasError = true;
              await Backendless.Data.of('Devices').save( ({ [`objectId`]: (getObjectProperty(___arguments.context.pageData, 'residentDetailsItem.deviceObjectId')),[`deviceIMEI`]: (getObjectProperty(device, 'info.imei')),[`deviceStatus`]: 'ASSIGNED' }) );
              ___arguments.context.pageData['addResidentStep'] = 5;
              ___arguments.context.pageData['addResidentSection'] = 12;
              ___arguments.context.pageData['refreshResidentTable'] = true;
              ___arguments.context.pageData['isEnrollingResident'] = true;
              if (getObjectProperty(___arguments.context.pageData, 'residentDetailsItem')) {
                ___arguments.context.pageData['residentDetailsItem'] = ((await Backendless.Data.of('ResidentInformation').find(Backendless.DataQueryBuilder.create().setWhereClause((['objectId = \'',(getObjectProperty(___arguments.context.pageData, 'resident.objectId')),'\''].join(''))).setPageSize(10)))[0]);
                ___arguments.context.pageData['isSuccessModalOpen'] = true;
              }

            } catch (error) {
              await addToast(({ [`status`]: 'failure',[`message`]: 'Error adding resident device.' }));

            }
          }
        }
      }
    }
  }

  },
  /* handler:onClick */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    var classes;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'resident')) {
    classes = [];
  } else {
    classes = ['default_cursor'];
  }

  return classes

  },
  /* handler:onClassListAssignment */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (!(getObjectProperty(___arguments.context.pageData, 'residentDetailsItem.deviceIMEI'))) {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('9de1565a02d81a814a65b57fc24eee26', true);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('9de1565a02d81a814a65b57fc24eee26', false);
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./components/reusable/r_5871cdf8de1657653b530652a7730dc7/logic/9f021ada8b9c225cb39fc7d851c77b74/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'currentUser.userRole')) != 'super') {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('9f021ada8b9c225cb39fc7d851c77b74', true);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('9f021ada8b9c225cb39fc7d851c77b74', false);
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./components/reusable/r_5871cdf8de1657653b530652a7730dc7/logic/474b0b4fbe84f6678b5a6bb501b59532/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    var classes;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'resident')) {
    classes = [];
  } else {
    classes = ['default_cursor'];
  }

  return classes

  },
  /* handler:onClassListAssignment */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'resident')) {
    ___arguments.context.pageData['addResidentStep'] = 5;
    ___arguments.context.pageData['addResidentSection'] = 12;
    ___arguments.context.pageData['isTransitioningResidentSection'] = true;
  }

  },
  /* handler:onClick */
  /* content */
}))

define('./components/reusable/r_5871cdf8de1657653b530652a7730dc7/logic/eeca80815c873dd8412249c16049aa39/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    var item;


  (function (componentUid, options) { ___arguments.context.getComponentByUid(componentUid).options = options })('eeca80815c873dd8412249c16049aa39', [({ [`value`]: 'manager',[`label`]: 'Case Manager' }), ({ [`value`]: 'admin',[`label`]: 'Admin' })]);

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./components/reusable/r_5871cdf8de1657653b530652a7730dc7/logic/8899112802ae7d2fb3f6be64712a3865/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  async ['onChange'](___arguments) {
      ___arguments.context.pageData['fullName'] = (await BackendlessUI.Functions.Custom['fn_256db7f8ee15af9d778314d97f563562'](___arguments.value));

  },
  /* handler:onChange */
  /* content */
}))

define('./components/reusable/r_5871cdf8de1657653b530652a7730dc7/logic/cdd5affebdcae353b07ff67c8a5c6a1f/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'recordTableVariant')) != 'resident') {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('cdd5affebdcae353b07ff67c8a5c6a1f', true);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('cdd5affebdcae353b07ff67c8a5c6a1f', false);
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./components/reusable/r_5871cdf8de1657653b530652a7730dc7/logic/36b812048ae176bf00b512cc5b086ed3/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'recordTableVariant')) == 'resident') {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('36b812048ae176bf00b512cc5b086ed3', true);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('36b812048ae176bf00b512cc5b086ed3', false);
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./components/reusable/r_5871cdf8de1657653b530652a7730dc7/logic/2df5b5bc3add76ca14f7d825d2f62aa5/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'recordTableVariant')) == 'resident') {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('2df5b5bc3add76ca14f7d825d2f62aa5', true);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('2df5b5bc3add76ca14f7d825d2f62aa5', false);
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./components/reusable/r_5871cdf8de1657653b530652a7730dc7/logic/1e77e23928ef281b39a96f71f1eb3069/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'recordTableVariant')) != 'resident') {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('1e77e23928ef281b39a96f71f1eb3069', true);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('1e77e23928ef281b39a96f71f1eb3069', false);
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./components/reusable/r_5871cdf8de1657653b530652a7730dc7/logic/99bdb68696f5ddc83938faeda578929d/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var pillarHistoryArray, lastPillar, device, item, resident, profilePictureUrl, currentUser, fcmResponse, emptyArray, listOfFilteredContacts, listOfContacts, hasError, phoneContactNumber;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function encodePath(path) {
  if(path.startsWith("http://") || path.startsWith("https://")) {
    return path
  }

  let decodedPath
  try {
    decodedPath = decodeURI(path)
  } finally {
    return (decodedPath || path).split("/").map(encodeURIComponent).join("/")
  }
}

async function asyncListFilter(sourceList, callback) {
  const list = await Promise.all(sourceList.map(async source => ({
    source,
    value: await callback(source),
  })));

  const resultList = list.filter(item => item.value)

  return resultList.map(item => item.source)
}

function replaceItemInList(l, i, c) {   const index = c ? l.findIndex(el => el[c] === i[c]) : l.indexOf(i);  if (index >= 0) { l[index] = i; }  return l;}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}

/**
 * Describe this function...
 */
async function residentContactStepTwo() {
  ___arguments.context.pageData['isLoadingIntakeForm'] = true;
  try {
    device = (await asyncListFilter((getObjectProperty(___arguments.context.pageData, 'selectedItemResident.deviceId')), async (item) => {
       if ((getObjectProperty(item, 'deviceStatus')) == 'ONBOARDSTARTED') {
        return true;
      }


     return ;
    }))[0];
    (getObjectProperty(___arguments.context.pageData, 'residentIntakeForm'))['contactArray'] = (getObjectProperty(___arguments.context.pageData, 'residentContactReviewArray'));
    (getObjectProperty(___arguments.context.pageData, 'residentIntakeForm'))['pillarInfo'] = ({ [`pillar`]: (getObjectProperty(___arguments.context.pageData, 'residentPillarRadio')),[`pillarStart`]: String(new Date()),[`pillarEnd`]: undefined });
    (getObjectProperty(___arguments.context.pageData, 'residentIntakeForm'))['status'] = 'ACTIVE';
    await Backendless.Data.of('Residents').save( ({ [`objectId`]: (getObjectProperty(___arguments.context.pageData, 'selectedItemResident.objectId')),[`status`]: 'ACTIVE',[`pillar`]: (getObjectProperty(___arguments.context.pageData, 'residentPillarRadio')),[`pillarHistory`]: (JSON.stringify([({ [`pillar`]: (getObjectProperty(___arguments.context.pageData, 'residentPillarRadio')),[`pillarStart`]: String(new Date()),[`pillarEnd`]: undefined })])),[`residentIntakeForm`]: (JSON.stringify((getObjectProperty(___arguments.context.pageData, 'residentIntakeForm')))) }) );
    if ((getObjectProperty(___arguments.context.pageData, 'residentPillarRadio')) == 4) {
      emptyArray = [];
      fcmResponse = (await Backendless.Request.post(`${Backendless.appPath}/services/AWSApis/handleB2BFireBaseMessaging`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send({ 'token': (getObjectProperty(device, 'FCMToken')),'contactArray': emptyArray,'variant': 'deviceEnrollment' }));
    } else {
      fcmResponse = (await Backendless.Request.post(`${Backendless.appPath}/services/AWSApis/handleB2BFireBaseMessaging`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send({ 'token': (getObjectProperty(device, 'FCMToken')),'contactArray': (getObjectProperty(___arguments.context.pageData, 'residentContactReviewArray')),'variant': 'deviceEnrollment' }));
    }
    if ((getObjectProperty(fcmResponse, 'statusCode')) == '200') {
      await Backendless.Data.of('Devices').save( ({ [`objectId`]: (getObjectProperty(device, 'objectId')),[`deviceStatus`]: 'ASSIGNED' }) );
      ___arguments.context.pageData['refreshResidentTable'] = true;
      ___arguments.context.pageData['isSuccessModalOpen'] = true;
      ___arguments.context.pageData['isLoadingIntakeForm'] = false;
      ___arguments.context.pageData['isAddRecordModalOpen'] = false;
    } else {
      throw (new Error(''))
    }

  } catch (error) {
    if ((getObjectProperty(___arguments.context.pageData, 'residentIntakeVariant')) == 'updateContacts') {
      await BackendlessUI.Functions.Custom['fn_77bfd63e533a9b4c5577e5fe31a4b5ca'](___arguments.context.pageData, 'Error updating contacts', 'failure');
    } else {
      await BackendlessUI.Functions.Custom['fn_77bfd63e533a9b4c5577e5fe31a4b5ca'](___arguments.context.pageData, 'Error completing device assignment.', 'failure');
    }
    ___arguments.context.pageData['isLoadingIntakeForm'] = false;

  }
}

/**
 * Describe this function...
 */
async function residentContactStepOne() {
  hasError = false;
  listOfContacts = [({ [`index`]: 1,[`fullName`]: (getObjectProperty(___arguments.context.pageData, 'residentContactFullNameOne')),[`cellPhone`]: (getObjectProperty(___arguments.context.pageData, 'residentContactCellPhoneOne')),[`homePhone`]: (getObjectProperty(___arguments.context.pageData, 'residentContactHomePhoneOne')) }), ({ [`index`]: 2,[`fullName`]: (getObjectProperty(___arguments.context.pageData, 'residentContactFullNameTwo')),[`cellPhone`]: (getObjectProperty(___arguments.context.pageData, 'residentContactCellPhoneTwo')),[`homePhone`]: (getObjectProperty(___arguments.context.pageData, 'residentContactHomePhoneTwo')) }), ({ [`index`]: 3,[`fullName`]: (getObjectProperty(___arguments.context.pageData, 'residentContactFullNameThree')),[`cellPhone`]: (getObjectProperty(___arguments.context.pageData, 'residentContactCellPhoneThree')),[`homePhone`]: (getObjectProperty(___arguments.context.pageData, 'residentContactHomePhoneThree')) }), ({ [`index`]: 4,[`fullName`]: (getObjectProperty(___arguments.context.pageData, 'residentContactFullNameFour')),[`cellPhone`]: (getObjectProperty(___arguments.context.pageData, 'residentContactCellPhoneFour')),[`homePhone`]: (getObjectProperty(___arguments.context.pageData, 'residentContactHomePhoneFour')) }), ({ [`index`]: 5,[`fullName`]: (getObjectProperty(___arguments.context.pageData, 'residentContactFullNameFive')),[`cellPhone`]: (getObjectProperty(___arguments.context.pageData, 'residentContactCellPhoneFive')),[`homePhone`]: (getObjectProperty(___arguments.context.pageData, 'residentContactHomePhoneFive')) })];
  for (var item_index in listOfContacts) {
    item = listOfContacts[item_index];
    if (getObjectProperty(item, 'cellPhone')) {
      if (!(await BackendlessUI.Functions.Custom['fn_6d4580c12cbae4a32f38d054d2dc9e71']((await BackendlessUI.Functions.Custom['fn_f282814277b3c0aa13f33d3b2cda111b']((getObjectProperty(item, 'cellPhone')), 'remove'))))) {
        if ((getObjectProperty(item, 'index')) == 1) {
          phoneContactNumber = 'One';
        } else if ((getObjectProperty(item, 'index')) == 2) {
          phoneContactNumber = 'Two';
        } else if ((getObjectProperty(item, 'index')) == 3) {
          phoneContactNumber = 'Three';
        } else if ((getObjectProperty(item, 'index')) == 4) {
          phoneContactNumber = 'Four';
        } else if ((getObjectProperty(item, 'index')) == 5) {
          phoneContactNumber = 'Five';
        }
        ___arguments.context.pageData[(String('cellPhoneContactError') + String(phoneContactNumber))] = true;
        hasError = true;
      }
    }
    if (getObjectProperty(item, 'homePhone')) {
      if (!(await BackendlessUI.Functions.Custom['fn_6d4580c12cbae4a32f38d054d2dc9e71']((await BackendlessUI.Functions.Custom['fn_f282814277b3c0aa13f33d3b2cda111b']((getObjectProperty(item, 'homePhone')), 'remove'))))) {
        if ((getObjectProperty(item, 'index')) == 1) {
          phoneContactNumber = 'One';
        } else if ((getObjectProperty(item, 'index')) == 2) {
          phoneContactNumber = 'Two';
        } else if ((getObjectProperty(item, 'index')) == 3) {
          phoneContactNumber = 'Three';
        } else if ((getObjectProperty(item, 'index')) == 4) {
          phoneContactNumber = 'Four';
        } else if ((getObjectProperty(item, 'index')) == 5) {
          phoneContactNumber = 'Five';
        }
        ___arguments.context.pageData[(String('homePhoneContactError') + String(phoneContactNumber))] = true;
        hasError = true;
      }
    }
  }
  if ((getObjectProperty(___arguments.context.pageData, 'residentPillarRadio')) == '1') {
    listOfContacts = listOfContacts.slice(0, 1);
  } else if ((getObjectProperty(___arguments.context.pageData, 'residentPillarRadio')) == '2') {
    listOfContacts = listOfContacts.slice(0, 3);
  }
  if (listOfContacts && !hasError) {
    listOfFilteredContacts = (await asyncListFilter(listOfContacts, async (item) => {
       if (!(await BackendlessUI.Functions.Custom['fn_a7ad4c1fd5d18ca6bff3eb611aeee2ac']((getObjectProperty(item, 'fullName')))) && (getObjectProperty(item, 'fullName'))) {
        if ((getObjectProperty(item, 'homePhone')) && (await BackendlessUI.Functions.Custom['fn_6d4580c12cbae4a32f38d054d2dc9e71']((await BackendlessUI.Functions.Custom['fn_f282814277b3c0aa13f33d3b2cda111b']((getObjectProperty(item, 'homePhone')), 'remove'))))) {
          if ((getObjectProperty(item, 'cellPhone')) && !(await BackendlessUI.Functions.Custom['fn_a7ad4c1fd5d18ca6bff3eb611aeee2ac']((getObjectProperty(item, 'cellPhone')))) && !(await BackendlessUI.Functions.Custom['fn_6d4580c12cbae4a32f38d054d2dc9e71']((await BackendlessUI.Functions.Custom['fn_f282814277b3c0aa13f33d3b2cda111b']((getObjectProperty(item, 'cellPhone')), 'remove'))))) {
            return false;
          } else {
            return true;
          }
        }
        if ((getObjectProperty(item, 'cellPhone')) && (await BackendlessUI.Functions.Custom['fn_6d4580c12cbae4a32f38d054d2dc9e71']((await BackendlessUI.Functions.Custom['fn_f282814277b3c0aa13f33d3b2cda111b']((getObjectProperty(item, 'cellPhone')), 'remove'))))) {
          if ((getObjectProperty(item, 'homePhone')) && !(await BackendlessUI.Functions.Custom['fn_a7ad4c1fd5d18ca6bff3eb611aeee2ac']((getObjectProperty(item, 'homePhone')))) && !(await BackendlessUI.Functions.Custom['fn_6d4580c12cbae4a32f38d054d2dc9e71']((await BackendlessUI.Functions.Custom['fn_f282814277b3c0aa13f33d3b2cda111b']((getObjectProperty(item, 'homePhone')), 'remove'))))) {
            return false;
          } else {
            return true;
          }
        }
      }


     return ;
    }));
    if (listOfFilteredContacts.length > 0) {
      ___arguments.context.pageData['residentContactReviewArray'] = listOfFilteredContacts;
      if ((getObjectProperty(___arguments.context.pageData, 'residentIntakeVariant')) != 'updateContacts') {
        ___arguments.context.pageData['residentIntakeContactsStep'] = 2;
      }
    }
  }
}


  currentUser = (getObjectProperty(___arguments.context.pageData, 'currentUser'));
  if ((getObjectProperty(___arguments.context.pageData, 'residentIntakeVariant')) == 'intake') {
    if ((getObjectProperty(___arguments.context.pageData, 'residentIntakeStep')) == 2) {
      if (!(await BackendlessUI.Functions.Custom['fn_36435bc8e7f437df45a6682ebdbf7388']((getObjectProperty(___arguments.context.pageData, 'residentIntakeForm.emergencyContact.email'))))) {
        ___arguments.context.pageData['residentIntakeFormEmailError'] = true;
      }
    }
    if (!(getObjectProperty(___arguments.context.pageData, 'residentIntakeFormEmailError'))) {
      if ((getObjectProperty(___arguments.context.pageData, 'residentIntakeStep')) == 4) {
        ___arguments.context.pageData['isLoadingIntakeForm'] = true;
        try {
          (getObjectProperty(___arguments.context.pageData, 'residentIntakeForm'))['status'] = 'INCOMPLETE';
          resident = (await Backendless.Data.of('Residents').save( ({ [`name`]: (getObjectProperty(___arguments.context.pageData, 'residentIntakeForm.personalDetails.fullName')),[`nameInUse`]: (getObjectProperty(___arguments.context.pageData, 'residentIntakeForm.personalDetails.nameInUse')),[`status`]: 'INCOMPLETE' }) ));
          if (getObjectProperty(___arguments.context.pageData, 'selectedResidentImageFile')) {
            profilePictureUrl = (await Backendless.Files.upload(
                ((getObjectProperty(___arguments.context.pageData, 'selectedResidentImageFile'))[0]),
                encodePath((['/profile_pictures/',(getObjectProperty(resident, 'objectId')),'/'].join(''))),
                false
              ).then(result => result.fileURL));
            (getObjectProperty(___arguments.context.pageData, 'residentIntakeForm'))['profilePicture'] = profilePictureUrl;
          }
          await Backendless.Data.of('Residents').addRelation(resident, 'managerId', [currentUser]);
          await Backendless.Data.of('Residents').addRelation(resident, 'organizationId', [(getObjectProperty(currentUser, 'organizationId'))]);
          (getObjectProperty(___arguments.context.pageData, 'residentIntakeForm'))['residentObjectId'] = (getObjectProperty(resident, 'objectId'));
          await Backendless.Data.of('Residents').save( ({ [`objectId`]: (getObjectProperty(resident, 'objectId')),[`profilePicture`]: profilePictureUrl,[`residentIntakeForm`]: (JSON.stringify((getObjectProperty(___arguments.context.pageData, 'residentIntakeForm')))) }) );
          ___arguments.context.pageData['isNewResident'] = true;
          ___arguments.context.pageData['isSuccessModalOpen'] = true;
          ___arguments.context.pageData['isAddRecordModalOpen'] = false;
          ___arguments.context.pageData['isLoadingIntakeForm'] = false;
          ___arguments.context.pageData['refreshResidentTable'] = true;
          ___arguments.context.pageData['noResultsScreen'] = false;

        } catch (error) {
          await BackendlessUI.Functions.Custom['fn_77bfd63e533a9b4c5577e5fe31a4b5ca'](___arguments.context.pageData, 'failure', 'Error Adding Resident');
          ___arguments.context.pageData['isLoadingIntakeForm'] = false;

        }
      }
      if ((getObjectProperty(___arguments.context.pageData, 'residentIntakeStep')) < 4) {
        ___arguments.context.pageData['residentIntakeStep'] = ((getObjectProperty(___arguments.context.pageData, 'residentIntakeStep')) + 1);
      }
    }
  } else if ((getObjectProperty(___arguments.context.pageData, 'residentIntakeVariant')) == 'viewIntake') {
    if ((getObjectProperty(___arguments.context.pageData, 'residentIntakeStep')) == 4) {
      ___arguments.context.pageData['isLoadingIntakeForm'] = true;
      try {
        if (getObjectProperty(___arguments.context.pageData, 'selectedResidentImageFile')) {
          profilePictureUrl = (await Backendless.Files.upload(
              ((getObjectProperty(___arguments.context.pageData, 'selectedResidentImageFile'))[0]),
              encodePath((['/profile_pictures/',(getObjectProperty(resident, 'objectId')),'/'].join(''))),
              false
            ).then(result => result.fileURL));
          (getObjectProperty(___arguments.context.pageData, 'residentIntakeForm'))['profilePicture'] = profilePictureUrl;
          await Backendless.Data.of('Residents').save( ({ [`objectId`]: (getObjectProperty(___arguments.context.pageData, 'residentIntakeForm.residentObjectId')),[`nameInUse`]: (getObjectProperty(___arguments.context.pageData, 'residentIntakeForm.personalDetails.nameInUse')),[`name`]: (getObjectProperty(___arguments.context.pageData, 'residentIntakeForm.personalDetails.fullName')),[`profilePicture`]: profilePictureUrl,[`residentIntakeForm`]: (JSON.stringify((getObjectProperty(___arguments.context.pageData, 'residentIntakeForm')))) }) );
        } else {
          await Backendless.Data.of('Residents').save( ({ [`objectId`]: (getObjectProperty(___arguments.context.pageData, 'residentIntakeForm.residentObjectId')),[`nameInUse`]: (getObjectProperty(___arguments.context.pageData, 'residentIntakeForm.personalDetails.nameInUse')),[`name`]: (getObjectProperty(___arguments.context.pageData, 'residentIntakeForm.personalDetails.fullName')),[`residentIntakeForm`]: (JSON.stringify((getObjectProperty(___arguments.context.pageData, 'residentIntakeForm')))) }) );
        }
        ___arguments.context.pageData['isAddRecordModalOpen'] = false;
        ___arguments.context.pageData['isLoadingIntakeForm'] = false;
        ___arguments.context.pageData['refreshResidentTable'] = true;
        ___arguments.context.pageData['noResultsScreen'] = false;

      } catch (error) {
        await BackendlessUI.Functions.Custom['fn_77bfd63e533a9b4c5577e5fe31a4b5ca'](___arguments.context.pageData, 'failure', 'Error Editing Resident');
        ___arguments.context.pageData['isLoadingIntakeForm'] = false;

      }
    } else {
      ___arguments.context.pageData['residentIntakeStep'] = 4;
      if (getObjectProperty(___arguments.context.pageData, 'isNewResident')) {
        ___arguments.context.pageData['residentIntakeVariant'] = 'intake';
      }
    }
  } else if ((getObjectProperty(___arguments.context.pageData, 'residentIntakeVariant')) == 'pillar') {
    ___arguments.context.pageData['hasOnboardStarted'] = undefined;
    ___arguments.context.pageData['residentIntakeVariant'] = 'device';
    await BackendlessUI.Functions.Custom['fn_9b44aef25264690eed0615df9af44168'](___arguments.context.pageData, '2');
    if (getObjectProperty(___arguments.context.pageData, 'isQRSuccessful')) {
      while (!(getObjectProperty(___arguments.context.pageData, 'hasStoppedPolling'))) {
        try {
          resident = (await Backendless.Data.of('Residents').findById((getObjectProperty(___arguments.context.pageData, 'selectedItemResident.objectId')), Backendless.DataQueryBuilder.create().setRelationsDepth(2)));
          device = (await asyncListFilter((getObjectProperty(resident, 'deviceId')), async (item) => {
             if ((getObjectProperty(item, 'deviceStatus')) == 'ONBOARDSTARTED') {
              return true;
            } else {
              return false;
            }


           return ;
          }));
          if (device.length == 1) {
            ___arguments.context.pageData['hasStoppedPolling'] = true;
            ___arguments.context.pageData['refreshResidentTable'] = true;
            ___arguments.context.pageData['hasOnboardStarted'] = true;
            ___arguments.context.pageData['isSuccessModalOpen'] = true;
          } else {
            await new Promise(r => setTimeout(r, 3000 || 0));
          }

        } catch (error) {
          ___arguments.context.pageData['hasStoppedPolling'] = true;
          await BackendlessUI.Functions.Custom['fn_77bfd63e533a9b4c5577e5fe31a4b5ca'](___arguments.context.pageData, 'Error adding resident device.', 'failure');

        }
      }
    }
  } else if ((getObjectProperty(___arguments.context.pageData, 'residentIntakeVariant')) == 'updatePillar') {
    if ((getObjectProperty(___arguments.context.pageData, 'selectedItemResident.pillar')) != (getObjectProperty(___arguments.context.pageData, 'residentPillarRadio'))) {
      try {
        ___arguments.context.pageData['isLoadingIntakeForm'] = true;
        pillarHistoryArray = (JSON.parse((getObjectProperty(___arguments.context.pageData, 'selectedItemResident.pillarHistory'))));
        lastPillar = pillarHistoryArray.slice(-1)[0];
        replaceItemInList(pillarHistoryArray, ({ [`pillar`]: (getObjectProperty(lastPillar, 'pillar')),[`pillarStart`]: (getObjectProperty(lastPillar, 'pillarStart')),[`pillarEnd`]: String(new Date()) }), 'pillarStart');
        (getObjectProperty(___arguments.context.pageData, 'residentIntakeForm'))['pillarInfo'] = ({ [`pillar`]: (getObjectProperty(___arguments.context.pageData, 'residentPillarRadio')),[`pillarStart`]: String(new Date()),[`pillarEnd`]: undefined });
        await Backendless.Data.of('Residents').save( ({ [`objectId`]: (getObjectProperty(___arguments.context.pageData, 'selectedItemResident.objectId')),[`pillarHistory`]: (JSON.stringify((addItemToList(pillarHistoryArray, ({ [`pillar`]: (getObjectProperty(___arguments.context.pageData, 'residentPillarRadio')),[`pillarStart`]: String(new Date()),[`pillarEnd`]: undefined }))))),[`pillar`]: (getObjectProperty(___arguments.context.pageData, 'residentPillarRadio')),[`residentIntakeForm`]: (JSON.stringify((getObjectProperty(___arguments.context.pageData, 'residentIntakeForm')))) }) );
        ___arguments.context.pageData['isLoadingIntakeForm'] = false;
        ___arguments.context.pageData['isAddRecordModalOpen'] = false;
        if ((getObjectProperty(___arguments.context.pageData, 'residentPillarRadio')) == 'graduated') {
          ___arguments.context.pageData['residentIntakeVariant'] = 'graduated';
        }

      } catch (error) {
        await BackendlessUI.Functions.Custom['fn_77bfd63e533a9b4c5577e5fe31a4b5ca'](___arguments.context.pageData, 'failure', 'Error updating resident pillar');
        ___arguments.context.pageData['isLoadingIntakeForm'] = false;

      }
    } else {
      ___arguments.context.pageData['isAddRecordModalOpen'] = false;
    }
  } else if ((getObjectProperty(___arguments.context.pageData, 'residentIntakeVariant')) == 'device') {
    if ((getObjectProperty(___arguments.context.pageData, 'residentPillarRadio')) == 4) {
      await residentContactStepTwo();
    } else {
      try {
        ___arguments.context.pageData['selectedItemResident'] = (await Backendless.Data.of('Residents').findById((getObjectProperty(___arguments.context.pageData, 'selectedItemResident.objectId')), Backendless.DataQueryBuilder.create().setRelationsDepth(2)));
        ___arguments.context.pageData['residentIntakeContactsStep'] = 1;
        ___arguments.context.pageData['residentIntakeVariant'] = 'contacts';

      } catch (error) {
        await BackendlessUI.Functions.Custom['fn_77bfd63e533a9b4c5577e5fe31a4b5ca'](___arguments.context.pageData, 'failure', 'Error getting resident info');

      }
    }
  } else if ((getObjectProperty(___arguments.context.pageData, 'residentIntakeVariant')) == 'contacts') {
    if ((getObjectProperty(___arguments.context.pageData, 'residentIntakeContactsStep')) == 2) {
      await residentContactStepTwo();
    } else {
      await residentContactStepOne();
    }
  } else if ((getObjectProperty(___arguments.context.pageData, 'residentIntakeVariant')) == 'updateContacts') {
    await residentContactStepOne();
    await residentContactStepTwo();
  }

  },
  /* handler:onClick */
  /* handler:onStyleAssignment */
  async ['onStyleAssignment'](___arguments) {
    var isDisabled, button;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  isDisabled = false;
  button = ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('99bdb68696f5ddc83938faeda578929d'));
  if (getObjectProperty(___arguments.context.pageData, 'isLoadingIntakeForm')) {
    isDisabled = true;
  }
  if ((getObjectProperty(___arguments.context.pageData, 'residentIntakeVariant')) == 'pillar' && (!(getObjectProperty(___arguments.context.pageData, 'residentPillarRadio')) || !(getObjectProperty(___arguments.context.pageData, 'hasAcknowledged')))) {
    isDisabled = true;
  } else if (!(getObjectProperty(___arguments.context.pageData, 'hasOnboardStarted')) && (getObjectProperty(___arguments.context.pageData, 'residentIntakeVariant')) == 'device') {
    isDisabled = true;
  } else if ((getObjectProperty(___arguments.context.pageData, 'residentIntakeStep')) == 1 || (getObjectProperty(___arguments.context.pageData, 'residentIntakeStep')) == 4) {
    if (!(getObjectProperty(___arguments.context.pageData, 'residentIntakeForm.personalDetails.fullName')) || (await BackendlessUI.Functions.Custom['fn_a7ad4c1fd5d18ca6bff3eb611aeee2ac']((getObjectProperty(___arguments.context.pageData, 'residentIntakeForm.personalDetails.fullName'))))) {
      isDisabled = true;
    } else if (!(getObjectProperty(___arguments.context.pageData, 'residentIntakeForm.personalDetails.nameInUse')) || (await BackendlessUI.Functions.Custom['fn_a7ad4c1fd5d18ca6bff3eb611aeee2ac']((getObjectProperty(___arguments.context.pageData, 'residentIntakeForm.personalDetails.nameInUse'))))) {
      isDisabled = true;
    } else if (!(getObjectProperty(___arguments.context.pageData, 'residentIntakeForm.personalDetails.dob')) || (await BackendlessUI.Functions.Custom['fn_a7ad4c1fd5d18ca6bff3eb611aeee2ac']((getObjectProperty(___arguments.context.pageData, 'residentIntakeForm.personalDetails.dob')))) || (getObjectProperty(___arguments.context.pageData, 'residentIntakeForm.personalDetails.dob')).length != 10) {
      isDisabled = true;
    } else if (!(getObjectProperty(___arguments.context.pageData, 'residentIntakeForm.personalDetails.genderIdentity.answer'))) {
      isDisabled = true;
    } else if (!(getObjectProperty(___arguments.context.pageData, 'residentIntakeForm.personalDetails.pronouns.answer'))) {
      isDisabled = true;
    } else if (!(getObjectProperty(___arguments.context.pageData, 'residentIntakeForm.personalDetails.race.answer'))) {
      isDisabled = true;
    } else if ((getObjectProperty(___arguments.context.pageData, 'residentIntakeForm.personalDetails.race.answer')) == 'other') {
      if (!(getObjectProperty(___arguments.context.pageData, 'residentIntakeForm.personalDetails.race.other')) || (await BackendlessUI.Functions.Custom['fn_a7ad4c1fd5d18ca6bff3eb611aeee2ac']((getObjectProperty(___arguments.context.pageData, 'residentIntakeForm.personalDetails.race.other'))))) {
        isDisabled = true;
      }
    } else if ((getObjectProperty(___arguments.context.pageData, 'residentIntakeForm.personalDetails.pronouns.answer')) == 'Other') {
      if (!(getObjectProperty(___arguments.context.pageData, 'residentIntakeForm.personalDetails.pronouns.other')) || (await BackendlessUI.Functions.Custom['fn_a7ad4c1fd5d18ca6bff3eb611aeee2ac']((getObjectProperty(___arguments.context.pageData, 'residentIntakeForm.personalDetails.pronouns.other'))))) {
        isDisabled = true;
      }
    } else if ((getObjectProperty(___arguments.context.pageData, 'residentIntakeForm.personalDetails.genderIdentity.answer')) == 'Other') {
      if (!(getObjectProperty(___arguments.context.pageData, 'residentIntakeForm.personalDetails.genderIdentity.other')) || (await BackendlessUI.Functions.Custom['fn_a7ad4c1fd5d18ca6bff3eb611aeee2ac']((getObjectProperty(___arguments.context.pageData, 'residentIntakeForm.personalDetails.genderIdentity.other'))))) {
        isDisabled = true;
      }
    }
  } else if ((getObjectProperty(___arguments.context.pageData, 'residentIntakeStep')) == 2 || (getObjectProperty(___arguments.context.pageData, 'residentIntakeStep')) == 4) {
    if (!(getObjectProperty(___arguments.context.pageData, 'residentIntakeForm.emergencyContact.fullName')) || (await BackendlessUI.Functions.Custom['fn_a7ad4c1fd5d18ca6bff3eb611aeee2ac']((getObjectProperty(___arguments.context.pageData, 'residentIntakeForm.emergencyContact.fullName'))))) {
      isDisabled = true;
    } else if (!(getObjectProperty(___arguments.context.pageData, 'residentIntakeForm.emergencyContact.email')) || (await BackendlessUI.Functions.Custom['fn_a7ad4c1fd5d18ca6bff3eb611aeee2ac']((getObjectProperty(___arguments.context.pageData, 'residentIntakeForm.emergencyContact.email'))))) {
      isDisabled = true;
    } else if (!(getObjectProperty(___arguments.context.pageData, 'residentIntakeForm.emergencyContact.phoneNumber')) || (await BackendlessUI.Functions.Custom['fn_a7ad4c1fd5d18ca6bff3eb611aeee2ac']((getObjectProperty(___arguments.context.pageData, 'residentIntakeForm.emergencyContact.phoneNumber'))))) {
      isDisabled = true;
    } else if (!(getObjectProperty(___arguments.context.pageData, 'residentIntakeForm.emergencyContact.relationship.answer'))) {
      isDisabled = true;
    } else if ((getObjectProperty(___arguments.context.pageData, 'residentIntakeForm.emergencyContact.relationship.answer')) == 'Other') {
      if (!(getObjectProperty(___arguments.context.pageData, 'residentIntakeForm.emergencyContact.relationship.other')) || (await BackendlessUI.Functions.Custom['fn_a7ad4c1fd5d18ca6bff3eb611aeee2ac']((getObjectProperty(___arguments.context.pageData, 'residentIntakeForm.emergencyContact.relationship.other'))))) {
        isDisabled = true;
      }
    }
  } else if ((getObjectProperty(___arguments.context.pageData, 'residentIntakeStep')) == 3 || (getObjectProperty(___arguments.context.pageData, 'residentIntakeStep')) == 4) {
    if (!(getObjectProperty(___arguments.context.pageData, 'residentIntakeForm.navigationDetails.comingFrom')) || (await BackendlessUI.Functions.Custom['fn_a7ad4c1fd5d18ca6bff3eb611aeee2ac']((getObjectProperty(___arguments.context.pageData, 'residentIntakeForm.navigationDetails.comingFrom'))))) {
      isDisabled = true;
    } else if (!(getObjectProperty(___arguments.context.pageData, 'residentIntakeForm.navigationDetails.howLong')) || (await BackendlessUI.Functions.Custom['fn_a7ad4c1fd5d18ca6bff3eb611aeee2ac']((getObjectProperty(___arguments.context.pageData, 'residentIntakeForm.navigationDetails.howLong'))))) {
      isDisabled = true;
    }
  }
  ___arguments.context.pageData['isResidentIntakeButtonDisabled'] = isDisabled;

  },
  /* handler:onStyleAssignment */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    var classesList;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  classesList = ['default_button'];
  if (getObjectProperty(___arguments.context.pageData, 'isResidentIntakeButtonDisabled')) {
    addItemToList(classesList, 'disabled_button');
  } else {
    addItemToList(classesList, 'enabled_button');
  }

  return classesList

  },
  /* handler:onClassListAssignment */
  /* handler:onLabelAssignment */
  ['onLabelAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'isLoadingIntakeForm')) {
    return 'Please wait...';
  } else {
    if ((getObjectProperty(___arguments.context.pageData, 'residentIntakeVariant')) == 'intake') {
      if ((getObjectProperty(___arguments.context.pageData, 'residentIntakeStep')) == 4) {
        return 'Finish Intake';
      } else if ((getObjectProperty(___arguments.context.pageData, 'residentIntakeStep')) == 3) {
        return 'Review';
      } else {
        return 'Continue';
      }
    } else if ((getObjectProperty(___arguments.context.pageData, 'residentIntakeVariant')) == 'viewIntake') {
      if ((getObjectProperty(___arguments.context.pageData, 'residentIntakeStep')) == 4) {
        return 'Save';
      } else {
        return 'Review';
      }
    } else if ((getObjectProperty(___arguments.context.pageData, 'residentIntakeVariant')) == 'graduated') {
      return 'Updated Pillar';
    } else if ((getObjectProperty(___arguments.context.pageData, 'residentIntakeVariant')) == 'updatePillar') {
      return 'Update';
    } else if ((getObjectProperty(___arguments.context.pageData, 'residentIntakeVariant')) == 'pillar') {
      return 'Scan QR Code';
    } else if ((getObjectProperty(___arguments.context.pageData, 'residentIntakeVariant')) == 'device') {
      if (getObjectProperty(___arguments.context.pageData, 'hasOnboardStarted')) {
        return 'Add Contacts';
      } else {
        return 'Waiting For Device...';
      }
    } else if ((getObjectProperty(___arguments.context.pageData, 'residentIntakeVariant')) == 'contacts') {
      return 'Next';
    } else if ((getObjectProperty(___arguments.context.pageData, 'residentIntakeVariant')) == 'updateContacts') {
      return 'Save';
    }
  }

  },
  /* handler:onLabelAssignment */
  /* content */
}))

define('./components/reusable/r_5871cdf8de1657653b530652a7730dc7/logic/e43d840387f7ac376c4fb3664f5f9f95/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (((getObjectProperty(___arguments.context.pageData, 'residentIntakeVariant')) == 'intake' || (getObjectProperty(___arguments.context.pageData, 'residentIntakeVariant')) == 'viewIntake') && (getObjectProperty(___arguments.context.pageData, 'residentIntakeStep')) == 1) {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('e43d840387f7ac376c4fb3664f5f9f95', true);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('e43d840387f7ac376c4fb3664f5f9f95', false);
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./components/reusable/r_5871cdf8de1657653b530652a7730dc7/logic/13da7acd9cea55df725bf9077c625f7e/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'residentIntakeVariant')) == 'device') {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('13da7acd9cea55df725bf9077c625f7e', true);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('13da7acd9cea55df725bf9077c625f7e', false);
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./components/reusable/r_5871cdf8de1657653b530652a7730dc7/logic/b6e6bb9cba1fff5a489d183cff3afcbc/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'isLoadingQRCode')) {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('b6e6bb9cba1fff5a489d183cff3afcbc', false);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('b6e6bb9cba1fff5a489d183cff3afcbc', true);
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./components/reusable/r_5871cdf8de1657653b530652a7730dc7/logic/bf7d9ce47e4eb80a9db9d12923d99716/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'getQRCodeError')) {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('bf7d9ce47e4eb80a9db9d12923d99716', true);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('bf7d9ce47e4eb80a9db9d12923d99716', false);
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./components/reusable/r_5871cdf8de1657653b530652a7730dc7/logic/0e7346953abad935c3ab7fb174610df9/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'isLoadingQRCode')) {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('0e7346953abad935c3ab7fb174610df9', true);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('0e7346953abad935c3ab7fb174610df9', false);
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./components/reusable/r_5871cdf8de1657653b530652a7730dc7/logic/a9d6f6613d785f391392c816f1565e58/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'residentIntakeVariant')) == 'contacts' || (getObjectProperty(___arguments.context.pageData, 'residentIntakeVariant')) == 'updateContacts') {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('a9d6f6613d785f391392c816f1565e58', true);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('a9d6f6613d785f391392c816f1565e58', false);
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./components/reusable/r_5871cdf8de1657653b530652a7730dc7/logic/4cb5417742d1b4be670af67f06d6ecd7/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      ___arguments.context.pageData['confirmationOption'] = 'cancelIntake';
  ___arguments.context.pageData['isConfirmationModalOpen'] = true;

  },
  /* handler:onClick */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (!(getObjectProperty(___arguments.context.pageData, 'hasOnboardStarted')) && (getObjectProperty(___arguments.context.pageData, 'residentIntakeVariant')) == 'device') {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('4cb5417742d1b4be670af67f06d6ecd7', false);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('4cb5417742d1b4be670af67f06d6ecd7', true);
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./components/reusable/r_5871cdf8de1657653b530652a7730dc7/logic/f194cee12a76aa92d3e98275a8ffd627/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'residentIntakeVariant')) == 'intake' || (getObjectProperty(___arguments.context.pageData, 'residentIntakeVariant')) == 'viewIntake') {
    return 'Resident Intake Form';
  } else if ((getObjectProperty(___arguments.context.pageData, 'residentIntakeVariant')) == 'updatePillar') {
    return 'Update Resident Pillar';
  } else if ((getObjectProperty(___arguments.context.pageData, 'residentIntakeVariant')) == 'pillar' || (getObjectProperty(___arguments.context.pageData, 'residentIntakeVariant')) == 'device') {
    return 'Assign Device';
  } else if ((getObjectProperty(___arguments.context.pageData, 'residentIntakeVariant')) == 'contacts') {
    return 'Contact Details';
  } else if ((getObjectProperty(___arguments.context.pageData, 'residentIntakeVariant')) == 'updateContacts') {
    return 'Update Contacts';
  }

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./components/reusable/r_5871cdf8de1657653b530652a7730dc7/logic/662adda1361e1d152b2d9d2d59491323/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  async ['onChange'](___arguments) {
      ___arguments.context.pageData['residentContactFullNameOne'] = (await BackendlessUI.Functions.Custom['fn_256db7f8ee15af9d778314d97f563562'](___arguments.value));

  },
  /* handler:onChange */
  /* content */
}))

define('./components/reusable/r_5871cdf8de1657653b530652a7730dc7/logic/40d72aefff6e9c899339b36444a38d06/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'residentPillarRadio')) == 1) {
    return 'Contact 1/1';
  } else if ((getObjectProperty(___arguments.context.pageData, 'residentPillarRadio')) == 2) {
    return 'Contact 1/3';
  } else if ((getObjectProperty(___arguments.context.pageData, 'residentPillarRadio')) == 3 || (getObjectProperty(___arguments.context.pageData, 'residentPillarRadio')) == 4) {
    return 'Contact 1/5';
  }

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./components/reusable/r_5871cdf8de1657653b530652a7730dc7/logic/d2279151cdba0c943c4945ae322202a6/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'residentIntakeContactsStep')) == 1) {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('d2279151cdba0c943c4945ae322202a6', true);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('d2279151cdba0c943c4945ae322202a6', false);
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./components/reusable/r_5871cdf8de1657653b530652a7730dc7/logic/214469d7319cdac95d13b649565c16af/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'residentIntakeContactsStep')) == 2) {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('214469d7319cdac95d13b649565c16af', true);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('214469d7319cdac95d13b649565c16af', false);
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./components/reusable/r_5871cdf8de1657653b530652a7730dc7/logic/3363ba1eeeef3b34823ece1ad6799db9/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  async ['onChange'](___arguments) {
      ___arguments.context.pageData['cellPhoneContactErrorOne'] = undefined;
  ___arguments.context.pageData['residentContactCellPhoneOne'] = (await BackendlessUI.Functions.Custom['fn_f282814277b3c0aa13f33d3b2cda111b'](___arguments.value, 'create'));

  },
  /* handler:onChange */
  /* content */
}))

define('./components/reusable/r_5871cdf8de1657653b530652a7730dc7/logic/76ec360a187e5ef63eb64b3633648ae7/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  async ['onChange'](___arguments) {
      ___arguments.context.pageData['homePhoneContactErrorOne'] = undefined;
  ___arguments.context.pageData['residentContactHomePhoneOne'] = (await BackendlessUI.Functions.Custom['fn_f282814277b3c0aa13f33d3b2cda111b'](___arguments.value, 'create'));

  },
  /* handler:onChange */
  /* content */
}))

define('./components/reusable/r_5871cdf8de1657653b530652a7730dc7/logic/66296db36a09d5f1d966be2dbe4233d5/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    var confirmationOption;


  ___arguments.context.pageData['confirmationOption'] = 'cancelIntake';
  ___arguments.context.pageData['isConfirmationModalOpen'] = true;

  },
  /* handler:onClick */
  /* handler:onDisabledStateAssignment */
  ['onDisabledStateAssignment'](___arguments) {
    var classes;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (!(getObjectProperty(___arguments.context.pageData, 'hasOnboardStarted')) && (getObjectProperty(___arguments.context.pageData, 'residentIntakeVariant')) == 'device') {
    return true;
  } else {
    return false;
  }

  },
  /* handler:onDisabledStateAssignment */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    var classes;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (!(getObjectProperty(___arguments.context.pageData, 'hasOnboardStarted')) && (getObjectProperty(___arguments.context.pageData, 'residentIntakeVariant')) == 'device') {
    classes = ['disabled_button_cancel'];
  } else {
    classes = ['default_button'];
  }

  return classes

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./components/reusable/r_5871cdf8de1657653b530652a7730dc7/logic/c679f474e10a17ed48b8e5f47c778209/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      ___arguments.context.pageData['residentIntakeContactsStep'] = 1;

  },
  /* handler:onClick */
  /* content */
}))

define('./components/reusable/r_5871cdf8de1657653b530652a7730dc7/logic/69b8803c973e539bd8cd2356686cc793/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  async ['onChange'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  (getObjectProperty(___arguments.context.pageData, 'residentIntakeForm.personalDetails'))['fullName'] = (await BackendlessUI.Functions.Custom['fn_256db7f8ee15af9d778314d97f563562'](___arguments.value));

  },
  /* handler:onChange */
  /* content */
}))

define('./components/reusable/r_5871cdf8de1657653b530652a7730dc7/logic/1b616d58262e69a165ed31543d3db4fc/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  async ['onChange'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  (getObjectProperty(___arguments.context.pageData, 'residentIntakeForm.personalDetails'))['nameInUse'] = (await BackendlessUI.Functions.Custom['fn_256db7f8ee15af9d778314d97f563562'](___arguments.value));

  },
  /* handler:onChange */
  /* content */
}))

define('./components/reusable/r_5871cdf8de1657653b530652a7730dc7/logic/d42b17c1cd864bba65bc7c2888459364/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  async ['onChange'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  (getObjectProperty(___arguments.context.pageData, 'residentIntakeForm.personalDetails'))['dob'] = (await BackendlessUI.Functions.Custom['fn_8d46c0c781a168eb2601b8313797f5df'](___arguments.value, 'create'));

  },
  /* handler:onChange */
  /* content */
}))

define('./components/reusable/r_5871cdf8de1657653b530652a7730dc7/logic/44517b25c38ffc8b458fe3d5d3e151ee/bundle.js', [], () => ({
  /* content */
  /* handler:onCheckedStateAssignment */
  ['onCheckedStateAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.pageData, 'residentIntakeForm.personalDetails.race.answer')) == 'Asian')

  },
  /* handler:onCheckedStateAssignment */
  /* handler:onChange */
  ['onChange'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (___arguments.value) {
    (getObjectProperty(___arguments.context.pageData, 'residentIntakeForm.personalDetails.race'))['answer'] = 'Asian';
  } else {
    (getObjectProperty(___arguments.context.pageData, 'residentIntakeForm.personalDetails.race'))['answer'] = undefined;
  }

  },
  /* handler:onChange */
  /* content */
}))

define('./components/reusable/r_5871cdf8de1657653b530652a7730dc7/logic/3a61f0e3deab248addf1c8d9644fc4de/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  ['onChange'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (___arguments.value) {
    (getObjectProperty(___arguments.context.pageData, 'residentIntakeForm.personalDetails.race'))['answer'] = 'American Indian or Alaska Native';
  } else {
    (getObjectProperty(___arguments.context.pageData, 'residentIntakeForm.personalDetails.race'))['answer'] = undefined;
  }

  },
  /* handler:onChange */
  /* handler:onCheckedStateAssignment */
  ['onCheckedStateAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.pageData, 'residentIntakeForm.personalDetails.race.answer')) == 'American Indian or Alaska Native')

  },
  /* handler:onCheckedStateAssignment */
  /* content */
}))

define('./components/reusable/r_5871cdf8de1657653b530652a7730dc7/logic/590ef43ae77f89b974990d6df22588ad/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  ['onChange'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (___arguments.value) {
    (getObjectProperty(___arguments.context.pageData, 'residentIntakeForm.personalDetails.race'))['answer'] = 'Black or African American';
  } else {
    (getObjectProperty(___arguments.context.pageData, 'residentIntakeForm.personalDetails.race'))['answer'] = undefined;
  }

  },
  /* handler:onChange */
  /* handler:onCheckedStateAssignment */
  ['onCheckedStateAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.pageData, 'residentIntakeForm.personalDetails.race.answer')) == 'Black or African American')

  },
  /* handler:onCheckedStateAssignment */
  /* content */
}))

define('./components/reusable/r_5871cdf8de1657653b530652a7730dc7/logic/668b8c19d1bfa57c068c80ca81470d6d/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  ['onChange'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (___arguments.value) {
    (getObjectProperty(___arguments.context.pageData, 'residentIntakeForm.personalDetails.race'))['answer'] = 'Hispanic or Latino';
  } else {
    (getObjectProperty(___arguments.context.pageData, 'residentIntakeForm.personalDetails.race'))['answer'] = undefined;
  }

  },
  /* handler:onChange */
  /* handler:onCheckedStateAssignment */
  ['onCheckedStateAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.pageData, 'residentIntakeForm.personalDetails.race.answer')) == 'Hispanic or Latino')

  },
  /* handler:onCheckedStateAssignment */
  /* content */
}))

define('./components/reusable/r_5871cdf8de1657653b530652a7730dc7/logic/50cd6b4a4ca5effa0033e7078241c4d2/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  ['onChange'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (___arguments.value) {
    (getObjectProperty(___arguments.context.pageData, 'residentIntakeForm.personalDetails.race'))['answer'] = 'Native Hawaiian or Other Pacific Islander';
  } else {
    (getObjectProperty(___arguments.context.pageData, 'residentIntakeForm.personalDetails'))['answer'] = undefined;
  }

  },
  /* handler:onChange */
  /* handler:onCheckedStateAssignment */
  ['onCheckedStateAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.pageData, 'residentIntakeForm.personalDetails.race.answer')) == 'Native Hawaiian or Other Pacific Islander')

  },
  /* handler:onCheckedStateAssignment */
  /* content */
}))

define('./components/reusable/r_5871cdf8de1657653b530652a7730dc7/logic/251625c3e8a87c1b4c9b0502ca0f378c/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  ['onChange'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (___arguments.value) {
    (getObjectProperty(___arguments.context.pageData, 'residentIntakeForm.personalDetails.race'))['answer'] = 'White';
  } else {
    (getObjectProperty(___arguments.context.pageData, 'residentIntakeForm.personalDetails.race'))['answer'] = undefined;
  }

  },
  /* handler:onChange */
  /* handler:onCheckedStateAssignment */
  ['onCheckedStateAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.pageData, 'residentIntakeForm.personalDetails.race.answer')) == 'White')

  },
  /* handler:onCheckedStateAssignment */
  /* content */
}))

define('./components/reusable/r_5871cdf8de1657653b530652a7730dc7/logic/903bc8b60106c5a3487fd33e11e3570e/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  ['onChange'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (___arguments.value) {
    (getObjectProperty(___arguments.context.pageData, 'residentIntakeForm.personalDetails.race'))['answer'] = 'Middle Eastern or North African';
  } else {
    (getObjectProperty(___arguments.context.pageData, 'residentIntakeForm.personalDetails'))['answer'] = undefined;
  }

  },
  /* handler:onChange */
  /* handler:onCheckedStateAssignment */
  ['onCheckedStateAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.pageData, 'residentIntakeForm.personalDetails.race.answer')) == 'Middle Eastern or North African')

  },
  /* handler:onCheckedStateAssignment */
  /* content */
}))

define('./components/reusable/r_5871cdf8de1657653b530652a7730dc7/logic/0d15d3fd47fc4eeeefb1b47be8a40541/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  ['onChange'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (___arguments.value) {
    (getObjectProperty(___arguments.context.pageData, 'residentIntakeForm.personalDetails.race'))['answer'] = 'default';
  } else {
    (getObjectProperty(___arguments.context.pageData, 'residentIntakeForm.personalDetails.race'))['answer'] = undefined;
  }

  },
  /* handler:onChange */
  /* handler:onCheckedStateAssignment */
  ['onCheckedStateAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.pageData, 'residentIntakeForm.personalDetails.race.answer')) == 'default')

  },
  /* handler:onCheckedStateAssignment */
  /* content */
}))

define('./components/reusable/r_5871cdf8de1657653b530652a7730dc7/logic/92a48012735ac93fab420bd4ce451234/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  ['onChange'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (___arguments.value) {
    (getObjectProperty(___arguments.context.pageData, 'residentIntakeForm.personalDetails.race'))['answer'] = 'other';
  } else {
    (getObjectProperty(___arguments.context.pageData, 'residentIntakeForm.personalDetails.race'))['answer'] = undefined;
  }

  },
  /* handler:onChange */
  /* handler:onCheckedStateAssignment */
  ['onCheckedStateAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.pageData, 'residentIntakeForm.personalDetails.race.answer')) == 'other')

  },
  /* handler:onCheckedStateAssignment */
  /* content */
}))

define('./components/reusable/r_5871cdf8de1657653b530652a7730dc7/logic/1ff58c0daec647aab2dabcf9e581daba/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  ['onChange'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  (getObjectProperty(___arguments.context.pageData, 'residentIntakeForm.personalDetails.race'))['other'] = ___arguments.value;

  },
  /* handler:onChange */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'residentIntakeForm.personalDetails.race.answer')) == 'other') {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('1ff58c0daec647aab2dabcf9e581daba', true);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('1ff58c0daec647aab2dabcf9e581daba', false);
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./components/reusable/r_5871cdf8de1657653b530652a7730dc7/logic/fc8a1b1ddd1f3e020c7a5343eb6ed713/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  ['onChange'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  (getObjectProperty(___arguments.context.pageData, 'residentIntakeForm.personalDetails.genderIdentity'))['answer'] = ___arguments.value;

  },
  /* handler:onChange */
  /* content */
}))

define('./components/reusable/r_5871cdf8de1657653b530652a7730dc7/logic/e5940d1a18fb5677e854430215be6d26/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'residentIntakeForm.personalDetails.genderIdentity.answer')) == 'Other') {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('e5940d1a18fb5677e854430215be6d26', true);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('e5940d1a18fb5677e854430215be6d26', false);
  }

  },
  /* handler:onStyleAssignment */
  /* handler:onChange */
  ['onChange'](___arguments) {
      ___arguments.context.pageData['residentIntakeForm.personalDetails.genderIdentity.other'] = ___arguments.value;

  },
  /* handler:onChange */
  /* content */
}))

define('./components/reusable/r_5871cdf8de1657653b530652a7730dc7/logic/6b343497b6d83ab2bd3d5c73882f6c03/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  ['onChange'](___arguments) {
      ___arguments.context.pageData['residentIntakeForm.personalDetails.pronouns.other'] = ___arguments.value;

  },
  /* handler:onChange */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'residentIntakeForm.personalDetails.pronouns.answer')) == 'Other') {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('6b343497b6d83ab2bd3d5c73882f6c03', true);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('6b343497b6d83ab2bd3d5c73882f6c03', false);
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./components/reusable/r_5871cdf8de1657653b530652a7730dc7/logic/eaa3b2238cb4bf5ea4b591a7dab4cb5c/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  ['onChange'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  (getObjectProperty(___arguments.context.pageData, 'residentIntakeForm.personalDetails.pronouns'))['answer'] = ___arguments.value;

  },
  /* handler:onChange */
  /* content */
}))

define('./components/reusable/r_5871cdf8de1657653b530652a7730dc7/logic/977cbe6facf5c60e14b68e6f97291f99/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'residentIntakeForm.personalDetails.genderIdentity.answer')) == 'Other' && (getObjectProperty(___arguments.context.pageData, 'residentIntakeForm.personalDetails.pronouns.answer')) != 'Other') {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('977cbe6facf5c60e14b68e6f97291f99', true);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('977cbe6facf5c60e14b68e6f97291f99', false);
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./components/reusable/r_5871cdf8de1657653b530652a7730dc7/logic/19f4004d22befe96b518932d05e71877/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'residentIntakeForm.personalDetails.pronouns.answer')) == 'Other' && (getObjectProperty(___arguments.context.pageData, 'residentIntakeForm.personalDetails.genderIdentity.answer')) != 'Other') {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('19f4004d22befe96b518932d05e71877', true);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('19f4004d22befe96b518932d05e71877', false);
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./components/reusable/r_5871cdf8de1657653b530652a7730dc7/logic/ac8dedbbb434b2df2bbaa59664c9ed22/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (((getObjectProperty(___arguments.context.pageData, 'residentIntakeVariant')) == 'intake' || (getObjectProperty(___arguments.context.pageData, 'residentIntakeVariant')) == 'viewIntake') && (getObjectProperty(___arguments.context.pageData, 'residentIntakeStep')) == 2) {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('ac8dedbbb434b2df2bbaa59664c9ed22', true);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('ac8dedbbb434b2df2bbaa59664c9ed22', false);
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./components/reusable/r_5871cdf8de1657653b530652a7730dc7/logic/b3f1f4227fdf11c2317c1e84f787eeb6/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (((getObjectProperty(___arguments.context.pageData, 'residentIntakeVariant')) == 'intake' || (getObjectProperty(___arguments.context.pageData, 'residentIntakeVariant')) == 'viewIntake') && (getObjectProperty(___arguments.context.pageData, 'residentIntakeStep')) == 3) {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('b3f1f4227fdf11c2317c1e84f787eeb6', true);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('b3f1f4227fdf11c2317c1e84f787eeb6', false);
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./components/reusable/r_5871cdf8de1657653b530652a7730dc7/logic/0bb12058b0e42728be7706f1bfdb2ff5/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  async ['onChange'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  (getObjectProperty(___arguments.context.pageData, 'residentIntakeForm.emergencyContact'))['fullName'] = (await BackendlessUI.Functions.Custom['fn_256db7f8ee15af9d778314d97f563562'](___arguments.value));

  },
  /* handler:onChange */
  /* content */
}))

define('./components/reusable/r_5871cdf8de1657653b530652a7730dc7/logic/14e50b594402ac0a4ee1ba284f89af0f/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  ['onChange'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  ___arguments.context.pageData['residentIntakeFormEmailError'] = false;
  (getObjectProperty(___arguments.context.pageData, 'residentIntakeForm.emergencyContact'))['email'] = ___arguments.value;

  },
  /* handler:onChange */
  /* content */
}))

define('./components/reusable/r_5871cdf8de1657653b530652a7730dc7/logic/c7fc37657f22bb8769c79859e58711e3/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  async ['onChange'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  (getObjectProperty(___arguments.context.pageData, 'residentIntakeForm.emergencyContact'))['phoneNumber'] = (await BackendlessUI.Functions.Custom['fn_f282814277b3c0aa13f33d3b2cda111b'](___arguments.value, 'create'));

  },
  /* handler:onChange */
  /* content */
}))

define('./components/reusable/r_5871cdf8de1657653b530652a7730dc7/logic/f6f90c9cd1cd9e35a78bef418eedab57/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  ['onChange'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  (getObjectProperty(___arguments.context.pageData, 'residentIntakeForm.emergencyContact.relationship'))['answer'] = ___arguments.value;

  },
  /* handler:onChange */
  /* content */
}))

define('./components/reusable/r_5871cdf8de1657653b530652a7730dc7/logic/cadf0939f0678e37de2296887cabcb01/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  ['onChange'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  (getObjectProperty(___arguments.context.pageData, 'residentIntakeForm.navigationDetails'))['comingFrom'] = ___arguments.value;

  },
  /* handler:onChange */
  /* content */
}))

define('./components/reusable/r_5871cdf8de1657653b530652a7730dc7/logic/1eb13b928f58fa778297ae31638be8c9/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  ['onChange'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  (getObjectProperty(___arguments.context.pageData, 'residentIntakeForm.navigationDetails'))['howLong'] = ___arguments.value;

  },
  /* handler:onChange */
  /* content */
}))

define('./components/reusable/r_5871cdf8de1657653b530652a7730dc7/logic/ef2a0e33a0f825abe3230cedbb6e0c1e/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'residentIntakeFormEmailError')) {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('ef2a0e33a0f825abe3230cedbb6e0c1e', true);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('ef2a0e33a0f825abe3230cedbb6e0c1e', false);
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./components/reusable/r_5871cdf8de1657653b530652a7730dc7/logic/889df8137a67fa6b4959c02b3202f1fc/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      ___arguments.context.pageData['residentIntakeVariant'] = 'viewIntake';
  ___arguments.context.pageData['residentIntakeStep'] = 2;

  },
  /* handler:onClick */
  /* content */
}))

define('./components/reusable/r_5871cdf8de1657653b530652a7730dc7/logic/91b8042d03aa02535d927106c6613ef5/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  ['onChange'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  (getObjectProperty(___arguments.context.pageData, 'residentIntakeForm.emergencyContact.relationship'))['other'] = ___arguments.value;

  },
  /* handler:onChange */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'residentIntakeForm.emergencyContact.relationship.answer')) == 'Other') {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('91b8042d03aa02535d927106c6613ef5', true);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('91b8042d03aa02535d927106c6613ef5', false);
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./components/reusable/r_5871cdf8de1657653b530652a7730dc7/logic/7f3d01da9ec480bbc1d9166c688d2465/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'residentIntakeForm.emergencyContact.relationship.answer')) == 'Other') {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('7f3d01da9ec480bbc1d9166c688d2465', true);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('7f3d01da9ec480bbc1d9166c688d2465', false);
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./components/reusable/r_5871cdf8de1657653b530652a7730dc7/logic/ecc36191fdd6c8a151aaca937ea9507f/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'residentIntakeForm.personalDetails.genderIdentity.answer')) == 'Other' || (getObjectProperty(___arguments.context.pageData, 'residentIntakeForm.personalDetails.pronouns.answer')) == 'Other') {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('ecc36191fdd6c8a151aaca937ea9507f', true);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('ecc36191fdd6c8a151aaca937ea9507f', false);
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./components/reusable/r_5871cdf8de1657653b530652a7730dc7/logic/be7ee9d76f0c1a9a1157e812114cca43/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'residentPillarRadio')) == 2) {
    return 'Contact 2/3';
  } else if ((getObjectProperty(___arguments.context.pageData, 'residentPillarRadio')) == 3 || (getObjectProperty(___arguments.context.pageData, 'residentPillarRadio')) == 4) {
    return 'Contact 2/5';
  }

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./components/reusable/r_5871cdf8de1657653b530652a7730dc7/logic/0baa621265d79ec83fd91566817bd87d/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'residentPillarRadio')) == 2) {
    return 'Contact 3/3';
  } else if ((getObjectProperty(___arguments.context.pageData, 'residentPillarRadio')) == 3 || (getObjectProperty(___arguments.context.pageData, 'residentPillarRadio')) == 4) {
    return 'Contact 3/5';
  }

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./components/reusable/r_5871cdf8de1657653b530652a7730dc7/logic/a9a9ebfaa7b6e97642f60de721f17728/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'residentPillarRadio')) == '1') {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('a9a9ebfaa7b6e97642f60de721f17728', false);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('a9a9ebfaa7b6e97642f60de721f17728', true);
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./components/reusable/r_5871cdf8de1657653b530652a7730dc7/logic/a0b45eb5ac9f8e7fa0e703ea340538bd/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'residentPillarRadio')) == '1') {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('a0b45eb5ac9f8e7fa0e703ea340538bd', false);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('a0b45eb5ac9f8e7fa0e703ea340538bd', true);
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./components/reusable/r_5871cdf8de1657653b530652a7730dc7/logic/ac1d864c8127e0756ac2303dba35d824/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'residentPillarRadio')) == '1' || (getObjectProperty(___arguments.context.pageData, 'residentPillarRadio')) == '2') {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('ac1d864c8127e0756ac2303dba35d824', false);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('ac1d864c8127e0756ac2303dba35d824', true);
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./components/reusable/r_5871cdf8de1657653b530652a7730dc7/logic/7bbd8cace7629d93df7db3761169ec9a/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'residentPillarRadio')) == '1' || (getObjectProperty(___arguments.context.pageData, 'residentPillarRadio')) == '2') {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('7bbd8cace7629d93df7db3761169ec9a', false);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('7bbd8cace7629d93df7db3761169ec9a', true);
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./components/reusable/r_5871cdf8de1657653b530652a7730dc7/logic/b2ae958e747d951c208a7448cd8b0f33/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  async ['onChange'](___arguments) {
      ___arguments.context.pageData['residentContactFullNameTwo'] = (await BackendlessUI.Functions.Custom['fn_256db7f8ee15af9d778314d97f563562'](___arguments.value));

  },
  /* handler:onChange */
  /* content */
}))

define('./components/reusable/r_5871cdf8de1657653b530652a7730dc7/logic/a47c159628e939051648cf13806fbeb8/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  async ['onChange'](___arguments) {
      ___arguments.context.pageData['cellPhoneContactErrorTwo'] = undefined;
  ___arguments.context.pageData['residentContactCellPhoneTwo'] = (await BackendlessUI.Functions.Custom['fn_f282814277b3c0aa13f33d3b2cda111b'](___arguments.value, 'create'));

  },
  /* handler:onChange */
  /* content */
}))

define('./components/reusable/r_5871cdf8de1657653b530652a7730dc7/logic/0b6a7fd90d73e133ec0191aa078fe735/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  async ['onChange'](___arguments) {
      ___arguments.context.pageData['homePhoneContactErrorTwo'] = undefined;
  ___arguments.context.pageData['residentContactHomePhoneTwo'] = (await BackendlessUI.Functions.Custom['fn_f282814277b3c0aa13f33d3b2cda111b'](___arguments.value, 'create'));

  },
  /* handler:onChange */
  /* content */
}))

define('./components/reusable/r_5871cdf8de1657653b530652a7730dc7/logic/e2de42deada421eb4c824d26b2fa4e6a/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  async ['onChange'](___arguments) {
      ___arguments.context.pageData['residentContactFullNameThree'] = (await BackendlessUI.Functions.Custom['fn_256db7f8ee15af9d778314d97f563562'](___arguments.value));

  },
  /* handler:onChange */
  /* content */
}))

define('./components/reusable/r_5871cdf8de1657653b530652a7730dc7/logic/0c72115ec1d57e3975e490e5d612f3a4/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  async ['onChange'](___arguments) {
      ___arguments.context.pageData['cellPhoneContactErrorThree'] = undefined;
  ___arguments.context.pageData['residentContactCellPhoneThree'] = (await BackendlessUI.Functions.Custom['fn_f282814277b3c0aa13f33d3b2cda111b'](___arguments.value, 'create'));

  },
  /* handler:onChange */
  /* content */
}))

define('./components/reusable/r_5871cdf8de1657653b530652a7730dc7/logic/e58b0f85efe27f997df8417e0207c629/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  async ['onChange'](___arguments) {
      ___arguments.context.pageData['homePhoneContactErrorThree'] = undefined;
  ___arguments.context.pageData['residentContactHomePhoneThree'] = (await BackendlessUI.Functions.Custom['fn_f282814277b3c0aa13f33d3b2cda111b'](___arguments.value, 'create'));

  },
  /* handler:onChange */
  /* content */
}))

define('./components/reusable/r_5871cdf8de1657653b530652a7730dc7/logic/b0c7e6bfcc2e7feb9be7cd854cd1d87d/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  async ['onChange'](___arguments) {
      ___arguments.context.pageData['residentContactFullNameFour'] = (await BackendlessUI.Functions.Custom['fn_256db7f8ee15af9d778314d97f563562'](___arguments.value));

  },
  /* handler:onChange */
  /* content */
}))

define('./components/reusable/r_5871cdf8de1657653b530652a7730dc7/logic/4092576856cb19a2eafd8afffccfd14c/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  async ['onChange'](___arguments) {
      ___arguments.context.pageData['cellPhoneContactErrorFour'] = undefined;
  ___arguments.context.pageData['residentContactCellPhoneFour'] = (await BackendlessUI.Functions.Custom['fn_f282814277b3c0aa13f33d3b2cda111b'](___arguments.value, 'create'));

  },
  /* handler:onChange */
  /* content */
}))

define('./components/reusable/r_5871cdf8de1657653b530652a7730dc7/logic/760b4ca34a3c115138c1e664f98b7298/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  async ['onChange'](___arguments) {
      ___arguments.context.pageData['homePhoneContactErrorFour'] = undefined;
  ___arguments.context.pageData['residentContactHomePhoneFour'] = (await BackendlessUI.Functions.Custom['fn_f282814277b3c0aa13f33d3b2cda111b'](___arguments.value, 'create'));

  },
  /* handler:onChange */
  /* content */
}))

define('./components/reusable/r_5871cdf8de1657653b530652a7730dc7/logic/7027fa1d6089d3321998b369b1d832ac/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  async ['onChange'](___arguments) {
      ___arguments.context.pageData['residentContactFullNameFive'] = (await BackendlessUI.Functions.Custom['fn_256db7f8ee15af9d778314d97f563562'](___arguments.value));

  },
  /* handler:onChange */
  /* content */
}))

define('./components/reusable/r_5871cdf8de1657653b530652a7730dc7/logic/8f5bbef3c615acefa247e49466a5b86f/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  async ['onChange'](___arguments) {
      ___arguments.context.pageData['cellPhoneContactErrorFive'] = undefined;
  ___arguments.context.pageData['residentContactCellPhoneFive'] = (await BackendlessUI.Functions.Custom['fn_f282814277b3c0aa13f33d3b2cda111b'](___arguments.value, 'create'));

  },
  /* handler:onChange */
  /* content */
}))

define('./components/reusable/r_5871cdf8de1657653b530652a7730dc7/logic/c71e814a73fd686acca7fa9410cf5683/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  async ['onChange'](___arguments) {
      ___arguments.context.pageData['homePhoneContactErrorFive'] = undefined;
  ___arguments.context.pageData['residentContactHomePhoneFive'] = (await BackendlessUI.Functions.Custom['fn_f282814277b3c0aa13f33d3b2cda111b'](___arguments.value, 'create'));

  },
  /* handler:onChange */
  /* content */
}))

define('./components/reusable/r_5871cdf8de1657653b530652a7730dc7/logic/e1ff42595c67ff25fc4a6bb9a026d525/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    var hasError, phoneContactNumber;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'cellPhoneContactErrorOne')) {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('e1ff42595c67ff25fc4a6bb9a026d525', true);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('e1ff42595c67ff25fc4a6bb9a026d525', false);
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./components/reusable/r_5871cdf8de1657653b530652a7730dc7/logic/e964a8cf8a8cb1160d4d0992d718ef89/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'cellPhoneContactErrorTwo')) {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('e964a8cf8a8cb1160d4d0992d718ef89', true);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('e964a8cf8a8cb1160d4d0992d718ef89', false);
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./components/reusable/r_5871cdf8de1657653b530652a7730dc7/logic/f4311b4f7fba84f8d7be99cfe2e9cd7c/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'cellPhoneContactErrorThree')) {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('f4311b4f7fba84f8d7be99cfe2e9cd7c', true);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('f4311b4f7fba84f8d7be99cfe2e9cd7c', false);
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./components/reusable/r_5871cdf8de1657653b530652a7730dc7/logic/35e5106d4cd4a276dbad27b2dd523886/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'cellPhoneContactErrorFour')) {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('35e5106d4cd4a276dbad27b2dd523886', true);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('35e5106d4cd4a276dbad27b2dd523886', false);
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./components/reusable/r_5871cdf8de1657653b530652a7730dc7/logic/58954f265d01e1e82febef6d4f69088c/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'cellPhoneContactErrorFive')) {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('58954f265d01e1e82febef6d4f69088c', true);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('58954f265d01e1e82febef6d4f69088c', false);
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./components/reusable/r_5871cdf8de1657653b530652a7730dc7/logic/c06155af28f57e634cc3829cd1ca5aeb/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'homePhoneContactErrorOne')) {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('c06155af28f57e634cc3829cd1ca5aeb', true);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('c06155af28f57e634cc3829cd1ca5aeb', false);
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./components/reusable/r_5871cdf8de1657653b530652a7730dc7/logic/d1890737f56675215608f099052b5452/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'homePhoneContactErrorTwo')) {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('d1890737f56675215608f099052b5452', true);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('d1890737f56675215608f099052b5452', false);
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./components/reusable/r_5871cdf8de1657653b530652a7730dc7/logic/882cbf11fbbd8c14c77a4485e9f6140d/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'homePhoneContactErrorThree')) {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('882cbf11fbbd8c14c77a4485e9f6140d', true);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('882cbf11fbbd8c14c77a4485e9f6140d', false);
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./components/reusable/r_5871cdf8de1657653b530652a7730dc7/logic/74581037d2bb0ead484ee284803837e2/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'homePhoneContactErrorFour')) {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('74581037d2bb0ead484ee284803837e2', true);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('74581037d2bb0ead484ee284803837e2', false);
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./components/reusable/r_5871cdf8de1657653b530652a7730dc7/logic/ab5bbfb1c66d9e48ec3a91b3def30a55/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'homePhoneContactErrorFive')) {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('ab5bbfb1c66d9e48ec3a91b3def30a55', true);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('ab5bbfb1c66d9e48ec3a91b3def30a55', false);
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./components/reusable/r_5871cdf8de1657653b530652a7730dc7/logic/d451966bcf53457e31ecd2ed895acace/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'residentIntakeVariant')) == 'graduate') {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('d451966bcf53457e31ecd2ed895acace', true);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('d451966bcf53457e31ecd2ed895acace', false);
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./components/reusable/r_5871cdf8de1657653b530652a7730dc7/logic/799731983606aff194fc1719a8a6330b/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'residentIntakeForm.personalDetails.race.answer')) == 'other') {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('799731983606aff194fc1719a8a6330b', true);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('799731983606aff194fc1719a8a6330b', false);
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./components/reusable/r_5871cdf8de1657653b530652a7730dc7/logic/97bcb3456ab9180e4d3570230a35b456/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    var classes;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'residentPillarRadio')) == 1) {
    classes = ['pillar_secondary_border'];
  } else {
    classes = [null];
  }

  return classes

  },
  /* handler:onClassListAssignment */
  /* handler:onClick */
  ['onClick'](___arguments) {
      ___arguments.context.pageData['residentPillarRadio'] = 1;

  },
  /* handler:onClick */
  /* content */
}))

define('./components/reusable/r_5871cdf8de1657653b530652a7730dc7/logic/b66e60338d7af3f2cf41c57797a49daf/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    var classes;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'residentPillarRadio')) == 1) {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('b66e60338d7af3f2cf41c57797a49daf', true);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('b66e60338d7af3f2cf41c57797a49daf', false);
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./components/reusable/r_5871cdf8de1657653b530652a7730dc7/logic/ff5cf495e85ac75b691b98175cd30f48/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'residentIntakeVariant')) == 'pillar' || (getObjectProperty(___arguments.context.pageData, 'residentIntakeVariant')) == 'updatePillar') {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('ff5cf495e85ac75b691b98175cd30f48', true);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('ff5cf495e85ac75b691b98175cd30f48', false);
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./components/reusable/r_5871cdf8de1657653b530652a7730dc7/logic/5b77db5c527d8054a8413b6a916dc8e3/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'residentPillarRadio')) == 2) {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('5b77db5c527d8054a8413b6a916dc8e3', true);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('5b77db5c527d8054a8413b6a916dc8e3', false);
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./components/reusable/r_5871cdf8de1657653b530652a7730dc7/logic/93aa2f8331c1f0742dbc38a186b687e4/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'residentPillarRadio')) == 3) {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('93aa2f8331c1f0742dbc38a186b687e4', true);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('93aa2f8331c1f0742dbc38a186b687e4', false);
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./components/reusable/r_5871cdf8de1657653b530652a7730dc7/logic/d33150526201082984a4335310446cb8/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'residentPillarRadio')) == 4) {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('d33150526201082984a4335310446cb8', true);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('d33150526201082984a4335310446cb8', false);
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./components/reusable/r_5871cdf8de1657653b530652a7730dc7/logic/98f2253f5076f04d8088fee47445c3f2/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    var classes;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'residentPillarRadio')) == 2) {
    classes = ['pillar_secondary_border'];
  } else {
    classes = [null];
  }

  return classes

  },
  /* handler:onClassListAssignment */
  /* handler:onClick */
  ['onClick'](___arguments) {
      ___arguments.context.pageData['residentPillarRadio'] = 2;

  },
  /* handler:onClick */
  /* content */
}))

define('./components/reusable/r_5871cdf8de1657653b530652a7730dc7/logic/2f429cf1322ebad6e9ad61b9d48f6b39/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    var classes;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'residentPillarRadio')) == 3) {
    classes = ['pillar_secondary_border'];
  } else {
    classes = [null];
  }

  return classes

  },
  /* handler:onClassListAssignment */
  /* handler:onClick */
  ['onClick'](___arguments) {
      ___arguments.context.pageData['residentPillarRadio'] = 3;

  },
  /* handler:onClick */
  /* content */
}))

define('./components/reusable/r_5871cdf8de1657653b530652a7730dc7/logic/ded148dc338e548364d5df1ec8a4a3d2/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    var classes;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'residentPillarRadio')) == 4) {
    classes = ['pillar_secondary_border'];
  } else {
    classes = [null];
  }

  return classes

  },
  /* handler:onClassListAssignment */
  /* handler:onClick */
  ['onClick'](___arguments) {
    var classes;


  ___arguments.context.pageData['residentPillarRadio'] = 4;

  },
  /* handler:onClick */
  /* content */
}))

define('./components/reusable/r_5871cdf8de1657653b530652a7730dc7/logic/05ebc46da08bf4ef7d473f46ee5af39d/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  ['onChange'](___arguments) {
      if (___arguments.value) {
    ___arguments.context.pageData['hasAcknowledged'] = true;
  } else {
    ___arguments.context.pageData['hasAcknowledged'] = undefined;
  }

  },
  /* handler:onChange */
  /* content */
}))

define('./components/reusable/r_5871cdf8de1657653b530652a7730dc7/logic/3db6e2d1b6739d09b1882d6cdfb49f95/bundle.js', [], () => ({
  /* content */
  /* handler:onSelectFiles */
  ['onSelectFiles'](___arguments) {
      ___arguments.context.pageData['selectedResidentImageFile'] = ___arguments.selectedFiles;

  },
  /* handler:onSelectFiles */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'selectedResidentImageFile')) {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('3db6e2d1b6739d09b1882d6cdfb49f95', false);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('3db6e2d1b6739d09b1882d6cdfb49f95', true);
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./components/reusable/r_5871cdf8de1657653b530652a7730dc7/logic/46a95b64fdae72346654e295fad4682f/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      ___arguments.context.pageData['selectedResidentImageFile'] = undefined;
  ___arguments.context.pageData['selectedResidentImage'] = undefined;

  },
  /* handler:onClick */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'selectedResidentImageFile')) {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('46a95b64fdae72346654e295fad4682f', true);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('46a95b64fdae72346654e295fad4682f', false);
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./components/reusable/r_5871cdf8de1657653b530652a7730dc7/logic/19ab6eb2fd62b21e22ef2936d25dbd3d/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  async ['onStyleAssignment'](___arguments) {
    var selectedResidentImageFile, currentUser;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function readBrowserFileAs(file, type) {
   const readers = { base64: "readAsDataURL", utf8: "readAsText", bytes: "readAsArrayBuffer" }

   return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader[readers[type]](file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = error => reject(error)
  })
}


  currentUser = (getObjectProperty(___arguments.context.pageData, 'currentUser'));
  selectedResidentImageFile = (getObjectProperty(___arguments.context.pageData, 'selectedResidentImageFile'));
  if (selectedResidentImageFile) {
    ___arguments.context.pageData['selectedResidentImage'] = (await readBrowserFileAs((selectedResidentImageFile[0]), 'base64'));
  } else {
    if ((getObjectProperty(___arguments.context.pageData, 'residentIntakeForm.profilePicture')) && !(getObjectProperty(___arguments.context.pageData, 'selectedResidentImage'))) {
      ___arguments.context.pageData['selectedResidentImage'] = (getObjectProperty(___arguments.context.pageData, 'residentIntakeForm.profilePicture'));
    } else if (!(getObjectProperty(___arguments.context.pageData, 'residentIntakeForm.profilePicture')) && !(getObjectProperty(___arguments.context.pageData, 'selectedResidentImage'))) {
      ___arguments.context.pageData['selectedResidentImage'] = 'https://app.aquax.co/api/files/cyberdive_photos/cd-icon-default-photo.svg';
    }
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./components/reusable/r_5871cdf8de1657653b530652a7730dc7/logic/5c29da1faad7ad9286ea050dd61db939/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  ___arguments.context.pageData['residentIntakeStep'] = ((getObjectProperty(___arguments.context.pageData, 'residentIntakeStep')) - 1);

  },
  /* handler:onClick */
  /* content */
}))

define('./components/reusable/r_5871cdf8de1657653b530652a7730dc7/logic/b6df222f8359eef1f94e911b4a574ac1/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'residentIntakeVariant')) == 'intake' && !((getObjectProperty(___arguments.context.pageData, 'residentIntakeStep')) == 1) && !((getObjectProperty(___arguments.context.pageData, 'residentIntakeStep')) == 4)) {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('b6df222f8359eef1f94e911b4a574ac1', true);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('b6df222f8359eef1f94e911b4a574ac1', false);
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./components/reusable/r_5871cdf8de1657653b530652a7730dc7/logic/319d9df474ea0ff41d59ce8f193c8891/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (((getObjectProperty(___arguments.context.pageData, 'residentIntakeVariant')) == 'intake' || (getObjectProperty(___arguments.context.pageData, 'residentIntakeVariant')) == 'viewIntake') && (getObjectProperty(___arguments.context.pageData, 'residentIntakeStep')) == 4) {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('319d9df474ea0ff41d59ce8f193c8891', true);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('319d9df474ea0ff41d59ce8f193c8891', false);
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./components/reusable/r_5871cdf8de1657653b530652a7730dc7/logic/230d5362554683833b3aa60113dfc177/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      ___arguments.context.pageData['residentIntakeVariant'] = 'viewIntake';
  ___arguments.context.pageData['residentIntakeStep'] = 1;

  },
  /* handler:onClick */
  /* content */
}))

define('./components/reusable/r_5871cdf8de1657653b530652a7730dc7/logic/3326a11a36b2deec7da8f732f66f4bee/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      ___arguments.context.pageData['residentIntakeVariant'] = 'viewIntake';
  ___arguments.context.pageData['residentIntakeStep'] = 3;

  },
  /* handler:onClick */
  /* content */
}))

define('./components/reusable/r_5871cdf8de1657653b530652a7730dc7/logic/ccdfb107b7011e65f7957b88675a4a7a/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'residentIntakeForm.personalDetails.race.answer')) == 'default') {
    return 'Prefer Not To Answer';
  } else {
    return (getObjectProperty(___arguments.context.pageData, 'residentIntakeForm.personalDetails.race.answer'));
  }

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./components/reusable/r_5871cdf8de1657653b530652a7730dc7/logic/62b7f247ce3ac01c4309effd024560c1/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    var classes;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'residentPillarRadio')) == 'graduate') {
    classes = ['pillar_secondary_border'];
  } else {
    classes = [null];
  }

  return classes

  },
  /* handler:onClassListAssignment */
  /* handler:onClick */
  ['onClick'](___arguments) {
    var classes;


  ___arguments.context.pageData['residentPillarRadio'] = 'graduate';

  },
  /* handler:onClick */
  /* content */
}))

define('./components/reusable/r_5871cdf8de1657653b530652a7730dc7/logic/34e673459c4732ae86d898b78b4e1e34/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'residentIntakeVariant')) == 'updatePillar') {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('34e673459c4732ae86d898b78b4e1e34', true);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('34e673459c4732ae86d898b78b4e1e34', false);
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./components/reusable/r_5871cdf8de1657653b530652a7730dc7/logic/1edc3b944504a253f18c51ffdb82ebc8/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'residentPillarRadio')) == 'graduate') {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('1edc3b944504a253f18c51ffdb82ebc8', true);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('1edc3b944504a253f18c51ffdb82ebc8', false);
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./components/reusable/r_5871cdf8de1657653b530652a7730dc7/logic/cde8434bb1ba7191231e946eecdb5e2a/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'residentIntakeVariant')) == 'updatePillar') {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('cde8434bb1ba7191231e946eecdb5e2a', false);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('cde8434bb1ba7191231e946eecdb5e2a', true);
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./components/reusable/r_5871cdf8de1657653b530652a7730dc7/logic/d3cdf7aa268b9215506acb54e4a1175d/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'residentPillarRadio')) != 4 && (getObjectProperty(___arguments.context.pageData, 'residentPillarRadio')) != 'graduate') {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('d3cdf7aa268b9215506acb54e4a1175d', true);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('d3cdf7aa268b9215506acb54e4a1175d', false);
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./components/reusable/r_5871cdf8de1657653b530652a7730dc7/logic/8ce2988c00ae0f7c0d268de149763384/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'residentPillarRadio')) != 'graduate') {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('8ce2988c00ae0f7c0d268de149763384', true);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('8ce2988c00ae0f7c0d268de149763384', false);
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./components/reusable/r_5871cdf8de1657653b530652a7730dc7/logic/b86577b35f6672b14ebaf375053ac7c1/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'residentPillarRadio')) != 4 && (getObjectProperty(___arguments.context.pageData, 'residentPillarRadio')) != 'graduate') {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('b86577b35f6672b14ebaf375053ac7c1', true);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('b86577b35f6672b14ebaf375053ac7c1', false);
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./components/reusable/r_5871cdf8de1657653b530652a7730dc7/logic/265d0afba1d7ec99b7ebd4be80622fe1/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'residentPillarRadio')) != 'graduate') {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('265d0afba1d7ec99b7ebd4be80622fe1', true);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('265d0afba1d7ec99b7ebd4be80622fe1', false);
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./components/reusable/r_5871cdf8de1657653b530652a7730dc7/logic/35370bbd1a390a0acdfa6c5216cc85b5/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'residentPillarRadio')) != 4 && (getObjectProperty(___arguments.context.pageData, 'residentPillarRadio')) != 'graduate') {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('35370bbd1a390a0acdfa6c5216cc85b5', true);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('35370bbd1a390a0acdfa6c5216cc85b5', false);
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./components/reusable/r_5871cdf8de1657653b530652a7730dc7/logic/88038899fba7a4259ec28b117802e3ff/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'residentPillarRadio')) != 'graduate') {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('88038899fba7a4259ec28b117802e3ff', true);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('88038899fba7a4259ec28b117802e3ff', false);
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./components/reusable/r_5871cdf8de1657653b530652a7730dc7/logic/b474f1384d755019503c79e815539a8a/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'residentPillarRadio')) != 'graduate') {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('b474f1384d755019503c79e815539a8a', true);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('b474f1384d755019503c79e815539a8a', false);
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./components/reusable/r_5871cdf8de1657653b530652a7730dc7/logic/86acd97385412c6c71ebce05521a1659/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'residentPillarRadio')) != 4 && (getObjectProperty(___arguments.context.pageData, 'residentPillarRadio')) != 'graduate') {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('86acd97385412c6c71ebce05521a1659', true);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('86acd97385412c6c71ebce05521a1659', false);
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./components/reusable/r_5871cdf8de1657653b530652a7730dc7/logic/9d1b7fd112aa980459c99582653f95ab/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'residentPillarRadio')) == 4) {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('9d1b7fd112aa980459c99582653f95ab', true);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('9d1b7fd112aa980459c99582653f95ab', false);
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./components/reusable/r_5871cdf8de1657653b530652a7730dc7/logic/dd7d46c82a8d5aede09d265f11b5b58d/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'residentPillarRadio')) == 4) {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('dd7d46c82a8d5aede09d265f11b5b58d', true);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('dd7d46c82a8d5aede09d265f11b5b58d', false);
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./components/reusable/r_5871cdf8de1657653b530652a7730dc7/logic/b222784fb22268583e1c2dbff6487c17/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'residentPillarRadio')) == 4) {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('b222784fb22268583e1c2dbff6487c17', true);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('b222784fb22268583e1c2dbff6487c17', false);
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./components/reusable/r_5871cdf8de1657653b530652a7730dc7/logic/3d516facc2b326c5e6570deb06472ca5/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'residentPillarRadio')) == 1) {
    return 'You are permitted to save one contact on the device.';
  } else if ((getObjectProperty(___arguments.context.pageData, 'residentPillarRadio')) == 2) {
    return 'You are permitted to save three contact on the device.';
  } else if ((getObjectProperty(___arguments.context.pageData, 'residentPillarRadio')) == 3) {
    return 'You are permitted to save five contact on the device.';
  }

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./components/reusable/r_5871cdf8de1657653b530652a7730dc7/logic/1953674b118d4a2f088eb19a344bd9b1/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'residentPillarRadio')) == 1) {
    return 'The device will have only four apps installed.';
  } else if ((getObjectProperty(___arguments.context.pageData, 'residentPillarRadio')) == 2) {
    return 'The device will have only six apps installed.';
  } else if ((getObjectProperty(___arguments.context.pageData, 'residentPillarRadio')) == 3) {
    return 'The device will have only eight apps installed.';
  }

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./components/reusable/r_5871cdf8de1657653b530652a7730dc7/logic/54d7c67c647aace69402de7f9721f305/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'residentPillarRadio')) == 1) {
    return 'You can add only 1 contact for Pillar 1 residents';
  } else if ((getObjectProperty(___arguments.context.pageData, 'residentPillarRadio')) == 2) {
    return 'You can add only 3 contacts for Pillar 2 residents';
  } else if ((getObjectProperty(___arguments.context.pageData, 'residentPillarRadio')) == 3) {
    return 'You can add only 5 contacts for Pillar 3 residents';
  } else if ((getObjectProperty(___arguments.context.pageData, 'residentPillarRadio')) == 4) {
    return 'You can add only 5 contacts for Pillar 3 residents';
  }

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./components/reusable/r_5871cdf8de1657653b530652a7730dc7/logic/3b35073e031afc15c8de6771e9077ee5/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'residentIntakeVariant')) == 'updateContacts') {
    return 'Contacts';
  } else {
    return 'Add Contacts';
  }

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./components/reusable/r_7223f41eb2de2dc412f9535163d45837/logic/ff7f35b30ccf79c740575d78f04765e4/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
    var dayText, endTime, startTime, item, dayArray;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  startTime = (await BackendlessUI.Functions.Custom['fn_419a5da59f8ecf4150695a570f593a75']((getObjectProperty(___arguments.context.pageData, 'timeDrawerOneTime'))));
  endTime = (await BackendlessUI.Functions.Custom['fn_419a5da59f8ecf4150695a570f593a75']((getObjectProperty(___arguments.context.pageData, 'timeDrawerTwoTime'))));
  dayArray = (getObjectProperty(___arguments.context.pageData, 'curfewDayArray'));
  if (startTime && endTime && dayArray) {
    if (dayArray.length == 8) {
      dayText = 'Repeat every day';
    } else {
      dayText = (await Promise.all(dayArray.map(async item => {  if (item == 'Monday') {
          return 'Mon';
        } else if (item == 'Tuesday') {
          return 'Tues';
        } else if (item == 'Wednesday') {
          return 'Wed';
        } else if (item == 'Thursday') {
          return 'Thurs';
        } else if (item == 'Friday') {
          return 'Fri';
        } else if (item == 'Saturday') {
          return 'Sat';
        } else if (item == 'Sunday') {
          return 'Sun';
        }
      ; return ;}))).join(',');
    }
    return ([String(startTime.split(':')[0]) + String(startTime.slice(-2, startTime.length).toLowerCase()),' to ',String(endTime.split(':')[0]) + String(endTime.slice(-2, endTime.length).toLowerCase()),' | ',dayText].join(''));
  }

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./components/reusable/r_7223f41eb2de2dc412f9535163d45837/logic/1744f31d40d3b1943b7cadce9f616e1a/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      ___arguments.context.pageData['curfewStep'] = 3;

  },
  /* handler:onClick */
  /* content */
}))

define('./components/reusable/r_7223f41eb2de2dc412f9535163d45837/logic/adb6f2429441a7995c81f753f2c94c36/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    var isTimeDrawerOneOpen;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  isTimeDrawerOneOpen = (getObjectProperty(___arguments.context.pageData, 'isTimeDrawerOneOpen'));
  if (isTimeDrawerOneOpen) {
    ___arguments.context.pageData['isTimeDrawerOneOpen'] = false;
  } else {
    ___arguments.context.pageData['curfewError'] = undefined;
    ___arguments.context.pageData['isTimeDrawerTwoOpen'] = false;
    ___arguments.context.pageData['isDayDrawerOpen'] = false;
    ___arguments.context.pageData['isTimeDrawerOneOpen'] = true;
  }

  },
  /* handler:onClick */
  /* content */
}))

define('./components/reusable/r_7223f41eb2de2dc412f9535163d45837/logic/fb9ad416b534708849c2a9b6a2d74236/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'timeDrawerOneTime')) {
    return (await BackendlessUI.Functions.Custom['fn_419a5da59f8ecf4150695a570f593a75']((getObjectProperty(___arguments.context.pageData, 'timeDrawerOneTime'))));
  } else {
    return 'Choose time';
  }

  },
  /* handler:onContentAssignment */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'timeDrawerOneTime')) {
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('fb9ad416b534708849c2a9b6a2d74236'))['font-weight'] = 'bold';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('fb9ad416b534708849c2a9b6a2d74236'))['color'] = '#000000';
  } else {
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('fb9ad416b534708849c2a9b6a2d74236'))['font-weight'] = 'normal';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('fb9ad416b534708849c2a9b6a2d74236'))['color'] = 'rgba(0, 0, 0, 0.42)';
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./components/reusable/r_7223f41eb2de2dc412f9535163d45837/logic/140fc9750a7a1ff5f166e5f59afac0e1/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'isTimeDrawerOneOpen')) {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('140fc9750a7a1ff5f166e5f59afac0e1', true);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('140fc9750a7a1ff5f166e5f59afac0e1', false);
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./components/reusable/r_7223f41eb2de2dc412f9535163d45837/logic/e2c295b7bb54e3593cdbdbba30c999ca/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'isTimeDrawerOneOpen')) {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('e2c295b7bb54e3593cdbdbba30c999ca', false);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('e2c295b7bb54e3593cdbdbba30c999ca', true);
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./components/reusable/r_7223f41eb2de2dc412f9535163d45837/logic/f6bb63b154e7aab240dd48bca77ebad9/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      ___arguments.context.pageData['isTimeDrawerOneOpen'] = false;

  },
  /* handler:onClick */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'isTimeDrawerOneOpen')) {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('f6bb63b154e7aab240dd48bca77ebad9', true);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('f6bb63b154e7aab240dd48bca77ebad9', false);
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./components/reusable/r_7223f41eb2de2dc412f9535163d45837/logic/a41b86958e6d43bbc8ce64c9fa6e8abf/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'editCurfewItem')) {
    (getObjectProperty(___arguments.context.pageData, 'editCurfewItem'))['startTime'] = 13;
  } else {
    ___arguments.context.pageData['timeDrawerOneTime'] = 13;
  }

  },
  /* handler:onClick */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    var classesList;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  classesList = [];
  if ((getObjectProperty(___arguments.context.pageData, 'timeDrawerOneTime')) == 13 || (getObjectProperty(___arguments.context.pageData, 'editCurfewItem')) && (getObjectProperty((getObjectProperty(___arguments.context.pageData, 'editCurfewItem')), 'startTime')) == 13) {
    addItemToList(classesList, 'time_box_selected');
  } else {
    addItemToList(classesList, 'time_box');
  }

  return classesList

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./components/reusable/r_7223f41eb2de2dc412f9535163d45837/logic/3f5afcdd016cb4e985a8f5959fb9c29a/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'editCurfewItem')) {
    (getObjectProperty(___arguments.context.pageData, 'editCurfewItem'))['startTime'] = 14;
  } else {
    ___arguments.context.pageData['timeDrawerOneTime'] = 14;
  }

  },
  /* handler:onClick */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    var classesList;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  classesList = [];
  if ((getObjectProperty(___arguments.context.pageData, 'timeDrawerOneTime')) == 14 || (getObjectProperty(___arguments.context.pageData, 'editCurfewItem')) && (getObjectProperty((getObjectProperty(___arguments.context.pageData, 'editCurfewItem')), 'startTime')) == 14) {
    addItemToList(classesList, 'time_box_selected');
  } else {
    addItemToList(classesList, 'time_box');
  }

  return classesList

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./components/reusable/r_7223f41eb2de2dc412f9535163d45837/logic/60dc99f7db6180cf3a74d4d8d9e6a839/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'editCurfewItem')) {
    (getObjectProperty(___arguments.context.pageData, 'editCurfewItem'))['startTime'] = 15;
  } else {
    ___arguments.context.pageData['timeDrawerOneTime'] = 15;
  }

  },
  /* handler:onClick */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    var classesList;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  classesList = [];
  if ((getObjectProperty(___arguments.context.pageData, 'timeDrawerOneTime')) == 15 || (getObjectProperty(___arguments.context.pageData, 'editCurfewItem')) && (getObjectProperty((getObjectProperty(___arguments.context.pageData, 'editCurfewItem')), 'startTime')) == 15) {
    addItemToList(classesList, 'time_box_selected');
  } else {
    addItemToList(classesList, 'time_box');
  }

  return classesList

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./components/reusable/r_7223f41eb2de2dc412f9535163d45837/logic/4d310dcce2dfee9dac8261219fba0de5/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'editCurfewItem')) {
    (getObjectProperty(___arguments.context.pageData, 'editCurfewItem'))['startTime'] = 16;
  } else {
    ___arguments.context.pageData['timeDrawerOneTime'] = 16;
  }

  },
  /* handler:onClick */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    var classesList;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  classesList = [];
  if ((getObjectProperty(___arguments.context.pageData, 'timeDrawerOneTime')) == 16 || (getObjectProperty(___arguments.context.pageData, 'editCurfewItem')) && (getObjectProperty((getObjectProperty(___arguments.context.pageData, 'editCurfewItem')), 'startTime')) == 16) {
    addItemToList(classesList, 'time_box_selected');
  } else {
    addItemToList(classesList, 'time_box');
  }

  return classesList

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./components/reusable/r_7223f41eb2de2dc412f9535163d45837/logic/8d7e494bbc451e42ac6afbabc5ee6a22/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'editCurfewItem')) {
    (getObjectProperty(___arguments.context.pageData, 'editCurfewItem'))['startTime'] = 17;
  } else {
    ___arguments.context.pageData['timeDrawerOneTime'] = 17;
  }

  },
  /* handler:onClick */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    var classesList;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  classesList = [];
  if ((getObjectProperty(___arguments.context.pageData, 'timeDrawerOneTime')) == 17 || (getObjectProperty(___arguments.context.pageData, 'editCurfewItem')) && (getObjectProperty((getObjectProperty(___arguments.context.pageData, 'editCurfewItem')), 'startTime')) == 17) {
    addItemToList(classesList, 'time_box_selected');
  } else {
    addItemToList(classesList, 'time_box');
  }

  return classesList

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./components/reusable/r_7223f41eb2de2dc412f9535163d45837/logic/58b24d1cedee19d8ddd1624dfff3f9a6/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'editCurfewItem')) {
    (getObjectProperty(___arguments.context.pageData, 'editCurfewItem'))['startTime'] = 18;
  } else {
    ___arguments.context.pageData['timeDrawerOneTime'] = 18;
  }

  },
  /* handler:onClick */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    var classesList;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  classesList = [];
  if ((getObjectProperty(___arguments.context.pageData, 'timeDrawerOneTime')) == 18 || (getObjectProperty(___arguments.context.pageData, 'editCurfewItem')) && (getObjectProperty((getObjectProperty(___arguments.context.pageData, 'editCurfewItem')), 'startTime')) == 18) {
    addItemToList(classesList, 'time_box_selected');
  } else {
    addItemToList(classesList, 'time_box');
  }

  return classesList

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./components/reusable/r_7223f41eb2de2dc412f9535163d45837/logic/94a58231e43cdf7c7aa66f2a4fff5037/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'editCurfewItem')) {
    (getObjectProperty(___arguments.context.pageData, 'editCurfewItem'))['startTime'] = 19;
  } else {
    ___arguments.context.pageData['timeDrawerOneTime'] = 19;
  }

  },
  /* handler:onClick */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    var classesList;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  classesList = [];
  if ((getObjectProperty(___arguments.context.pageData, 'timeDrawerOneTime')) == 19 || (getObjectProperty(___arguments.context.pageData, 'editCurfewItem')) && (getObjectProperty((getObjectProperty(___arguments.context.pageData, 'editCurfewItem')), 'startTime')) == 19) {
    addItemToList(classesList, 'time_box_selected');
  } else {
    addItemToList(classesList, 'time_box');
  }

  return classesList

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./components/reusable/r_7223f41eb2de2dc412f9535163d45837/logic/891d0847ee0d5ef3e90971b85fcff495/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'editCurfewItem')) {
    (getObjectProperty(___arguments.context.pageData, 'editCurfewItem'))['startTime'] = 20;
  } else {
    ___arguments.context.pageData['timeDrawerOneTime'] = 20;
  }

  },
  /* handler:onClick */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    var classesList;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  classesList = [];
  if ((getObjectProperty(___arguments.context.pageData, 'timeDrawerOneTime')) == 20 || (getObjectProperty(___arguments.context.pageData, 'editCurfewItem')) && (getObjectProperty((getObjectProperty(___arguments.context.pageData, 'editCurfewItem')), 'startTime')) == 20) {
    addItemToList(classesList, 'time_box_selected');
  } else {
    addItemToList(classesList, 'time_box');
  }

  return classesList

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./components/reusable/r_7223f41eb2de2dc412f9535163d45837/logic/8f87676dfb68cfc62f0120ae26f561a4/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'editCurfewItem')) {
    (getObjectProperty(___arguments.context.pageData, 'editCurfewItem'))['startTime'] = 21;
  } else {
    ___arguments.context.pageData['timeDrawerOneTime'] = 21;
  }

  },
  /* handler:onClick */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    var classesList;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  classesList = [];
  if ((getObjectProperty(___arguments.context.pageData, 'timeDrawerOneTime')) == 21 || (getObjectProperty(___arguments.context.pageData, 'editCurfewItem')) && (getObjectProperty((getObjectProperty(___arguments.context.pageData, 'editCurfewItem')), 'startTime')) == 21) {
    addItemToList(classesList, 'time_box_selected');
  } else {
    addItemToList(classesList, 'time_box');
  }

  return classesList

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./components/reusable/r_7223f41eb2de2dc412f9535163d45837/logic/3c8cfae477a115a9e9c36732ec37bc59/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'editCurfewItem')) {
    (getObjectProperty(___arguments.context.pageData, 'editCurfewItem'))['startTime'] = 22;
  } else {
    ___arguments.context.pageData['timeDrawerOneTime'] = 22;
  }

  },
  /* handler:onClick */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    var classesList;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  classesList = [];
  if ((getObjectProperty(___arguments.context.pageData, 'timeDrawerOneTime')) == 22 || (getObjectProperty(___arguments.context.pageData, 'editCurfewItem')) && (getObjectProperty((getObjectProperty(___arguments.context.pageData, 'editCurfewItem')), 'startTime')) == 22) {
    addItemToList(classesList, 'time_box_selected');
  } else {
    addItemToList(classesList, 'time_box');
  }

  return classesList

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./components/reusable/r_7223f41eb2de2dc412f9535163d45837/logic/32a2e159ed38a63116d5c11d6c99cc99/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'editCurfewItem')) {
    (getObjectProperty(___arguments.context.pageData, 'editCurfewItem'))['startTime'] = 23;
  } else {
    ___arguments.context.pageData['timeDrawerOneTime'] = 23;
  }

  },
  /* handler:onClick */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    var classesList;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  classesList = [];
  if ((getObjectProperty(___arguments.context.pageData, 'timeDrawerOneTime')) == 23 || (getObjectProperty(___arguments.context.pageData, 'editCurfewItem')) && (getObjectProperty((getObjectProperty(___arguments.context.pageData, 'editCurfewItem')), 'startTime')) == 23) {
    addItemToList(classesList, 'time_box_selected');
  } else {
    addItemToList(classesList, 'time_box');
  }

  return classesList

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./components/reusable/r_7223f41eb2de2dc412f9535163d45837/logic/beb6abe1e8d69b57c49e65e1008982ec/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'editCurfewItem')) {
    (getObjectProperty(___arguments.context.pageData, 'editCurfewItem'))['startTime'] = 12;
  } else {
    ___arguments.context.pageData['timeDrawerOneTime'] = 12;
  }

  },
  /* handler:onClick */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    var classesList;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  classesList = [];
  if ((getObjectProperty(___arguments.context.pageData, 'timeDrawerOneTime')) == 12 || (getObjectProperty(___arguments.context.pageData, 'editCurfewItem')) && (getObjectProperty((getObjectProperty(___arguments.context.pageData, 'editCurfewItem')), 'startTime')) == 12) {
    addItemToList(classesList, 'time_box_selected');
  } else {
    addItemToList(classesList, 'time_box');
  }

  return classesList

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./components/reusable/r_7223f41eb2de2dc412f9535163d45837/logic/622e013ea52767c7c404172ef0845f5c/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'editCurfewItem')) {
    (getObjectProperty(___arguments.context.pageData, 'editCurfewItem'))['startTime'] = 1;
  } else {
    ___arguments.context.pageData['timeDrawerOneTime'] = 1;
  }

  },
  /* handler:onClick */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    var classesList;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  classesList = [];
  if ((getObjectProperty(___arguments.context.pageData, 'timeDrawerOneTime')) == 1 || (getObjectProperty(___arguments.context.pageData, 'editCurfewItem')) && (getObjectProperty((getObjectProperty(___arguments.context.pageData, 'editCurfewItem')), 'startTime')) == 1) {
    addItemToList(classesList, 'time_box_selected');
  } else {
    addItemToList(classesList, 'time_box');
  }

  return classesList

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./components/reusable/r_7223f41eb2de2dc412f9535163d45837/logic/e8af1d5238811c413a7a574eebab18f9/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'editCurfewItem')) {
    (getObjectProperty(___arguments.context.pageData, 'editCurfewItem'))['startTime'] = 2;
  } else {
    ___arguments.context.pageData['timeDrawerOneTime'] = 2;
  }

  },
  /* handler:onClick */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    var classesList;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  classesList = [];
  if ((getObjectProperty(___arguments.context.pageData, 'timeDrawerOneTime')) == 2 || (getObjectProperty(___arguments.context.pageData, 'editCurfewItem')) && (getObjectProperty((getObjectProperty(___arguments.context.pageData, 'editCurfewItem')), 'startTime')) == 2) {
    addItemToList(classesList, 'time_box_selected');
  } else {
    addItemToList(classesList, 'time_box');
  }

  return classesList

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./components/reusable/r_7223f41eb2de2dc412f9535163d45837/logic/e79b08d6211659a5211bf7dd398d4d3a/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'editCurfewItem')) {
    (getObjectProperty(___arguments.context.pageData, 'editCurfewItem'))['startTime'] = 3;
  } else {
    ___arguments.context.pageData['timeDrawerOneTime'] = 3;
  }

  },
  /* handler:onClick */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    var classesList;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  classesList = [];
  if ((getObjectProperty(___arguments.context.pageData, 'timeDrawerOneTime')) == 3 || (getObjectProperty(___arguments.context.pageData, 'editCurfewItem')) && (getObjectProperty((getObjectProperty(___arguments.context.pageData, 'editCurfewItem')), 'startTime')) == 3) {
    addItemToList(classesList, 'time_box_selected');
  } else {
    addItemToList(classesList, 'time_box');
  }

  return classesList

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./components/reusable/r_7223f41eb2de2dc412f9535163d45837/logic/a0f151e7dd2c334c37637a2e135a1967/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'editCurfewItem')) {
    (getObjectProperty(___arguments.context.pageData, 'editCurfewItem'))['startTime'] = 4;
  } else {
    ___arguments.context.pageData['timeDrawerOneTime'] = 4;
  }

  },
  /* handler:onClick */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    var classesList;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  classesList = [];
  if ((getObjectProperty(___arguments.context.pageData, 'timeDrawerOneTime')) == 4 || (getObjectProperty(___arguments.context.pageData, 'editCurfewItem')) && (getObjectProperty((getObjectProperty(___arguments.context.pageData, 'editCurfewItem')), 'startTime')) == 4) {
    addItemToList(classesList, 'time_box_selected');
  } else {
    addItemToList(classesList, 'time_box');
  }

  return classesList

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./components/reusable/r_7223f41eb2de2dc412f9535163d45837/logic/ff0d199aa2ebbf6beb5607aa7e216771/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'editCurfewItem')) {
    (getObjectProperty(___arguments.context.pageData, 'editCurfewItem'))['startTime'] = 5;
  } else {
    ___arguments.context.pageData['timeDrawerOneTime'] = 5;
  }

  },
  /* handler:onClick */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    var classesList;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  classesList = [];
  if ((getObjectProperty(___arguments.context.pageData, 'timeDrawerOneTime')) == 5 || (getObjectProperty(___arguments.context.pageData, 'editCurfewItem')) && (getObjectProperty((getObjectProperty(___arguments.context.pageData, 'editCurfewItem')), 'startTime')) == 5) {
    addItemToList(classesList, 'time_box_selected');
  } else {
    addItemToList(classesList, 'time_box');
  }

  return classesList

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./components/reusable/r_7223f41eb2de2dc412f9535163d45837/logic/af91ffe7af479e61cf4a151b73573c21/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'editCurfewItem')) {
    (getObjectProperty(___arguments.context.pageData, 'editCurfewItem'))['startTime'] = 6;
  } else {
    ___arguments.context.pageData['timeDrawerOneTime'] = 6;
  }

  },
  /* handler:onClick */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    var classesList;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  classesList = [];
  if ((getObjectProperty(___arguments.context.pageData, 'timeDrawerOneTime')) == 6 || (getObjectProperty(___arguments.context.pageData, 'editCurfewItem')) && (getObjectProperty((getObjectProperty(___arguments.context.pageData, 'editCurfewItem')), 'startTime')) == 6) {
    addItemToList(classesList, 'time_box_selected');
  } else {
    addItemToList(classesList, 'time_box');
  }

  return classesList

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./components/reusable/r_7223f41eb2de2dc412f9535163d45837/logic/645f3d94d1661cf1b481fc383188872a/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'editCurfewItem')) {
    (getObjectProperty(___arguments.context.pageData, 'editCurfewItem'))['startTime'] = 7;
  } else {
    ___arguments.context.pageData['timeDrawerOneTime'] = 7;
  }

  },
  /* handler:onClick */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    var classesList;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  classesList = [];
  if ((getObjectProperty(___arguments.context.pageData, 'timeDrawerOneTime')) == 7 || (getObjectProperty(___arguments.context.pageData, 'editCurfewItem')) && (getObjectProperty((getObjectProperty(___arguments.context.pageData, 'editCurfewItem')), 'startTime')) == 7) {
    addItemToList(classesList, 'time_box_selected');
  } else {
    addItemToList(classesList, 'time_box');
  }

  return classesList

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./components/reusable/r_7223f41eb2de2dc412f9535163d45837/logic/60fb2344a235483496a7ef8e16d1d6a5/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'editCurfewItem')) {
    (getObjectProperty(___arguments.context.pageData, 'editCurfewItem'))['startTime'] = 8;
  } else {
    ___arguments.context.pageData['timeDrawerOneTime'] = 8;
  }

  },
  /* handler:onClick */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    var classesList;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  classesList = [];
  if ((getObjectProperty(___arguments.context.pageData, 'timeDrawerOneTime')) == 8 || (getObjectProperty(___arguments.context.pageData, 'editCurfewItem')) && (getObjectProperty((getObjectProperty(___arguments.context.pageData, 'editCurfewItem')), 'startTime')) == 8) {
    addItemToList(classesList, 'time_box_selected');
  } else {
    addItemToList(classesList, 'time_box');
  }

  return classesList

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./components/reusable/r_7223f41eb2de2dc412f9535163d45837/logic/16d1a77edfbd960e04536641cdfc47a1/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'editCurfewItem')) {
    (getObjectProperty(___arguments.context.pageData, 'editCurfewItem'))['startTime'] = 9;
  } else {
    ___arguments.context.pageData['timeDrawerOneTime'] = 9;
  }

  },
  /* handler:onClick */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    var classesList;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  classesList = [];
  if ((getObjectProperty(___arguments.context.pageData, 'timeDrawerOneTime')) == 9 || (getObjectProperty(___arguments.context.pageData, 'editCurfewItem')) && (getObjectProperty((getObjectProperty(___arguments.context.pageData, 'editCurfewItem')), 'startTime')) == 9) {
    addItemToList(classesList, 'time_box_selected');
  } else {
    addItemToList(classesList, 'time_box');
  }

  return classesList

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./components/reusable/r_7223f41eb2de2dc412f9535163d45837/logic/b69bdf3fbb8c601118ef43d729992c7c/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'editCurfewItem')) {
    (getObjectProperty(___arguments.context.pageData, 'editCurfewItem'))['startTime'] = 10;
  } else {
    ___arguments.context.pageData['timeDrawerOneTime'] = 10;
  }

  },
  /* handler:onClick */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    var classesList;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  classesList = [];
  if ((getObjectProperty(___arguments.context.pageData, 'timeDrawerOneTime')) == 10 || (getObjectProperty(___arguments.context.pageData, 'editCurfewItem')) && (getObjectProperty((getObjectProperty(___arguments.context.pageData, 'editCurfewItem')), 'startTime')) == 10) {
    addItemToList(classesList, 'time_box_selected');
  } else {
    addItemToList(classesList, 'time_box');
  }

  return classesList

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./components/reusable/r_7223f41eb2de2dc412f9535163d45837/logic/d55e9475a75b2a826acfcee22d637f96/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'editCurfewItem')) {
    (getObjectProperty(___arguments.context.pageData, 'editCurfewItem'))['startTime'] = 11;
  } else {
    ___arguments.context.pageData['timeDrawerOneTime'] = 11;
  }

  },
  /* handler:onClick */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    var classesList;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  classesList = [];
  if ((getObjectProperty(___arguments.context.pageData, 'timeDrawerOneTime')) == 11 || (getObjectProperty(___arguments.context.pageData, 'editCurfewItem')) && (getObjectProperty((getObjectProperty(___arguments.context.pageData, 'editCurfewItem')), 'startTime')) == 11) {
    addItemToList(classesList, 'time_box_selected');
  } else {
    addItemToList(classesList, 'time_box');
  }

  return classesList

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./components/reusable/r_7223f41eb2de2dc412f9535163d45837/logic/63a64bf1bca6c84bb096d12416c83c2a/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'editCurfewItem')) {
    (getObjectProperty(___arguments.context.pageData, 'editCurfewItem'))['startTime'] = 24;
  } else {
    ___arguments.context.pageData['timeDrawerOneTime'] = 24;
  }

  },
  /* handler:onClick */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    var classesList;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  classesList = [];
  if ((getObjectProperty(___arguments.context.pageData, 'timeDrawerOneTime')) == 24 || (getObjectProperty(___arguments.context.pageData, 'editCurfewItem')) && (getObjectProperty((getObjectProperty(___arguments.context.pageData, 'editCurfewItem')), 'startTime')) == 24) {
    addItemToList(classesList, 'time_box_selected');
  } else {
    addItemToList(classesList, 'time_box');
  }

  return classesList

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./components/reusable/r_7223f41eb2de2dc412f9535163d45837/logic/7e57e63cbfa572922ac50ab77f33f599/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    var isTimeDrawerTwoOpen;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  isTimeDrawerTwoOpen = (getObjectProperty(___arguments.context.pageData, 'isTimeDrawerTwoOpen'));
  if (isTimeDrawerTwoOpen) {
    ___arguments.context.pageData['isTimeDrawerTwoOpen'] = false;
  } else {
    ___arguments.context.pageData['curfewError'] = undefined;
    ___arguments.context.pageData['isTimeDrawerTwoOpen'] = true;
    ___arguments.context.pageData['isDayDrawerOpen'] = false;
    ___arguments.context.pageData['isTimeDrawerOneOpen'] = false;
  }

  },
  /* handler:onClick */
  /* content */
}))

define('./components/reusable/r_7223f41eb2de2dc412f9535163d45837/logic/6fe785593caa9b0a73bca0ea1299af38/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'timeDrawerTwoTime')) {
    return (await BackendlessUI.Functions.Custom['fn_419a5da59f8ecf4150695a570f593a75']((getObjectProperty(___arguments.context.pageData, 'timeDrawerTwoTime'))));
  } else {
    return 'Choose time';
  }

  },
  /* handler:onContentAssignment */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'timeDrawerTwoTime')) {
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('6fe785593caa9b0a73bca0ea1299af38'))['font-weight'] = 'bold';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('6fe785593caa9b0a73bca0ea1299af38'))['color'] = '#000000';
  } else {
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('6fe785593caa9b0a73bca0ea1299af38'))['font-weight'] = 'normal';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('6fe785593caa9b0a73bca0ea1299af38'))['color'] = 'rgba(0, 0, 0, 0.42)';
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./components/reusable/r_7223f41eb2de2dc412f9535163d45837/logic/c3fad6decdc47b4addd294a74e311b3d/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'isTimeDrawerTwoOpen')) {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('c3fad6decdc47b4addd294a74e311b3d', true);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('c3fad6decdc47b4addd294a74e311b3d', false);
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./components/reusable/r_7223f41eb2de2dc412f9535163d45837/logic/bf2b99c416bb91b4077cd069c16e95af/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'isTimeDrawerTwoOpen')) {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('bf2b99c416bb91b4077cd069c16e95af', false);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('bf2b99c416bb91b4077cd069c16e95af', true);
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./components/reusable/r_7223f41eb2de2dc412f9535163d45837/logic/2dcb13aa13646494796660311f8979ac/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'isTimeDrawerTwoOpen')) {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('2dcb13aa13646494796660311f8979ac', true);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('2dcb13aa13646494796660311f8979ac', false);
  }

  },
  /* handler:onStyleAssignment */
  /* handler:onClick */
  ['onClick'](___arguments) {
      ___arguments.context.pageData['isTimeDrawerTwoOpen'] = false;

  },
  /* handler:onClick */
  /* content */
}))

define('./components/reusable/r_7223f41eb2de2dc412f9535163d45837/logic/8e9e84e74ad7b83e48d2c471e217e949/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'editCurfewItem')) {
    (getObjectProperty(___arguments.context.pageData, 'editCurfewItem'))['endTime'] = 13;
  } else {
    ___arguments.context.pageData['timeDrawerTwoTime'] = 13;
  }

  },
  /* handler:onClick */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    var classesList;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  classesList = [];
  if ((getObjectProperty(___arguments.context.pageData, 'editCurfewItem')) && (getObjectProperty((getObjectProperty(___arguments.context.pageData, 'editCurfewItem')), 'endTime')) == 13) {
    addItemToList(classesList, 'time_box_selected');
  } else if (!(getObjectProperty(___arguments.context.pageData, 'editCurfewItem')) && (getObjectProperty(___arguments.context.pageData, 'timeDrawerTwoTime')) == 13) {
    addItemToList(classesList, 'time_box_selected');
  } else {
    addItemToList(classesList, 'time_box');
  }

  return classesList

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./components/reusable/r_7223f41eb2de2dc412f9535163d45837/logic/7c447e11afe91c5b2e52f24818411c64/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'editCurfewItem')) {
    (getObjectProperty(___arguments.context.pageData, 'editCurfewItem'))['endTime'] = 14;
  } else {
    ___arguments.context.pageData['timeDrawerTwoTime'] = 14;
  }

  },
  /* handler:onClick */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    var classesList;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  classesList = [];
  if ((getObjectProperty(___arguments.context.pageData, 'timeDrawerTwoTime')) == 14 || (getObjectProperty(___arguments.context.pageData, 'editCurfewItem')) && (getObjectProperty((getObjectProperty(___arguments.context.pageData, 'editCurfewItem')), 'endTime')) == 14) {
    addItemToList(classesList, 'time_box_selected');
  } else {
    addItemToList(classesList, 'time_box');
  }

  return classesList

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./components/reusable/r_7223f41eb2de2dc412f9535163d45837/logic/6c6abc374b6362b33623327390572078/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'editCurfewItem')) {
    (getObjectProperty(___arguments.context.pageData, 'editCurfewItem'))['endTime'] = 15;
  } else {
    ___arguments.context.pageData['timeDrawerTwoTime'] = 15;
  }

  },
  /* handler:onClick */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    var classesList;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  classesList = [];
  if ((getObjectProperty(___arguments.context.pageData, 'timeDrawerTwoTime')) == 15 || (getObjectProperty(___arguments.context.pageData, 'editCurfewItem')) && (getObjectProperty((getObjectProperty(___arguments.context.pageData, 'editCurfewItem')), 'endTime')) == 15) {
    addItemToList(classesList, 'time_box_selected');
  } else {
    addItemToList(classesList, 'time_box');
  }

  return classesList

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./components/reusable/r_7223f41eb2de2dc412f9535163d45837/logic/1806adcf917f547ca1c46c49c7c9614a/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'editCurfewItem')) {
    (getObjectProperty(___arguments.context.pageData, 'editCurfewItem'))['endTime'] = 16;
  } else {
    ___arguments.context.pageData['timeDrawerTwoTime'] = 16;
  }

  },
  /* handler:onClick */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    var classesList;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  classesList = [];
  if ((getObjectProperty(___arguments.context.pageData, 'timeDrawerTwoTime')) == 16 || (getObjectProperty(___arguments.context.pageData, 'editCurfewItem')) && (getObjectProperty((getObjectProperty(___arguments.context.pageData, 'editCurfewItem')), 'endTime')) == 16) {
    addItemToList(classesList, 'time_box_selected');
  } else {
    addItemToList(classesList, 'time_box');
  }

  return classesList

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./components/reusable/r_7223f41eb2de2dc412f9535163d45837/logic/3edf62fb97a71ff5dcbf002baf65db17/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'editCurfewItem')) {
    (getObjectProperty(___arguments.context.pageData, 'editCurfewItem'))['endTime'] = 17;
  } else {
    ___arguments.context.pageData['timeDrawerTwoTime'] = 17;
  }

  },
  /* handler:onClick */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    var classesList;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  classesList = [];
  if ((getObjectProperty(___arguments.context.pageData, 'timeDrawerTwoTime')) == 17 || (getObjectProperty(___arguments.context.pageData, 'editCurfewItem')) && (getObjectProperty((getObjectProperty(___arguments.context.pageData, 'editCurfewItem')), 'endTime')) == 17) {
    addItemToList(classesList, 'time_box_selected');
  } else {
    addItemToList(classesList, 'time_box');
  }

  return classesList

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./components/reusable/r_7223f41eb2de2dc412f9535163d45837/logic/c4dae3a2ff25c88517d302072a3631fc/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'editCurfewItem')) {
    (getObjectProperty(___arguments.context.pageData, 'editCurfewItem'))['endTime'] = 18;
  } else {
    ___arguments.context.pageData['timeDrawerTwoTime'] = 18;
  }

  },
  /* handler:onClick */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    var classesList;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  classesList = [];
  if ((getObjectProperty(___arguments.context.pageData, 'timeDrawerTwoTime')) == 18 || (getObjectProperty(___arguments.context.pageData, 'editCurfewItem')) && (getObjectProperty((getObjectProperty(___arguments.context.pageData, 'editCurfewItem')), 'endTime')) == 18) {
    addItemToList(classesList, 'time_box_selected');
  } else {
    addItemToList(classesList, 'time_box');
  }

  return classesList

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./components/reusable/r_7223f41eb2de2dc412f9535163d45837/logic/6cd8934e34f2ead89b03e913ae13ef98/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'editCurfewItem')) {
    (getObjectProperty(___arguments.context.pageData, 'editCurfewItem'))['endTime'] = 19;
  } else {
    ___arguments.context.pageData['timeDrawerTwoTime'] = 19;
  }

  },
  /* handler:onClick */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    var classesList;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  classesList = [];
  if ((getObjectProperty(___arguments.context.pageData, 'timeDrawerTwoTime')) == 19 || (getObjectProperty(___arguments.context.pageData, 'editCurfewItem')) && (getObjectProperty((getObjectProperty(___arguments.context.pageData, 'editCurfewItem')), 'endTime')) == 19) {
    addItemToList(classesList, 'time_box_selected');
  } else {
    addItemToList(classesList, 'time_box');
  }

  return classesList

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./components/reusable/r_7223f41eb2de2dc412f9535163d45837/logic/0916733359a30cb25efc8965acf460d2/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'editCurfewItem')) {
    (getObjectProperty(___arguments.context.pageData, 'editCurfewItem'))['endTime'] = 20;
  } else {
    ___arguments.context.pageData['timeDrawerTwoTime'] = 20;
  }

  },
  /* handler:onClick */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    var classesList;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  classesList = [];
  if ((getObjectProperty(___arguments.context.pageData, 'timeDrawerTwoTime')) == 20 || (getObjectProperty(___arguments.context.pageData, 'editCurfewItem')) && (getObjectProperty((getObjectProperty(___arguments.context.pageData, 'editCurfewItem')), 'endTime')) == 20) {
    addItemToList(classesList, 'time_box_selected');
  } else {
    addItemToList(classesList, 'time_box');
  }

  return classesList

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./components/reusable/r_7223f41eb2de2dc412f9535163d45837/logic/f0e5d3f46cbd94c5d1518a7cc6b446c8/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'editCurfewItem')) {
    (getObjectProperty(___arguments.context.pageData, 'editCurfewItem'))['endTime'] = 21;
  } else {
    ___arguments.context.pageData['timeDrawerTwoTime'] = 21;
  }

  },
  /* handler:onClick */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    var classesList;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  classesList = [];
  if ((getObjectProperty(___arguments.context.pageData, 'timeDrawerTwoTime')) == 21 || (getObjectProperty(___arguments.context.pageData, 'editCurfewItem')) && (getObjectProperty((getObjectProperty(___arguments.context.pageData, 'editCurfewItem')), 'endTime')) == 21) {
    addItemToList(classesList, 'time_box_selected');
  } else {
    addItemToList(classesList, 'time_box');
  }

  return classesList

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./components/reusable/r_7223f41eb2de2dc412f9535163d45837/logic/ead3cd0fa1e286cb6ba6a807f98d677b/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'editCurfewItem')) {
    (getObjectProperty(___arguments.context.pageData, 'editCurfewItem'))['endTime'] = 22;
  } else {
    ___arguments.context.pageData['timeDrawerTwoTime'] = 22;
  }

  },
  /* handler:onClick */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    var classesList;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  classesList = [];
  if ((getObjectProperty(___arguments.context.pageData, 'timeDrawerTwoTime')) == 22 || (getObjectProperty(___arguments.context.pageData, 'editCurfewItem')) && (getObjectProperty((getObjectProperty(___arguments.context.pageData, 'editCurfewItem')), 'endTime')) == 22) {
    addItemToList(classesList, 'time_box_selected');
  } else {
    addItemToList(classesList, 'time_box');
  }

  return classesList

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./components/reusable/r_7223f41eb2de2dc412f9535163d45837/logic/d0f561917f28e2e86d11c9b733947f4c/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'editCurfewItem')) {
    (getObjectProperty(___arguments.context.pageData, 'editCurfewItem'))['endTime'] = 23;
  } else {
    ___arguments.context.pageData['timeDrawerTwoTime'] = 23;
  }

  },
  /* handler:onClick */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    var classesList;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  classesList = [];
  if ((getObjectProperty(___arguments.context.pageData, 'timeDrawerTwoTime')) == 23 || (getObjectProperty(___arguments.context.pageData, 'editCurfewItem')) && (getObjectProperty((getObjectProperty(___arguments.context.pageData, 'editCurfewItem')), 'endTime')) == 23) {
    addItemToList(classesList, 'time_box_selected');
  } else {
    addItemToList(classesList, 'time_box');
  }

  return classesList

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./components/reusable/r_7223f41eb2de2dc412f9535163d45837/logic/d146d775a3e8a0952d6b209276b55289/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'editCurfewItem')) {
    (getObjectProperty(___arguments.context.pageData, 'editCurfewItem'))['endTime'] = 12;
  } else {
    ___arguments.context.pageData['timeDrawerTwoTime'] = 12;
  }

  },
  /* handler:onClick */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    var classesList;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  classesList = [];
  if ((getObjectProperty(___arguments.context.pageData, 'timeDrawerTwoTime')) == 12 || (getObjectProperty(___arguments.context.pageData, 'editCurfewItem')) && (getObjectProperty((getObjectProperty(___arguments.context.pageData, 'editCurfewItem')), 'endTime')) == 12) {
    addItemToList(classesList, 'time_box_selected');
  } else {
    addItemToList(classesList, 'time_box');
  }

  return classesList

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./components/reusable/r_7223f41eb2de2dc412f9535163d45837/logic/febd27ca9f31805c19ddc625817b374c/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'editCurfewItem')) {
    (getObjectProperty(___arguments.context.pageData, 'editCurfewItem'))['endTime'] = 1;
  } else {
    ___arguments.context.pageData['timeDrawerTwoTime'] = 1;
  }

  },
  /* handler:onClick */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    var classesList;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  classesList = [];
  if ((getObjectProperty(___arguments.context.pageData, 'timeDrawerTwoTime')) == 1 || (getObjectProperty(___arguments.context.pageData, 'editCurfewItem')) && (getObjectProperty((getObjectProperty(___arguments.context.pageData, 'editCurfewItem')), 'endTime')) == 1) {
    addItemToList(classesList, 'time_box_selected');
  } else {
    addItemToList(classesList, 'time_box');
  }

  return classesList

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./components/reusable/r_7223f41eb2de2dc412f9535163d45837/logic/c111416f22266b08b44f8f8b08489014/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'editCurfewItem')) {
    (getObjectProperty(___arguments.context.pageData, 'editCurfewItem'))['endTime'] = 2;
  } else {
    ___arguments.context.pageData['timeDrawerTwoTime'] = 2;
  }

  },
  /* handler:onClick */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    var classesList;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  classesList = [];
  if ((getObjectProperty(___arguments.context.pageData, 'timeDrawerTwoTime')) == 2 || (getObjectProperty(___arguments.context.pageData, 'editCurfewItem')) && (getObjectProperty((getObjectProperty(___arguments.context.pageData, 'editCurfewItem')), 'endTime')) == 2) {
    addItemToList(classesList, 'time_box_selected');
  } else {
    addItemToList(classesList, 'time_box');
  }

  return classesList

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./components/reusable/r_7223f41eb2de2dc412f9535163d45837/logic/f9e56961271f7e3b75f42be6b7068c07/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'editCurfewItem')) {
    (getObjectProperty(___arguments.context.pageData, 'editCurfewItem'))['endTime'] = 3;
  } else {
    ___arguments.context.pageData['timeDrawerTwoTime'] = 3;
  }

  },
  /* handler:onClick */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    var classesList;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  classesList = [];
  if ((getObjectProperty(___arguments.context.pageData, 'timeDrawerTwoTime')) == 3 || (getObjectProperty(___arguments.context.pageData, 'editCurfewItem')) && (getObjectProperty((getObjectProperty(___arguments.context.pageData, 'editCurfewItem')), 'endTime')) == 3) {
    addItemToList(classesList, 'time_box_selected');
  } else {
    addItemToList(classesList, 'time_box');
  }

  return classesList

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./components/reusable/r_7223f41eb2de2dc412f9535163d45837/logic/732fb4bee82a35867bb1b41eb0c40ee4/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'editCurfewItem')) {
    (getObjectProperty(___arguments.context.pageData, 'editCurfewItem'))['endTime'] = 4;
  } else {
    ___arguments.context.pageData['timeDrawerTwoTime'] = 4;
  }

  },
  /* handler:onClick */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    var classesList;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  classesList = [];
  if ((getObjectProperty(___arguments.context.pageData, 'timeDrawerTwoTime')) == 4 || (getObjectProperty(___arguments.context.pageData, 'editCurfewItem')) && (getObjectProperty((getObjectProperty(___arguments.context.pageData, 'editCurfewItem')), 'endTime')) == 4) {
    addItemToList(classesList, 'time_box_selected');
  } else {
    addItemToList(classesList, 'time_box');
  }

  return classesList

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./components/reusable/r_7223f41eb2de2dc412f9535163d45837/logic/7b53dcd6698dbbda576af97b684ba79c/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'editCurfewItem')) {
    (getObjectProperty(___arguments.context.pageData, 'editCurfewItem'))['endTime'] = 5;
  } else {
    ___arguments.context.pageData['timeDrawerTwoTime'] = 5;
  }

  },
  /* handler:onClick */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    var classesList;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  classesList = [];
  if ((getObjectProperty(___arguments.context.pageData, 'timeDrawerTwoTime')) == 5 || (getObjectProperty(___arguments.context.pageData, 'editCurfewItem')) && (getObjectProperty((getObjectProperty(___arguments.context.pageData, 'editCurfewItem')), 'endTime')) == 5) {
    addItemToList(classesList, 'time_box_selected');
  } else {
    addItemToList(classesList, 'time_box');
  }

  return classesList

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./components/reusable/r_7223f41eb2de2dc412f9535163d45837/logic/c61fc47a4fe30bec9323d2ad6f9e1c07/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'editCurfewItem')) {
    (getObjectProperty(___arguments.context.pageData, 'editCurfewItem'))['endTime'] = 6;
  } else {
    ___arguments.context.pageData['timeDrawerTwoTime'] = 6;
  }

  },
  /* handler:onClick */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    var classesList;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  classesList = [];
  if ((getObjectProperty(___arguments.context.pageData, 'timeDrawerTwoTime')) == 6 || (getObjectProperty(___arguments.context.pageData, 'editCurfewItem')) && (getObjectProperty((getObjectProperty(___arguments.context.pageData, 'editCurfewItem')), 'endTime')) == 6) {
    addItemToList(classesList, 'time_box_selected');
  } else {
    addItemToList(classesList, 'time_box');
  }

  return classesList

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./components/reusable/r_7223f41eb2de2dc412f9535163d45837/logic/235a3d71fb5ca8c24c5186d81da7dbbe/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'editCurfewItem')) {
    (getObjectProperty(___arguments.context.pageData, 'editCurfewItem'))['endTime'] = 7;
  } else {
    ___arguments.context.pageData['timeDrawerTwoTime'] = 7;
  }

  },
  /* handler:onClick */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    var classesList;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  classesList = [];
  if ((getObjectProperty(___arguments.context.pageData, 'timeDrawerTwoTime')) == 7 || (getObjectProperty(___arguments.context.pageData, 'editCurfewItem')) && (getObjectProperty((getObjectProperty(___arguments.context.pageData, 'editCurfewItem')), 'endTime')) == 7) {
    addItemToList(classesList, 'time_box_selected');
  } else {
    addItemToList(classesList, 'time_box');
  }

  return classesList

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./components/reusable/r_7223f41eb2de2dc412f9535163d45837/logic/923277c3e2b89f65e344a6b6f138b9c3/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'editCurfewItem')) {
    (getObjectProperty(___arguments.context.pageData, 'editCurfewItem'))['endTime'] = 8;
  } else {
    ___arguments.context.pageData['timeDrawerTwoTime'] = 8;
  }

  },
  /* handler:onClick */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    var classesList;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  classesList = [];
  if ((getObjectProperty(___arguments.context.pageData, 'timeDrawerTwoTime')) == 8 || (getObjectProperty(___arguments.context.pageData, 'editCurfewItem')) && (getObjectProperty((getObjectProperty(___arguments.context.pageData, 'editCurfewItem')), 'endTime')) == 8) {
    addItemToList(classesList, 'time_box_selected');
  } else {
    addItemToList(classesList, 'time_box');
  }

  return classesList

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./components/reusable/r_7223f41eb2de2dc412f9535163d45837/logic/8e85ca3b3507c43555636ebb16ff7b7c/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'editCurfewItem')) {
    (getObjectProperty(___arguments.context.pageData, 'editCurfewItem'))['endTime'] = 9;
  } else {
    ___arguments.context.pageData['timeDrawerTwoTime'] = 9;
  }

  },
  /* handler:onClick */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    var classesList;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  classesList = [];
  if ((getObjectProperty(___arguments.context.pageData, 'timeDrawerTwoTime')) == 9 || (getObjectProperty(___arguments.context.pageData, 'editCurfewItem')) && (getObjectProperty((getObjectProperty(___arguments.context.pageData, 'editCurfewItem')), 'endTime')) == 9) {
    addItemToList(classesList, 'time_box_selected');
  } else {
    addItemToList(classesList, 'time_box');
  }

  return classesList

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./components/reusable/r_7223f41eb2de2dc412f9535163d45837/logic/61c3efefcfcbf1d8a61ca946b06c2b6b/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'editCurfewItem')) {
    (getObjectProperty(___arguments.context.pageData, 'editCurfewItem'))['endTime'] = 10;
  } else {
    ___arguments.context.pageData['timeDrawerTwoTime'] = 10;
  }

  },
  /* handler:onClick */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    var classesList;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  classesList = [];
  if ((getObjectProperty(___arguments.context.pageData, 'timeDrawerTwoTime')) == 10 || (getObjectProperty(___arguments.context.pageData, 'editCurfewItem')) && (getObjectProperty((getObjectProperty(___arguments.context.pageData, 'editCurfewItem')), 'endTime')) == 10) {
    addItemToList(classesList, 'time_box_selected');
  } else {
    addItemToList(classesList, 'time_box');
  }

  return classesList

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./components/reusable/r_7223f41eb2de2dc412f9535163d45837/logic/af8424833cfc733e605e8146627359e3/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'editCurfewItem')) {
    (getObjectProperty(___arguments.context.pageData, 'editCurfewItem'))['endTime'] = 11;
  } else {
    ___arguments.context.pageData['timeDrawerTwoTime'] = 11;
  }

  },
  /* handler:onClick */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    var classesList;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  classesList = [];
  if ((getObjectProperty(___arguments.context.pageData, 'timeDrawerTwoTime')) == 11 || (getObjectProperty(___arguments.context.pageData, 'editCurfewItem')) && (getObjectProperty((getObjectProperty(___arguments.context.pageData, 'editCurfewItem')), 'endTime')) == 11) {
    addItemToList(classesList, 'time_box_selected');
  } else {
    addItemToList(classesList, 'time_box');
  }

  return classesList

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./components/reusable/r_7223f41eb2de2dc412f9535163d45837/logic/1e84eb3720ea0fbc4ce87b8b197f81ef/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'editCurfewItem')) {
    (getObjectProperty(___arguments.context.pageData, 'editCurfewItem'))['endTime'] = 24;
  } else {
    ___arguments.context.pageData['timeDrawerTwoTime'] = 24;
  }

  },
  /* handler:onClick */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    var classesList;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  classesList = [];
  if ((getObjectProperty(___arguments.context.pageData, 'timeDrawerTwoTime')) == 24 || (getObjectProperty(___arguments.context.pageData, 'editCurfewItem')) && (getObjectProperty((getObjectProperty(___arguments.context.pageData, 'editCurfewItem')), 'endTime')) == 24) {
    addItemToList(classesList, 'time_box_selected');
  } else {
    addItemToList(classesList, 'time_box');
  }

  return classesList

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./components/reusable/r_7223f41eb2de2dc412f9535163d45837/logic/5c8a61741168b2f9bc26186222c29842/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'curfewError')) {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('5c8a61741168b2f9bc26186222c29842', true);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('5c8a61741168b2f9bc26186222c29842', false);
  }

  },
  /* handler:onStyleAssignment */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'curfewError')) == 'range') {
    return 'The start time can not be higher than the end time';
  } else if ((getObjectProperty(___arguments.context.pageData, 'curfewError')) == 'duplicate') {
    return 'The start time and end time can not be the same';
  } else if ((getObjectProperty(___arguments.context.pageData, 'curfewError')) == 'empty') {
    return 'You have to select a start and end time';
  }

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./components/reusable/r_7223f41eb2de2dc412f9535163d45837/logic/a005a2cb0ee823f17965563884c9aadb/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    var isDayDrawerOpen;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  isDayDrawerOpen = (getObjectProperty(___arguments.context.pageData, 'isDayDrawerOpen'));
  if (isDayDrawerOpen) {
    ___arguments.context.pageData['isDayDrawerOpen'] = false;
  } else {
    ___arguments.context.pageData['isDayDrawerOpen'] = true;
    ___arguments.context.pageData['isTimeDrawerTwoOpen'] = false;
    ___arguments.context.pageData['isTimeDrawerOneOpen'] = false;
  }

  },
  /* handler:onClick */
  /* content */
}))

define('./components/reusable/r_7223f41eb2de2dc412f9535163d45837/logic/b1b4ccf60df445ccfeccd0a81d511ad4/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'curfewDayArray')) {
    if ((getObjectProperty(___arguments.context.pageData, 'curfewDayArray')).length == 0) {
      ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('b1b4ccf60df445ccfeccd0a81d511ad4'))['font-weight'] = 'normal';
      ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('b1b4ccf60df445ccfeccd0a81d511ad4'))['color'] = 'rgba(0, 0, 0, 0.42)';
    } else {
      ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('b1b4ccf60df445ccfeccd0a81d511ad4'))['font-weight'] = 'bold';
      ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('b1b4ccf60df445ccfeccd0a81d511ad4'))['color'] = '#000000';
    }
  }

  },
  /* handler:onStyleAssignment */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    var numberString, dayArray;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  dayArray = (getObjectProperty(___arguments.context.pageData, 'curfewDayArray'));
  if (dayArray) {
    if (dayArray.length == 0) {
      return 'Choose days';
    } else if (dayArray.length == 1) {
      return (dayArray[0]);
    } else if (dayArray.length == 2) {
      return ([dayArray[0],' and ',dayArray[1]].join(''));
    } else if (dayArray.length == 3) {
      return ([dayArray[0],', ',dayArray[1],' and ',dayArray[2]].join(''));
    } else {
      if (dayArray.length == 4) {
        numberString = 'Four';
      } else if (dayArray.length == 5) {
        numberString = 'Five';
      } else if (dayArray.length == 6) {
        numberString = 'Six';
      } else if (dayArray.length == 7) {
        return 'Repeat every day';
      }
      if (numberString) {
        return (String(numberString) + String(' days out of the week'));
      }
    }
  }

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./components/reusable/r_7223f41eb2de2dc412f9535163d45837/logic/4d52b8bafefd874e49b547b61543dadb/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'isDayDrawerOpen')) {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('4d52b8bafefd874e49b547b61543dadb', true);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('4d52b8bafefd874e49b547b61543dadb', false);
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./components/reusable/r_7223f41eb2de2dc412f9535163d45837/logic/6b04a05a2cb5e7a31fe6a87ea3a81b79/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'isDayDrawerOpen')) {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('6b04a05a2cb5e7a31fe6a87ea3a81b79', false);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('6b04a05a2cb5e7a31fe6a87ea3a81b79', true);
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./components/reusable/r_7223f41eb2de2dc412f9535163d45837/logic/8cfe1df18433c64961e6b877f63dde72/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'isDayDrawerOpen')) {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('8cfe1df18433c64961e6b877f63dde72', true);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('8cfe1df18433c64961e6b877f63dde72', false);
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./components/reusable/r_7223f41eb2de2dc412f9535163d45837/logic/da293e9ca21cb67a40e563e5f5f4f5cb/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  ['onChange'](___arguments) {
    var curfewDayArray;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}

function removeItemInList(l, i, c) {   const index = c ? l.findIndex(el => el[c] === i[c]) : l.indexOf(i);  if (index >= 0) { l.splice(index, 1); }  return l;}


  ___arguments.context.pageData['curfewDayError'] = undefined;
  curfewDayArray = (getObjectProperty(___arguments.context.pageData, 'curfewDayArray'));
  if (___arguments.value) {
    addItemToList(curfewDayArray, 'Monday');
    ___arguments.context.pageData['curfewDayArray'] = curfewDayArray;
  } else {
    removeItemInList(curfewDayArray, 'Monday', '');
    ___arguments.context.pageData['curfewDayArray'] = curfewDayArray;
  }

  },
  /* handler:onChange */
  /* handler:onCheckedStateAssignment */
  ['onCheckedStateAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'curfewDayArray')) {
    if ((getObjectProperty(___arguments.context.pageData, 'curfewDayArray')).includes('Monday')) {
      return true;
    } else {
      return false;
    }
  }

  },
  /* handler:onCheckedStateAssignment */
  /* content */
}))

define('./components/reusable/r_7223f41eb2de2dc412f9535163d45837/logic/bb0d0589a973d62c3a36272f759e38aa/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  ['onChange'](___arguments) {
    var dayArray, curfewDayArray;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}

function removeItemInList(l, i, c) {   const index = c ? l.findIndex(el => el[c] === i[c]) : l.indexOf(i);  if (index >= 0) { l.splice(index, 1); }  return l;}


  ___arguments.context.pageData['curfewDayError'] = undefined;
  curfewDayArray = (getObjectProperty(___arguments.context.pageData, 'curfewDayArray'));
  if (___arguments.value) {
    addItemToList(curfewDayArray, 'Tuesday');
    ___arguments.context.pageData['curfewDayArray'] = curfewDayArray;
  } else {
    removeItemInList(curfewDayArray, 'Tuesday', '');
    ___arguments.context.pageData['curfewDayArray'] = curfewDayArray;
  }

  },
  /* handler:onChange */
  /* handler:onCheckedStateAssignment */
  ['onCheckedStateAssignment'](___arguments) {
    var curfewDayArray;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'curfewDayArray')) {
    if ((getObjectProperty(___arguments.context.pageData, 'curfewDayArray')).includes('Tuesday')) {
      return true;
    } else {
      return false;
    }
  }

  },
  /* handler:onCheckedStateAssignment */
  /* content */
}))

define('./components/reusable/r_7223f41eb2de2dc412f9535163d45837/logic/851796f56d3948aa8b2890cd4fc6856d/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  ['onChange'](___arguments) {
    var dayArray, curfewDayArray;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}

function removeItemInList(l, i, c) {   const index = c ? l.findIndex(el => el[c] === i[c]) : l.indexOf(i);  if (index >= 0) { l.splice(index, 1); }  return l;}


  ___arguments.context.pageData['curfewDayError'] = undefined;
  curfewDayArray = (getObjectProperty(___arguments.context.pageData, 'curfewDayArray'));
  if (___arguments.value) {
    addItemToList(curfewDayArray, 'Wednesday');
    ___arguments.context.pageData['curfewDayArray'] = curfewDayArray;
  } else {
    removeItemInList(curfewDayArray, 'Wednesday', '');
    ___arguments.context.pageData['curfewDayArray'] = curfewDayArray;
  }

  },
  /* handler:onChange */
  /* handler:onCheckedStateAssignment */
  ['onCheckedStateAssignment'](___arguments) {
    var curfewDayArray;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'curfewDayArray')) {
    if ((getObjectProperty(___arguments.context.pageData, 'curfewDayArray')).includes('Wednesday')) {
      return true;
    } else {
      return false;
    }
  }

  },
  /* handler:onCheckedStateAssignment */
  /* content */
}))

define('./components/reusable/r_7223f41eb2de2dc412f9535163d45837/logic/7fc3420c7411a8ea53c3c68a593ea72b/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  ['onChange'](___arguments) {
    var dayArray, curfewDayArray;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}

function removeItemInList(l, i, c) {   const index = c ? l.findIndex(el => el[c] === i[c]) : l.indexOf(i);  if (index >= 0) { l.splice(index, 1); }  return l;}


  ___arguments.context.pageData['curfewDayError'] = undefined;
  curfewDayArray = (getObjectProperty(___arguments.context.pageData, 'curfewDayArray'));
  if (___arguments.value) {
    addItemToList(curfewDayArray, 'Thursday');
    ___arguments.context.pageData['curfewDayArray'] = curfewDayArray;
  } else {
    removeItemInList(curfewDayArray, 'Thursday', '');
    ___arguments.context.pageData['curfewDayArray'] = curfewDayArray;
  }

  },
  /* handler:onChange */
  /* handler:onCheckedStateAssignment */
  ['onCheckedStateAssignment'](___arguments) {
    var curfewDayArray;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'curfewDayArray')) {
    if ((getObjectProperty(___arguments.context.pageData, 'curfewDayArray')).includes('Thursday')) {
      return true;
    } else {
      return false;
    }
  }

  },
  /* handler:onCheckedStateAssignment */
  /* content */
}))

define('./components/reusable/r_7223f41eb2de2dc412f9535163d45837/logic/c6e3d407326f0162770ebcc5276c2603/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  ['onChange'](___arguments) {
    var dayArray, curfewDayArray;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}

function removeItemInList(l, i, c) {   const index = c ? l.findIndex(el => el[c] === i[c]) : l.indexOf(i);  if (index >= 0) { l.splice(index, 1); }  return l;}


  ___arguments.context.pageData['curfewDayError'] = undefined;
  curfewDayArray = (getObjectProperty(___arguments.context.pageData, 'curfewDayArray'));
  if (___arguments.value) {
    addItemToList(curfewDayArray, 'Friday');
    ___arguments.context.pageData['curfewDayArray'] = curfewDayArray;
  } else {
    removeItemInList(curfewDayArray, 'Friday', '');
    ___arguments.context.pageData['curfewDayArray'] = curfewDayArray;
  }

  },
  /* handler:onChange */
  /* handler:onCheckedStateAssignment */
  ['onCheckedStateAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'curfewDayArray')) {
    if ((getObjectProperty(___arguments.context.pageData, 'curfewDayArray')).includes('Friday')) {
      return true;
    } else {
      return false;
    }
  }

  },
  /* handler:onCheckedStateAssignment */
  /* content */
}))

define('./components/reusable/r_7223f41eb2de2dc412f9535163d45837/logic/d8f78ffd30776559527ef826ee3592eb/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  ['onChange'](___arguments) {
    var dayArray, curfewDayArray;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}

function removeItemInList(l, i, c) {   const index = c ? l.findIndex(el => el[c] === i[c]) : l.indexOf(i);  if (index >= 0) { l.splice(index, 1); }  return l;}


  ___arguments.context.pageData['curfewDayError'] = undefined;
  curfewDayArray = (getObjectProperty(___arguments.context.pageData, 'curfewDayArray'));
  if (___arguments.value) {
    addItemToList(curfewDayArray, 'Saturday');
    ___arguments.context.pageData['curfewDayArray'] = curfewDayArray;
  } else {
    removeItemInList(curfewDayArray, 'Saturday', '');
    ___arguments.context.pageData['curfewDayArray'] = curfewDayArray;
  }

  },
  /* handler:onChange */
  /* handler:onCheckedStateAssignment */
  ['onCheckedStateAssignment'](___arguments) {
    var curfewDayArray;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'curfewDayArray')) {
    if ((getObjectProperty(___arguments.context.pageData, 'curfewDayArray')).includes('Saturday')) {
      return true;
    } else {
      return false;
    }
  }

  },
  /* handler:onCheckedStateAssignment */
  /* content */
}))

define('./components/reusable/r_7223f41eb2de2dc412f9535163d45837/logic/3203652234256dd50bfd5b8964f62e41/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  ['onChange'](___arguments) {
    var dayArray, curfewDayArray;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}

function removeItemInList(l, i, c) {   const index = c ? l.findIndex(el => el[c] === i[c]) : l.indexOf(i);  if (index >= 0) { l.splice(index, 1); }  return l;}


  ___arguments.context.pageData['curfewDayError'] = undefined;
  curfewDayArray = (getObjectProperty(___arguments.context.pageData, 'curfewDayArray'));
  if (___arguments.value) {
    addItemToList(curfewDayArray, 'Sunday');
    ___arguments.context.pageData['curfewDayArray'] = curfewDayArray;
  } else {
    removeItemInList(curfewDayArray, 'Sunday', '');
    ___arguments.context.pageData['curfewDayArray'] = curfewDayArray;
  }

  },
  /* handler:onChange */
  /* handler:onCheckedStateAssignment */
  ['onCheckedStateAssignment'](___arguments) {
    var curfewDayArray;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'curfewDayArray')) {
    if ((getObjectProperty(___arguments.context.pageData, 'curfewDayArray')).includes('Sunday')) {
      return true;
    } else {
      return false;
    }
  }

  },
  /* handler:onCheckedStateAssignment */
  /* content */
}))

define('./components/reusable/r_7223f41eb2de2dc412f9535163d45837/logic/615a5c894e34288b3b660443b18b8584/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var pillarBar, currentUser, curfewTableResponse, timeDrawerTwoTime, timeDrawerOneTime, curfewDayArray, item, curfewName, curfewPillarArray, pillarRadio, selectedItemCurfew, curfewStep, toast, isLoading;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

/**
 * Describe this function...
 */
async function addToast(toast) {
  await BackendlessUI.Functions.Custom['fn_77bfd63e533a9b4c5577e5fe31a4b5ca'](___arguments.context.pageData, (getObjectProperty(toast, 'message')), (getObjectProperty(toast, 'status')));
}

/**
 * Describe this function...
 */
async function isButtonLoading(isLoading) {
  ___arguments.context.pageData['isButtonLoading'] = isLoading;
}


  selectedItemCurfew = (getObjectProperty(___arguments.context.pageData, 'selectedItemArrayCurfew'))[0];
  curfewDayArray = (getObjectProperty(___arguments.context.pageData, 'curfewDayArray'));
  curfewName = (getObjectProperty(___arguments.context.pageData, 'curfewName'));
  curfewStep = (getObjectProperty(___arguments.context.pageData, 'curfewStep'));
  timeDrawerOneTime = (getObjectProperty(___arguments.context.pageData, 'timeDrawerOneTime'));
  timeDrawerTwoTime = (getObjectProperty(___arguments.context.pageData, 'timeDrawerTwoTime'));
  curfewPillarArray = (getObjectProperty(___arguments.context.pageData, 'curfewPillarArray'));
  currentUser = (getObjectProperty(___arguments.context.pageData, 'currentUser'));
  pillarBar = (getObjectProperty(___arguments.context.pageData, 'curfewPillarSelectionBar'));
  pillarRadio = (getObjectProperty(___arguments.context.pageData, 'pillarRadio'));
  if (curfewStep == 1 || curfewStep == 3) {
    if (!curfewName || (await BackendlessUI.Functions.Custom['fn_a7ad4c1fd5d18ca6bff3eb611aeee2ac'](curfewName))) {
      ___arguments.context.pageData['curfewNameError'] = true;
      return ;
    } else if (!timeDrawerTwoTime || !timeDrawerOneTime) {
      ___arguments.context.pageData['curfewError'] = 'empty';
      return ;
    } else if (timeDrawerTwoTime == timeDrawerOneTime) {
      ___arguments.context.pageData['curfewError'] = 'duplicate';
      return ;
    } else if (timeDrawerTwoTime < timeDrawerOneTime) {
      ___arguments.context.pageData['curfewError'] = 'range';
      return ;
    } else if (!curfewDayArray || curfewDayArray.length == 0) {
      ___arguments.context.pageData['curfewDayError'] = true;
      return ;
    }
    ___arguments.context.pageData['curfewStep'] = 2;
  } else if (curfewStep == 2) {
    await isButtonLoading(true);
    if (curfewPillarArray.length == 0) {
      ___arguments.context.pageData['curfewPillarError'] = true;
      return ;
    }
    try {
      if ((getObjectProperty(___arguments.context.pageData, 'curfewTableVariant')) == 'edit') {
        curfewTableResponse = (await Backendless.Data.of('Curfews').save( ({ [`objectId`]: (getObjectProperty(selectedItemCurfew, 'objectId')),[`name`]: curfewName,[`status`]: 'DEACTIVATED',[`pillar`]: pillarRadio,[`days`]: (JSON.stringify(({ [`dayArray`]: curfewDayArray }))),[`times`]: (JSON.stringify(({ [`startTime`]: timeDrawerOneTime,[`endTime`]: timeDrawerTwoTime }))) }) ));
        await Backendless.Data.of('Curfews').addRelation(curfewTableResponse, 'organizationId', [(getObjectProperty(currentUser, 'organizationId'))]);
      } else if ((getObjectProperty(___arguments.context.pageData, 'curfewTableVariant')) == 'add') {
        for (var item_index in curfewPillarArray) {
          item = curfewPillarArray[item_index];
          curfewTableResponse = (await Backendless.Data.of('Curfews').save( ({ [`name`]: curfewName,[`status`]: 'DEACTIVATED',[`pillar`]: item,[`days`]: (JSON.stringify(({ [`dayArray`]: curfewDayArray }))),[`times`]: (JSON.stringify(({ [`startTime`]: timeDrawerOneTime,[`endTime`]: timeDrawerTwoTime }))) }) ));
          await Backendless.Data.of('Curfews').addRelation(curfewTableResponse, 'organizationId', [(getObjectProperty(currentUser, 'organizationId'))]);
        }
      }
      ___arguments.context.pageData['refreshCurfewTable'] = ({ [`pillar`]: pillarBar });
      ___arguments.context.pageData['timeDrawerOneTime'] = undefined;
      ___arguments.context.pageData['timeDrawerTwoTime'] = undefined;
      ___arguments.context.pageData['curfewName'] = undefined;
      ___arguments.context.pageData['dayArray'] = undefined;
      ___arguments.context.pageData['curfewPillarArray'] = [];
      await isButtonLoading(false);
      ___arguments.context.pageData['isAddRecordModalOpen'] = false;

    } catch (error) {
      await addToast(({ [`status`]: 'failure',[`message`]: 'Error adding curfew' }));
      await isButtonLoading(false);

    }
  }

  },
  /* handler:onClick */
  /* handler:onLabelAssignment */
  ['onLabelAssignment'](___arguments) {
    var curfewModalSteps;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  curfewModalSteps = (getObjectProperty(___arguments.context.pageData, 'curfewStep'));
  if (getObjectProperty(___arguments.context.pageData, 'isButtonLoading')) {
    return 'Please wait...';
  } else if (curfewModalSteps == 1 || curfewModalSteps == 3) {
    return 'Continue';
  } else if (curfewModalSteps == 2) {
    return 'Save';
  }

  },
  /* handler:onLabelAssignment */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    var classesList, isDisabled, isButtonLoading, curfewPillarArray, pillarRadio, curfewStep;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  classesList = [];
  if (getObjectProperty(___arguments.context.pageData, 'isAddCufewButtonDisabled')) {
    addItemToList(classesList, 'disabled_button');
  } else {
    addItemToList(classesList, 'enabled_button');
  }

  return classesList

  },
  /* handler:onClassListAssignment */
  /* handler:onStyleAssignment */
  async ['onStyleAssignment'](___arguments) {
    var isDisabled, isButtonLoading, curfewDayArray, curfewName, curfewStep, curfewPillarArray, pillarRadio, timeDrawerTwoTime, timeDrawerOneTime, pillarBar, currentUser;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  pillarRadio = (getObjectProperty(___arguments.context.pageData, 'pillarRadio'));
  isButtonLoading = (getObjectProperty(___arguments.context.pageData, 'isButtonLoading'));
  curfewStep = (getObjectProperty(___arguments.context.pageData, 'curfewStep'));
  curfewPillarArray = (getObjectProperty(___arguments.context.pageData, 'curfewPillarArray'));
  curfewDayArray = (getObjectProperty(___arguments.context.pageData, 'curfewDayArray'));
  curfewName = (getObjectProperty(___arguments.context.pageData, 'curfewName'));
  timeDrawerOneTime = (getObjectProperty(___arguments.context.pageData, 'timeDrawerOneTime'));
  timeDrawerTwoTime = (getObjectProperty(___arguments.context.pageData, 'timeDrawerTwoTime'));
  curfewPillarArray = (getObjectProperty(___arguments.context.pageData, 'curfewPillarArray'));
  currentUser = (getObjectProperty(___arguments.context.pageData, 'currentUser'));
  pillarBar = (getObjectProperty(___arguments.context.pageData, 'curfewPillarSelectionBar'));
  pillarRadio = (getObjectProperty(___arguments.context.pageData, 'pillarRadio'));
  isDisabled = false;
  if (curfewStep == '1') {
    if ((await BackendlessUI.Functions.Custom['fn_a7ad4c1fd5d18ca6bff3eb611aeee2ac'](curfewName)) || !curfewName) {
      isDisabled = true;
    } else if (!timeDrawerOneTime || !timeDrawerTwoTime) {
      isDisabled = true;
    } else if (curfewDayArray && curfewDayArray.length == 0) {
      isDisabled = true;
    }
  } else if (curfewStep == '2') {
    if ((getObjectProperty(___arguments.context.pageData, 'curfewTableVariant')) == 'add') {
      if (curfewPillarArray && curfewPillarArray.length == 0) {
        isDisabled = true;
      }
    }
  } else if (curfewStep == '3') {
    if ((await BackendlessUI.Functions.Custom['fn_a7ad4c1fd5d18ca6bff3eb611aeee2ac'](curfewName)) || !curfewName) {
      isDisabled = true;
    } else if (curfewDayArray && curfewDayArray.length == 0) {
      isDisabled = true;
    }
  }
  if (isButtonLoading) {
    isDisabled = true;
  }
  ___arguments.context.pageData['isAddCufewButtonDisabled'] = isDisabled;

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./components/reusable/r_7223f41eb2de2dc412f9535163d45837/logic/26c2557f7ea4f30b4bdfb5bde57b9287/bundle.js', [], () => ({
  /* content */
  /* handler:onBeforeMount */
  ['onBeforeMount'](___arguments) {
      ___arguments.context.pageData['dayArray'] = [];

  },
  /* handler:onBeforeMount */
  /* content */
}))

define('./components/reusable/r_7223f41eb2de2dc412f9535163d45837/logic/cc986fc858ad1b39f41ec7ff23490229/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'curfewStep')) == 1 || (getObjectProperty(___arguments.context.pageData, 'curfewStep')) == 3) {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('cc986fc858ad1b39f41ec7ff23490229', true);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('cc986fc858ad1b39f41ec7ff23490229', false);
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./components/reusable/r_7223f41eb2de2dc412f9535163d45837/logic/15b7d3d4a9e88a8690162414a309d07d/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'curfewStep')) == 2) {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('15b7d3d4a9e88a8690162414a309d07d', true);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('15b7d3d4a9e88a8690162414a309d07d', false);
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./components/reusable/r_7223f41eb2de2dc412f9535163d45837/logic/1fa16df9332f248cd473948fc43dbab6/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  async ['onChange'](___arguments) {
      ___arguments.context.pageData['curfewNameError'] = false;
  ___arguments.context.pageData['curfewName'] = (await BackendlessUI.Functions.Custom['fn_256db7f8ee15af9d778314d97f563562'](___arguments.value));

  },
  /* handler:onChange */
  /* handler:onClassListAssignment */
  async ['onClassListAssignment'](___arguments) {
    var classes;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'curfewNameError')) {
    classes = ['input_red_border'];
  } else if ((await BackendlessUI.Functions.Custom['fn_a7ad4c1fd5d18ca6bff3eb611aeee2ac']((getObjectProperty(___arguments.context.pageData, 'curfewName')))) || !(getObjectProperty(___arguments.context.pageData, 'curfewName'))) {
    classes = [];
  } else {
    classes = ['input_bold_text'];
  }

  return classes

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./components/reusable/r_7223f41eb2de2dc412f9535163d45837/logic/3745dde80d1c1be72a6d9d39293506b5/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var pillarBar, currentUser, curfewTableResponse, timeDrawerTwoTime, timeDrawerOneTime, dayArray, item, curfewName, curfewPillarArray, curfewStep, toast;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

/**
 * Describe this function...
 */
async function addToast(toast) {
  await BackendlessUI.Functions.Custom['fn_77bfd63e533a9b4c5577e5fe31a4b5ca'](___arguments.context.pageData, (getObjectProperty(toast, 'message')), (getObjectProperty(toast, 'status')));
}


  curfewPillarArray = (getObjectProperty(___arguments.context.pageData, 'curfewPillarArray'));
  dayArray = (getObjectProperty(___arguments.context.pageData, 'dayArray'));
  curfewName = (getObjectProperty(___arguments.context.pageData, 'curfewName'));
  curfewStep = (getObjectProperty(___arguments.context.pageData, 'curfewStep'));
  timeDrawerOneTime = (getObjectProperty(___arguments.context.pageData, 'timeDrawerOneTime'));
  timeDrawerTwoTime = (getObjectProperty(___arguments.context.pageData, 'timeDrawerTwoTime'));
  curfewPillarArray = (getObjectProperty(___arguments.context.pageData, 'curfewPillarArray'));
  currentUser = (getObjectProperty(___arguments.context.pageData, 'currentUser'));
  pillarBar = (getObjectProperty(___arguments.context.pageData, 'curfewPillarSelectionBar'));
  if ((getObjectProperty(___arguments.context.pageData, 'curfewStep')) == 1) {
    ___arguments.context.pageData['isAddRecordModalOpen'] = false;
  } else if ((getObjectProperty(___arguments.context.pageData, 'curfewStep')) == 2) {
    ___arguments.context.pageData['curfewStep'] = 3;
  }

  },
  /* handler:onClick */
  /* handler:onLabelAssignment */
  ['onLabelAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'curfewStep')) == 1) {
    return 'Cancel';
  } else if ((getObjectProperty(___arguments.context.pageData, 'curfewStep')) == 2) {
    return 'Back';
  }

  },
  /* handler:onLabelAssignment */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'curfewStep')) == 3) {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('3745dde80d1c1be72a6d9d39293506b5', false);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('3745dde80d1c1be72a6d9d39293506b5', true);
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./components/reusable/r_7a13eef1f41abd3db22976515ea669dd/logic/88f49973b02da1e3d5c30d7eb0d61cb4/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  ['onChange'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}

function removeItemInList(l, i, c) {   const index = c ? l.findIndex(el => el[c] === i[c]) : l.indexOf(i);  if (index >= 0) { l.splice(index, 1); }  return l;}


  if (___arguments.value) {
    ___arguments.context.pageData['curfewPillarArray'] = (addItemToList((getObjectProperty(___arguments.context.pageData, 'curfewPillarArray')), '1'));
  } else {
    ___arguments.context.pageData['curfewPillarArray'] = (removeItemInList((getObjectProperty(___arguments.context.pageData, 'curfewPillarArray')), '1', ''));
  }

  },
  /* handler:onChange */
  /* handler:onCheckedStateAssignment */
  ['onCheckedStateAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'curfewPillarArray')).includes('1')) {
    return true;
  } else {
    return false;
  }

  },
  /* handler:onCheckedStateAssignment */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'curfewTableVariant')) == 'add') {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('88f49973b02da1e3d5c30d7eb0d61cb4', true);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('88f49973b02da1e3d5c30d7eb0d61cb4', false);
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./components/reusable/r_7a13eef1f41abd3db22976515ea669dd/logic/763da95877947fb7faebaac61ea4f627/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
    var tableCount;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  tableCount = (await Backendless.Data.of('Residents').getObjectCount(Backendless.DataQueryBuilder.create().setWhereClause((await BackendlessUI.Functions.Custom['fn_5da19a0685ee9e7b97339f9e3dd69c3b']('pillars', null, (getObjectProperty(___arguments.context.pageData, 'currentUser.organizationId.orgName')), null, null, null, '1')))));
  if (tableCount == 1) {
    return (['(',tableCount,' resident)'].join(''));
  } else {
    return (['(',tableCount,' residents)'].join(''));
  }

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./components/reusable/r_7a13eef1f41abd3db22976515ea669dd/logic/f1ffc60c3278408c88a247e36c5549f7/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  ['onChange'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}

function removeItemInList(l, i, c) {   const index = c ? l.findIndex(el => el[c] === i[c]) : l.indexOf(i);  if (index >= 0) { l.splice(index, 1); }  return l;}


  if (___arguments.value) {
    ___arguments.context.pageData['curfewPillarArray'] = (addItemToList((getObjectProperty(___arguments.context.pageData, 'curfewPillarArray')), '2'));
  } else {
    ___arguments.context.pageData['curfewPillarArray'] = (removeItemInList((getObjectProperty(___arguments.context.pageData, 'curfewPillarArray')), '2', ''));
  }

  },
  /* handler:onChange */
  /* handler:onCheckedStateAssignment */
  ['onCheckedStateAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'curfewPillarArray')).includes('2')) {
    return true;
  } else {
    return false;
  }

  },
  /* handler:onCheckedStateAssignment */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'curfewTableVariant')) == 'add') {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('f1ffc60c3278408c88a247e36c5549f7', true);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('f1ffc60c3278408c88a247e36c5549f7', false);
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./components/reusable/r_7a13eef1f41abd3db22976515ea669dd/logic/5a9185ab4e06b45eb6bb0485103a4b43/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
    var tableCount;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  tableCount = (await Backendless.Data.of('Residents').getObjectCount(Backendless.DataQueryBuilder.create().setWhereClause((await BackendlessUI.Functions.Custom['fn_5da19a0685ee9e7b97339f9e3dd69c3b']('pillars', null, (getObjectProperty(___arguments.context.pageData, 'currentUser.organizationId.orgName')), null, null, null, '2')))));
  if (tableCount == 1) {
    return (['(',tableCount,' resident)'].join(''));
  } else {
    return (['(',tableCount,' residents)'].join(''));
  }

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./components/reusable/r_7a13eef1f41abd3db22976515ea669dd/logic/f2fc2914a33bf1f693ef533c353c25d5/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'curfewTableVariant')) == 'add') {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('f2fc2914a33bf1f693ef533c353c25d5', true);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('f2fc2914a33bf1f693ef533c353c25d5', false);
  }

  },
  /* handler:onStyleAssignment */
  /* handler:onChange */
  ['onChange'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}

function removeItemInList(l, i, c) {   const index = c ? l.findIndex(el => el[c] === i[c]) : l.indexOf(i);  if (index >= 0) { l.splice(index, 1); }  return l;}


  if (___arguments.value) {
    ___arguments.context.pageData['curfewPillarArray'] = (addItemToList((getObjectProperty(___arguments.context.pageData, 'curfewPillarArray')), '3'));
  } else {
    ___arguments.context.pageData['curfewPillarArray'] = (removeItemInList((getObjectProperty(___arguments.context.pageData, 'curfewPillarArray')), '3', ''));
  }

  },
  /* handler:onChange */
  /* handler:onCheckedStateAssignment */
  ['onCheckedStateAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'curfewPillarArray')).includes('3')) {
    return true;
  } else {
    return false;
  }

  },
  /* handler:onCheckedStateAssignment */
  /* content */
}))

define('./components/reusable/r_7a13eef1f41abd3db22976515ea669dd/logic/ce873cfd05d3c92e314d90cfc8a61fc1/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
    var tableCount;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  tableCount = (await Backendless.Data.of('Residents').getObjectCount(Backendless.DataQueryBuilder.create().setWhereClause((await BackendlessUI.Functions.Custom['fn_5da19a0685ee9e7b97339f9e3dd69c3b']('pillars', null, (getObjectProperty(___arguments.context.pageData, 'currentUser.organizationId.orgName')), null, null, null, '3')))));
  if (tableCount == 1) {
    return (['(',tableCount,' resident)'].join(''));
  } else {
    return (['(',tableCount,' residents)'].join(''));
  }

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./components/reusable/r_7a13eef1f41abd3db22976515ea669dd/logic/fc09b39e9b76ce86a177ac62bff8c18d/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  ['onChange'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}

function removeItemInList(l, i, c) {   const index = c ? l.findIndex(el => el[c] === i[c]) : l.indexOf(i);  if (index >= 0) { l.splice(index, 1); }  return l;}


  if (___arguments.value) {
    ___arguments.context.pageData['curfewPillarArray'] = (addItemToList((getObjectProperty(___arguments.context.pageData, 'curfewPillarArray')), '4'));
  } else {
    ___arguments.context.pageData['curfewPillarArray'] = (removeItemInList((getObjectProperty(___arguments.context.pageData, 'curfewPillarArray')), '4', ''));
  }

  },
  /* handler:onChange */
  /* handler:onCheckedStateAssignment */
  ['onCheckedStateAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'curfewPillarArray')).includes('4')) {
    return true;
  } else {
    return false;
  }

  },
  /* handler:onCheckedStateAssignment */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'curfewTableVariant')) == 'add') {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('fc09b39e9b76ce86a177ac62bff8c18d', true);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('fc09b39e9b76ce86a177ac62bff8c18d', false);
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./components/reusable/r_7a13eef1f41abd3db22976515ea669dd/logic/b16a87abb9175b7aba67766e682209df/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
    var tableCount;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  tableCount = (await Backendless.Data.of('Residents').getObjectCount(Backendless.DataQueryBuilder.create().setWhereClause((await BackendlessUI.Functions.Custom['fn_5da19a0685ee9e7b97339f9e3dd69c3b']('pillars', null, (getObjectProperty(___arguments.context.pageData, 'currentUser.organizationId.orgName')), null, null, null, '4')))));
  if (tableCount == 1) {
    return (['(',tableCount,' resident)'].join(''));
  } else {
    return (['(',tableCount,' residents)'].join(''));
  }

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./components/reusable/r_7a13eef1f41abd3db22976515ea669dd/logic/58a2e7c3f1dcf71b35ed50810ab2f314/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    var tableCount;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'curfewPillarError')) {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('58a2e7c3f1dcf71b35ed50810ab2f314', true);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('58a2e7c3f1dcf71b35ed50810ab2f314', false);
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./components/reusable/r_7a13eef1f41abd3db22976515ea669dd/logic/b792ec61b4630b1d032d131a03bef374/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'curfewTableVariant')) == 'edit') {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('b792ec61b4630b1d032d131a03bef374', true);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('b792ec61b4630b1d032d131a03bef374', false);
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./components/reusable/r_7a13eef1f41abd3db22976515ea669dd/logic/847e21b17e11e55cc18a454307148e05/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'curfewTableVariant')) == 'edit') {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('847e21b17e11e55cc18a454307148e05', true);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('847e21b17e11e55cc18a454307148e05', false);
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./components/reusable/r_7a13eef1f41abd3db22976515ea669dd/logic/8a58295fe0e70a674f02d696bf667e2b/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'curfewTableVariant')) == 'edit') {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('8a58295fe0e70a674f02d696bf667e2b', true);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('8a58295fe0e70a674f02d696bf667e2b', false);
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./components/reusable/r_7a13eef1f41abd3db22976515ea669dd/logic/ff465afc2a3ec69affb2ca6da49dfbf1/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'curfewTableVariant')) == 'edit') {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('ff465afc2a3ec69affb2ca6da49dfbf1', true);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('ff465afc2a3ec69affb2ca6da49dfbf1', false);
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./components/reusable/r_7a13eef1f41abd3db22976515ea669dd/logic/cd24bb1b20d45a2e804861f89d3a0c47/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'curfewTableVariant')) == 'edit') {
    return 'Select the pillar for curfew';
  } else {
    return 'Select the pillars for curfew (multiple selection)';
  }

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./components/reusable/r_5c31d6f5a2c63979d7069c7467837d31/logic/5928920a5736f5aaa2359ac56d3fcd73/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'isTransitioningResidentSection')) {
    if ((getObjectProperty(___arguments.context.pageData, 'addResidentStep')) == 1) {
      ___arguments.context.pageData['residentStepperVisibility'] = true;
      if (getObjectProperty(___arguments.context.pageData, 'residentStepperVisibility')) {
        ___arguments.context.pageData['residentStepperSectionOneVisibility'] = true;
        ___arguments.context.pageData['residentStepperSectionTwoVisibility'] = true;
        ___arguments.context.pageData['residentStepperSectionThreeVisibility'] = true;
        ___arguments.context.pageData['residentStepperSectionFourVisibility'] = false;
        ___arguments.context.pageData['residentStepperSectionFiveVisibility'] = false;
        if ((getObjectProperty(___arguments.context.pageData, 'addResidentSection')) == 1) {
          if (!(getObjectProperty(___arguments.context.pageData, 'resident'))) {
            ___arguments.context.pageData['residentStepperSectionOneClass'] = ['blue_stepper', 'default_cursor'];
            ___arguments.context.pageData['residentStepperSectionTwoClass'] = ['grey_stepper', 'default_cursor'];
            ___arguments.context.pageData['residentStepperSectionThreeClass'] = ['grey_stepper', 'default_cursor'];
          } else {
            ___arguments.context.pageData['residentStepperSectionOneClass'] = ['blue_stepper'];
            ___arguments.context.pageData['residentStepperSectionTwoClass'] = ['grey_stepper'];
            ___arguments.context.pageData['residentStepperSectionThreeClass'] = ['grey_stepper'];
          }
          ___arguments.context.pageData['residentStepperSectionOneNumberVisibility'] = true;
          ___arguments.context.pageData['residentStepperSectionTwoNumberVisibility'] = false;
          ___arguments.context.pageData['residentStepperSectionThreeNumberVisibility'] = false;
          ___arguments.context.pageData['residentStepperSectionOneStyle'] = ({ [`margin-top`]: '0px' });
          ___arguments.context.pageData['residentStepperSectionTwoStyle'] = ({ [`margin-top`]: '7px' });
          ___arguments.context.pageData['residentStepperSectionThreeStyle'] = ({ [`margin-top`]: '7px' });
        } else if ((getObjectProperty(___arguments.context.pageData, 'addResidentSection')) == 2) {
          ___arguments.context.pageData['residentStepperSectionOneClass'] = ['green_stepper'];
          ___arguments.context.pageData['residentStepperSectionTwoClass'] = ['blue_stepper'];
          ___arguments.context.pageData['residentStepperSectionThreeClass'] = ['grey_stepper'];
          ___arguments.context.pageData['residentStepperSectionOneNumberVisibility'] = false;
          ___arguments.context.pageData['residentStepperSectionTwoNumberVisibility'] = true;
          ___arguments.context.pageData['residentStepperSectionThreeNumberVisibility'] = false;
          ___arguments.context.pageData['residentStepperSectionOneStyle'] = ({ [`margin-top`]: '7px' });
          ___arguments.context.pageData['residentStepperSectionTwoStyle'] = ({ [`margin-top`]: '0px' });
          ___arguments.context.pageData['residentStepperSectionThreeStyle'] = ({ [`margin-top`]: '7px' });
        } else if ((getObjectProperty(___arguments.context.pageData, 'addResidentSection')) == 3) {
          ___arguments.context.pageData['residentStepperSectionOneClass'] = ['green_stepper'];
          ___arguments.context.pageData['residentStepperSectionTwoClass'] = ['green_stepper'];
          ___arguments.context.pageData['residentStepperSectionThreeClass'] = ['blue_stepper'];
          ___arguments.context.pageData['residentStepperSectionOneNumberVisibility'] = false;
          ___arguments.context.pageData['residentStepperSectionTwoNumberVisibility'] = false;
          ___arguments.context.pageData['residentStepperSectionThreeNumberVisibility'] = true;
          ___arguments.context.pageData['residentStepperSectionOneStyle'] = ({ [`margin-top`]: '7px' });
          ___arguments.context.pageData['residentStepperSectionTwoStyle'] = ({ [`margin-top`]: '7px' });
          ___arguments.context.pageData['residentStepperSectionThreeStyle'] = ({ [`margin-top`]: '0px' });
        }
      }
    } else if ((getObjectProperty(___arguments.context.pageData, 'addResidentStep')) == 3) {
      ___arguments.context.pageData['residentStepperVisibility'] = true;
      if (getObjectProperty(___arguments.context.pageData, 'residentStepperVisibility')) {
        ___arguments.context.pageData['residentStepperSectionOneVisibility'] = true;
        ___arguments.context.pageData['residentStepperSectionTwoVisibility'] = true;
        ___arguments.context.pageData['residentStepperSectionThreeVisibility'] = true;
        ___arguments.context.pageData['residentStepperSectionFourVisibility'] = true;
        ___arguments.context.pageData['residentStepperSectionFiveVisibility'] = true;
        if ((getObjectProperty(___arguments.context.pageData, 'addResidentSection')) == 5) {
          ___arguments.context.pageData['residentStepperSectionOneClass'] = ['blue_stepper'];
          ___arguments.context.pageData['residentStepperSectionTwoClass'] = ['grey_stepper'];
          ___arguments.context.pageData['residentStepperSectionThreeClass'] = ['grey_stepper'];
          ___arguments.context.pageData['residentStepperSectionFourClass'] = ['grey_stepper'];
          ___arguments.context.pageData['residentStepperSectionFiveClass'] = ['grey_stepper'];
          ___arguments.context.pageData['residentStepperSectionOneNumberVisibility'] = true;
          ___arguments.context.pageData['residentStepperSectionTwoNumberVisibility'] = false;
          ___arguments.context.pageData['residentStepperSectionThreeNumberVisibility'] = false;
          ___arguments.context.pageData['residentStepperSectionFourNumberVisibility'] = false;
          ___arguments.context.pageData['residentStepperSectionFiveNumberVisibility'] = false;
          ___arguments.context.pageData['residentStepperSectionOneStyle'] = ({ [`margin-top`]: '0px' });
          ___arguments.context.pageData['residentStepperSectionTwoStyle'] = ({ [`margin-top`]: '7px' });
          ___arguments.context.pageData['residentStepperSectionThreeStyle'] = ({ [`margin-top`]: '7px' });
          ___arguments.context.pageData['residentStepperSectionFourStyle'] = ({ [`margin-top`]: '7px' });
          ___arguments.context.pageData['residentStepperSectionFiveStyle'] = ({ [`margin-top`]: '7px' });
        } else if ((getObjectProperty(___arguments.context.pageData, 'addResidentSection')) == 6) {
          ___arguments.context.pageData['residentStepperSectionOneClass'] = ['green_stepper'];
          ___arguments.context.pageData['residentStepperSectionTwoClass'] = ['blue_stepper'];
          ___arguments.context.pageData['residentStepperSectionThreeClass'] = ['grey_stepper'];
          ___arguments.context.pageData['residentStepperSectionFourClass'] = ['grey_stepper'];
          ___arguments.context.pageData['residentStepperSectionFiveClass'] = ['grey_stepper'];
          ___arguments.context.pageData['residentStepperSectionOneNumberVisibility'] = false;
          ___arguments.context.pageData['residentStepperSectionTwoNumberVisibility'] = true;
          ___arguments.context.pageData['residentStepperSectionThreeNumberVisibility'] = false;
          ___arguments.context.pageData['residentStepperSectionFourNumberVisibility'] = false;
          ___arguments.context.pageData['residentStepperSectionFiveNumberVisibility'] = false;
          ___arguments.context.pageData['residentStepperSectionOneStyle'] = ({ [`margin-top`]: '7px' });
          ___arguments.context.pageData['residentStepperSectionTwoStyle'] = ({ [`margin-top`]: '0px' });
          ___arguments.context.pageData['residentStepperSectionThreeStyle'] = ({ [`margin-top`]: '7px' });
          ___arguments.context.pageData['residentStepperSectionFourStyle'] = ({ [`margin-top`]: '7px' });
          ___arguments.context.pageData['residentStepperSectionFiveStyle'] = ({ [`margin-top`]: '7px' });
        } else if ((getObjectProperty(___arguments.context.pageData, 'addResidentSection')) == 7) {
          ___arguments.context.pageData['residentStepperSectionOneClass'] = ['green_stepper'];
          ___arguments.context.pageData['residentStepperSectionTwoClass'] = ['green_stepper'];
          ___arguments.context.pageData['residentStepperSectionThreeClass'] = ['blue_stepper'];
          ___arguments.context.pageData['residentStepperSectionFourClass'] = ['grey_stepper'];
          ___arguments.context.pageData['residentStepperSectionFiveClass'] = ['grey_stepper'];
          ___arguments.context.pageData['residentStepperSectionOneNumberVisibility'] = false;
          ___arguments.context.pageData['residentStepperSectionTwoNumberVisibility'] = false;
          ___arguments.context.pageData['residentStepperSectionThreeNumberVisibility'] = true;
          ___arguments.context.pageData['residentStepperSectionFourNumberVisibility'] = false;
          ___arguments.context.pageData['residentStepperSectionFiveNumberVisibility'] = false;
          ___arguments.context.pageData['residentStepperSectionOneStyle'] = ({ [`margin-top`]: '7px' });
          ___arguments.context.pageData['residentStepperSectionTwoStyle'] = ({ [`margin-top`]: '7px' });
          ___arguments.context.pageData['residentStepperSectionThreeStyle'] = ({ [`margin-top`]: '0px' });
          ___arguments.context.pageData['residentStepperSectionFourStyle'] = ({ [`margin-top`]: '7px' });
          ___arguments.context.pageData['residentStepperSectionFiveStyle'] = ({ [`margin-top`]: '7px' });
        } else if ((getObjectProperty(___arguments.context.pageData, 'addResidentSection')) == 8) {
          ___arguments.context.pageData['residentStepperSectionOneClass'] = ['green_stepper'];
          ___arguments.context.pageData['residentStepperSectionTwoClass'] = ['green_stepper'];
          ___arguments.context.pageData['residentStepperSectionThreeClass'] = ['green_stepper'];
          ___arguments.context.pageData['residentStepperSectionFourClass'] = ['blue_stepper'];
          ___arguments.context.pageData['residentStepperSectionFiveClass'] = ['grey_stepper'];
          ___arguments.context.pageData['residentStepperSectionOneNumberVisibility'] = false;
          ___arguments.context.pageData['residentStepperSectionTwoNumberVisibility'] = false;
          ___arguments.context.pageData['residentStepperSectionThreeNumberVisibility'] = false;
          ___arguments.context.pageData['residentStepperSectionFourNumberVisibility'] = true;
          ___arguments.context.pageData['residentStepperSectionFiveNumberVisibility'] = false;
          ___arguments.context.pageData['residentStepperSectionOneStyle'] = ({ [`margin-top`]: '7px' });
          ___arguments.context.pageData['residentStepperSectionTwoStyle'] = ({ [`margin-top`]: '7px' });
          ___arguments.context.pageData['residentStepperSectionThreeStyle'] = ({ [`margin-top`]: '7px' });
          ___arguments.context.pageData['residentStepperSectionFourStyle'] = ({ [`margin-top`]: '0px' });
          ___arguments.context.pageData['residentStepperSectionFiveStyle'] = ({ [`margin-top`]: '7px' });
        } else if ((getObjectProperty(___arguments.context.pageData, 'addResidentSection')) == 9) {
          ___arguments.context.pageData['residentStepperSectionOneClass'] = ['green_stepper'];
          ___arguments.context.pageData['residentStepperSectionTwoClass'] = ['green_stepper'];
          ___arguments.context.pageData['residentStepperSectionThreeClass'] = ['green_stepper'];
          ___arguments.context.pageData['residentStepperSectionFourClass'] = ['green_stepper'];
          ___arguments.context.pageData['residentStepperSectionFiveClass'] = ['blue_stepper'];
          ___arguments.context.pageData['residentStepperSectionOneNumberVisibility'] = false;
          ___arguments.context.pageData['residentStepperSectionTwoNumberVisibility'] = false;
          ___arguments.context.pageData['residentStepperSectionThreeNumberVisibility'] = false;
          ___arguments.context.pageData['residentStepperSectionFourNumberVisibility'] = false;
          ___arguments.context.pageData['residentStepperSectionFiveNumberVisibility'] = true;
          ___arguments.context.pageData['residentStepperSectionOneStyle'] = ({ [`margin-top`]: '7px' });
          ___arguments.context.pageData['residentStepperSectionTwoStyle'] = ({ [`margin-top`]: '7px' });
          ___arguments.context.pageData['residentStepperSectionThreeStyle'] = ({ [`margin-top`]: '7px' });
          ___arguments.context.pageData['residentStepperSectionFourStyle'] = ({ [`margin-top`]: '7px' });
          ___arguments.context.pageData['residentStepperSectionFiveStyle'] = ({ [`margin-top`]: '0px' });
        }
      }
    } else if ((getObjectProperty(___arguments.context.pageData, 'addResidentStep')) == 4) {
      ___arguments.context.pageData['residentStepperVisibility'] = true;
      if (getObjectProperty(___arguments.context.pageData, 'residentStepperVisibility')) {
        ___arguments.context.pageData['residentStepperSectionOneVisibility'] = true;
        ___arguments.context.pageData['residentStepperSectionTwoVisibility'] = true;
        ___arguments.context.pageData['residentStepperSectionThreeVisibility'] = false;
        ___arguments.context.pageData['residentStepperSectionFourVisibility'] = false;
        ___arguments.context.pageData['residentStepperSectionFiveVisibility'] = false;
        if ((getObjectProperty(___arguments.context.pageData, 'addResidentSection')) == 10) {
          ___arguments.context.pageData['residentStepperSectionOneClass'] = ['blue_stepper'];
          ___arguments.context.pageData['residentStepperSectionTwoClass'] = ['grey_stepper'];
          ___arguments.context.pageData['residentStepperSectionOneNumberVisibility'] = true;
          ___arguments.context.pageData['residentStepperSectionTwoNumberVisibility'] = false;
          ___arguments.context.pageData['residentStepperSectionOneStyle'] = ({ [`margin-top`]: '0px' });
          ___arguments.context.pageData['residentStepperSectionTwoStyle'] = ({ [`margin-top`]: '7px' });
        } else if ((getObjectProperty(___arguments.context.pageData, 'addResidentSection')) == 11) {
          ___arguments.context.pageData['residentStepperSectionOneClass'] = ['green_stepper'];
          ___arguments.context.pageData['residentStepperSectionTwoClass'] = ['blue_stepper'];
          ___arguments.context.pageData['residentStepperSectionOneNumberVisibility'] = false;
          ___arguments.context.pageData['residentStepperSectionTwoNumberVisibility'] = true;
          ___arguments.context.pageData['residentStepperSectionOneStyle'] = ({ [`margin-top`]: '7px' });
          ___arguments.context.pageData['residentStepperSectionTwoStyle'] = ({ [`margin-top`]: '0px' });
        }
      }
    } else {
      ___arguments.context.pageData['residentStepperVisibility'] = false;
    }
    ___arguments.context.pageData['isTransitioningResidentSection'] = undefined;
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./components/reusable/r_5c31d6f5a2c63979d7069c7467837d31/logic/e6dd3c4b6e0b369cf558711fff45e123/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    var classes;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'resident')) {
    if ((getObjectProperty(___arguments.context.pageData, 'addResidentStep')) == 1) {
      ___arguments.context.pageData['addResidentSection'] = 1;
    } else if ((getObjectProperty(___arguments.context.pageData, 'addResidentStep')) == 3) {
      ___arguments.context.pageData['addResidentSection'] = 5;
    } else if ((getObjectProperty(___arguments.context.pageData, 'addResidentStep')) == 4) {
      ___arguments.context.pageData['addResidentSection'] = 10;
    }
    ___arguments.context.pageData['isTransitioningResidentSection'] = true;
  }

  },
  /* handler:onClick */
  /* content */
}))

define('./components/reusable/r_5c31d6f5a2c63979d7069c7467837d31/logic/a787145815e8e24ddf26f9eccc338729/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    var classes;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'resident')) {
    if ((getObjectProperty(___arguments.context.pageData, 'addResidentStep')) == 1) {
      ___arguments.context.pageData['addResidentSection'] = 2;
    } else if ((getObjectProperty(___arguments.context.pageData, 'addResidentStep')) == 3) {
      ___arguments.context.pageData['addResidentSection'] = 6;
    } else if ((getObjectProperty(___arguments.context.pageData, 'addResidentStep')) == 4) {
      ___arguments.context.pageData['addResidentSection'] = 11;
    }
    ___arguments.context.pageData['isTransitioningResidentSection'] = true;
  }

  },
  /* handler:onClick */
  /* content */
}))

define('./components/reusable/r_5c31d6f5a2c63979d7069c7467837d31/logic/d15a278b31c70f2a4fcb27446fc6d9d9/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    var classes;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'resident')) {
    if ((getObjectProperty(___arguments.context.pageData, 'addResidentStep')) == 1) {
      ___arguments.context.pageData['addResidentSection'] = 3;
    } else if ((getObjectProperty(___arguments.context.pageData, 'addResidentStep')) == 3) {
      ___arguments.context.pageData['addResidentSection'] = 7;
    }
    ___arguments.context.pageData['isTransitioningResidentSection'] = true;
  }

  },
  /* handler:onClick */
  /* content */
}))

define('./components/reusable/r_5c31d6f5a2c63979d7069c7467837d31/logic/741610ca7ffef37943e11cdf2f0bf5c4/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    var classes;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'resident')) {
    if ((getObjectProperty(___arguments.context.pageData, 'addResidentStep')) == 3) {
      ___arguments.context.pageData['addResidentSection'] = 8;
    }
    ___arguments.context.pageData['isTransitioningResidentSection'] = true;
  }

  },
  /* handler:onClick */
  /* content */
}))

define('./components/reusable/r_5c31d6f5a2c63979d7069c7467837d31/logic/21d5c824253791033ff1db695ac4930f/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    var classes;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'resident')) {
    if ((getObjectProperty(___arguments.context.pageData, 'addResidentStep')) == 3) {
      ___arguments.context.pageData['addResidentSection'] = 9;
    }
    ___arguments.context.pageData['isTransitioningResidentSection'] = true;
  }

  },
  /* handler:onClick */
  /* content */
}))

define('./components/reusable/r_7d14d39ce5a76b14695d8f57fa598a2a/logic/81538f0be1da4e9cec7ed8d629a8b488/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    var classesList;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  classesList = ['modal'];
  if (getObjectProperty(___arguments.context.pageData, 'isUpdateUserModalOpen')) {
    addItemToList(classesList, 'open');
  }

  return classesList

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./components/reusable/r_7d14d39ce5a76b14695d8f57fa598a2a/logic/53a52b7b3e2365a5db8fb3ccac5892fc/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      ___arguments.context.pageData['isUpdateUserModalOpen'] = false;

  },
  /* handler:onClick */
  /* content */
}))

define('./components/reusable/r_7d14d39ce5a76b14695d8f57fa598a2a/logic/2009d68b8b0eb8c8d29ef3c5c861813a/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    var isLoadingAction;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  isLoadingAction = (getObjectProperty(___arguments.context.pageData, 'isLoadingAction'));
  if (isLoadingAction) {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('2009d68b8b0eb8c8d29ef3c5c861813a', true);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('2009d68b8b0eb8c8d29ef3c5c861813a', false);
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./components/reusable/r_7d14d39ce5a76b14695d8f57fa598a2a/logic/75bb4aff4c68ac476776beb1110e7876/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    var isLoadingAction;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  isLoadingAction = (getObjectProperty(___arguments.context.pageData, 'isLoadingAction'));
  if (isLoadingAction) {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('75bb4aff4c68ac476776beb1110e7876', false);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('75bb4aff4c68ac476776beb1110e7876', true);
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./components/reusable/r_7d14d39ce5a76b14695d8f57fa598a2a/logic/4d0d4516c7778dfde96275d544f6354d/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      ___arguments.context.pageData['isUpdateUserModalOpen'] = false;

  },
  /* handler:onClick */
  /* content */
}))

define('./components/reusable/r_7d14d39ce5a76b14695d8f57fa598a2a/logic/6d0e2ddfd8db162c01a3d3888e5325c4/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var isDone, currentUser, toastError, error, itemToUpdate, isUpdatingOrganization, updatedOrganizationData, changedAttributes, selectedItemOrganization, confirmPasswordResponse, updateConfirmPassword, isAgreeingToUpdate, recordTableVariant, isUpdatingProfile, uniqueID, updatedOrganizationRelation, updateUserData, updatedOrg, index, selectedItemEmail, selectedItemRole, selectedItemPhone, selectedItemName, attribute, inputs, menuOptionSelection, selectedImageUrl, variant;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

/**
 * Describe this function...
 */
async function updateCurrentUserInfo() {
  try {
    ___arguments.context.pageData['currentUser'] = ((await Backendless.Data.of('Users').find(Backendless.DataQueryBuilder.create().setWhereClause((['email =','\'',(getObjectProperty(currentUser, 'email')),'\''].join(''))).setRelationsDepth(2).setRelationsPageSize(1).setPageSize(10)))[0]);

  } catch (error) {
    await setToast(({ [`status`]: 'failure',[`message`]: 'There was a problem loading the user information.' }));

  } finally {
    isDone = true;

  }
}

/**
 * Describe this function...
 */
async function setToast(toastError) {
  ___arguments.context.pageData['isLoadingAction'] = false;
  await BackendlessUI.Functions.Custom['fn_77bfd63e533a9b4c5577e5fe31a4b5ca'](___arguments.context.pageData, (getObjectProperty(toastError, 'message')), (getObjectProperty(toastError, 'status')));
}

function generateUUID() {
   const chr4 = () => Math.random().toString(16).slice(-4);
   const chr8 = () => `${chr4()}${chr4()}`;
   const short = () => chr8();
   const long = () => `${chr8()}-${chr4()}-${chr4()}-${chr4()}-${chr8()}${chr4()}`;

   return { short, long, }
}

function encodePath(path) {
  if(path.startsWith("http://") || path.startsWith("https://")) {
    return path
  }

  let decodedPath
  try {
    decodedPath = decodeURI(path)
  } finally {
    return (decodedPath || path).split("/").map(encodeURIComponent).join("/")
  }
}

/**
 * Describe this function...
 */
async function createImageUrl(variant) {
  if ((getObjectProperty(___arguments.context.pageData, 'organizationUpdateObject.logo')) != (getObjectProperty(___arguments.context.pageData, 'selectedItemOrganization.logo'))) {
    if (variant == 'user') {
      if ((getObjectProperty(currentUser, 'userRole')) == 'super') {
        selectedImageUrl = (await Backendless.Files.upload(
            ((getObjectProperty(___arguments.context.pageData, 'selectedImage'))[0]),
            encodePath((['/profile_pictures/',(getObjectProperty(itemToUpdate, 'objectId')),'/'].join(''))),
            true
          ).then(result => result.fileURL));
        if (selectedImageUrl) {
          await Backendless.UserService.update( new Backendless.User(({ [`profilePicture`]: selectedImageUrl,[`objectId`]: (getObjectProperty(itemToUpdate, 'objectId')) })) );
        }
      } else {
        selectedImageUrl = (await Backendless.Files.upload(
            ((getObjectProperty(___arguments.context.pageData, 'selectedImage'))[0]),
            encodePath((['/profile_pictures/',(getObjectProperty(currentUser, 'objectId')),'/'].join(''))),
            true
          ).then(result => result.fileURL));
        if (selectedImageUrl) {
          await Backendless.UserService.update( new Backendless.User(({ [`profilePicture`]: selectedImageUrl,[`objectId`]: (getObjectProperty(currentUser, 'objectId')) })) );
        }
      }
    } else if (variant == 'organization') {
      if ((getObjectProperty(currentUser, 'userRole')) == 'super') {
        selectedImageUrl = (await Backendless.Files.upload(
            ((getObjectProperty(___arguments.context.pageData, 'selectedItemOrganizationImageFile'))[0]),
            encodePath((['/organization_logos/',(getObjectProperty(itemToUpdate, 'objectId')),'/'].join(''))),
            true
          ).then(result => result.fileURL));
        if (selectedImageUrl) {
          await Backendless.Data.of('Organizations').save( ({ [`logo`]: selectedImageUrl,[`objectId`]: (getObjectProperty(itemToUpdate, 'objectId')) }) );
        }
      } else {
        selectedImageUrl = (await Backendless.Files.upload(
            ((getObjectProperty(___arguments.context.pageData, 'selectedItemOrganizationImageFile'))[0]),
            encodePath((['/organization_logos/',(getObjectProperty(currentUser, 'objectId')),'/'].join(''))),
            true
          ).then(result => result.fileURL));
        if (selectedImageUrl) {
          await Backendless.Data.of('Organizations').save( ({ [`logo`]: selectedImageUrl,[`objectId`]: (getObjectProperty(currentUser, 'organizationId.objectId')) }) );
        }
      }
    }
  }
}


  recordTableVariant = (getObjectProperty(___arguments.context.pageData, 'recordTableVariant'));
  menuOptionSelection = (getObjectProperty(___arguments.context.pageData, 'menuOptionSelection'));
  currentUser = (getObjectProperty(___arguments.context.pageData, 'currentUser'));
  updateConfirmPassword = (getObjectProperty(___arguments.context.pageData, 'updateConfirmPassword'));
  isUpdatingProfile = menuOptionSelection == 'viewProfile';
  isUpdatingOrganization = menuOptionSelection == 'viewOrganization';
  isAgreeingToUpdate = (getObjectProperty(___arguments.context.pageData, 'isAgreeingToUpdate'));
  selectedItemOrganization = (getObjectProperty(___arguments.context.pageData, 'selectedItemOrganization'));
  if (recordTableVariant == 'user' || isUpdatingProfile) {
    isDone = false;
    if (!isAgreeingToUpdate && isUpdatingProfile) {
      ___arguments.context.pageData['isAgreeingToUpdate'] = true;
      return ;
    } else if (isAgreeingToUpdate && isUpdatingProfile) {
      if (updateConfirmPassword) {
        ___arguments.context.pageData['isLoadingAction'] = true;
        confirmPasswordResponse = (await Backendless.Request.post(`${Backendless.appPath}/services/PasswordVerification/VerifyPassword`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send({ 'password': updateConfirmPassword,'token': (Backendless.getCurrentUserToken()) }));
        if (!(getObjectProperty(confirmPasswordResponse, 'valid'))) {
          ___arguments.context.pageData['isLoadingAction'] = false;
          await setToast(({ [`status`]: 'failure',[`message`]: 'The password you entered is incorrect.' }));
          return ;
        }
      }
    }
    ___arguments.context.pageData['isLoadingAction'] = true;
    if ((getObjectProperty(___arguments.context.pageData, 'selectedItemArrayUser')) && (getObjectProperty(___arguments.context.pageData, 'selectedItemArrayUser')).length >= 1 || isUpdatingProfile) {
      if (currentUser && isUpdatingProfile) {
        itemToUpdate = currentUser;
        changedAttributes = ({ [`objectId`]: (getObjectProperty(currentUser, 'objectId')) });
      } else {
        itemToUpdate = (getObjectProperty(___arguments.context.pageData, 'selectedItemArrayUser'))[0];
        changedAttributes = ({ [`objectId`]: (getObjectProperty(itemToUpdate, 'objectId')) });
      }
      index = 0;
      selectedItemPhone = String('+1') + String((await BackendlessUI.Functions.Custom['fn_f282814277b3c0aa13f33d3b2cda111b']((getObjectProperty(___arguments.context.pageData, 'selectedItemPhone')), 'remove')));
      selectedItemName = (getObjectProperty(___arguments.context.pageData, 'selectedItemName'));
      selectedItemRole = (getObjectProperty(___arguments.context.pageData, 'selectedItemRole'));
      selectedItemEmail = (getObjectProperty(___arguments.context.pageData, 'selectedItemEmail'));
      inputs = [selectedItemName, selectedItemPhone, selectedItemRole, selectedItemEmail, selectedItemOrganization];
      for (var attribute_index in inputs) {
        attribute = inputs[attribute_index];
        if (attribute) {
          if (index == 0 && selectedItemName != (getObjectProperty(itemToUpdate, 'name'))) {
            changedAttributes['name'] = selectedItemName;
          } else if (index == 1 && selectedItemPhone != (getObjectProperty(itemToUpdate, 'phoneNumber'))) {
            changedAttributes['phoneNumber'] = selectedItemPhone;
          } else if (index == 2 && selectedItemRole != (getObjectProperty(itemToUpdate, 'userRole'))) {
            changedAttributes['userRole'] = selectedItemRole;
          } else if (index == 3 && selectedItemEmail != (getObjectProperty(itemToUpdate, 'email'))) {
            changedAttributes['email'] = selectedItemEmail;
          } else if (index == 4 && selectedItemOrganization != (getObjectProperty(itemToUpdate, 'objectId'))) {
            updatedOrg = selectedItemOrganization;
          }
        }
        index = (typeof index == 'number' ? index : 0) + 1;
      }
      if (changedAttributes) {
        try {
          updateUserData = (await Backendless.UserService.update( new Backendless.User(changedAttributes) ));
          if (updatedOrg) {
            updatedOrganizationRelation = (await Backendless.Data.of('Users').setRelation(itemToUpdate, 'organizationId', (await Backendless.Data.of('Organizations').find(Backendless.DataQueryBuilder.create().setWhereClause((['objectId = \'',updatedOrg,'\''].join(''))).setPageSize(10)))));
          }
          if (updateUserData || updatedOrganizationRelation) {
            await createImageUrl('user');
            if ((getObjectProperty(changedAttributes, 'email')) && (getObjectProperty(itemToUpdate, 'inviteStatus')) == 'PENDING') {
              uniqueID = (generateUUID().long());
              await Backendless.Cache.put((getObjectProperty(changedAttributes, 'email')), ({ [`key`]: uniqueID }), 7200);
              await Backendless.Cache.expireIn((getObjectProperty(changedAttributes, 'email')), 7200);
              if ((getObjectProperty(itemToUpdate, 'userRole')) == 'admin') {
                await BackendlessUI.Functions.Custom['fn_67c6f852cf5ce414b74b8f0b9401b852']('engineers@cyberdive.co', 'Cyber Dive Team', (getObjectProperty(changedAttributes, 'email')), 'd-f90fbbee97014b54bb155e790792e5bf', ({ [`link`]: (await BackendlessUI.Functions.Custom['fn_a65e994797048d593245ed62a80fb31f']('invite', ({ [`user_email`]: (getObjectProperty(changedAttributes, 'email')),[`admin_email`]: (getObjectProperty(currentUser, 'email')) }))),[`organization_name`]: (getObjectProperty(itemToUpdate, 'orgName')),[`organization_admin`]: 'Cyber Dive Team' }));
              } else if ((getObjectProperty(itemToUpdate, 'userRole')) == 'manager') {
                await BackendlessUI.Functions.Custom['fn_67c6f852cf5ce414b74b8f0b9401b852']('engineers@cyberdive.co', 'Cyber Dive Team', (getObjectProperty(changedAttributes, 'email')), 'd-7ca2c090070c49319c4d0c3c0d03071f', ({ [`link`]: (await BackendlessUI.Functions.Custom['fn_a65e994797048d593245ed62a80fb31f']('invite', ({ [`user_email`]: (getObjectProperty(changedAttributes, 'email')),[`admin_email`]: (getObjectProperty(currentUser, 'email')) }))),[`organization_name`]: (getObjectProperty(itemToUpdate, 'orgName')),[`organization_admin`]: 'Cyber Dive Team',[`case_manager`]: (getObjectProperty(itemToUpdate, 'name')) }));
              }
            }
            if (isUpdatingProfile) {
              if ((getObjectProperty(itemToUpdate, 'userRole')) == 'admin') {
                await BackendlessUI.Functions.Custom['fn_67c6f852cf5ce414b74b8f0b9401b852']('engineers@cyberdive.co', 'Cyber Dive Team', (getObjectProperty(itemToUpdate, 'email')), 'd-e3d0f9eb7bae4cc5a877d00e80bc9e84', ({ [`organization_admin`]: (getObjectProperty(itemToUpdate, 'name')) }));
                await BackendlessUI.Functions.Custom['fn_67c6f852cf5ce414b74b8f0b9401b852']('engineers@cyberdive.co', 'Cyber Dive Team', 'engineers@cyberdive.co', 'd-eee48d5acc6c4d8a88be92e2d1c66fa0', ({ [`organization_name`]: (getObjectProperty(itemToUpdate, 'organizationId.orgName')),[`organization_admin`]: (getObjectProperty(itemToUpdate, 'name')),[`super_admin`]: 'Cyber Dive' }));
              }
            }
            ___arguments.context.pageData['isUserRecordActionDrawerOpen'] = false;
            ___arguments.context.pageData['isUpdateUserModalOpen'] = false;
            ___arguments.context.pageData['scrollToTopSelectedItem'] = true;
            ___arguments.context.pageData['isSelectedItemModalOpen'] = false;
            ___arguments.context.pageData['isUserMenuOpen'] = false;
            ___arguments.context.pageData['menuOptionSelection'] = undefined;
            ___arguments.context.pageData['refreshUserTable'] = ({  });
            await updateCurrentUserInfo();
            if (isUpdatingProfile) {
              await setToast(({ [`status`]: 'success',[`message`]: 'You\'ve successfully updated your information' }));
            } else {
              await setToast(({ [`status`]: 'success',[`message`]: [(getObjectProperty(itemToUpdate, 'name')),' from ',(getObjectProperty(itemToUpdate, 'orgName')),'\'s have been successfully updated.'].join('') }));
            }
          }

        } catch (error) {
          ___arguments.context.pageData['isLoadingAction'] = false;
          if (isUpdatingProfile) {
            await setToast(({ [`status`]: 'failure',[`message`]: 'There was an error updating you information.' }));
          } else {
            await setToast(({ [`status`]: 'failure',[`message`]: 'There was an error updating this user.' }));
          }

        } finally {
          ___arguments.context.pageData['isLoadingAction'] = false;

        }
      }
    }
  }
  if (recordTableVariant == 'organization' || isUpdatingOrganization) {
    if (!isAgreeingToUpdate && isUpdatingOrganization) {
      ___arguments.context.pageData['isAgreeingToUpdate'] = true;
      return ;
    } else if (isAgreeingToUpdate && isUpdatingOrganization) {
      if (updateConfirmPassword) {
        ___arguments.context.pageData['isLoadingAction'] = true;
        confirmPasswordResponse = (await Backendless.Request.post(`${Backendless.appPath}/services/PasswordVerification/VerifyPassword`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send({ 'password': updateConfirmPassword,'token': (Backendless.getCurrentUserToken()) }));
        if (!(getObjectProperty(confirmPasswordResponse, 'valid'))) {
          ___arguments.context.pageData['isLoadingAction'] = false;
          await setToast(({ [`status`]: 'failure',[`message`]: 'The password you entered is incorrect.' }));
          return ;
        }
      }
    }
    ___arguments.context.pageData['isLoadingAction'] = true;
    if ((getObjectProperty(___arguments.context.pageData, 'selectedItemOrganization')) || isUpdatingOrganization) {
      if (isUpdatingOrganization && currentUser) {
        itemToUpdate = (getObjectProperty(currentUser, 'organizationId'));
        changedAttributes = ({ [`objectId`]: (getObjectProperty(itemToUpdate, 'objectId')) });
      } else {
        itemToUpdate = (getObjectProperty(___arguments.context.pageData, 'organizationUpdateObject'));
        changedAttributes = ({ [`objectId`]: (getObjectProperty(itemToUpdate, 'objectId')) });
      }
      if ((getObjectProperty(selectedItemOrganization, 'billingPhone')) != (getObjectProperty(itemToUpdate, 'billingPhone'))) {
        changedAttributes['billingPhone'] = (String('+1') + String((await BackendlessUI.Functions.Custom['fn_f282814277b3c0aa13f33d3b2cda111b']((getObjectProperty(itemToUpdate, 'billingPhone')), 'remove'))));
      } else if ((getObjectProperty(selectedItemOrganization, 'orgName')) != (getObjectProperty(itemToUpdate, 'orgName'))) {
        changedAttributes['orgName'] = (getObjectProperty(itemToUpdate, 'orgName'));
      } else if ((getObjectProperty(selectedItemOrganization, 'taxId')) != (getObjectProperty(itemToUpdate, 'taxId'))) {
        changedAttributes['taxId'] = (getObjectProperty(itemToUpdate, 'taxId'));
      } else if ((getObjectProperty(selectedItemOrganization, 'billingEmail')) != (getObjectProperty(itemToUpdate, 'billingEmail'))) {
        changedAttributes['billingEmail'] = (getObjectProperty(itemToUpdate, 'billingEmail'));
      } else if ((getObjectProperty(selectedItemOrganization, 'billingName')) != (getObjectProperty(itemToUpdate, 'billingName'))) {
        changedAttributes['billingName'] = (getObjectProperty(itemToUpdate, 'billingName'));
      } else if ((getObjectProperty(selectedItemOrganization, 'websiteLink')) != (getObjectProperty(itemToUpdate, 'websiteLink'))) {
        changedAttributes['websiteLink'] = (getObjectProperty(itemToUpdate, 'websiteLink'));
      } else if ((getObjectProperty(selectedItemOrganization, 'address')) != (getObjectProperty(itemToUpdate, 'address'))) {
        changedAttributes['address'] = (getObjectProperty(itemToUpdate, 'address'));
      } else if ((getObjectProperty(selectedItemOrganization, 'city')) != (getObjectProperty(itemToUpdate, 'city'))) {
        changedAttributes['city'] = (getObjectProperty(itemToUpdate, 'city'));
      } else if ((getObjectProperty(selectedItemOrganization, 'state')) != (getObjectProperty(itemToUpdate, 'state'))) {
        changedAttributes['state'] = (getObjectProperty(itemToUpdate, 'state'));
      } else if ((getObjectProperty(selectedItemOrganization, 'zipCode')) != (getObjectProperty(itemToUpdate, 'zipCode'))) {
        changedAttributes['zipCode'] = (getObjectProperty(itemToUpdate, 'zipCode'));
      }
      if (changedAttributes) {
        try {
          await createImageUrl('organization');
          updatedOrganizationData = (await Backendless.Data.of('Organizations').save( changedAttributes ));
          if (updatedOrganizationData) {
            if (isUpdatingOrganization) {
              await BackendlessUI.Functions.Custom['fn_67c6f852cf5ce414b74b8f0b9401b852']('engineers@cyberdive.co', 'Cyber Dive Team', (getObjectProperty(currentUser, 'email')), 'd-3007e3b999db4973a22e5018a5ecd676', ({ [`organization_admin`]: (getObjectProperty(currentUser, 'name')),[`organization_name`]: (getObjectProperty(itemToUpdate, 'orgName')) }));
              await BackendlessUI.Functions.Custom['fn_67c6f852cf5ce414b74b8f0b9401b852']('engineers@cyberdive.co', 'Cyber Dive Team', 'engineers@cyberdive.co', 'd-6f15d8bbf6b345d1a9988d64ca18f3ff', ({ [`organization_name`]: (getObjectProperty(itemToUpdate, 'orgName')),[`organization_admin`]: (getObjectProperty(currentUser, 'name')),[`super_admin`]: 'Cyber Dive' }));
            }
            if ((getObjectProperty(currentUser, 'userRole')) != 'super') {
              await updateCurrentUserInfo();
              itemToUpdate = (getObjectProperty(___arguments.context.pageData, 'currentUser.organizationId'));
            } else {
              ___arguments.context.pageData['refreshOrgTable'] = true;
            }
            (getObjectProperty(___arguments.context.pageData, 'organizationUpdateObject'))['billingPhone'] = (String('+1') + String(getObjectProperty(___arguments.context.pageData, 'organizationUpdateObject.billingPhone')));
            ___arguments.context.pageData['selectedItemOrganization'] = (getObjectProperty(___arguments.context.pageData, 'organizationUpdateObject'));
            ___arguments.context.pageData['isUpdateUserModalOpen'] = false;
            ___arguments.context.pageData['scrollToTopSelectedItem'] = true;
            ___arguments.context.pageData['isSelectedItemModalOpen'] = false;
            ___arguments.context.pageData['menuOptionSelection'] = undefined;
            await setToast(({ [`status`]: 'success',[`message`]: ['You\'ve successfully updated the ',(getObjectProperty(itemToUpdate, 'orgName')),' organization!'].join('') }));
          }

        } catch (error) {
          console.log(error);
          await setToast(({ [`status`]: 'failure',[`message`]: 'There was an error updating this organization.' }));

        }
      }
      ___arguments.context.pageData['isLoadingAction'] = false;
    }
  }

  },
  /* handler:onClick */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (!(getObjectProperty(___arguments.context.pageData, 'updateConfirmPassword')) && (getObjectProperty(___arguments.context.pageData, 'isUpdateConfirmPassword'))) {
    ___arguments.context.pageData['isUpdateUserButtonDisabled'] = true;
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('6d0e2ddfd8db162c01a3d3888e5325c4'))['background-color'] = '#fbdddd';
  } else {
    ___arguments.context.pageData['isUpdateUserButtonDisabled'] = false;
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('6d0e2ddfd8db162c01a3d3888e5325c4'))['background-color'] = '#E6654F';
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./components/reusable/r_7d14d39ce5a76b14695d8f57fa598a2a/logic/8363955a1d5aff9ed2b7c3bb91b418f9/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
    var selectedItem, recordTableVariant, menuOptionSelection, currentUser;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  recordTableVariant = (getObjectProperty(___arguments.context.pageData, 'recordTableVariant'));
  menuOptionSelection = (getObjectProperty(___arguments.context.pageData, 'menuOptionSelection'));
  currentUser = (getObjectProperty(___arguments.context.pageData, 'currentUser'));
  if (currentUser) {
    if (menuOptionSelection == 'viewProfile') {
      return 'Are you sure you want to update this information?';
    } else if (menuOptionSelection == 'viewOrganization') {
      return 'Are you sure you want to update your organization\'s information?';
    }
    if (recordTableVariant == 'organization') {
      if (getObjectProperty(___arguments.context.pageData, 'selectedItemOrganization')) {
        return (['Are you sure you want to update ',(await BackendlessUI.Functions.Custom['fn_9807a958b9f47818617415e4438b5e47']((getObjectProperty(___arguments.context.pageData, 'selectedItemOrganization.orgName')), 10)),'\'s information?'].join(''));
      }
    } else if (recordTableVariant == 'user') {
      if ((getObjectProperty(___arguments.context.pageData, 'selectedItemArrayUser')).length >= 1) {
        selectedItem = (getObjectProperty(___arguments.context.pageData, 'selectedItemArrayUser'))[0];
        return (['Are you sure you want to update ',(await BackendlessUI.Functions.Custom['fn_9807a958b9f47818617415e4438b5e47']((getObjectProperty(selectedItem, 'name')), 10)),'\'s information?'].join(''));
      }
    }
  }

  },
  /* handler:onContentAssignment */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    var isAgreeingToUpdate;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'isAgreeingToUpdate')) {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('8363955a1d5aff9ed2b7c3bb91b418f9', false);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('8363955a1d5aff9ed2b7c3bb91b418f9', true);
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./components/reusable/r_7d14d39ce5a76b14695d8f57fa598a2a/logic/8f6f78a7f638dd3b9c3aca612e49fc23/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    
  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./components/reusable/r_7d14d39ce5a76b14695d8f57fa598a2a/logic/e05400c2ed9b5551c3ada884bd7dbc6a/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'isAgreeingToUpdate')) {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('e05400c2ed9b5551c3ada884bd7dbc6a', true);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('e05400c2ed9b5551c3ada884bd7dbc6a', false);
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./components/reusable/r_80def5488d29a50d13585c4ee37b916c/logic/dabd70836ff8be2ef60d21f6f9bc1ab2/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    var classesList;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  classesList = ['modal'];
  if (getObjectProperty(___arguments.context.pageData, 'isViewDetailsModalOpen')) {
    addItemToList(classesList, 'open');
  }

  return classesList

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./components/reusable/r_80def5488d29a50d13585c4ee37b916c/logic/df1f2d98b6d1f8947296604920e3cc57/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      ___arguments.context.pageData['isViewDetailsModalOpen'] = false;

  },
  /* handler:onClick */
  /* content */
}))

define('./components/reusable/r_80def5488d29a50d13585c4ee37b916c/logic/215f327de78372d52a13b7f329eff9d5/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    var status2;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'viewDetailsSelection')) {
    status2 = (getObjectProperty((getObjectProperty(___arguments.context.pageData, 'viewDetailsSelection')), 'status'));
    if (status2 == 'DEACTIVATED') {
      ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('215f327de78372d52a13b7f329eff9d5'))['background-color'] = '#fbdddd';
    } else if (status2 == 'ACTIVE') {
      ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('215f327de78372d52a13b7f329eff9d5'))['background-color'] = '#e2f5ea';
    }
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./components/reusable/r_80def5488d29a50d13585c4ee37b916c/logic/e4bc0464d62a5b8d16ce41ec3e47de1e/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    var status2;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'viewDetailsSelection')) {
    status2 = (getObjectProperty((getObjectProperty(___arguments.context.pageData, 'viewDetailsSelection')), 'status'));
    if (status2 == 'DEACTIVATED') {
      return 'deactivated';
    } else if (status2 == 'ACTIVE') {
      return 'active';
    }
  }

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./components/reusable/r_80def5488d29a50d13585c4ee37b916c/logic/13ca0aad96011e2bcc2334674f72e42c/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    var created;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'viewDetailsSelection')) {
    created = (getObjectProperty((getObjectProperty(___arguments.context.pageData, 'viewDetailsSelection')), 'created'));
    return ([((new Date(created)).toLocaleDateString()),', ',((new Date(created)).toLocaleTimeString())].join(''));
  }

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./components/reusable/r_80def5488d29a50d13585c4ee37b916c/logic/f0c32f3cc8245f57ad42392378c5ec7a/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    var updated, created;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'viewDetailsSelection')) {
    updated = (getObjectProperty((getObjectProperty(___arguments.context.pageData, 'viewDetailsSelection')), 'updated'));
    created = (getObjectProperty((getObjectProperty(___arguments.context.pageData, 'viewDetailsSelection')), 'created'));
    if (updated) {
      return ([((new Date(updated)).toLocaleDateString()),', ',((new Date(updated)).toLocaleTimeString())].join(''));
    } else {
      return ([((new Date(created)).toLocaleDateString()),', ',((new Date(created)).toLocaleTimeString())].join(''));
    }
  }

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./components/reusable/r_80def5488d29a50d13585c4ee37b916c/logic/334787c3ebf3cd821f73a2278386ed93/bundle.js', [], () => ({
  /* content */
  /* handler:onValueAssignment */
  ['onValueAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'viewDetailsSelection')) {
    return (getObjectProperty((getObjectProperty(___arguments.context.pageData, 'viewDetailsSelection')), 'orgName'));
  }

  },
  /* handler:onValueAssignment */
  /* content */
}))

define('./components/reusable/r_80def5488d29a50d13585c4ee37b916c/logic/58fe5becbe63263f116526e298fe3342/bundle.js', [], () => ({
  /* content */
  /* handler:onValueAssignment */
  ['onValueAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'viewDetailsSelection')) {
    return (getObjectProperty((getObjectProperty(___arguments.context.pageData, 'viewDetailsSelection')), 'address'));
  }

  },
  /* handler:onValueAssignment */
  /* content */
}))

define('./components/reusable/r_80def5488d29a50d13585c4ee37b916c/logic/c148f824158300bef8bee1ae333b2926/bundle.js', [], () => ({
  /* content */
  /* handler:onValueAssignment */
  ['onValueAssignment'](___arguments) {
    var cityZip;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'viewDetailsSelection')) {
    return (getObjectProperty((getObjectProperty(___arguments.context.pageData, 'viewDetailsSelection')), 'zipCode'));
  }

  },
  /* handler:onValueAssignment */
  /* content */
}))

define('./components/reusable/r_80def5488d29a50d13585c4ee37b916c/logic/2816c326153dd8ed12116b4d31ae8967/bundle.js', [], () => ({
  /* content */
  /* handler:onValueAssignment */
  ['onValueAssignment'](___arguments) {
    var cityZip;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'viewDetailsSelection')) {
    return (getObjectProperty((getObjectProperty(___arguments.context.pageData, 'viewDetailsSelection')), 'state'));
  }

  },
  /* handler:onValueAssignment */
  /* content */
}))

define('./components/reusable/r_80def5488d29a50d13585c4ee37b916c/logic/4252c03c902a758e1592a2d3f06c4926/bundle.js', [], () => ({
  /* content */
  /* handler:onValueAssignment */
  ['onValueAssignment'](___arguments) {
    var cityZip;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'viewDetailsSelection')) {
    return (getObjectProperty((getObjectProperty(___arguments.context.pageData, 'viewDetailsSelection')), 'city'));
  }

  },
  /* handler:onValueAssignment */
  /* content */
}))

define('./components/reusable/r_80def5488d29a50d13585c4ee37b916c/logic/1e7a4b5789567883f776129a56547537/bundle.js', [], () => ({
  /* content */
  /* handler:onValueAssignment */
  ['onValueAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'viewDetailsSelection')) {
    return (getObjectProperty((getObjectProperty(___arguments.context.pageData, 'viewDetailsSelection')), 'websiteLink'));
  }

  },
  /* handler:onValueAssignment */
  /* content */
}))

define('./components/reusable/r_80def5488d29a50d13585c4ee37b916c/logic/381ff40e1ba66aa6ddd9c4acbe7b315e/bundle.js', [], () => ({
  /* content */
  /* handler:onValueAssignment */
  ['onValueAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'viewDetailsSelection')) {
    return (getObjectProperty((getObjectProperty(___arguments.context.pageData, 'viewDetailsSelection')), 'taxId'));
  }

  },
  /* handler:onValueAssignment */
  /* content */
}))

define('./components/reusable/r_80def5488d29a50d13585c4ee37b916c/logic/67952bf1061dd0a157c11f83448db50f/bundle.js', [], () => ({
  /* content */
  /* handler:onValueAssignment */
  ['onValueAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'viewDetailsSelection')) {
    return (getObjectProperty((getObjectProperty(___arguments.context.pageData, 'viewDetailsSelection')), 'billingName'));
  }

  },
  /* handler:onValueAssignment */
  /* content */
}))

define('./components/reusable/r_80def5488d29a50d13585c4ee37b916c/logic/a0a3b3cb36cb59598526f1175e160fd2/bundle.js', [], () => ({
  /* content */
  /* handler:onValueAssignment */
  ['onValueAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'viewDetailsSelection')) {
    return (getObjectProperty((getObjectProperty(___arguments.context.pageData, 'viewDetailsSelection')), 'billingEmail'));
  }

  },
  /* handler:onValueAssignment */
  /* content */
}))

define('./components/reusable/r_80def5488d29a50d13585c4ee37b916c/logic/2369a5119b96f43a38d7411bc05d019d/bundle.js', [], () => ({
  /* content */
  /* handler:onValueAssignment */
  async ['onValueAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'viewDetailsSelection')) {
    return (await BackendlessUI.Functions.Custom['fn_f282814277b3c0aa13f33d3b2cda111b']((getObjectProperty((getObjectProperty(___arguments.context.pageData, 'viewDetailsSelection')), 'billingPhone')), 'create'));
  }

  },
  /* handler:onValueAssignment */
  /* content */
}))

define('./components/reusable/r_80def5488d29a50d13585c4ee37b916c/logic/2f94f2121b4e31b9d9638903e602eb33/bundle.js', [], () => ({
  /* content */
  /* handler:onSourceUrlAssignment */
  ['onSourceUrlAssignment'](___arguments) {
    var selectedItem;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'viewDetailsSelection')) {
    if (getObjectProperty((getObjectProperty(___arguments.context.pageData, 'viewDetailsSelection')), 'logo')) {
      return (getObjectProperty((getObjectProperty(___arguments.context.pageData, 'viewDetailsSelection')), 'logo'));
    } else {
      return 'https://app.aquax.co/api/files/cyberdive_photos/cd-icon-default-photo.svg';
    }
  }

  },
  /* handler:onSourceUrlAssignment */
  /* content */
}))

define('./components/reusable/r_80def5488d29a50d13585c4ee37b916c/logic/9a9ed92d165a41b4717cef9f482b4e67/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      ___arguments.context.pageData['isViewDetailsModalOpen'] = false;

  },
  /* handler:onClick */
  /* content */
}))

define('./components/reusable/r_80def5488d29a50d13585c4ee37b916c/logic/b8ac62e7d18654e2450e60fd7be56843/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'viewDetailsModalVariant')) == 'organization') {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('b8ac62e7d18654e2450e60fd7be56843', true);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('b8ac62e7d18654e2450e60fd7be56843', false);
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./components/reusable/r_80def5488d29a50d13585c4ee37b916c/logic/ef23dd4f5371acef05daabfcdef14b92/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'viewDetailsModalVariant')) == 'device') {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('ef23dd4f5371acef05daabfcdef14b92', true);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('ef23dd4f5371acef05daabfcdef14b92', false);
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./components/reusable/r_80def5488d29a50d13585c4ee37b916c/logic/25fcb9d4879936888865add4320c4fc2/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'viewDetailsModalVariant')) == 'organization') {
    return 'Org Details';
  } else if ((getObjectProperty(___arguments.context.pageData, 'viewDetailsModalVariant')) == 'device') {
    return 'Device Details';
  }

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./components/reusable/r_80def5488d29a50d13585c4ee37b916c/logic/345c8e7e5011b372bafcccf7a98dc4fd/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (String('Pillar ') + String(getObjectProperty(___arguments.context.pageData, 'viewDetailsSelection.residentId.pillar')))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./components/reusable/r_80def5488d29a50d13585c4ee37b916c/logic/bb86935effc28bc781d76aff7e71cf2f/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'viewDetailsSelection.deviceStatus')) == 'ASSIGNED') {
    return 'Assigned';
  } else if ((getObjectProperty(___arguments.context.pageData, 'viewDetailsSelection.deviceStatus')) == 'ONBOARDSTARTED') {
    return 'Onboard Started';
  } else if ((getObjectProperty(___arguments.context.pageData, 'viewDetailsSelection.deviceStatus')) == 'ONBOARDPENDING') {
    return 'Onboard Pending';
  } else if ((getObjectProperty(___arguments.context.pageData, 'viewDetailsSelection.deviceStatus')) == 'DEACTIVATED') {
    return 'Deactivated';
  }

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./components/reusable/r_80def5488d29a50d13585c4ee37b916c/logic/c072db4c2ddd3c7bdb29ae0775742d21/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    var classes, classesList;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'viewDetailsSelection.deviceStatus')) == 'ASSIGNED') {
    classes = ['active_status'];
  } else {
    classes = ['deactivated_status'];
  }

  return classes

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./components/reusable/r_80def5488d29a50d13585c4ee37b916c/logic/ef34656cd075240525c73ee7731b872d/bundle.js', [], () => ({
  /* content */
  /* handler:onSourceUrlAssignment */
  ['onSourceUrlAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'viewDetailsSelection.residentId.profilePicture')) {
    return (getObjectProperty(___arguments.context.pageData, 'viewDetailsSelection.residentId.profilePicture'));
  } else {
    return 'https://app.aquax.co/api/files/cyberdive_photos/cd-icon-default-photo.svg';
  }

  },
  /* handler:onSourceUrlAssignment */
  /* content */
}))

define('./components/reusable/r_80def5488d29a50d13585c4ee37b916c/logic/667782d9bd5be6f625733af88e32e3e4/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    var created;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'viewDetailsSelection.updated')) {
    created = (getObjectProperty(___arguments.context.pageData, 'viewDetailsSelection.updated'));
    return ([((new Date(created)).toLocaleDateString()),', ',((new Date(created)).toLocaleTimeString())].join(''));
  }

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./components/reusable/r_a5cc17adcd43a953ff497b8130a1de3b/logic/9dc356530b7be4f49ba427ea265f8911/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    var classesList;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  classesList = ['modal'];
  if (getObjectProperty(___arguments.context.pageData, 'isSelectedItemModalOpen')) {
    addItemToList(classesList, 'open');
  }

  return classesList

  },
  /* handler:onClassListAssignment */
  /* handler:onStyleAssignment */
  async ['onStyleAssignment'](___arguments) {
    var item;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'scrollToTopSelectedItem')) && (getObjectProperty(___arguments.context.pageData, 'isSelectedItemModalOpen'))) {
    await new Promise(r => setTimeout(r, 100 || 0));
    (function (component) { component.el.scrollIntoView() })(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('38622c8308061e08e949ba98e80450c7')));
    ___arguments.context.pageData['scrollToTopSelectedItem'] = false;
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./components/reusable/r_a5cc17adcd43a953ff497b8130a1de3b/logic/1c1b526579f45e2999da8377c634708f/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    var recordTableVariant, menuOptionSelection, currentUser;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  recordTableVariant = (getObjectProperty(___arguments.context.pageData, 'recordTableVariant'));
  menuOptionSelection = (getObjectProperty(___arguments.context.pageData, 'menuOptionSelection'));
  currentUser = (getObjectProperty(___arguments.context.pageData, 'currentUser'));
  if (menuOptionSelection) {
    if (currentUser) {
      if (menuOptionSelection == 'viewOrganization') {
        return 'Edit Org Details';
      } else if (menuOptionSelection == 'viewProfile') {
        return 'Edit Profile';
      }
    }
  } else {
    if (recordTableVariant == 'user') {
      return 'Update User Details';
    } else if (recordTableVariant == 'organization') {
      return 'Update Org Details';
    } else if (recordTableVariant == 'resident') {
      return 'Update User Details';
    }
  }

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./components/reusable/r_a5cc17adcd43a953ff497b8130a1de3b/logic/ac5ac9e57bdbad65502ef5d33254d5c1/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    var recordTableVariant, menuOption, isSelectedItemModalOpen;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  recordTableVariant = (getObjectProperty(___arguments.context.pageData, 'recordTableVariant'));
  isSelectedItemModalOpen = (getObjectProperty(___arguments.context.pageData, 'isSelectedItemModalOpen'));
  menuOption = (getObjectProperty(___arguments.context.pageData, 'menuOptionSelection'));
  if (menuOption) {
    if (menuOption == 'viewProfile') {
      (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('ac5ac9e57bdbad65502ef5d33254d5c1', true);
    } else {
      (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('ac5ac9e57bdbad65502ef5d33254d5c1', false);
    }
  } else {
    if (recordTableVariant == 'user') {
      (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('ac5ac9e57bdbad65502ef5d33254d5c1', true);
    } else {
      (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('ac5ac9e57bdbad65502ef5d33254d5c1', false);
    }
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./components/reusable/r_a5cc17adcd43a953ff497b8130a1de3b/logic/c98880baa7e9f46af6f2477eb6c9d0ae/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  async ['onChange'](___arguments) {
    var isNotDisabled, currentUser, menuOptionSelection;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  ___arguments.context.pageData['selectedItemName'] = (await BackendlessUI.Functions.Custom['fn_256db7f8ee15af9d778314d97f563562'](___arguments.value));
  currentUser = (getObjectProperty(___arguments.context.pageData, 'currentUser'));
  menuOptionSelection = (getObjectProperty(___arguments.context.pageData, 'menuOptionSelection'));
  if (currentUser) {
    if ((await BackendlessUI.Functions.Custom['fn_a7ad4c1fd5d18ca6bff3eb611aeee2ac'](___arguments.value))) {
      ___arguments.context.pageData['emptyName'] = true;
      ___arguments.context.pageData['disableButton'] = true;
    } else if (menuOptionSelection == 'viewProfile' && ___arguments.value != (getObjectProperty(currentUser, 'name')) || (getObjectProperty(___arguments.context.pageData, 'selectedItemArrayUser')) && ___arguments.value != (getObjectProperty(((getObjectProperty(___arguments.context.pageData, 'selectedItemArrayUser'))[0]), 'name'))) {
      (getObjectProperty(___arguments.context.pageData, 'isUserDetailsButtonDisabled'))['name'] = false;
      ___arguments.context.pageData['emptyName'] = false;
      isNotDisabled = true;
      if (getObjectProperty(___arguments.context.pageData, 'emptyPhone')) {
        isNotDisabled = false;
      } else if (getObjectProperty(___arguments.context.pageData, 'emptyEmail')) {
        isNotDisabled = false;
      }
      if (isNotDisabled) {
        ___arguments.context.pageData['disableButton'] = false;
      }
    } else {
      (getObjectProperty(___arguments.context.pageData, 'isUserDetailsButtonDisabled'))['name'] = true;
    }
  }

  },
  /* handler:onChange */
  /* content */
}))

define('./components/reusable/r_a5cc17adcd43a953ff497b8130a1de3b/logic/271a9182bf8aefea13aeb3dcd4542e3f/bundle.js', [], () => ({
  /* content */
  /* handler:onValueAssignment */
  ['onValueAssignment'](___arguments) {
    var selectedItem, currentUser, menuOptionSelection, isSelectedItemModalOpen;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  isSelectedItemModalOpen = (getObjectProperty(___arguments.context.pageData, 'isSelectedItemModalOpen'));
  menuOptionSelection = (getObjectProperty(___arguments.context.pageData, 'menuOptionSelection'));
  currentUser = (getObjectProperty(___arguments.context.pageData, 'currentUser'));
  if (menuOptionSelection == 'viewProfile') {
    if (currentUser) {
      if (getObjectProperty(___arguments.context.pageData, 'isSelectedItemModalOpen')) {
        return (getObjectProperty(currentUser, 'email'));
      }
    }
  } else {
    if ((getObjectProperty(___arguments.context.pageData, 'selectedItemArrayUser')) && (getObjectProperty(___arguments.context.pageData, 'selectedItemArrayUser')).length >= 1) {
      selectedItem = (getObjectProperty(___arguments.context.pageData, 'selectedItemArrayUser'))[0];
      if (getObjectProperty(___arguments.context.pageData, 'isSelectedItemModalOpen')) {
        return (getObjectProperty(selectedItem, 'email'));
      }
    }
  }

  },
  /* handler:onValueAssignment */
  /* handler:onChange */
  async ['onChange'](___arguments) {
    var isNotDisabled, selectedItem, currentUser, menuOptionSelection;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  ___arguments.context.pageData['selectedItemEmail'] = ___arguments.value;
  ___arguments.context.pageData['emailError'] = null;
  currentUser = (getObjectProperty(___arguments.context.pageData, 'currentUser'));
  menuOptionSelection = (getObjectProperty(___arguments.context.pageData, 'menuOptionSelection'));
  if (currentUser) {
    if ((await BackendlessUI.Functions.Custom['fn_a7ad4c1fd5d18ca6bff3eb611aeee2ac'](___arguments.value))) {
      ___arguments.context.pageData['emptyEmail'] = true;
      ___arguments.context.pageData['disableButton'] = true;
    } else if (menuOptionSelection == 'viewProfile' && ___arguments.value != (getObjectProperty(currentUser, 'email')) || (getObjectProperty(___arguments.context.pageData, 'selectedItemArrayUser')) && ___arguments.value != (getObjectProperty(((getObjectProperty(___arguments.context.pageData, 'selectedItemArrayUser'))[0]), 'email'))) {
      (getObjectProperty(___arguments.context.pageData, 'isUserDetailsButtonDisabled'))['email'] = false;
      ___arguments.context.pageData['emptyEmail'] = false;
      isNotDisabled = true;
      if (getObjectProperty(___arguments.context.pageData, 'emptyPhone')) {
        isNotDisabled = false;
      } else if (getObjectProperty(___arguments.context.pageData, 'emptyName')) {
        isNotDisabled = false;
      }
      if (isNotDisabled) {
        ___arguments.context.pageData['disableButton'] = false;
      }
    } else {
      (getObjectProperty(___arguments.context.pageData, 'isUserDetailsButtonDisabled'))['email'] = true;
    }
  }

  },
  /* handler:onChange */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    var classesList;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  classesList = [];
  if (getObjectProperty(___arguments.context.pageData, 'emailError')) {
    addItemToList(classesList, 'input_red_border');
  }

  return classesList

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./components/reusable/r_a5cc17adcd43a953ff497b8130a1de3b/logic/673fe7af1e36c086bc236a73ee78fb86/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'emailError')) {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('673fe7af1e36c086bc236a73ee78fb86', true);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('673fe7af1e36c086bc236a73ee78fb86', false);
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./components/reusable/r_a5cc17adcd43a953ff497b8130a1de3b/logic/1e17d4d539197ab631ff4df6a3baaa5c/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var hasNoError;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  hasNoError = true;
  if ((getObjectProperty(___arguments.context.pageData, 'selectedItemName')) && (await BackendlessUI.Functions.Custom['fn_a7ad4c1fd5d18ca6bff3eb611aeee2ac']((getObjectProperty(___arguments.context.pageData, 'selectedItemName'))))) {
    ___arguments.context.pageData['nameError'] = true;
    hasNoError = false;
  }
  if ((getObjectProperty(___arguments.context.pageData, 'selectedItemPhone')) && !(await BackendlessUI.Functions.Custom['fn_6d4580c12cbae4a32f38d054d2dc9e71']((await BackendlessUI.Functions.Custom['fn_f282814277b3c0aa13f33d3b2cda111b']((getObjectProperty(___arguments.context.pageData, 'selectedItemPhone')), 'remove'))))) {
    ___arguments.context.pageData['phoneError'] = true;
    hasNoError = false;
  }
  if ((getObjectProperty(___arguments.context.pageData, 'selectedItemEmail')) && !(await BackendlessUI.Functions.Custom['fn_36435bc8e7f437df45a6682ebdbf7388']((getObjectProperty(___arguments.context.pageData, 'selectedItemEmail'))))) {
    ___arguments.context.pageData['emailError'] = true;
    hasNoError = false;
  }
  if (hasNoError) {
    ___arguments.context.pageData['isAgreeingToUpdate'] = false;
    ___arguments.context.pageData['updateConfirmPassword'] = undefined;
    ___arguments.context.pageData['isUpdateUserModalOpen'] = true;
  }

  },
  /* handler:onClick */
  /* handler:onDisabledStateAssignment */
  ['onDisabledStateAssignment'](___arguments) {
    var isDisabled, selectedItem, isDisabledArray, isUserDetailsButtonDisabled;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  isUserDetailsButtonDisabled = (getObjectProperty(___arguments.context.pageData, 'isUserDetailsButtonDisabled'));
  isDisabled = true;
  if (!(typeof isUserDetailsButtonDisabled === 'undefined')) {
    isDisabledArray = [(getObjectProperty(isUserDetailsButtonDisabled, 'name')), (getObjectProperty(isUserDetailsButtonDisabled, 'phone')), (getObjectProperty(isUserDetailsButtonDisabled, 'email')), (getObjectProperty(isUserDetailsButtonDisabled, 'role')), (getObjectProperty(isUserDetailsButtonDisabled, 'selectedImage')), (getObjectProperty(isUserDetailsButtonDisabled, 'organization'))];
    for (var selectedItem_index in isDisabledArray) {
      selectedItem = isDisabledArray[selectedItem_index];
      if (!selectedItem) {
        isDisabled = false;
      }
    }
    if (getObjectProperty(___arguments.context.pageData, 'disableButton')) {
      ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('1e17d4d539197ab631ff4df6a3baaa5c'))['background-color'] = 'rgb(56,113,255,0.38)';
      return true;
    }
    if (isDisabled) {
      ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('1e17d4d539197ab631ff4df6a3baaa5c'))['background-color'] = 'rgb(56,113,255,0.38)';
      return true;
    } else {
      ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('1e17d4d539197ab631ff4df6a3baaa5c'))['background-color'] = '#3871FF';
      return false;
    }
  }

  },
  /* handler:onDisabledStateAssignment */
  /* content */
}))

define('./components/reusable/r_a5cc17adcd43a953ff497b8130a1de3b/logic/0b826f22c4287cda94866f0b405b197d/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      ___arguments.context.pageData['isSelectedItemModalOpen'] = false;

  },
  /* handler:onClick */
  /* content */
}))

define('./components/reusable/r_a5cc17adcd43a953ff497b8130a1de3b/logic/a9bf1d85399381bef84e98414c044655/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    var recordTableVariant, menuOption, isSelectedItemModalOpen;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  recordTableVariant = (getObjectProperty(___arguments.context.pageData, 'recordTableVariant'));
  isSelectedItemModalOpen = (getObjectProperty(___arguments.context.pageData, 'isSelectedItemModalOpen'));
  menuOption = (getObjectProperty(___arguments.context.pageData, 'menuOptionSelection'));
  if (menuOption) {
    if (menuOption == 'viewOrganization') {
      (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('a9bf1d85399381bef84e98414c044655', true);
    } else {
      (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('a9bf1d85399381bef84e98414c044655', false);
    }
  } else {
    if (recordTableVariant == 'organization') {
      (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('a9bf1d85399381bef84e98414c044655', true);
    } else {
      (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('a9bf1d85399381bef84e98414c044655', false);
    }
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./components/reusable/r_a5cc17adcd43a953ff497b8130a1de3b/logic/7cbc62b33b4a4eb6eafbe01734ce25ae/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  async ['onStyleAssignment'](___arguments) {
    var currentUser, menuOptionSelection, organizationUpdateObject;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function readBrowserFileAs(file, type) {
   const readers = { base64: "readAsDataURL", utf8: "readAsText", bytes: "readAsArrayBuffer" }

   return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader[readers[type]](file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = error => reject(error)
  })
}


  organizationUpdateObject = (getObjectProperty(___arguments.context.pageData, 'organizationUpdateObject'));
  menuOptionSelection = (getObjectProperty(___arguments.context.pageData, 'menuOptionSelection'));
  currentUser = (getObjectProperty(___arguments.context.pageData, 'currentUser'));
  if (getObjectProperty(___arguments.context.pageData, 'selectedItemOrganizationImageFile')) {
    (getObjectProperty(___arguments.context.pageData, 'organizationUpdateObject'))['logo'] = (await readBrowserFileAs(((getObjectProperty(___arguments.context.pageData, 'selectedItemOrganizationImageFile'))[0]), 'base64'));
  } else {
    if (menuOptionSelection == 'viewOrganization') {
      if (currentUser) {
        if (getObjectProperty(currentUser, 'organizationId.logo')) {
          ___arguments.context.pageData['selectedItemlogo'] = (getObjectProperty(currentUser, 'organizationId.logo'));
        } else {
          ___arguments.context.pageData['selectedItemlogo'] = 'https://app.aquax.co/api/files/cyberdive_photos/cd-icon-default-photo.svg';
        }
      }
    } else if (getObjectProperty(___arguments.context.pageData, 'selectedItemOrganization')) {
      if (!(getObjectProperty(___arguments.context.pageData, 'organizationUpdateObject.logo'))) {
        (getObjectProperty(___arguments.context.pageData, 'organizationUpdateObject'))['logo'] = 'https://app.aquax.co/api/files/cyberdive_photos/cd-icon-default-photo.svg';
      }
    }
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./components/reusable/r_a5cc17adcd43a953ff497b8130a1de3b/logic/70532bfc2f3a40762804cbeae4256318/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  async ['onChange'](___arguments) {
    var isNotDisabled, selectedItemOrganization, currentUser, menuOptionSelection;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  selectedItemOrganization = (getObjectProperty(___arguments.context.pageData, 'selectedItemOrganization'));
  currentUser = (getObjectProperty(___arguments.context.pageData, 'currentUser'));
  menuOptionSelection = (getObjectProperty(___arguments.context.pageData, 'menuOptionSelection'));
  (getObjectProperty(___arguments.context.pageData, 'organizationUpdateObject'))['orgName'] = (await BackendlessUI.Functions.Custom['fn_256db7f8ee15af9d778314d97f563562'](___arguments.value));
  ___arguments.context.pageData['orgNameError'] = false;
  if (currentUser) {
    if ((await BackendlessUI.Functions.Custom['fn_a7ad4c1fd5d18ca6bff3eb611aeee2ac'](___arguments.value))) {
      ___arguments.context.pageData['emptyOrgName'] = true;
      ___arguments.context.pageData['disableButton'] = true;
    } else if (menuOptionSelection == 'viewOrganization' && ___arguments.value != (getObjectProperty(currentUser, 'organizationId.orgName'))) {
      (getObjectProperty(___arguments.context.pageData, 'isEditOrgButtonDisabled'))['orgName'] = false;
      ___arguments.context.pageData['emptyOrgName'] = false;
      isNotDisabled = true;
      if (getObjectProperty(___arguments.context.pageData, 'emptyAddress')) {
        isNotDisabled = false;
      } else if (getObjectProperty(___arguments.context.pageData, 'emptyWebsite')) {
        isNotDisabled = false;
      } else if (getObjectProperty(___arguments.context.pageData, 'emptyTaxId')) {
        isNotDisabled = false;
      } else if (getObjectProperty(___arguments.context.pageData, 'emptyBillingName')) {
        isNotDisabled = false;
      } else if (getObjectProperty(___arguments.context.pageData, 'emptyBillingPhone')) {
        isNotDisabled = false;
      } else if (getObjectProperty(___arguments.context.pageData, 'emptyBillingEmail')) {
        isNotDisabled = false;
      }
      if (isNotDisabled) {
        ___arguments.context.pageData['disableButton'] = false;
      }
    } else if (selectedItemOrganization && ___arguments.value != (getObjectProperty(selectedItemOrganization, 'orgName'))) {
      (getObjectProperty(___arguments.context.pageData, 'isEditOrgButtonDisabled'))['orgName'] = false;
      ___arguments.context.pageData['emptyOrgName'] = false;
      isNotDisabled = true;
      if (getObjectProperty(___arguments.context.pageData, 'emptyAddress')) {
        isNotDisabled = false;
      } else if (getObjectProperty(___arguments.context.pageData, 'emptyWebsite')) {
        isNotDisabled = false;
      } else if (getObjectProperty(___arguments.context.pageData, 'emptyTaxId')) {
        isNotDisabled = false;
      } else if (getObjectProperty(___arguments.context.pageData, 'emptyBillingName')) {
        isNotDisabled = false;
      } else if (getObjectProperty(___arguments.context.pageData, 'emptyBillingPhone')) {
        isNotDisabled = false;
      } else if (getObjectProperty(___arguments.context.pageData, 'emptyBillingEmail')) {
        isNotDisabled = false;
      }
      if (isNotDisabled) {
        ___arguments.context.pageData['disableButton'] = false;
      }
    } else {
      (getObjectProperty(___arguments.context.pageData, 'isEditOrgButtonDisabled'))['orgName'] = true;
    }
  }

  },
  /* handler:onChange */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    var classesList;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  classesList = [];
  if (getObjectProperty(___arguments.context.pageData, 'orgNameError')) {
    addItemToList(classesList, 'input_red_border');
  }

  return classesList

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./components/reusable/r_a5cc17adcd43a953ff497b8130a1de3b/logic/208f3d9c4628a6269003e837ea4ecc15/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'orgNameError')) {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('208f3d9c4628a6269003e837ea4ecc15', true);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('208f3d9c4628a6269003e837ea4ecc15', false);
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./components/reusable/r_a5cc17adcd43a953ff497b8130a1de3b/logic/baa76486f60c1f3a6f4b51f89e6eb12a/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  async ['onChange'](___arguments) {
    var currentUser, isNotDisabled, menuOptionSelection, selectedItemOrganization;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  selectedItemOrganization = (getObjectProperty(___arguments.context.pageData, 'selectedItemOrganization'));
  currentUser = (getObjectProperty(___arguments.context.pageData, 'currentUser'));
  menuOptionSelection = (getObjectProperty(___arguments.context.pageData, 'menuOptionSelection'));
  (getObjectProperty(___arguments.context.pageData, 'organizationUpdateObject'))['address'] = ___arguments.value;
  if (currentUser) {
    if ((await BackendlessUI.Functions.Custom['fn_a7ad4c1fd5d18ca6bff3eb611aeee2ac'](___arguments.value))) {
      ___arguments.context.pageData['emptyAddress'] = true;
      ___arguments.context.pageData['disableButton'] = true;
    } else if (menuOptionSelection == 'viewOrganization' && ___arguments.value != (getObjectProperty(currentUser, 'organizationId.address'))) {
      (getObjectProperty(___arguments.context.pageData, 'isEditOrgButtonDisabled'))['address'] = false;
      ___arguments.context.pageData['emptyAddress'] = false;
      isNotDisabled = true;
      if (getObjectProperty(___arguments.context.pageData, 'emptyOrgName')) {
        isNotDisabled = false;
      } else if (getObjectProperty(___arguments.context.pageData, 'emptyWebsite')) {
        isNotDisabled = false;
      } else if (getObjectProperty(___arguments.context.pageData, 'emptyTaxId')) {
        isNotDisabled = false;
      } else if (getObjectProperty(___arguments.context.pageData, 'emptyBillingName')) {
        isNotDisabled = false;
      } else if (getObjectProperty(___arguments.context.pageData, 'emptyBillingPhone')) {
        isNotDisabled = false;
      } else if (getObjectProperty(___arguments.context.pageData, 'emptyBillingEmail')) {
        isNotDisabled = false;
      } else if (getObjectProperty(___arguments.context.pageData, 'emptyState')) {
        isNotDisabled = false;
      } else if (getObjectProperty(___arguments.context.pageData, 'emptyCity')) {
        isNotDisabled = false;
      } else if (getObjectProperty(___arguments.context.pageData, 'emptyZipCode')) {
        isNotDisabled = false;
      }
      if (isNotDisabled) {
        ___arguments.context.pageData['disableButton'] = false;
      }
    } else if (selectedItemOrganization && ___arguments.value != (getObjectProperty(selectedItemOrganization, 'address'))) {
      (getObjectProperty(___arguments.context.pageData, 'isEditOrgButtonDisabled'))['address'] = false;
      ___arguments.context.pageData['emptyAddress'] = false;
      isNotDisabled = true;
      if (getObjectProperty(___arguments.context.pageData, 'emptyOrgName')) {
        isNotDisabled = false;
      } else if (getObjectProperty(___arguments.context.pageData, 'emptyWebsite')) {
        isNotDisabled = false;
      } else if (getObjectProperty(___arguments.context.pageData, 'emptyTaxId')) {
        isNotDisabled = false;
      } else if (getObjectProperty(___arguments.context.pageData, 'emptyBillingName')) {
        isNotDisabled = false;
      } else if (getObjectProperty(___arguments.context.pageData, 'emptyBillingPhone')) {
        isNotDisabled = false;
      } else if (getObjectProperty(___arguments.context.pageData, 'emptyBillingEmail')) {
        isNotDisabled = false;
      } else if (getObjectProperty(___arguments.context.pageData, 'emptyState')) {
        isNotDisabled = false;
      } else if (getObjectProperty(___arguments.context.pageData, 'emptyCity')) {
        isNotDisabled = false;
      } else if (getObjectProperty(___arguments.context.pageData, 'emptyZipCode')) {
        isNotDisabled = false;
      }
      if (isNotDisabled) {
        ___arguments.context.pageData['disableButton'] = false;
      }
    } else {
      (getObjectProperty(___arguments.context.pageData, 'isEditOrgButtonDisabled'))['address'] = true;
    }
  }

  },
  /* handler:onChange */
  /* content */
}))

define('./components/reusable/r_a5cc17adcd43a953ff497b8130a1de3b/logic/d2a62d5c13d8fd56e3329c1b00c23cbd/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  async ['onChange'](___arguments) {
    var currentUser, isNotDisabled, menuOptionSelection, selectedItemOrganization;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  selectedItemOrganization = (getObjectProperty(___arguments.context.pageData, 'selectedItemOrganization'));
  currentUser = (getObjectProperty(___arguments.context.pageData, 'currentUser'));
  menuOptionSelection = (getObjectProperty(___arguments.context.pageData, 'menuOptionSelection'));
  (getObjectProperty(___arguments.context.pageData, 'organizationUpdateObject'))['websiteLink'] = ___arguments.value;
  ___arguments.context.pageData['websiteError'] = false;
  if (currentUser) {
    if ((await BackendlessUI.Functions.Custom['fn_a7ad4c1fd5d18ca6bff3eb611aeee2ac'](___arguments.value))) {
      ___arguments.context.pageData['emptyWebsite'] = true;
      ___arguments.context.pageData['disableButton'] = true;
    } else if (menuOptionSelection == 'viewOrganization' && ___arguments.value != (getObjectProperty(currentUser, 'organizationId.websiteLink'))) {
      (getObjectProperty(___arguments.context.pageData, 'isEditOrgButtonDisabled'))['website'] = false;
      ___arguments.context.pageData['emptyName'] = false;
      isNotDisabled = true;
      if (getObjectProperty(___arguments.context.pageData, 'emptyOrgName')) {
        isNotDisabled = false;
      } else if (getObjectProperty(___arguments.context.pageData, 'emptyAddress')) {
        isNotDisabled = false;
      } else if (getObjectProperty(___arguments.context.pageData, 'emptyTaxId')) {
        isNotDisabled = false;
      } else if (getObjectProperty(___arguments.context.pageData, 'emptyBillingName')) {
        isNotDisabled = false;
      } else if (getObjectProperty(___arguments.context.pageData, 'emptyBillingPhone')) {
        isNotDisabled = false;
      } else if (getObjectProperty(___arguments.context.pageData, 'emptyBillingEmail')) {
        isNotDisabled = false;
      }
      if (isNotDisabled) {
        ___arguments.context.pageData['disableButton'] = false;
      }
    } else if (selectedItemOrganization && ___arguments.value != (getObjectProperty(selectedItemOrganization, 'websiteLink'))) {
      (getObjectProperty(___arguments.context.pageData, 'isEditOrgButtonDisabled'))['website'] = false;
      ___arguments.context.pageData['emptyName'] = false;
      isNotDisabled = true;
      if (getObjectProperty(___arguments.context.pageData, 'emptyOrgName')) {
        isNotDisabled = false;
      } else if (getObjectProperty(___arguments.context.pageData, 'emptyAddress')) {
        isNotDisabled = false;
      } else if (getObjectProperty(___arguments.context.pageData, 'emptyTaxId')) {
        isNotDisabled = false;
      } else if (getObjectProperty(___arguments.context.pageData, 'emptyBillingName')) {
        isNotDisabled = false;
      } else if (getObjectProperty(___arguments.context.pageData, 'emptyBillingPhone')) {
        isNotDisabled = false;
      } else if (getObjectProperty(___arguments.context.pageData, 'emptyBillingEmail')) {
        isNotDisabled = false;
      }
      if (isNotDisabled) {
        ___arguments.context.pageData['disableButton'] = false;
      }
    } else {
      (getObjectProperty(___arguments.context.pageData, 'isEditOrgButtonDisabled'))['website'] = true;
    }
  }

  },
  /* handler:onChange */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    var classesList;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  classesList = [];
  if (getObjectProperty(___arguments.context.pageData, 'websiteError')) {
    addItemToList(classesList, 'input_red_border');
  }

  return classesList

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./components/reusable/r_a5cc17adcd43a953ff497b8130a1de3b/logic/059315a5a6848ddcf12ea01c2f7249be/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'websiteError')) {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('059315a5a6848ddcf12ea01c2f7249be', true);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('059315a5a6848ddcf12ea01c2f7249be', false);
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./components/reusable/r_a5cc17adcd43a953ff497b8130a1de3b/logic/b849f68daf65d7f80bb4ab861c32adb9/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  async ['onChange'](___arguments) {
    var currentUser, isNotDisabled, menuOptionSelection, selectedItemOrganization;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  selectedItemOrganization = (getObjectProperty(___arguments.context.pageData, 'selectedItemOrganization'));
  currentUser = (getObjectProperty(___arguments.context.pageData, 'currentUser'));
  menuOptionSelection = (getObjectProperty(___arguments.context.pageData, 'menuOptionSelection'));
  ___arguments.context.pageData['taxIdError'] = false;
  (getObjectProperty(___arguments.context.pageData, 'organizationUpdateObject'))['taxId'] = (await BackendlessUI.Functions.Custom['fn_3c37569726d2e37c1909af054741f424'](___arguments.value, 'create', null));
  if (currentUser) {
    if ((await BackendlessUI.Functions.Custom['fn_a7ad4c1fd5d18ca6bff3eb611aeee2ac'](___arguments.value))) {
      ___arguments.context.pageData['emptyTaxId'] = true;
      ___arguments.context.pageData['disableButton'] = true;
    } else if (menuOptionSelection == 'viewOrganization' && ___arguments.value != (getObjectProperty(currentUser, 'organizationId.taxId'))) {
      (getObjectProperty(___arguments.context.pageData, 'isEditOrgButtonDisabled'))['taxId'] = false;
      ___arguments.context.pageData['emptyTaxId'] = false;
      isNotDisabled = true;
      if (getObjectProperty(___arguments.context.pageData, 'emptyOrgName')) {
        isNotDisabled = false;
      } else if (getObjectProperty(___arguments.context.pageData, 'emptyAddress')) {
        isNotDisabled = false;
      } else if (getObjectProperty(___arguments.context.pageData, 'emptyWebsite')) {
        isNotDisabled = false;
      } else if (getObjectProperty(___arguments.context.pageData, 'emptyBillingName')) {
        isNotDisabled = false;
      } else if (getObjectProperty(___arguments.context.pageData, 'emptyBillingPhone')) {
        isNotDisabled = false;
      } else if (getObjectProperty(___arguments.context.pageData, 'emptyBillingEmail')) {
        isNotDisabled = false;
      }
      if (isNotDisabled) {
        ___arguments.context.pageData['disableButton'] = false;
      }
    } else if (selectedItemOrganization && ___arguments.value != (getObjectProperty(selectedItemOrganization, 'taxId'))) {
      (getObjectProperty(___arguments.context.pageData, 'isEditOrgButtonDisabled'))['taxId'] = false;
      ___arguments.context.pageData['emptyTaxId'] = false;
      isNotDisabled = true;
      if (getObjectProperty(___arguments.context.pageData, 'emptyOrgName')) {
        isNotDisabled = false;
      } else if (getObjectProperty(___arguments.context.pageData, 'emptyAddress')) {
        isNotDisabled = false;
      } else if (getObjectProperty(___arguments.context.pageData, 'emptyWebsite')) {
        isNotDisabled = false;
      } else if (getObjectProperty(___arguments.context.pageData, 'emptyBillingName')) {
        isNotDisabled = false;
      } else if (getObjectProperty(___arguments.context.pageData, 'emptyBillingPhone')) {
        isNotDisabled = false;
      } else if (getObjectProperty(___arguments.context.pageData, 'emptyBillingEmail')) {
        isNotDisabled = false;
      }
      if (isNotDisabled) {
        ___arguments.context.pageData['disableButton'] = false;
      }
    } else {
      (getObjectProperty(___arguments.context.pageData, 'isEditOrgButtonDisabled'))['taxId'] = true;
    }
  }

  },
  /* handler:onChange */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    var classesList;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  classesList = [];
  if (getObjectProperty(___arguments.context.pageData, 'taxIdError')) {
    addItemToList(classesList, 'input_red_border');
  }

  return classesList

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./components/reusable/r_a5cc17adcd43a953ff497b8130a1de3b/logic/e6c33cbbee6818f87184bc074ccc09e7/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  async ['onChange'](___arguments) {
    var currentUser, isNotDisabled, menuOptionSelection, selectedItemOrganization;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  selectedItemOrganization = (getObjectProperty(___arguments.context.pageData, 'selectedItemOrganization'));
  currentUser = (getObjectProperty(___arguments.context.pageData, 'currentUser'));
  menuOptionSelection = (getObjectProperty(___arguments.context.pageData, 'menuOptionSelection'));
  (getObjectProperty(___arguments.context.pageData, 'organizationUpdateObject'))['billingName'] = (await BackendlessUI.Functions.Custom['fn_256db7f8ee15af9d778314d97f563562'](___arguments.value));
  if (currentUser) {
    if ((await BackendlessUI.Functions.Custom['fn_a7ad4c1fd5d18ca6bff3eb611aeee2ac'](___arguments.value))) {
      ___arguments.context.pageData['emptyBillingName'] = true;
      ___arguments.context.pageData['disableButton'] = true;
    } else if (menuOptionSelection == 'viewOrganization' && ___arguments.value != (getObjectProperty(currentUser, 'organizationId.billingName'))) {
      (getObjectProperty(___arguments.context.pageData, 'isEditOrgButtonDisabled'))['billingName'] = false;
      ___arguments.context.pageData['emptyBillingName'] = false;
      isNotDisabled = true;
      if (getObjectProperty(___arguments.context.pageData, 'emptyOrgName')) {
        isNotDisabled = false;
      } else if (getObjectProperty(___arguments.context.pageData, 'emptyAddress')) {
        isNotDisabled = false;
      } else if (getObjectProperty(___arguments.context.pageData, 'emptyTaxId')) {
        isNotDisabled = false;
      } else if (getObjectProperty(___arguments.context.pageData, 'emptyWebsite')) {
        isNotDisabled = false;
      } else if (getObjectProperty(___arguments.context.pageData, 'emptyBillingPhone')) {
        isNotDisabled = false;
      } else if (getObjectProperty(___arguments.context.pageData, 'emptyBillingEmail')) {
        isNotDisabled = false;
      }
      if (isNotDisabled) {
        ___arguments.context.pageData['disableButton'] = false;
      }
    } else if (selectedItemOrganization && ___arguments.value != (getObjectProperty(selectedItemOrganization, 'billingName'))) {
      (getObjectProperty(___arguments.context.pageData, 'isEditOrgButtonDisabled'))['billingName'] = false;
      ___arguments.context.pageData['emptyBillingName'] = false;
      isNotDisabled = true;
      if (getObjectProperty(___arguments.context.pageData, 'emptyOrgName')) {
        isNotDisabled = false;
      } else if (getObjectProperty(___arguments.context.pageData, 'emptyAddress')) {
        isNotDisabled = false;
      } else if (getObjectProperty(___arguments.context.pageData, 'emptyTaxId')) {
        isNotDisabled = false;
      } else if (getObjectProperty(___arguments.context.pageData, 'emptyWebsite')) {
        isNotDisabled = false;
      } else if (getObjectProperty(___arguments.context.pageData, 'emptyBillingPhone')) {
        isNotDisabled = false;
      } else if (getObjectProperty(___arguments.context.pageData, 'emptyBillingEmail')) {
        isNotDisabled = false;
      }
      if (isNotDisabled) {
        ___arguments.context.pageData['disableButton'] = false;
      }
    } else {
      (getObjectProperty(___arguments.context.pageData, 'isEditOrgButtonDisabled'))['billingName'] = true;
    }
  }

  },
  /* handler:onChange */
  /* content */
}))

define('./components/reusable/r_a5cc17adcd43a953ff497b8130a1de3b/logic/a1499419c93f8b141ec80a93d22462cc/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  async ['onChange'](___arguments) {
    var isNotDisabled, selectedItemOrganization, currentUser, menuOptionSelection;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function subsequenceFromStartLast(sequence, at1) {
  var start = at1;
  var end = sequence.length - 1 + 1;
  return sequence.slice(start, end);
}


  selectedItemOrganization = (getObjectProperty(___arguments.context.pageData, 'selectedItemOrganization'));
  currentUser = (getObjectProperty(___arguments.context.pageData, 'currentUser'));
  menuOptionSelection = (getObjectProperty(___arguments.context.pageData, 'menuOptionSelection'));
  (getObjectProperty(___arguments.context.pageData, 'organizationUpdateObject'))['billingPhone'] = (await BackendlessUI.Functions.Custom['fn_f282814277b3c0aa13f33d3b2cda111b'](___arguments.value, 'create'));
  ___arguments.context.pageData['billingPhoneError'] = false;
  if (currentUser) {
    if ((await BackendlessUI.Functions.Custom['fn_a7ad4c1fd5d18ca6bff3eb611aeee2ac'](___arguments.value))) {
      ___arguments.context.pageData['billingPhone'] = true;
      ___arguments.context.pageData['disableButton'] = true;
    } else if (menuOptionSelection == 'viewOrganization' && (getObjectProperty(___arguments.context.pageData, 'selectedItemOrgBillingPhone')) != subsequenceFromStartLast((getObjectProperty(currentUser, 'organizationId.billingPhone')), 2)) {
      (getObjectProperty(___arguments.context.pageData, 'isEditOrgButtonDisabled'))['billingPhone'] = false;
      ___arguments.context.pageData['emptyBillingPhone'] = false;
      isNotDisabled = true;
      if (getObjectProperty(___arguments.context.pageData, 'emptyOrgName')) {
        isNotDisabled = false;
      } else if (getObjectProperty(___arguments.context.pageData, 'emptyAddress')) {
        isNotDisabled = false;
      } else if (getObjectProperty(___arguments.context.pageData, 'emptyTaxId')) {
        isNotDisabled = false;
      } else if (getObjectProperty(___arguments.context.pageData, 'emptyWebsite')) {
        isNotDisabled = false;
      } else if (getObjectProperty(___arguments.context.pageData, 'emptyBillingName')) {
        isNotDisabled = false;
      } else if (getObjectProperty(___arguments.context.pageData, 'emptyBillingEmail')) {
        isNotDisabled = false;
      }
      if (isNotDisabled) {
        ___arguments.context.pageData['disableButton'] = false;
      }
    } else if (selectedItemOrganization && (await BackendlessUI.Functions.Custom['fn_f282814277b3c0aa13f33d3b2cda111b']((getObjectProperty(___arguments.context.pageData, 'organizationUpdateObject.billingPhone')), 'remove')) != subsequenceFromStartLast((getObjectProperty(selectedItemOrganization, 'billingPhone')), 2)) {
      (getObjectProperty(___arguments.context.pageData, 'isEditOrgButtonDisabled'))['billingPhone'] = false;
      ___arguments.context.pageData['emptyBillingPhone'] = false;
      isNotDisabled = true;
      if (getObjectProperty(___arguments.context.pageData, 'emptyOrgName')) {
        isNotDisabled = false;
      } else if (getObjectProperty(___arguments.context.pageData, 'emptyAddress')) {
        isNotDisabled = false;
      } else if (getObjectProperty(___arguments.context.pageData, 'emptyTaxId')) {
        isNotDisabled = false;
      } else if (getObjectProperty(___arguments.context.pageData, 'emptyWebsite')) {
        isNotDisabled = false;
      } else if (getObjectProperty(___arguments.context.pageData, 'emptyBillingName')) {
        isNotDisabled = false;
      } else if (getObjectProperty(___arguments.context.pageData, 'emptyBillingEmail')) {
        isNotDisabled = false;
      }
      if (isNotDisabled) {
        ___arguments.context.pageData['disableButton'] = false;
      }
    } else {
      (getObjectProperty(___arguments.context.pageData, 'isEditOrgButtonDisabled'))['billingPhone'] = true;
    }
  }

  },
  /* handler:onChange */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    var classesList;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  classesList = [];
  if (getObjectProperty(___arguments.context.pageData, 'billingPhoneError')) {
    addItemToList(classesList, 'input_red_border');
  }

  return classesList

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./components/reusable/r_a5cc17adcd43a953ff497b8130a1de3b/logic/afe250713ffff9e27b29d24b96558a3b/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  async ['onChange'](___arguments) {
    var currentUser, isNotDisabled, menuOptionSelection, selectedItemOrganization;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  selectedItemOrganization = (getObjectProperty(___arguments.context.pageData, 'selectedItemOrganization'));
  currentUser = (getObjectProperty(___arguments.context.pageData, 'currentUser'));
  menuOptionSelection = (getObjectProperty(___arguments.context.pageData, 'menuOptionSelection'));
  (getObjectProperty(___arguments.context.pageData, 'organizationUpdateObject'))['billingEmail'] = ___arguments.value;
  ___arguments.context.pageData['billingEmailError'] = false;
  if (currentUser) {
    if ((await BackendlessUI.Functions.Custom['fn_a7ad4c1fd5d18ca6bff3eb611aeee2ac'](___arguments.value))) {
      ___arguments.context.pageData['emptyBillingEmail'] = true;
      ___arguments.context.pageData['disableButton'] = true;
    } else if (menuOptionSelection == 'viewOrganization' && ___arguments.value != (getObjectProperty(currentUser, 'organizationId.billingEmail'))) {
      (getObjectProperty(___arguments.context.pageData, 'isEditOrgButtonDisabled'))['billingEmail'] = false;
      ___arguments.context.pageData['emptyBillingEmail'] = false;
      isNotDisabled = true;
      if (getObjectProperty(___arguments.context.pageData, 'emptyOrgName')) {
        isNotDisabled = false;
      } else if (getObjectProperty(___arguments.context.pageData, 'emptyAddress')) {
        isNotDisabled = false;
      } else if (getObjectProperty(___arguments.context.pageData, 'emptyTaxId')) {
        isNotDisabled = false;
      } else if (getObjectProperty(___arguments.context.pageData, 'emptyWebsite')) {
        isNotDisabled = false;
      } else if (getObjectProperty(___arguments.context.pageData, 'emptyBillingName')) {
        isNotDisabled = false;
      } else if (getObjectProperty(___arguments.context.pageData, 'emptyBillingPhone')) {
        isNotDisabled = false;
      }
      if (isNotDisabled) {
        ___arguments.context.pageData['disableButton'] = false;
      }
    } else if (selectedItemOrganization && ___arguments.value != (getObjectProperty(selectedItemOrganization, 'billingEmail'))) {
      (getObjectProperty(___arguments.context.pageData, 'isEditOrgButtonDisabled'))['billingEmail'] = false;
      ___arguments.context.pageData['emptyBillingEmail'] = false;
      isNotDisabled = true;
      if (getObjectProperty(___arguments.context.pageData, 'emptyOrgName')) {
        isNotDisabled = false;
      } else if (getObjectProperty(___arguments.context.pageData, 'emptyAddress')) {
        isNotDisabled = false;
      } else if (getObjectProperty(___arguments.context.pageData, 'emptyTaxId')) {
        isNotDisabled = false;
      } else if (getObjectProperty(___arguments.context.pageData, 'emptyWebsite')) {
        isNotDisabled = false;
      } else if (getObjectProperty(___arguments.context.pageData, 'emptyBillingName')) {
        isNotDisabled = false;
      } else if (getObjectProperty(___arguments.context.pageData, 'emptyBillingPhone')) {
        isNotDisabled = false;
      }
      if (isNotDisabled) {
        ___arguments.context.pageData['disableButton'] = false;
      }
    } else {
      (getObjectProperty(___arguments.context.pageData, 'isEditOrgButtonDisabled'))['billingEmail'] = true;
    }
  }

  },
  /* handler:onChange */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    var classesList;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  classesList = [];
  if (getObjectProperty(___arguments.context.pageData, 'billingEmailError')) {
    addItemToList(classesList, 'input_red_border');
  }

  return classesList

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./components/reusable/r_a5cc17adcd43a953ff497b8130a1de3b/logic/eb0a559ee59128c3a63674ef13c5ac87/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'billingEmailError')) {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('eb0a559ee59128c3a63674ef13c5ac87', true);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('eb0a559ee59128c3a63674ef13c5ac87', false);
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./components/reusable/r_a5cc17adcd43a953ff497b8130a1de3b/logic/467478e2e9662ea265ce9358c5858d41/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var duplicateOrganization, hasNoErrors, organizationUpdateObject;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  organizationUpdateObject = (getObjectProperty(___arguments.context.pageData, 'organizationUpdateObject'));
  duplicateOrganization = (await Backendless.Data.of('Organizations').find(Backendless.DataQueryBuilder.create().setWhereClause((['orgName = \'',(getObjectProperty(___arguments.context.pageData, 'selectedItemOrgName')),'\''].join(''))).setPageSize(10)));
  hasNoErrors = true;
  if ((getObjectProperty(organizationUpdateObject, 'billingPhone')) && !(await BackendlessUI.Functions.Custom['fn_6d4580c12cbae4a32f38d054d2dc9e71']((await BackendlessUI.Functions.Custom['fn_f282814277b3c0aa13f33d3b2cda111b']((getObjectProperty(organizationUpdateObject, 'billingPhone')), 'remove'))))) {
    ___arguments.context.pageData['billingPhoneError'] = true;
    hasNoErrors = false;
  }
  if ((getObjectProperty(organizationUpdateObject, 'billingEmail')) && !(await BackendlessUI.Functions.Custom['fn_36435bc8e7f437df45a6682ebdbf7388']((getObjectProperty(organizationUpdateObject, 'billingEmail'))))) {
    ___arguments.context.pageData['billingEmailError'] = true;
    hasNoErrors = false;
  }
  if ((getObjectProperty(organizationUpdateObject, 'orgName')) != (getObjectProperty(___arguments.context.pageData, 'currentUser.organizationId.orgName')) && duplicateOrganization.length > 0) {
    ___arguments.context.pageData['orgNameError'] = true;
    hasNoErrors = false;
  }
  if ((getObjectProperty(organizationUpdateObject, 'taxId')) && !(await BackendlessUI.Functions.Custom['fn_a3492c0a3c3e3c5e02e3736d07768491']((getObjectProperty(organizationUpdateObject, 'taxId'))))) {
    ___arguments.context.pageData['taxIdError'] = true;
    hasNoErrors = false;
  }
  if ((getObjectProperty(organizationUpdateObject, 'websiteLink')) && !(await BackendlessUI.Functions.Custom['fn_4d0b6f63e09985a12656f99b68cbc84d']((getObjectProperty(organizationUpdateObject, 'websiteLink'))))) {
    ___arguments.context.pageData['websiteError'] = true;
    hasNoErrors = false;
  }
  if ((getObjectProperty(organizationUpdateObject, 'zipCode')) && !(await BackendlessUI.Functions.Custom['fn_c16fa4da6fdc2a0ab06c37eb5691c2e2']((getObjectProperty(organizationUpdateObject, 'zipCode'))))) {
    ___arguments.context.pageData['zipCodeError'] = true;
    hasNoErrors = false;
  }
  if (hasNoErrors) {
    ___arguments.context.pageData['isAgreeingToUpdate'] = false;
    ___arguments.context.pageData['updateConfirmPassword'] = undefined;
    ___arguments.context.pageData['isUpdateUserModalOpen'] = true;
  }

  },
  /* handler:onClick */
  /* handler:onDisabledStateAssignment */
  ['onDisabledStateAssignment'](___arguments) {
    var isDisabled, selectedItem, isDisabledArray, isEditOrgButtonDisabled;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  isEditOrgButtonDisabled = (getObjectProperty(___arguments.context.pageData, 'isEditOrgButtonDisabled'));
  isDisabled = true;
  if (isEditOrgButtonDisabled) {
    isDisabledArray = [(getObjectProperty(isEditOrgButtonDisabled, 'orgName')), (getObjectProperty(isEditOrgButtonDisabled, 'website')), (getObjectProperty(isEditOrgButtonDisabled, 'address')), (getObjectProperty(isEditOrgButtonDisabled, 'city')), (getObjectProperty(isEditOrgButtonDisabled, 'state')), (getObjectProperty(isEditOrgButtonDisabled, 'zipCode')), (getObjectProperty(isEditOrgButtonDisabled, 'taxId')), (getObjectProperty(isEditOrgButtonDisabled, 'billingPhone')), (getObjectProperty(isEditOrgButtonDisabled, 'billingName')), (getObjectProperty(isEditOrgButtonDisabled, 'billingEmail')), (getObjectProperty(isEditOrgButtonDisabled, 'selectedImage'))];
    for (var selectedItem_index in isDisabledArray) {
      selectedItem = isDisabledArray[selectedItem_index];
      if (!selectedItem) {
        isDisabled = false;
      }
    }
    if (getObjectProperty(___arguments.context.pageData, 'disableButton')) {
      ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('467478e2e9662ea265ce9358c5858d41'))['background-color'] = 'rgb(56,113,255,0.38)';
      return true;
    }
    if (isDisabled) {
      ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('467478e2e9662ea265ce9358c5858d41'))['background-color'] = 'rgb(56,113,255,0.38)';
      return true;
    } else {
      ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('467478e2e9662ea265ce9358c5858d41'))['background-color'] = '#3871FF';
      return false;
    }
  }

  },
  /* handler:onDisabledStateAssignment */
  /* content */
}))

define('./components/reusable/r_a5cc17adcd43a953ff497b8130a1de3b/logic/6305de57349bc487d46bd31e83166789/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      ___arguments.context.pageData['isSelectedItemModalOpen'] = false;

  },
  /* handler:onClick */
  /* content */
}))

define('./components/reusable/r_a5cc17adcd43a953ff497b8130a1de3b/logic/8c832317d2d10d4d69e8d288f0887c66/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    var navbarSelection;


  ___arguments.context.pageData['isSelectedItemModalOpen'] = false;

  },
  /* handler:onClick */
  /* content */
}))

define('./components/reusable/r_a5cc17adcd43a953ff497b8130a1de3b/logic/4d3285b60f2badebd9c2277100645731/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  async ['onChange'](___arguments) {
    var currentUser, isNotDisabled, menuOptionSelection, selectedItemOrganization;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  selectedItemOrganization = (getObjectProperty(___arguments.context.pageData, 'selectedItemOrganization'));
  currentUser = (getObjectProperty(___arguments.context.pageData, 'currentUser'));
  menuOptionSelection = (getObjectProperty(___arguments.context.pageData, 'menuOptionSelection'));
  (getObjectProperty(___arguments.context.pageData, 'organizationUpdateObject'))['zipCode'] = (await BackendlessUI.Functions.Custom['fn_2dc3266507a474ba2f5a291d2361a812'](___arguments.value));
  if (currentUser) {
    if ((await BackendlessUI.Functions.Custom['fn_a7ad4c1fd5d18ca6bff3eb611aeee2ac'](___arguments.value))) {
      ___arguments.context.pageData['emptyZipCode'] = true;
      ___arguments.context.pageData['disableButton'] = true;
    } else if (menuOptionSelection == 'viewOrganization' && ___arguments.value != (getObjectProperty(currentUser, 'organizationId.zipCode'))) {
      (getObjectProperty(___arguments.context.pageData, 'isEditOrgButtonDisabled'))['zipCode'] = false;
      ___arguments.context.pageData['emptyZipCode'] = false;
      isNotDisabled = true;
      if (getObjectProperty(___arguments.context.pageData, 'emptyOrgName')) {
        isNotDisabled = false;
      } else if (getObjectProperty(___arguments.context.pageData, 'emptyWebsite')) {
        isNotDisabled = false;
      } else if (getObjectProperty(___arguments.context.pageData, 'emptyTaxId')) {
        isNotDisabled = false;
      } else if (getObjectProperty(___arguments.context.pageData, 'emptyBillingName')) {
        isNotDisabled = false;
      } else if (getObjectProperty(___arguments.context.pageData, 'emptyBillingPhone')) {
        isNotDisabled = false;
      } else if (getObjectProperty(___arguments.context.pageData, 'emptyBillingEmail')) {
        isNotDisabled = false;
      } else if (getObjectProperty(___arguments.context.pageData, 'emptyState')) {
        isNotDisabled = false;
      } else if (getObjectProperty(___arguments.context.pageData, 'emptyCity')) {
        isNotDisabled = false;
      } else if (getObjectProperty(___arguments.context.pageData, 'emptyAddress')) {
        isNotDisabled = false;
      }
      if (isNotDisabled) {
        ___arguments.context.pageData['disableButton'] = false;
      }
    } else if (selectedItemOrganization && ___arguments.value != (getObjectProperty(selectedItemOrganization, 'zipCode'))) {
      (getObjectProperty(___arguments.context.pageData, 'isEditOrgButtonDisabled'))['zipCode'] = false;
      ___arguments.context.pageData['emptyZipCode'] = false;
      isNotDisabled = true;
      if (getObjectProperty(___arguments.context.pageData, 'emptyOrgName')) {
        isNotDisabled = false;
      } else if (getObjectProperty(___arguments.context.pageData, 'emptyWebsite')) {
        isNotDisabled = false;
      } else if (getObjectProperty(___arguments.context.pageData, 'emptyTaxId')) {
        isNotDisabled = false;
      } else if (getObjectProperty(___arguments.context.pageData, 'emptyBillingName')) {
        isNotDisabled = false;
      } else if (getObjectProperty(___arguments.context.pageData, 'emptyBillingPhone')) {
        isNotDisabled = false;
      } else if (getObjectProperty(___arguments.context.pageData, 'emptyBillingEmail')) {
        isNotDisabled = false;
      } else if (getObjectProperty(___arguments.context.pageData, 'emptyState')) {
        isNotDisabled = false;
      } else if (getObjectProperty(___arguments.context.pageData, 'emptyCity')) {
        isNotDisabled = false;
      } else if (getObjectProperty(___arguments.context.pageData, 'emptyAddress')) {
        isNotDisabled = false;
      }
      if (isNotDisabled) {
        ___arguments.context.pageData['disableButton'] = false;
      }
    } else {
      (getObjectProperty(___arguments.context.pageData, 'isEditOrgButtonDisabled'))['zipCode'] = true;
    }
  }

  },
  /* handler:onChange */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    var classesList;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  classesList = [];
  if (getObjectProperty(___arguments.context.pageData, 'zipCodeError')) {
    addItemToList(classesList, 'input_red_border');
  }

  return classesList

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./components/reusable/r_a5cc17adcd43a953ff497b8130a1de3b/logic/689890b9d7222bb59577719d61184358/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'zipCodeError')) {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('689890b9d7222bb59577719d61184358', true);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('689890b9d7222bb59577719d61184358', false);
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./components/reusable/r_a5cc17adcd43a953ff497b8130a1de3b/logic/ac24c583d4bf974c6cd7912c6f0301b5/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  async ['onChange'](___arguments) {
    var currentUser, isNotDisabled, menuOptionSelection, selectedItemOrganization;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  selectedItemOrganization = (getObjectProperty(___arguments.context.pageData, 'selectedItemOrganization'));
  currentUser = (getObjectProperty(___arguments.context.pageData, 'currentUser'));
  menuOptionSelection = (getObjectProperty(___arguments.context.pageData, 'menuOptionSelection'));
  (getObjectProperty(___arguments.context.pageData, 'organizationUpdateObject'))['city'] = ___arguments.value;
  if (currentUser) {
    if ((await BackendlessUI.Functions.Custom['fn_a7ad4c1fd5d18ca6bff3eb611aeee2ac'](___arguments.value))) {
      ___arguments.context.pageData['emptyCity'] = true;
      ___arguments.context.pageData['disableButton'] = true;
    } else if (menuOptionSelection == 'viewOrganization' && ___arguments.value != (getObjectProperty(currentUser, 'organizationId.city'))) {
      (getObjectProperty(___arguments.context.pageData, 'isEditOrgButtonDisabled'))['city'] = false;
      ___arguments.context.pageData['emptyCity'] = false;
      isNotDisabled = true;
      if (getObjectProperty(___arguments.context.pageData, 'emptyOrgName')) {
        isNotDisabled = false;
      } else if (getObjectProperty(___arguments.context.pageData, 'emptyWebsite')) {
        isNotDisabled = false;
      } else if (getObjectProperty(___arguments.context.pageData, 'emptyTaxId')) {
        isNotDisabled = false;
      } else if (getObjectProperty(___arguments.context.pageData, 'emptyBillingName')) {
        isNotDisabled = false;
      } else if (getObjectProperty(___arguments.context.pageData, 'emptyBillingPhone')) {
        isNotDisabled = false;
      } else if (getObjectProperty(___arguments.context.pageData, 'emptyBillingEmail')) {
        isNotDisabled = false;
      } else if (getObjectProperty(___arguments.context.pageData, 'emptyState')) {
        isNotDisabled = false;
      } else if (getObjectProperty(___arguments.context.pageData, 'emptyZipCode')) {
        isNotDisabled = false;
      } else if (getObjectProperty(___arguments.context.pageData, 'emptyAddress')) {
        isNotDisabled = false;
      }
      if (isNotDisabled) {
        ___arguments.context.pageData['disableButton'] = false;
      }
    } else if (selectedItemOrganization && ___arguments.value != (getObjectProperty(selectedItemOrganization, 'city'))) {
      (getObjectProperty(___arguments.context.pageData, 'isEditOrgButtonDisabled'))['city'] = false;
      ___arguments.context.pageData['emptyCity'] = false;
      isNotDisabled = true;
      if (getObjectProperty(___arguments.context.pageData, 'emptyOrgName')) {
        isNotDisabled = false;
      } else if (getObjectProperty(___arguments.context.pageData, 'emptyWebsite')) {
        isNotDisabled = false;
      } else if (getObjectProperty(___arguments.context.pageData, 'emptyTaxId')) {
        isNotDisabled = false;
      } else if (getObjectProperty(___arguments.context.pageData, 'emptyBillingName')) {
        isNotDisabled = false;
      } else if (getObjectProperty(___arguments.context.pageData, 'emptyBillingPhone')) {
        isNotDisabled = false;
      } else if (getObjectProperty(___arguments.context.pageData, 'emptyBillingEmail')) {
        isNotDisabled = false;
      } else if (getObjectProperty(___arguments.context.pageData, 'emptyState')) {
        isNotDisabled = false;
      } else if (getObjectProperty(___arguments.context.pageData, 'emptyZipCode')) {
        isNotDisabled = false;
      } else if (getObjectProperty(___arguments.context.pageData, 'emptyAddress')) {
        isNotDisabled = false;
      }
      if (isNotDisabled) {
        ___arguments.context.pageData['disableButton'] = false;
      }
    } else {
      (getObjectProperty(___arguments.context.pageData, 'isEditOrgButtonDisabled'))['city'] = true;
    }
  }

  },
  /* handler:onChange */
  /* content */
}))

define('./components/reusable/r_a5cc17adcd43a953ff497b8130a1de3b/logic/07058b5df43c69258fa2ecb64e023e34/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  ['onChange'](___arguments) {
    var selectedItemOrganization, currentUser, menuOptionSelection;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  selectedItemOrganization = (getObjectProperty(___arguments.context.pageData, 'selectedItemOrganization'));
  currentUser = (getObjectProperty(___arguments.context.pageData, 'currentUser'));
  menuOptionSelection = (getObjectProperty(___arguments.context.pageData, 'menuOptionSelection'));
  (getObjectProperty(___arguments.context.pageData, 'organizationUpdateObject'))['state'] = ___arguments.value;
  if (currentUser) {
    if (menuOptionSelection == 'viewOrganization') {
      if (___arguments.value != (getObjectProperty(currentUser, 'organizationId.state'))) {
        (getObjectProperty(___arguments.context.pageData, 'isEditOrgButtonDisabled'))['state'] = false;
      } else {
        (getObjectProperty(___arguments.context.pageData, 'isEditOrgButtonDisabled'))['state'] = true;
      }
    } else if (selectedItemOrganization) {
      if (___arguments.value != (getObjectProperty(selectedItemOrganization, 'state'))) {
        (getObjectProperty(___arguments.context.pageData, 'isEditOrgButtonDisabled'))['state'] = false;
      } else {
        (getObjectProperty(___arguments.context.pageData, 'isEditOrgButtonDisabled'))['state'] = true;
      }
    }
  }

  },
  /* handler:onChange */
  /* content */
}))

define('./components/reusable/r_a5cc17adcd43a953ff497b8130a1de3b/logic/8889be342f1394ad5d780509723a61ed/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      ___arguments.context.pageData['isSelectedItemModalOpen'] = false;
  ___arguments.context.pageData['refreshSelectedItem'] = true;

  },
  /* handler:onClick */
  /* content */
}))

define('./components/reusable/r_a5cc17adcd43a953ff497b8130a1de3b/logic/220711eb5af25b6affaff763e09635f9/bundle.js', [], () => ({
  /* content */
  /* handler:onSelectFiles */
  ['onSelectFiles'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  ___arguments.context.pageData['selectedItemOrganizationImageFile'] = ___arguments.selectedFiles;
  (getObjectProperty(___arguments.context.pageData, 'isEditOrgButtonDisabled'))['selectedImage'] = false;

  },
  /* handler:onSelectFiles */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'selectedItemOrganizationImageFile')) {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('220711eb5af25b6affaff763e09635f9', false);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('220711eb5af25b6affaff763e09635f9', true);
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./components/reusable/r_a5cc17adcd43a953ff497b8130a1de3b/logic/6fa1b5fc049f608fc2683022570cda5c/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  async ['onStyleAssignment'](___arguments) {
    var selectedItem, currentUser, menuOptionSelection, selectedImage, isSelectedItemModalOpen;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function readBrowserFileAs(file, type) {
   const readers = { base64: "readAsDataURL", utf8: "readAsText", bytes: "readAsArrayBuffer" }

   return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader[readers[type]](file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = error => reject(error)
  })
}


  isSelectedItemModalOpen = (getObjectProperty(___arguments.context.pageData, 'isSelectedItemModalOpen'));
  menuOptionSelection = (getObjectProperty(___arguments.context.pageData, 'menuOptionSelection'));
  currentUser = (getObjectProperty(___arguments.context.pageData, 'currentUser'));
  selectedImage = (getObjectProperty(___arguments.context.pageData, 'selectedImage'));
  if (selectedImage) {
    ___arguments.context.pageData['selectedItemImage'] = (await readBrowserFileAs(((getObjectProperty(___arguments.context.pageData, 'selectedImage'))[0]), 'base64'));
  } else {
    if (menuOptionSelection == 'viewProfile') {
      if (currentUser) {
        if (getObjectProperty(currentUser, 'profilePicture')) {
          ___arguments.context.pageData['selectedItemImage'] = (getObjectProperty(currentUser, 'profilePicture'));
        } else {
          ___arguments.context.pageData['selectedItemImage'] = 'https://app.aquax.co/api/files/cyberdive_photos/cd-icon-default-photo.svg';
        }
      }
    } else {
      if ((getObjectProperty(___arguments.context.pageData, 'selectedItemArrayUser')) && (getObjectProperty(___arguments.context.pageData, 'selectedItemArrayUser')).length >= 1) {
        selectedItem = (getObjectProperty(___arguments.context.pageData, 'selectedItemArrayUser'))[0];
        if (getObjectProperty(selectedItem, 'profilePicture')) {
          ___arguments.context.pageData['selectedItemImage'] = (getObjectProperty(selectedItem, 'profilePicture'));
        } else {
          ___arguments.context.pageData['selectedItemImage'] = 'https://app.aquax.co/api/files/cyberdive_photos/cd-icon-default-photo.svg';
        }
      }
    }
  }

  },
  /* handler:onStyleAssignment */
  /* handler:onSourceUrlAssignment */
  ['onSourceUrlAssignment'](___arguments) {
    var currentUser;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (getObjectProperty(___arguments.context.pageData, 'selectedItemImage'))

  },
  /* handler:onSourceUrlAssignment */
  /* content */
}))

define('./components/reusable/r_a5cc17adcd43a953ff497b8130a1de3b/logic/c0f19a28b688bf0f9a6ee31f3cc59a34/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'selectedImage')) {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('c0f19a28b688bf0f9a6ee31f3cc59a34', false);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('c0f19a28b688bf0f9a6ee31f3cc59a34', true);
  }

  },
  /* handler:onStyleAssignment */
  /* handler:onSelectFiles */
  ['onSelectFiles'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  ___arguments.context.pageData['selectedImage'] = ___arguments.selectedFiles;
  (getObjectProperty(___arguments.context.pageData, 'isUserDetailsButtonDisabled'))['selectedImage'] = false;

  },
  /* handler:onSelectFiles */
  /* content */
}))

define('./components/reusable/r_a5cc17adcd43a953ff497b8130a1de3b/logic/08cc9e3a9ccba5a90ddb870a9f57eb26/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'selectedImage')) {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('08cc9e3a9ccba5a90ddb870a9f57eb26', true);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('08cc9e3a9ccba5a90ddb870a9f57eb26', false);
  }

  },
  /* handler:onStyleAssignment */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  ___arguments.context.pageData['selectedImage'] = undefined;
  (getObjectProperty(___arguments.context.pageData, 'isUserDetailsButtonDisabled'))['selectedImage'] = true;

  },
  /* handler:onClick */
  /* content */
}))

define('./components/reusable/r_a5cc17adcd43a953ff497b8130a1de3b/logic/202f2c63171a83fb807feebfc5315c12/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'menuOptionSelection')) {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('202f2c63171a83fb807feebfc5315c12', false);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('202f2c63171a83fb807feebfc5315c12', true);
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./components/reusable/r_a5cc17adcd43a953ff497b8130a1de3b/logic/29a11ad21d5e1aa58aa438c3a96443b6/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'isUpdatingResident')) && (getObjectProperty(___arguments.context.pageData, 'updateResidentStep')) == 'pillar') {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('29a11ad21d5e1aa58aa438c3a96443b6', true);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('29a11ad21d5e1aa58aa438c3a96443b6', false);
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./components/reusable/r_a5cc17adcd43a953ff497b8130a1de3b/logic/5efd716566d786bc4a96e2baa3797648/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'isUpdatingResident')) && (getObjectProperty(___arguments.context.pageData, 'updateResidentStep')) == 'preview') {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('5efd716566d786bc4a96e2baa3797648', true);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('5efd716566d786bc4a96e2baa3797648', false);
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./components/reusable/r_a5cc17adcd43a953ff497b8130a1de3b/logic/f076b0657d6e5832157e4639f2f4d70d/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      ___arguments.context.pageData['updateResidentStep'] = 'preview';
  (function (component) { component.el.scrollIntoView() })(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('38622c8308061e08e949ba98e80450c7')));

  },
  /* handler:onClick */
  /* content */
}))

define('./components/reusable/r_a5cc17adcd43a953ff497b8130a1de3b/logic/fd51361915698f8dd4643fe4a7df1a34/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      ___arguments.context.pageData['isSelectedItemModalOpen'] = false;
  ___arguments.context.pageData['isUpdatingResident'] = false;
  (function (component) { component.el.scrollIntoView() })(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('38622c8308061e08e949ba98e80450c7')));

  },
  /* handler:onClick */
  /* content */
}))

define('./components/reusable/r_a5cc17adcd43a953ff497b8130a1de3b/logic/e189b0f3e64cc4d6219a0f963ab47355/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      ___arguments.context.pageData['updateResidentStep'] = 'basic';

  },
  /* handler:onClick */
  /* content */
}))

define('./components/reusable/r_a5cc17adcd43a953ff497b8130a1de3b/logic/bcf424852bd89c92e5cfdd2e049ce5fe/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      ___arguments.context.pageData['updateResidentStep'] = 'pillar';

  },
  /* handler:onClick */
  /* content */
}))

define('./components/reusable/r_a5cc17adcd43a953ff497b8130a1de3b/logic/61db908421a61a6d11bb707f00619195/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'isUpdatingResident')) {
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('61db908421a61a6d11bb707f00619195'))['margin-top'] = '70px';
  } else {
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('61db908421a61a6d11bb707f00619195'))['margin-top'] = '45px';
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./components/reusable/r_a5cc17adcd43a953ff497b8130a1de3b/logic/bdc3862e0587a143b26e9f61c335685c/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    var classes, residentDetailsItem;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  classes = [];
  residentDetailsItem = (getObjectProperty(___arguments.context.pageData, 'residentDetailsItem'));
  if (residentDetailsItem) {
    if ((getObjectProperty(___arguments.context.pageData, 'selectedItemResidentPillar')) == '1') {
      ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('bdc3862e0587a143b26e9f61c335685c'))['margin-left'] = '0px';
    } else if ((getObjectProperty(residentDetailsItem, 'pillar')) == '1' && !(getObjectProperty(___arguments.context.pageData, 'selectedItemResidentPillar'))) {
      ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('bdc3862e0587a143b26e9f61c335685c'))['margin-left'] = '0px';
    } else {
      ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('bdc3862e0587a143b26e9f61c335685c'))['margin-left'] = '8px';
      classes = ['small_pillar_stepper'];
    }
  }

  return classes

  },
  /* handler:onClassListAssignment */
  /* handler:onClick */
  ['onClick'](___arguments) {
      ___arguments.context.pageData['selectedItemResidentPillar'] = '1';

  },
  /* handler:onClick */
  /* content */
}))

define('./components/reusable/r_a5cc17adcd43a953ff497b8130a1de3b/logic/b0b65b4e3cb2405ef7d4a58df056dfa7/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    var classes, residentDetailsItem;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  classes = [];
  residentDetailsItem = (getObjectProperty(___arguments.context.pageData, 'residentDetailsItem'));
  if (residentDetailsItem) {
    if ((getObjectProperty(___arguments.context.pageData, 'selectedItemResidentPillar')) == '2') {
      ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('b0b65b4e3cb2405ef7d4a58df056dfa7'))['margin-left'] = '0px';
    } else if ((getObjectProperty(residentDetailsItem, 'pillar')) == '2' && !(getObjectProperty(___arguments.context.pageData, 'selectedItemResidentPillar'))) {
      ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('b0b65b4e3cb2405ef7d4a58df056dfa7'))['margin-left'] = '0px';
    } else {
      ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('b0b65b4e3cb2405ef7d4a58df056dfa7'))['margin-left'] = '8px';
      classes = ['small_pillar_stepper'];
    }
  }

  return classes

  },
  /* handler:onClassListAssignment */
  /* handler:onClick */
  ['onClick'](___arguments) {
      ___arguments.context.pageData['selectedItemResidentPillar'] = '2';

  },
  /* handler:onClick */
  /* content */
}))

define('./components/reusable/r_a5cc17adcd43a953ff497b8130a1de3b/logic/d39a40c1d87fb5963a47efc8ed67d805/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    var classes, residentDetailsItem;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  classes = [];
  residentDetailsItem = (getObjectProperty(___arguments.context.pageData, 'residentDetailsItem'));
  if (residentDetailsItem) {
    if ((getObjectProperty(___arguments.context.pageData, 'selectedItemResidentPillar')) == '3') {
      ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('d39a40c1d87fb5963a47efc8ed67d805'))['margin-left'] = '0px';
    } else if ((getObjectProperty(residentDetailsItem, 'pillar')) == '3' && !(getObjectProperty(___arguments.context.pageData, 'selectedItemResidentPillar'))) {
      ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('d39a40c1d87fb5963a47efc8ed67d805'))['margin-left'] = '0px';
    } else {
      ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('d39a40c1d87fb5963a47efc8ed67d805'))['margin-left'] = '8px';
      classes = ['small_pillar_stepper'];
    }
  }

  return classes

  },
  /* handler:onClassListAssignment */
  /* handler:onClick */
  ['onClick'](___arguments) {
      ___arguments.context.pageData['selectedItemResidentPillar'] = '3';

  },
  /* handler:onClick */
  /* content */
}))

define('./components/reusable/r_a5cc17adcd43a953ff497b8130a1de3b/logic/a92398c5044190cd5395285c1c14f00e/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    var classes, residentDetailsItem;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  classes = [];
  residentDetailsItem = (getObjectProperty(___arguments.context.pageData, 'residentDetailsItem'));
  if (residentDetailsItem) {
    if ((getObjectProperty(___arguments.context.pageData, 'selectedItemResidentPillar')) == '4') {
      ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('a92398c5044190cd5395285c1c14f00e'))['margin-left'] = '0px';
    } else if ((getObjectProperty(residentDetailsItem, 'pillar')) == '4' && !(getObjectProperty(___arguments.context.pageData, 'selectedItemResidentPillar'))) {
      ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('a92398c5044190cd5395285c1c14f00e'))['margin-left'] = '0px';
    } else {
      ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('a92398c5044190cd5395285c1c14f00e'))['margin-left'] = '8px';
      classes = ['small_pillar_stepper'];
    }
  }

  return classes

  },
  /* handler:onClassListAssignment */
  /* handler:onClick */
  ['onClick'](___arguments) {
      ___arguments.context.pageData['selectedItemResidentPillar'] = '4';

  },
  /* handler:onClick */
  /* content */
}))

define('./components/reusable/r_a5cc17adcd43a953ff497b8130a1de3b/logic/c435333eff2586a1b281b99eb27b2898/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    var classes, residentDetailsItem;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  classes = [];
  residentDetailsItem = (getObjectProperty(___arguments.context.pageData, 'residentDetailsItem'));
  if (residentDetailsItem) {
    if ((getObjectProperty(___arguments.context.pageData, 'selectedItemResidentPillar')) != '1' && !(typeof (getObjectProperty(___arguments.context.pageData, 'selectedItemResidentPillar')) === 'undefined')) {
      classes = ['no_stepper'];
    } else if ((getObjectProperty(residentDetailsItem, 'pillar')) != '1' && !(getObjectProperty(___arguments.context.pageData, 'selectedItemResidentPillar'))) {
      classes = ['no_stepper'];
    } else {
      classes = ['small_pillar_stepper'];
    }
  }

  return classes

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./components/reusable/r_a5cc17adcd43a953ff497b8130a1de3b/logic/6e589f0e8bf4f75d24692382af48ab0f/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    var classes, residentDetailsItem;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  classes = [];
  residentDetailsItem = (getObjectProperty(___arguments.context.pageData, 'residentDetailsItem'));
  if (residentDetailsItem) {
    if ((getObjectProperty(___arguments.context.pageData, 'selectedItemResidentPillar')) != '2' && !(typeof (getObjectProperty(___arguments.context.pageData, 'selectedItemResidentPillar')) === 'undefined')) {
      classes = ['no_stepper'];
    } else if ((getObjectProperty(residentDetailsItem, 'pillar')) != '2' && !(getObjectProperty(___arguments.context.pageData, 'selectedItemResidentPillar'))) {
      classes = ['no_stepper'];
    } else {
      classes = ['small_pillar_stepper'];
    }
  }

  return classes

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./components/reusable/r_a5cc17adcd43a953ff497b8130a1de3b/logic/549b07bc9d58d55dd0d5421bbb63c9f7/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    var classes, residentDetailsItem;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  classes = [];
  residentDetailsItem = (getObjectProperty(___arguments.context.pageData, 'residentDetailsItem'));
  if (residentDetailsItem) {
    if ((getObjectProperty(___arguments.context.pageData, 'selectedItemResidentPillar')) != '3' && !(typeof (getObjectProperty(___arguments.context.pageData, 'selectedItemResidentPillar')) === 'undefined')) {
      classes = ['no_stepper'];
    } else if ((getObjectProperty(residentDetailsItem, 'pillar')) != '3' && !(getObjectProperty(___arguments.context.pageData, 'selectedItemResidentPillar'))) {
      classes = ['no_stepper'];
    } else {
      classes = ['small_pillar_stepper'];
    }
  }

  return classes

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./components/reusable/r_a5cc17adcd43a953ff497b8130a1de3b/logic/4e93666336b4de7c3d257ca783bce47c/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    var classes, residentDetailsItem;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  classes = [];
  residentDetailsItem = (getObjectProperty(___arguments.context.pageData, 'residentDetailsItem'));
  if (residentDetailsItem) {
    if ((getObjectProperty(___arguments.context.pageData, 'selectedItemResidentPillar')) != '4' && !(typeof (getObjectProperty(___arguments.context.pageData, 'selectedItemResidentPillar')) === 'undefined')) {
      classes = ['no_stepper'];
    } else if ((getObjectProperty(residentDetailsItem, 'pillar')) != '4' && !(getObjectProperty(___arguments.context.pageData, 'selectedItemResidentPillar'))) {
      classes = ['no_stepper'];
    } else {
      classes = ['small_pillar_stepper'];
    }
  }

  return classes

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./components/reusable/r_a5cc17adcd43a953ff497b8130a1de3b/logic/cdd68abf2e1c6b37c9f2de5d0796d18e/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    var residentDetailsItem;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  residentDetailsItem = (getObjectProperty(___arguments.context.pageData, 'residentDetailsItem'));
  if (residentDetailsItem) {
    if ((getObjectProperty(residentDetailsItem, 'pillar')) == '1') {
      return 'Joined on: 12/34/2024';
    } else {
      return 'unassigned';
    }
  }

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./components/reusable/r_a5cc17adcd43a953ff497b8130a1de3b/logic/6389f088e0a643716c7f64db5c6bf8cb/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    var residentDetailsItem;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  residentDetailsItem = (getObjectProperty(___arguments.context.pageData, 'residentDetailsItem'));
  if (residentDetailsItem) {
    if ((getObjectProperty(residentDetailsItem, 'pillar')) == '2') {
      return 'Joined on: 12/34/2024';
    } else {
      return 'unassigned';
    }
  }

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./components/reusable/r_a5cc17adcd43a953ff497b8130a1de3b/logic/6e227f796bc25dace15fb37120d9c317/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    var residentDetailsItem;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  residentDetailsItem = (getObjectProperty(___arguments.context.pageData, 'residentDetailsItem'));
  if (residentDetailsItem) {
    if ((getObjectProperty(residentDetailsItem, 'pillar')) == '3') {
      return 'Joined on: 12/34/2024';
    } else {
      return 'unassigned';
    }
  }

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./components/reusable/r_a5cc17adcd43a953ff497b8130a1de3b/logic/defea46ec17bbd0cd50855448a955d2d/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    var residentDetailsItem;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  residentDetailsItem = (getObjectProperty(___arguments.context.pageData, 'residentDetailsItem'));
  if (residentDetailsItem) {
    if ((getObjectProperty(residentDetailsItem, 'pillar')) == '4') {
      return 'Joined on: 12/34/2024';
    } else {
      return 'unassigned';
    }
  }

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./components/reusable/r_a5cc17adcd43a953ff497b8130a1de3b/logic/46f88a33ac2dd6b19af841d6b955b9cb/bundle.js', [], () => ({
  /* content */
  /* handler:onSourceUrlAssignment */
  ['onSourceUrlAssignment'](___arguments) {
      return 'https://app.aquax.co/api/files/cyberdive_photos/cd-icon-default-photo.svg';

  },
  /* handler:onSourceUrlAssignment */
  /* content */
}))

define('./components/reusable/r_a5cc17adcd43a953ff497b8130a1de3b/logic/effce6d1a1ec92c13aeed54d06e6394c/bundle.js', [], () => ({
  /* content */
  /* handler:onValueAssignment */
  ['onValueAssignment'](___arguments) {
    var selectedItem, currentUser, menuOptionSelection, isSelectedItemModalOpen;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  isSelectedItemModalOpen = (getObjectProperty(___arguments.context.pageData, 'isSelectedItemModalOpen'));
  menuOptionSelection = (getObjectProperty(___arguments.context.pageData, 'menuOptionSelection'));
  currentUser = (getObjectProperty(___arguments.context.pageData, 'currentUser'));
  if (menuOptionSelection == 'viewProfile') {
    if (currentUser) {
      if (getObjectProperty(___arguments.context.pageData, 'isSelectedItemModalOpen')) {
        return (getObjectProperty(currentUser, 'organizationId.orgName'));
      }
    }
  } else {
    if ((getObjectProperty(___arguments.context.pageData, 'selectedItemArrayUser')) && (getObjectProperty(___arguments.context.pageData, 'selectedItemArrayUser')).length >= 1) {
      selectedItem = (getObjectProperty(___arguments.context.pageData, 'selectedItemArrayUser'))[0];
      if (getObjectProperty(___arguments.context.pageData, 'isSelectedItemModalOpen')) {
        return (getObjectProperty(selectedItem, 'orgName'));
      }
    }
  }

  },
  /* handler:onValueAssignment */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'currentUser.userRole')) != 'super') {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('effce6d1a1ec92c13aeed54d06e6394c', true);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('effce6d1a1ec92c13aeed54d06e6394c', false);
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./components/reusable/r_a5cc17adcd43a953ff497b8130a1de3b/logic/e08735ca28bd347a46f513ff2fa7c593/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    var menuOptionSelection, currentUser;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  menuOptionSelection = (getObjectProperty(___arguments.context.pageData, 'menuOptionSelection'));
  currentUser = (getObjectProperty(___arguments.context.pageData, 'currentUser'));
  if (menuOptionSelection == 'viewProfile') {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('e08735ca28bd347a46f513ff2fa7c593', true);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('e08735ca28bd347a46f513ff2fa7c593', false);
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./components/reusable/r_a5cc17adcd43a953ff497b8130a1de3b/logic/d921631541146549a23c025e8f43f1a1/bundle.js', [], () => ({
  /* content */
  /* handler:onValueAssignment */
  ['onValueAssignment'](___arguments) {
    var selectedItem, currentUser, menuOptionSelection, isSelectedItemModalOpen;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  isSelectedItemModalOpen = (getObjectProperty(___arguments.context.pageData, 'isSelectedItemModalOpen'));
  menuOptionSelection = (getObjectProperty(___arguments.context.pageData, 'menuOptionSelection'));
  currentUser = (getObjectProperty(___arguments.context.pageData, 'currentUser'));
  if (menuOptionSelection == 'viewProfile') {
    if (currentUser) {
      if (getObjectProperty(___arguments.context.pageData, 'isSelectedItemModalOpen')) {
        if ((getObjectProperty(currentUser, 'userRole')) == 'manager') {
          return 'Case Manager';
        } else if ((getObjectProperty(currentUser, 'userRole')) == 'admin') {
          return 'Administrator';
        }
      }
    }
  } else {
    if ((getObjectProperty(___arguments.context.pageData, 'selectedItemArrayUser')) && (getObjectProperty(___arguments.context.pageData, 'selectedItemArrayUser')).length >= 1) {
      selectedItem = (getObjectProperty(___arguments.context.pageData, 'selectedItemArrayUser'))[0];
      if (getObjectProperty(___arguments.context.pageData, 'isSelectedItemModalOpen')) {
        if ((getObjectProperty(selectedItem, 'userRole')) == 'manager') {
          return 'Case Manager';
        } else if ((getObjectProperty(selectedItem, 'userRole')) == 'admin') {
          return 'Administrator';
        }
      }
    }
  }

  },
  /* handler:onValueAssignment */
  /* content */
}))

define('./components/reusable/r_a5cc17adcd43a953ff497b8130a1de3b/logic/e26cddd1c04311f28e71108d7f4dcc58/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  async ['onChange'](___arguments) {
    var isNotDisabled, currentUser, menuOptionSelection;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function subsequenceFromStartLast(sequence, at1) {
  var start = at1;
  var end = sequence.length - 1 + 1;
  return sequence.slice(start, end);
}


  menuOptionSelection = (getObjectProperty(___arguments.context.pageData, 'menuOptionSelection'));
  ___arguments.context.pageData['selectedItemPhone'] = (await BackendlessUI.Functions.Custom['fn_f282814277b3c0aa13f33d3b2cda111b'](___arguments.value, 'create'));
  ___arguments.context.pageData['phoneError'] = null;
  currentUser = (getObjectProperty(___arguments.context.pageData, 'currentUser'));
  if (currentUser) {
    if ((await BackendlessUI.Functions.Custom['fn_a7ad4c1fd5d18ca6bff3eb611aeee2ac'](___arguments.value))) {
      ___arguments.context.pageData['disableButton'] = true;
      ___arguments.context.pageData['emptyPhone'] = true;
    } else if (menuOptionSelection == 'viewProfile' && ___arguments.value != subsequenceFromStartLast((getObjectProperty(currentUser, 'phoneNumber')), 2) || (getObjectProperty(___arguments.context.pageData, 'selectedItemArrayUser')) && ___arguments.value != subsequenceFromStartLast((getObjectProperty(((getObjectProperty(___arguments.context.pageData, 'selectedItemArrayUser'))[0]), 'phoneNumber')), 2)) {
      (getObjectProperty(___arguments.context.pageData, 'isUserDetailsButtonDisabled'))['phone'] = false;
      ___arguments.context.pageData['emptyPhone'] = false;
      isNotDisabled = true;
      if (getObjectProperty(___arguments.context.pageData, 'emptyName')) {
        isNotDisabled = false;
      } else if (getObjectProperty(___arguments.context.pageData, 'emptyEmail')) {
        isNotDisabled = false;
      }
      if (isNotDisabled) {
        ___arguments.context.pageData['disableButton'] = false;
      }
    } else {
      (getObjectProperty(___arguments.context.pageData, 'isUserDetailsButtonDisabled'))['phone'] = true;
    }
  }

  },
  /* handler:onChange */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    var classesList;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  classesList = [];
  if (getObjectProperty(___arguments.context.pageData, 'phoneError')) {
    addItemToList(classesList, 'input_red_border');
  }

  return classesList

  },
  /* handler:onClassListAssignment */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    var selectedItem, currentUser, menuOptionSelection, isSelectedItemModalOpen;

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./components/reusable/r_a5cc17adcd43a953ff497b8130a1de3b/logic/ce1703078de733da49130eda43b968ac/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'scrollToTopSelectedItem')) {
    (function (component) { component.el.scrollIntoView() })(((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('38622c8308061e08e949ba98e80450c7')));
    ___arguments.context.pageData['scrollToTopSelectedItem'] = false;
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./components/reusable/r_a5cc17adcd43a953ff497b8130a1de3b/logic/b52abc210691664f0e6c953e3da9a9b9/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'currentUser.userRole')) == 'super') {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('b52abc210691664f0e6c953e3da9a9b9', true);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('b52abc210691664f0e6c953e3da9a9b9', false);
  }

  },
  /* handler:onStyleAssignment */
  /* handler:onChange */
  ['onChange'](___arguments) {
    var selectedItem, menuOptionSelection;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  ___arguments.context.pageData['selectedItemOrganization'] = ___arguments.value;
  menuOptionSelection = (getObjectProperty(___arguments.context.pageData, 'menuOptionSelection'));
  selectedItem = (getObjectProperty(___arguments.context.pageData, 'selectedItemArrayUser'))[0];
  if (___arguments.value != (getObjectProperty(selectedItem, 'orgName'))) {
    (getObjectProperty(___arguments.context.pageData, 'isUserDetailsButtonDisabled'))['organization'] = false;
  } else {
    (getObjectProperty(___arguments.context.pageData, 'isUserDetailsButtonDisabled'))['organization'] = true;
  }

  },
  /* handler:onChange */
  /* handler:onLabelAssignment */
  ['onLabelAssignment'](___arguments) {
    var selectedItem;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'selectedItemArrayUser')) && (getObjectProperty(___arguments.context.pageData, 'selectedItemArrayUser')).length >= 1) {
    selectedItem = (getObjectProperty(___arguments.context.pageData, 'selectedItemArrayUser'))[0];
    if (getObjectProperty(___arguments.context.pageData, 'isSelectedItemModalOpen')) {
      return (getObjectProperty(selectedItem, 'orgName'));
    }
  }

  },
  /* handler:onLabelAssignment */
  /* content */
}))

define('./components/reusable/r_a5cc17adcd43a953ff497b8130a1de3b/logic/fabc87b5e0aa19ccf9fcd66f755621ee/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  ['onChange'](___arguments) {
    var selectedItem;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  ___arguments.context.pageData['selectedItemRole'] = ___arguments.value;
  selectedItem = (getObjectProperty(___arguments.context.pageData, 'selectedItemArrayUser'))[0];
  if (___arguments.value != (getObjectProperty(selectedItem, 'userRole'))) {
    (getObjectProperty(___arguments.context.pageData, 'isUserDetailsButtonDisabled'))['role'] = false;
  } else {
    (getObjectProperty(___arguments.context.pageData, 'isUserDetailsButtonDisabled'))['role'] = true;
  }

  },
  /* handler:onChange */
  /* handler:onLabelAssignment */
  ['onLabelAssignment'](___arguments) {
    var selectedItem;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'selectedItemArrayUser')) && (getObjectProperty(___arguments.context.pageData, 'selectedItemArrayUser')).length >= 1) {
    selectedItem = (getObjectProperty(___arguments.context.pageData, 'selectedItemArrayUser'))[0];
    if (getObjectProperty(___arguments.context.pageData, 'isSelectedItemModalOpen')) {
      if ((getObjectProperty(selectedItem, 'userRole')) == 'admin') {
        return 'Administrator';
      } else if ((getObjectProperty(selectedItem, 'userRole')) == 'manager') {
        return 'Case Manager';
      }
    }
  }

  },
  /* handler:onLabelAssignment */
  /* content */
}))

define('./components/reusable/r_a5cc17adcd43a953ff497b8130a1de3b/logic/c0d93bcf030a5b176daa87cf33b93eba/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  (getObjectProperty(___arguments.context.pageData, 'organizationUpdateObject'))['logo'] = (getObjectProperty(___arguments.context.pageData, 'selectedItemOrganization.logo'));
  ___arguments.context.pageData['selectedItemOrganizationImageFile'] = undefined;
  (getObjectProperty(___arguments.context.pageData, 'isEditOrgButtonDisabled'))['selectedImage'] = true;

  },
  /* handler:onClick */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'selectedItemOrganizationImageFile')) {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('c0d93bcf030a5b176daa87cf33b93eba', true);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('c0d93bcf030a5b176daa87cf33b93eba', false);
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./components/reusable/r_573384045ca814e1fb830ae43d034f55/logic/36f7d69ff6d3a2cdb53868ee96be3157/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      ___arguments.context.pageData['isToastVisible'] = false;

  },
  /* handler:onClick */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'hasToastError')) {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('36f7d69ff6d3a2cdb53868ee96be3157', true);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('36f7d69ff6d3a2cdb53868ee96be3157', false);
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./components/reusable/r_573384045ca814e1fb830ae43d034f55/logic/56d4a14f0364823937fc867de6bb6145/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    var toasts, firstItem;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'isToastVisible')) {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('56d4a14f0364823937fc867de6bb6145', true);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('56d4a14f0364823937fc867de6bb6145', false);
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./components/reusable/r_573384045ca814e1fb830ae43d034f55/logic/8e42d14edea8249e307f6fe107d61f18/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'hasToastError')) {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('8e42d14edea8249e307f6fe107d61f18', true);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('8e42d14edea8249e307f6fe107d61f18', false);
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./components/reusable/r_53b389178b15c6dfffd3d4aa1bd54efe/logic/b979e0125a53df62e78b5794ff6e4938/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    var classesList;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  classesList = ['modal'];
  if (getObjectProperty(___arguments.context.pageData, 'isLogoutModalOpen')) {
    addItemToList(classesList, 'open');
  }

  return classesList

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./components/reusable/r_53b389178b15c6dfffd3d4aa1bd54efe/logic/23f8c4f7554ff5739842fb5427bf709a/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      ___arguments.context.pageData['isLogoutModalOpen'] = false;

  },
  /* handler:onClick */
  /* content */
}))

define('./components/reusable/r_53b389178b15c6dfffd3d4aa1bd54efe/logic/a837274ec1e454dbc9553887260483c8/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
      await Backendless.UserService.logout();
  if (!(await Backendless.UserService.isValidLogin())) {
    (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('Login', undefined);
  }

  },
  /* handler:onClick */
  /* content */
}))

define('./components/reusable/r_53b389178b15c6dfffd3d4aa1bd54efe/logic/8a383eafc76c58e9e52f65da8fcb4cdd/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      ___arguments.context.pageData['isLogoutModalOpen'] = false;

  },
  /* handler:onClick */
  /* content */
}))

define('./components/reusable/r_ae95a557491cf646d89334f5f43c1d69/logic/c02b109a522e843971e3b0b48e5c0b3b/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'isViewDetailsOpen')) {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('c02b109a522e843971e3b0b48e5c0b3b', true);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('c02b109a522e843971e3b0b48e5c0b3b', false);
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./components/reusable/r_ae95a557491cf646d89334f5f43c1d69/logic/2aa7c243ea2aea54cc725292f72b36da/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      ___arguments.context.pageData['isViewDetailsOpen'] = false;

  },
  /* handler:onClick */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'navbarSelection')) == 'deviceRecords') {
    return 'Device Records ';
  } else if ((getObjectProperty(___arguments.context.pageData, 'navbarSelection')) == 'userData') {
    return 'User Records ';
  } else if ((getObjectProperty(___arguments.context.pageData, 'navbarSelection')) == 'organizations') {
    return 'Organization Records ';
  } else if ((getObjectProperty(___arguments.context.pageData, 'navbarSelection')) == 'residentData') {
    return 'Resident Records ';
  }

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./components/reusable/r_ae95a557491cf646d89334f5f43c1d69/logic/a257f8ca1346db0fe77f8f468f581099/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'navbarSelection')) == 'residentData') {
    if (getObjectProperty(___arguments.context.pageData, 'residentIntakeForm.personalDetails.nameInUse')) {
      return (await BackendlessUI.Functions.Custom['fn_9807a958b9f47818617415e4438b5e47']((getObjectProperty(___arguments.context.pageData, 'residentIntakeForm.personalDetails.nameInUse')), 26));
    } else {
      return 'N/A';
    }
  } else if ((getObjectProperty(___arguments.context.pageData, 'navbarSelection')) == 'organizations') {
    if (getObjectProperty(___arguments.context.pageData, 'selectedItemOrganization.orgName')) {
      return (await BackendlessUI.Functions.Custom['fn_9807a958b9f47818617415e4438b5e47']((getObjectProperty(___arguments.context.pageData, 'selectedItemOrganization.orgName')), 26));
    } else {
      return 'N/A';
    }
  } else if ((getObjectProperty(___arguments.context.pageData, 'navbarSelection')) == 'userData') {
    if (getObjectProperty(___arguments.context.pageData, 'selectedItemUser.name')) {
      return (await BackendlessUI.Functions.Custom['fn_9807a958b9f47818617415e4438b5e47']((getObjectProperty(___arguments.context.pageData, 'selectedItemUser.name')), 26));
    } else {
      return 'N/A';
    }
  }

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./components/reusable/r_ae95a557491cf646d89334f5f43c1d69/logic/cecdf3a6d2031853efb35e543d9f512d/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      ___arguments.context.pageData['selectedItemDetailsSelection'] = 'details';

  },
  /* handler:onClick */
  /* content */
}))

define('./components/reusable/r_ae95a557491cf646d89334f5f43c1d69/logic/2afbf387b8ab72629f99c5d740180f43/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    var classes;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  classes = [];
  if ((getObjectProperty(___arguments.context.pageData, 'selectedItemDetailsSelection')) == 'details') {
    addItemToList(classes, 'selected_box_text');
  }

  return classes

  },
  /* handler:onClassListAssignment */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'navbarSelection')) == 'userData') {
    return 'User Details';
  } else if ((getObjectProperty(___arguments.context.pageData, 'navbarSelection')) == 'residentData') {
    return 'Profile Details';
  } else if ((getObjectProperty(___arguments.context.pageData, 'navbarSelection')) == 'organizations') {
    return 'Organization Details';
  }

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./components/reusable/r_ae95a557491cf646d89334f5f43c1d69/logic/a58958886a62f291347d8b07479e1cbe/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    var classes;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  classes = [];
  if ((getObjectProperty(___arguments.context.pageData, 'selectedItemDetailsSelection')) == 'details') {
    addItemToList(classes, 'selected_box_bar');
  }

  return classes

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./components/reusable/r_ae95a557491cf646d89334f5f43c1d69/logic/db79eae928d5145486922629f4d34bc8/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var response, item, listOfApplications, application, listOfSystemApps;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  ___arguments.context.pageData['selectedItemDetailsSelection'] = 'apps';
  ___arguments.context.pageData['noInstalledApps'] = false;
  ___arguments.context.pageData['installedAppsError'] = false;
  if (getObjectProperty(___arguments.context.pageData, 'selectedItemResident.deviceIMEI')) {
    ___arguments.context.pageData['isLoadingInstalledApps'] = true;
    listOfApplications = [];
    listOfSystemApps = ['com.hmdm.contacts', 'com.hmdm.control', 'com.android.vpndialogs', 'com.android.systemui', 'com.android.launcher3', 'com.android.settings', 'com.android.server.telecom', 'android', 'com.android.bluetooth', 'com.android.camera', 'com.android.chrome', 'com.google.android.contacts', 'com.google.android.dialer', 'com.google.android.documentsui', 'com.google.android.apps.photos', 'com.google.android.apps.docs', 'com.google.android.apps.maps', 'com.android.vending', 'com.google.android.gms', 'com.hmdm.launcher', 'com.hmdm.pager', 'com.hmdm.emuilauncherrestarter', 'com.google.android.inputmethod.latin', 'com.android.providers.media', 'com.google.android.apps.messaging', 'com.google.android.packageinstaller', 'com.android.phone', 'com.android.printspooler', 'com.android.settings.intelligence'];
    try {
      var application_list = (getObjectProperty((await Backendless.Request.get(`${Backendless.appPath}/services/HeadwindMDM/getDeviceInfo`).set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).query({ 'token': JSON.stringify((getObjectProperty((JSON.parse((getObjectProperty(___arguments.context.pageData, 'currentUser.organizationId.headwindData')))), 'token'))),'deviceId': JSON.stringify((getObjectProperty(___arguments.context.pageData, 'selectedItemResident.deviceId'))) }).send()), 'data.applications'));
      for (var application_index in application_list) {
        application = application_list[application_index];
        if (!(listOfSystemApps.includes((getObjectProperty(application, 'applicationPkg'))))) {
          addItemToList(listOfApplications, application);
        }
      }
      if (listOfApplications.length > 0) {
        (function (componentUid, listItems) { ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('da05b65a5c76c363a5ce3b73a51d85a7', (await Promise.all(listOfApplications.map(async item => {  response = (await Backendless.Request.post(`${Backendless.appPath}/services/AWSApis/handleApplicationDetails`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send(JSON.stringify((getObjectProperty(item, 'applicationPkg')))));
        ; return ({ [`AppIcon`]: (getObjectProperty(response, 'AppIcon')),[`ApplicationName`]: (getObjectProperty(response, 'ApplicationName')) });}))));
      } else {
        ___arguments.context.pageData['noInstalledApps'] = true;
      }
      ___arguments.context.pageData['isLoadingInstalledApps'] = false;

    } catch (error) {
      ___arguments.context.pageData['installedAppsError'] = true;
      ___arguments.context.pageData['noInstalledApps'] = true;
      ___arguments.context.pageData['isLoadingInstalledApps'] = false;

    }
  }

  },
  /* handler:onClick */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'navbarSelection')) == 'residentData') {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('db79eae928d5145486922629f4d34bc8', true);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('db79eae928d5145486922629f4d34bc8', false);
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./components/reusable/r_ae95a557491cf646d89334f5f43c1d69/logic/cb3293dbf573c615b434a9eff738412b/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    var classes;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  classes = [];
  if ((getObjectProperty(___arguments.context.pageData, 'selectedItemDetailsSelection')) == 'apps') {
    addItemToList(classes, 'selected_box_text');
  }

  return classes

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./components/reusable/r_ae95a557491cf646d89334f5f43c1d69/logic/d8af13878de963fddd44dcc87cb6260d/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    var classes;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  classes = [];
  if ((getObjectProperty(___arguments.context.pageData, 'selectedItemDetailsSelection')) == 'apps') {
    addItemToList(classes, 'selected_box_bar');
  }

  return classes

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./components/reusable/r_ae95a557491cf646d89334f5f43c1d69/logic/f8278ecbafd8a7b83b83757d86e95248/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      ___arguments.context.pageData['selectedItemDetailsSelection'] = 'contacts';

  },
  /* handler:onClick */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'navbarSelection')) == 'residentData') {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('f8278ecbafd8a7b83b83757d86e95248', true);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('f8278ecbafd8a7b83b83757d86e95248', false);
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./components/reusable/r_ae95a557491cf646d89334f5f43c1d69/logic/ab84b24474d41c960627fac6fa1f0d06/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    var classes;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  classes = [];
  if ((getObjectProperty(___arguments.context.pageData, 'selectedItemDetailsSelection')) == 'contacts') {
    addItemToList(classes, 'selected_box_text');
  }

  return classes

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./components/reusable/r_ae95a557491cf646d89334f5f43c1d69/logic/04b30aeadb041faf6302304d6c9ce28f/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    var classes;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  classes = [];
  if ((getObjectProperty(___arguments.context.pageData, 'selectedItemDetailsSelection')) == 'contacts') {
    addItemToList(classes, 'selected_box_bar');
  }

  return classes

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./components/reusable/r_ae95a557491cf646d89334f5f43c1d69/logic/cb6bb3f8c29404cc7fd67abc8a9319ef/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'isViewResidentActionDrawerOpen')) {
    ___arguments.context.pageData['isViewResidentActionDrawerOpen'] = false;
  } else {
    ___arguments.context.pageData['isViewResidentActionDrawerOpen'] = true;
  }

  },
  /* handler:onClick */
  /* content */
}))

define('./components/reusable/r_ae95a557491cf646d89334f5f43c1d69/logic/f25232312d9b217ff9fe2d7e59a69369/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'isViewResidentActionDrawerOpen')) {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('f25232312d9b217ff9fe2d7e59a69369', true);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('f25232312d9b217ff9fe2d7e59a69369', false);
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./components/reusable/r_ae95a557491cf646d89334f5f43c1d69/logic/2b836bdfa9ee118b9ddabedef0413d77/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var classes;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'selectedItemResident.deviceStatus')) != 'ASSIGNED') {
    await BackendlessUI.Functions.Custom['fn_d48a6d3a366b0ef43b6e0b4bab07fa46'](___arguments.context.pageData);
    ___arguments.context.pageData['isNewResident'] = false;
    ___arguments.context.pageData['hasStoppedPolling'] = undefined;
    ___arguments.context.pageData['hasOnboardStarted'] = undefined;
    ___arguments.context.pageData['residentIntakeStep'] = undefined;
    ___arguments.context.pageData['recordTableVariant'] = 'resident';
    ___arguments.context.pageData['residentPillarRadio'] = undefined;
    ___arguments.context.pageData['residentIntakeVariant'] = 'pillar';
    ___arguments.context.pageData['isAddRecordModalOpen'] = true;
  }

  },
  /* handler:onClick */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'selectedItemDetailsSelection')) == 'details' && (getObjectProperty(___arguments.context.pageData, 'navbarSelection')) == 'residentData') {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('2b836bdfa9ee118b9ddabedef0413d77', true);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('2b836bdfa9ee118b9ddabedef0413d77', false);
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./components/reusable/r_ae95a557491cf646d89334f5f43c1d69/logic/af6a42f350dc57f984084e2d7576370e/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    var classes;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'selectedItemResident.deviceStatus')) != 'ASSIGNED') {
    classes = ['active_action', 'actionIcon'];
  } else {
    classes = ['inactive_action', 'actionIcon'];
  }

  return classes

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./components/reusable/r_ae95a557491cf646d89334f5f43c1d69/logic/14e5c797e35e075b31d167edb2f20af2/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    var classes;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'selectedItemResident.deviceStatus')) != 'ASSIGNED') {
    classes = ['active_action', 'actionText'];
  } else {
    classes = ['inactive_action', 'actionText'];
  }

  return classes

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./components/reusable/r_ae95a557491cf646d89334f5f43c1d69/logic/481c84f6b399f35eb2176e9d1d25358c/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'selectedItemDetailsSelection')) == 'details' && (getObjectProperty(___arguments.context.pageData, 'navbarSelection')) == 'residentData') {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('481c84f6b399f35eb2176e9d1d25358c', true);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('481c84f6b399f35eb2176e9d1d25358c', false);
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./components/reusable/r_ae95a557491cf646d89334f5f43c1d69/logic/f71b8a04b10ff6bc15663e180e5e0ed8/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    var classes;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'selectedItemResident.deviceStatus')) == 'ASSIGNED') {
    classes = ['active_action', 'actionIcon'];
  } else {
    classes = ['inactive_action', 'actionIcon'];
  }

  return classes

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./components/reusable/r_ae95a557491cf646d89334f5f43c1d69/logic/bd327b2a0b28b2e13b7f98f6ed9ac676/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    var classes;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'selectedItemResident.deviceStatus')) == 'ASSIGNED') {
    classes = ['active_action', 'actionText'];
  } else {
    classes = ['inactive_action', 'actionText'];
  }

  return classes

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./components/reusable/r_ae95a557491cf646d89334f5f43c1d69/logic/86eeaea48d42510afcbcb3173b8df062/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'selectedItemResident.deviceStatus')) == 'ASSIGNED') {
    ___arguments.context.pageData['residentPillarRadio'] = (getObjectProperty(___arguments.context.pageData, 'selectedItemResident.pillar'));
    ___arguments.context.pageData['residentIntakeVariant'] = 'updatePillar';
    ___arguments.context.pageData['recordTableVariant'] = 'resident';
    ___arguments.context.pageData['isAddRecordModalOpen'] = true;
  }

  },
  /* handler:onClick */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'selectedItemDetailsSelection')) == 'details' && (getObjectProperty(___arguments.context.pageData, 'navbarSelection')) == 'residentData') {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('86eeaea48d42510afcbcb3173b8df062', true);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('86eeaea48d42510afcbcb3173b8df062', false);
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./components/reusable/r_ae95a557491cf646d89334f5f43c1d69/logic/003f3859578b90890d14f9cc6bf3fcef/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    var classes;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'selectedItemResident.deviceStatus')) == 'ASSIGNED') {
    classes = ['active_action', 'actionIcon'];
  } else {
    classes = ['inactive_action', 'actionIcon'];
  }

  return classes

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./components/reusable/r_ae95a557491cf646d89334f5f43c1d69/logic/1a71f4b615bbff59eed31b7f2ccf3758/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    var classes;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'selectedItemResident.deviceStatus')) == 'ASSIGNED') {
    classes = ['active_action', 'actionText'];
  } else {
    classes = ['inactive_action', 'actionText'];
  }

  return classes

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./components/reusable/r_ae95a557491cf646d89334f5f43c1d69/logic/4987e84dd62ce01d5add90b8ddbc74d3/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    var selectedItemOrganization, isSame, item, selectedItemUser;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'navbarSelection')) == 'residentData') {
    if ((getObjectProperty(___arguments.context.pageData, 'selectedItemResident.status')) == 'DEACTIVATED') {
      ___arguments.context.pageData['confirmationOption'] = 'activateResident';
      ___arguments.context.pageData['isConfirmationModalOpen'] = true;
    }
  } else if ((getObjectProperty(___arguments.context.pageData, 'navbarSelection')) == 'organizations') {
    selectedItemOrganization = (getObjectProperty(___arguments.context.pageData, 'selectedItemOrganization'));
    if ((getObjectProperty(selectedItemOrganization, 'status')) == 'DEACTIVATED') {
      ___arguments.context.pageData['isConfirmationModalOpen'] = true;
      ___arguments.context.pageData['confirmationOption'] = 'activateOrganization';
    }
  } else if ((getObjectProperty(___arguments.context.pageData, 'navbarSelection')) == 'userData') {
    selectedItemUser = (getObjectProperty(___arguments.context.pageData, 'selectedItemUser'));
    if (selectedItemUser) {
      if ((getObjectProperty(selectedItemUser, 'inviteStatus')) != 'PENDING' && (getObjectProperty(selectedItemUser, 'status')) == 'DEACTIVATED') {
        ___arguments.context.pageData['isConfirmationModalOpen'] = true;
        ___arguments.context.pageData['confirmationOption'] = 'activateUser';
      }
    }
  }

  },
  /* handler:onClick */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'selectedItemDetailsSelection')) == 'details' && ((getObjectProperty(___arguments.context.pageData, 'navbarSelection')) == 'userData' || (getObjectProperty(___arguments.context.pageData, 'navbarSelection')) == 'organizations')) {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('4987e84dd62ce01d5add90b8ddbc74d3', true);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('4987e84dd62ce01d5add90b8ddbc74d3', false);
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./components/reusable/r_ae95a557491cf646d89334f5f43c1d69/logic/1d080da8c5168c1e61b1eeeb48042a84/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    var classes, selectedItemUser, selectedItemOrganization;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'navbarSelection')) == 'residentData') {
    if ((getObjectProperty(___arguments.context.pageData, 'residentIntakeForm.status')) == 'DEACTIVATED') {
      classes = ['active_action', 'actionIcon'];
    } else {
      classes = ['inactive_action', 'actionIcon'];
    }
  } else if ((getObjectProperty(___arguments.context.pageData, 'navbarSelection')) == 'organizations') {
    selectedItemOrganization = (getObjectProperty(___arguments.context.pageData, 'selectedItemOrganization'));
    if ((getObjectProperty(selectedItemOrganization, 'status')) == 'ACTIVE') {
      classes = ['inactive_action', 'actionIcon'];
    } else {
      classes = ['active_action', 'actionIcon'];
    }
  } else if ((getObjectProperty(___arguments.context.pageData, 'navbarSelection')) == 'userData') {
    selectedItemUser = (getObjectProperty(___arguments.context.pageData, 'selectedItemUser'));
    if ((getObjectProperty(selectedItemUser, 'inviteStatus')) != 'PENDING' && (getObjectProperty(selectedItemUser, 'status')) == 'DEACTIVATED') {
      classes = ['active_action', 'actionText'];
    } else {
      classes = ['inactive_action', 'actionText'];
    }
  }

  return classes

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./components/reusable/r_ae95a557491cf646d89334f5f43c1d69/logic/754160d8d2b407ddc52dc5180dadedee/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    var classes, selectedItemUser, selectedItemOrganization;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'navbarSelection')) == 'residentData') {
    if ((getObjectProperty(___arguments.context.pageData, 'residentIntakeForm.status')) == 'DEACTIVATED') {
      classes = ['active_action', 'actionText'];
    } else {
      classes = ['inactive_action', 'actionText'];
    }
  } else if ((getObjectProperty(___arguments.context.pageData, 'navbarSelection')) == 'organizations') {
    selectedItemOrganization = (getObjectProperty(___arguments.context.pageData, 'selectedItemOrganization'));
    if ((getObjectProperty(selectedItemOrganization, 'inviteStatus')) != 'PENDING' && (getObjectProperty(selectedItemOrganization, 'status')) == 'ACTIVE') {
      classes = ['inactive_action', 'actionText'];
    } else {
      classes = ['active_action', 'actionText'];
    }
  } else if ((getObjectProperty(___arguments.context.pageData, 'navbarSelection')) == 'userData') {
    selectedItemUser = (getObjectProperty(___arguments.context.pageData, 'selectedItemUser'));
    if ((getObjectProperty(selectedItemUser, 'inviteStatus')) != 'PENDING' && (getObjectProperty(selectedItemUser, 'status')) == 'DEACTIVATED') {
      classes = ['active_action', 'actionText'];
    } else {
      classes = ['inactive_action', 'actionText'];
    }
  }

  return classes

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./components/reusable/r_ae95a557491cf646d89334f5f43c1d69/logic/efa8322e3f121b46f3962bafe886312b/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    var selectedItemUser, selectedItemOrganization;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'navbarSelection')) == 'residentData') {
    if ((getObjectProperty(___arguments.context.pageData, 'selectedItemResident.status')) == 'ACTIVE') {
      ___arguments.context.pageData['confirmationOption'] = 'deactivateResident';
      ___arguments.context.pageData['isConfirmationModalOpen'] = true;
    }
  } else if ((getObjectProperty(___arguments.context.pageData, 'navbarSelection')) == 'organizations') {
    selectedItemOrganization = (getObjectProperty(___arguments.context.pageData, 'selectedItemOrganization'));
    if ((getObjectProperty(selectedItemOrganization, 'status')) == 'ACTIVE') {
      ___arguments.context.pageData['isConfirmationModalOpen'] = true;
      ___arguments.context.pageData['confirmationOption'] = 'deactivateOrganization';
    }
  } else if ((getObjectProperty(___arguments.context.pageData, 'navbarSelection')) == 'userData') {
    selectedItemUser = (getObjectProperty(___arguments.context.pageData, 'selectedItemUser'));
    if (selectedItemUser) {
      if ((getObjectProperty(selectedItemUser, 'inviteStatus')) != 'PENDING' && (getObjectProperty(selectedItemUser, 'status')) == 'ACTIVE') {
        ___arguments.context.pageData['isConfirmationModalOpen'] = true;
        ___arguments.context.pageData['confirmationOption'] = 'activateUser';
      }
    }
  }

  },
  /* handler:onClick */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'selectedItemDetailsSelection')) == 'details' && ((getObjectProperty(___arguments.context.pageData, 'navbarSelection')) == 'userData' || (getObjectProperty(___arguments.context.pageData, 'navbarSelection')) == 'organizations')) {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('efa8322e3f121b46f3962bafe886312b', true);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('efa8322e3f121b46f3962bafe886312b', false);
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./components/reusable/r_ae95a557491cf646d89334f5f43c1d69/logic/41d8517f46f740760c37a39e7f927bdb/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    var classes, selectedItemUser, selectedItemOrganization;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'navbarSelection')) == 'residentData') {
    if ((getObjectProperty(___arguments.context.pageData, 'residentIntakeForm.status')) == 'ACTIVE') {
      classes = ['active_action', 'actionIcon'];
    } else {
      classes = ['inactive_action', 'actionIcon'];
    }
  } else if ((getObjectProperty(___arguments.context.pageData, 'navbarSelection')) == 'organizations') {
    selectedItemOrganization = (getObjectProperty(___arguments.context.pageData, 'selectedItemOrganization'));
    if ((getObjectProperty(selectedItemOrganization, 'status')) == 'DEACTIVATED') {
      classes = ['inactive_action', 'actionText'];
    } else {
      classes = ['active_action', 'actionText'];
    }
  } else if ((getObjectProperty(___arguments.context.pageData, 'navbarSelection')) == 'userData') {
    selectedItemUser = (getObjectProperty(___arguments.context.pageData, 'selectedItemUser'));
    if ((getObjectProperty(selectedItemUser, 'inviteStatus')) != 'PENDING' && (getObjectProperty(selectedItemUser, 'status')) == 'ACTIVE') {
      classes = ['active_action', 'actionText'];
    } else {
      classes = ['inactive_action', 'actionText'];
    }
  }

  return classes

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./components/reusable/r_ae95a557491cf646d89334f5f43c1d69/logic/cd1fd7297133700654290fd21035bb1b/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    var classes, selectedItemUser, selectedItemOrganization;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'navbarSelection')) == 'residentData') {
    if ((getObjectProperty(___arguments.context.pageData, 'residentIntakeForm.status')) == 'ACTIVE') {
      classes = ['active_action', 'actionText'];
    } else {
      classes = ['inactive_action', 'actionText'];
    }
  } else if ((getObjectProperty(___arguments.context.pageData, 'navbarSelection')) == 'organizations') {
    selectedItemOrganization = (getObjectProperty(___arguments.context.pageData, 'selectedItemOrganization'));
    if ((getObjectProperty(selectedItemOrganization, 'status')) == 'DEACTIVATED') {
      classes = ['inactive_action', 'actionText'];
    } else {
      classes = ['active_action', 'actionText'];
    }
  } else if ((getObjectProperty(___arguments.context.pageData, 'navbarSelection')) == 'userData') {
    selectedItemUser = (getObjectProperty(___arguments.context.pageData, 'selectedItemUser'));
    if ((getObjectProperty(selectedItemUser, 'inviteStatus')) != 'PENDING' && (getObjectProperty(selectedItemUser, 'status')) == 'ACTIVE') {
      classes = ['active_action', 'actionText'];
    } else {
      classes = ['inactive_action', 'actionText'];
    }
  }

  return classes

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./components/reusable/r_ae95a557491cf646d89334f5f43c1d69/logic/9c2c3bde92c9372bd3e2512cbd1f0761/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'selectedItemDetailsSelection')) == 'contacts' && (getObjectProperty(___arguments.context.pageData, 'navbarSelection')) == 'residentData') {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('9c2c3bde92c9372bd3e2512cbd1f0761', true);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('9c2c3bde92c9372bd3e2512cbd1f0761', false);
  }

  },
  /* handler:onStyleAssignment */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var contact;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

/**
 * Describe this function...
 */
async function enterContactsForm() {
  var contact_list = (getObjectProperty(___arguments.context.pageData, 'residentIntakeForm.contactArray'));
  for (var contact_index in contact_list) {
    contact = contact_list[contact_index];
    if ((getObjectProperty(contact, 'index')) == 1) {
      ___arguments.context.pageData['residentContactFullNameOne'] = (getObjectProperty(contact, 'fullName'));
      ___arguments.context.pageData['residentContactCellPhoneOne'] = (getObjectProperty(contact, 'cellPhone'));
      ___arguments.context.pageData['residentContactHomePhoneOne'] = (getObjectProperty(contact, 'homePhone'));
    } else if ((getObjectProperty(contact, 'index')) == 2) {
      ___arguments.context.pageData['residentContactFullNameTwo'] = (getObjectProperty(contact, 'fullName'));
      ___arguments.context.pageData['residentContactCellPhoneTwo'] = (getObjectProperty(contact, 'cellPhone'));
      ___arguments.context.pageData['residentContactHomePhoneTwo'] = (getObjectProperty(contact, 'homePhone'));
    } else if ((getObjectProperty(contact, 'index')) == 3) {
      ___arguments.context.pageData['residentContactFullNameThree'] = (getObjectProperty(contact, 'fullName'));
      ___arguments.context.pageData['residentContactCellPhoneThree'] = (getObjectProperty(contact, 'cellPhone'));
      ___arguments.context.pageData['residentContactHomePhoneThree'] = (getObjectProperty(contact, 'homePhone'));
    } else if ((getObjectProperty(contact, 'index')) == 4) {
      ___arguments.context.pageData['residentContactFullNameFour'] = (getObjectProperty(contact, 'fullName'));
      ___arguments.context.pageData['residentContactCellPhoneFour'] = (getObjectProperty(contact, 'cellPhone'));
      ___arguments.context.pageData['residentContactHomePhoneFour'] = (getObjectProperty(contact, 'homePhone'));
    } else if ((getObjectProperty(contact, 'index')) == 5) {
      ___arguments.context.pageData['residentContactFullNameFive'] = (getObjectProperty(contact, 'fullName'));
      ___arguments.context.pageData['residentContactCellPhoneFive'] = (getObjectProperty(contact, 'cellPhone'));
      ___arguments.context.pageData['residentContactHomePhoneFive'] = (getObjectProperty(contact, 'homePhone'));
    }
  }
  ___arguments.context.pageData['recordTableVariant'] = 'resident';
  ___arguments.context.pageData['residentIntakeVariant'] = 'updateContacts';
  ___arguments.context.pageData['residentIntakeContactsStep'] = 1;
  ___arguments.context.pageData['residentPillarRadio'] = (Number((getObjectProperty(___arguments.context.pageData, 'residentIntakeForm.pillarInfo.pillar'))));
  ___arguments.context.pageData['isAddRecordModalOpen'] = true;
}


  if ((getObjectProperty(___arguments.context.pageData, 'residentIntakeForm.pillarInfo.pillar')) == 1) {
    if ((getObjectProperty(___arguments.context.pageData, 'residentIntakeForm.contactArray')).length != 1) {
      await enterContactsForm();
    }
  } else if ((getObjectProperty(___arguments.context.pageData, 'residentIntakeForm.pillarInfo.pillar')) == 2) {
    if ((getObjectProperty(___arguments.context.pageData, 'residentIntakeForm.contactArray')).length != 3) {
      await enterContactsForm();
    }
  } else if ((getObjectProperty(___arguments.context.pageData, 'residentIntakeForm.pillarInfo.pillar')) == 3) {
    if ((getObjectProperty(___arguments.context.pageData, 'residentIntakeForm.contactArray')).length != 5) {
      await enterContactsForm();
    }
  } else if ((getObjectProperty(___arguments.context.pageData, 'residentIntakeForm.pillarInfo.pillar')) == 4) {
    await enterContactsForm();
  }

  },
  /* handler:onClick */
  /* content */
}))

define('./components/reusable/r_ae95a557491cf646d89334f5f43c1d69/logic/2ff6e9441ae93c5b64acca493577dacc/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    var classes;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'residentIntakeForm.pillarInfo.pillar')) == 1) {
    if ((getObjectProperty(___arguments.context.pageData, 'residentIntakeForm.contactArray')).length != 1) {
      classes = ['active_action', 'actionIcon'];
    } else {
      classes = ['inactive_action', 'actionIcon'];
    }
  } else if ((getObjectProperty(___arguments.context.pageData, 'residentIntakeForm.pillarInfo.pillar')) == 2) {
    if ((getObjectProperty(___arguments.context.pageData, 'residentIntakeForm.contactArray')).length != 3) {
      classes = ['active_action', 'actionIcon'];
    } else {
      classes = ['inactive_action', 'actionIcon'];
    }
  } else if ((getObjectProperty(___arguments.context.pageData, 'residentIntakeForm.pillarInfo.pillar')) == 3) {
    if ((getObjectProperty(___arguments.context.pageData, 'residentIntakeForm.contactArray')).length != 5) {
      classes = ['active_action', 'actionIcon'];
    } else {
      classes = ['inactive_action', 'actionIcon'];
    }
  } else if ((getObjectProperty(___arguments.context.pageData, 'residentIntakeForm.pillarInfo.pillar')) == 4) {
    classes = ['active_action', 'actionIcon'];
  } else {
    classes = ['inactive_action', 'actionIcon'];
  }

  return classes

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./components/reusable/r_ae95a557491cf646d89334f5f43c1d69/logic/ebcd0551782ca476d472125ce01937d1/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    var classes;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'residentIntakeForm.pillarInfo.pillar')) == 1) {
    if ((getObjectProperty(___arguments.context.pageData, 'residentIntakeForm.contactArray')).length != 1) {
      classes = ['active_action', 'actionText'];
    } else {
      classes = ['inactive_action', 'actionText'];
    }
  } else if ((getObjectProperty(___arguments.context.pageData, 'residentIntakeForm.pillarInfo.pillar')) == 2) {
    if ((getObjectProperty(___arguments.context.pageData, 'residentIntakeForm.contactArray')).length != 3) {
      classes = ['active_action', 'actionText'];
    } else {
      classes = ['inactive_action', 'actionText'];
    }
  } else if ((getObjectProperty(___arguments.context.pageData, 'residentIntakeForm.pillarInfo.pillar')) == 3) {
    if ((getObjectProperty(___arguments.context.pageData, 'residentIntakeForm.contactArray')).length != 5) {
      classes = ['active_action', 'actionText'];
    } else {
      classes = ['inactive_action', 'actionText'];
    }
  } else if ((getObjectProperty(___arguments.context.pageData, 'residentIntakeForm.pillarInfo.pillar')) == 4) {
    classes = ['active_action', 'actionText'];
  } else {
    classes = ['inactive_action', 'actionText'];
  }

  return classes

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./components/reusable/r_ae95a557491cf646d89334f5f43c1d69/logic/7fe63ab99b91d1967bd559bf76f46fab/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'selectedItemDetailsSelection')) == 'contacts' && (getObjectProperty(___arguments.context.pageData, 'navbarSelection')) == 'residentData') {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('7fe63ab99b91d1967bd559bf76f46fab', true);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('7fe63ab99b91d1967bd559bf76f46fab', false);
  }

  },
  /* handler:onStyleAssignment */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'selectedItemContact')) {
    ___arguments.context.pageData['confirmationOption'] = 'deleteContact';
    ___arguments.context.pageData['isConfirmationModalOpen'] = true;
  }

  },
  /* handler:onClick */
  /* content */
}))

define('./components/reusable/r_ae95a557491cf646d89334f5f43c1d69/logic/46bb1b287589cbc5c41e02735ac372b3/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    var classes;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'selectedItemContact')) {
    classes = ['active_action', 'actionIcon'];
  } else {
    classes = ['inactive_action', 'actionIcon'];
  }

  return classes

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./components/reusable/r_ae95a557491cf646d89334f5f43c1d69/logic/2fbd93f21972ac1de4a518adf8bd3e73/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    var classes;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'selectedItemContact')) {
    classes = ['active_action', 'actionText'];
  } else {
    classes = ['inactive_action', 'actionText'];
  }

  return classes

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./components/reusable/r_ae95a557491cf646d89334f5f43c1d69/logic/e9f7755bacf48d7ad0acba5716dc33ef/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'selectedItemDetailsSelection')) == 'details') {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('e9f7755bacf48d7ad0acba5716dc33ef', true);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('e9f7755bacf48d7ad0acba5716dc33ef', false);
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./components/reusable/r_ae95a557491cf646d89334f5f43c1d69/logic/41d81c0d14da76a1a73ca942707328a1/bundle.js', [], () => ({
  /* content */
  /* handler:onSourceUrlAssignment */
  ['onSourceUrlAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'navbarSelection')) == 'residentData') {
    if (getObjectProperty(___arguments.context.pageData, 'residentIntakeForm.profilePicture')) {
      return (getObjectProperty(___arguments.context.pageData, 'residentIntakeForm.profilePicture'));
    } else {
      return 'https://app.aquax.co/api/files/cyberdive_photos/cd-icon-default-photo.svg';
    }
  } else if ((getObjectProperty(___arguments.context.pageData, 'navbarSelection')) == 'organizations') {
    if (getObjectProperty(___arguments.context.pageData, 'selectedItemOrganization.logo')) {
      return (getObjectProperty(___arguments.context.pageData, 'selectedItemOrganization.logo'));
    } else {
      return 'https://app.aquax.co/api/files/cyberdive_photos/cd-icon-default-photo.svg';
    }
  } else if ((getObjectProperty(___arguments.context.pageData, 'navbarSelection')) == 'userData') {
    if (getObjectProperty(___arguments.context.pageData, 'selectedItemUser.profilePicture')) {
      return (getObjectProperty(___arguments.context.pageData, 'selectedItemUser.profilePicture'));
    } else {
      return 'https://app.aquax.co/api/files/cyberdive_photos/cd-icon-default-photo.svg';
    }
  }

  },
  /* handler:onSourceUrlAssignment */
  /* content */
}))

define('./components/reusable/r_ae95a557491cf646d89334f5f43c1d69/logic/f61122667deb6947b1a80822dbf897b8/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'navbarSelection')) == 'residentData') {
    if (getObjectProperty(___arguments.context.pageData, 'residentIntakeForm.personalDetails.nameInUse')) {
      return (await BackendlessUI.Functions.Custom['fn_9807a958b9f47818617415e4438b5e47']((getObjectProperty(___arguments.context.pageData, 'residentIntakeForm.personalDetails.nameInUse')), 10));
    } else {
      return 'N/A';
    }
  } else if ((getObjectProperty(___arguments.context.pageData, 'navbarSelection')) == 'organizations') {
    if (getObjectProperty(___arguments.context.pageData, 'selectedItemOrganization.orgName')) {
      return (await BackendlessUI.Functions.Custom['fn_9807a958b9f47818617415e4438b5e47']((getObjectProperty(___arguments.context.pageData, 'selectedItemOrganization.orgName')), 10));
    } else {
      return 'N/A';
    }
  } else if ((getObjectProperty(___arguments.context.pageData, 'navbarSelection')) == 'userData') {
    if (getObjectProperty(___arguments.context.pageData, 'selectedItemUser.name')) {
      return (await BackendlessUI.Functions.Custom['fn_9807a958b9f47818617415e4438b5e47']((getObjectProperty(___arguments.context.pageData, 'selectedItemUser.name')), 10));
    } else {
      return 'N/A';
    }
  }

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./components/reusable/r_ae95a557491cf646d89334f5f43c1d69/logic/c32c6bd2f9396a44a9c26a4346199fb9/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'navbarSelection')) == 'residentData') {
    return ((new Date((getObjectProperty(___arguments.context.pageData, 'selectedItemResident.created')))).toLocaleDateString());
  } else if ((getObjectProperty(___arguments.context.pageData, 'navbarSelection')) == 'organizations') {
    return ((new Date((getObjectProperty(___arguments.context.pageData, 'selectedItemOrganization.created')))).toLocaleDateString());
  } else if ((getObjectProperty(___arguments.context.pageData, 'navbarSelection')) == 'userData') {
    return ((new Date((getObjectProperty(___arguments.context.pageData, 'selectedItemUser.created')))).toLocaleDateString());
  }

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./components/reusable/r_ae95a557491cf646d89334f5f43c1d69/logic/aabca0575dd9a71bebc95a477a275019/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'residentIntakeForm.pillarInfo.pillar')) {
    return (String('Pillar ') + String(getObjectProperty(___arguments.context.pageData, 'residentIntakeForm.pillarInfo.pillar')));
  } else {
    return 'N/A';
  }

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./components/reusable/r_ae95a557491cf646d89334f5f43c1d69/logic/4c76ed87006fa9c7590c4cff779242ed/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
    var pillarDays;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  pillarDays = (await BackendlessUI.Functions.Custom['fn_7e3d62cb9ecfca7bbe1d85a6a421c66f']((getObjectProperty(___arguments.context.pageData, 'residentIntakeForm.pillarInfo.pillarStart'))));
  if (pillarDays == 0 || pillarDays == 1) {
    return '1 Day';
  } else {
    return (String(pillarDays) + String(' Days'));
  }

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./components/reusable/r_ae95a557491cf646d89334f5f43c1d69/logic/a41dfae89119cf79e748a392c4c9d497/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'residentIntakeForm.personalDetails.dob')) {
    return (getObjectProperty(___arguments.context.pageData, 'residentIntakeForm.personalDetails.dob'));
  } else {
    return 'N/A';
  }

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./components/reusable/r_ae95a557491cf646d89334f5f43c1d69/logic/d038ddb13553e364090b093a8087e44f/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'residentIntakeForm.personalDetails.dob')) {
    return (await BackendlessUI.Functions.Custom['fn_3c26f63ed44ba06a52e69b84db7a8b7b']((getObjectProperty(___arguments.context.pageData, 'residentIntakeForm.personalDetails.dob'))));
  } else {
    return 'N/A';
  }

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./components/reusable/r_ae95a557491cf646d89334f5f43c1d69/logic/084b889b21e8d07cfed28de8a94bd3de/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'residentIntakeForm.personalDetails.genderIdentity.answer')) {
    return (getObjectProperty(___arguments.context.pageData, 'residentIntakeForm.personalDetails.genderIdentity.answer'));
  } else {
    return 'N/A';
  }

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./components/reusable/r_ae95a557491cf646d89334f5f43c1d69/logic/57333d6c3f525a050c424d7a12ace7fa/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'residentIntakeForm.emergencyContact.fullName')) {
    return (getObjectProperty(___arguments.context.pageData, 'residentIntakeForm.emergencyContact.fullName'));
  } else {
    return 'N/A';
  }

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./components/reusable/r_ae95a557491cf646d89334f5f43c1d69/logic/d4a1942696ec74eedba461ea032f1034/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'residentIntakeForm.emergencyContact.email')) {
    return (getObjectProperty(___arguments.context.pageData, 'residentIntakeForm.emergencyContact.email'));
  } else {
    return 'N/A';
  }

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./components/reusable/r_ae95a557491cf646d89334f5f43c1d69/logic/623991a854c50ccf08c1cc5aedd8d61e/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'residentIntakeForm.emergencyContact.phoneNumber')) {
    return (String('+1') + String(getObjectProperty(___arguments.context.pageData, 'residentIntakeForm.emergencyContact.phoneNumber')));
  } else {
    return 'N/A';
  }

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./components/reusable/r_ae95a557491cf646d89334f5f43c1d69/logic/74361306029d47988564ecd1862b6456/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'residentIntakeForm.emergencyContact.relationship.answer')) {
    return (getObjectProperty(___arguments.context.pageData, 'residentIntakeForm.emergencyContact.relationship.answer'));
  } else {
    return 'N/A';
  }

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./components/reusable/r_ae95a557491cf646d89334f5f43c1d69/logic/9bbb49415a99c5ed7b8bd57d1b8bcdfb/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'residentIntakeForm.navigationDetails.comingFrom')) {
    return (getObjectProperty(___arguments.context.pageData, 'residentIntakeForm.navigationDetails.comingFrom'));
  } else {
    return 'N/A';
  }

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./components/reusable/r_ae95a557491cf646d89334f5f43c1d69/logic/ac08373ccfbec129dd44f805602f9863/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'residentIntakeForm.navigationDetails.howLong')) {
    return (getObjectProperty(___arguments.context.pageData, 'residentIntakeForm.navigationDetails.howLong'));
  } else {
    return 'N/A';
  }

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./components/reusable/r_ae95a557491cf646d89334f5f43c1d69/logic/8c7cc63d150a6f0c21251cedb5686883/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var selectedItemOrganization, selectedItemUser, menuOptionSelection;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function subsequenceFromStartLast(sequence, at1) {
  var start = at1;
  var end = sequence.length - 1 + 1;
  return sequence.slice(start, end);
}


  if ((getObjectProperty(___arguments.context.pageData, 'navbarSelection')) == 'residentData') {
    ___arguments.context.pageData['recordTableVariant'] = 'resident';
    ___arguments.context.pageData['residentIntakeVariant'] = 'viewIntake';
    ___arguments.context.pageData['isNewResident'] = false;
    ___arguments.context.pageData['residentIntakeStep'] = 4;
    ___arguments.context.pageData['isAddRecordModalOpen'] = true;
  } else if ((getObjectProperty(___arguments.context.pageData, 'navbarSelection')) == 'userData') {
    selectedItemUser = (getObjectProperty(___arguments.context.pageData, 'selectedItemUser'));
    menuOptionSelection = (getObjectProperty(___arguments.context.pageData, 'menuOptionSelection'));
    if (selectedItemUser) {
      ___arguments.context.pageData['userUpdateObject'] = (JSON.parse((JSON.stringify(selectedItemUser))));
      (getObjectProperty(___arguments.context.pageData, 'userUpdateObject'))['phoneNumber'] = (await BackendlessUI.Functions.Custom['fn_f282814277b3c0aa13f33d3b2cda111b']((subsequenceFromStartLast((getObjectProperty(___arguments.context.pageData, 'userUpdateObject.phoneNumber')), 2)), 'create'));
      ___arguments.context.pageData['scrollToTopSelectedItem'] = true;
      ___arguments.context.pageData['recordTableVariant'] = 'user';
      ___arguments.context.pageData['isSelectedItemModalOpen'] = true;
      ___arguments.context.pageData['emailError'] = null;
      ___arguments.context.pageData['phoneError'] = null;
      ___arguments.context.pageData['isUserDetailsButtonDisabled'] = ({ [`name`]: true,[`phone`]: true,[`email`]: true,[`role`]: true,[`selectedImage`]: true,[`organization`]: true });
    }
  } else if ((getObjectProperty(___arguments.context.pageData, 'navbarSelection')) == 'organizations') {
    selectedItemOrganization = (getObjectProperty(___arguments.context.pageData, 'selectedItemOrganization'));
    if (selectedItemOrganization) {
      ___arguments.context.pageData['organizationUpdateObject'] = (JSON.parse((JSON.stringify(selectedItemOrganization))));
      ___arguments.context.pageData['selectedItemOrganizationImageFile'] = undefined;
      (getObjectProperty(___arguments.context.pageData, 'organizationUpdateObject'))['billingPhone'] = (await BackendlessUI.Functions.Custom['fn_f282814277b3c0aa13f33d3b2cda111b']((subsequenceFromStartLast((getObjectProperty(___arguments.context.pageData, 'organizationUpdateObject.billingPhone')), 2)), 'create'));
      ___arguments.context.pageData['scrollToTopSelectedItem'] = true;
      ___arguments.context.pageData['recordTableVariant'] = 'organization';
      ___arguments.context.pageData['isSelectedItemModalOpen'] = true;
      ___arguments.context.pageData['isEditOrgButtonDisabled'] = ({ [`orgName`]: true,[`address`]: true,[`website`]: true,[`taxId`]: true,[`billingName`]: true,[`billingPhone`]: true,[`billingEmail`]: true,[`state`]: true,[`zipCode`]: true,[`city`]: true,[`selectedImage`]: true });
    }
  }

  },
  /* handler:onClick */
  /* content */
}))

define('./components/reusable/r_ae95a557491cf646d89334f5f43c1d69/logic/27e9d80471e22c3bedae5a7d1eaaf2cd/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'recordSelection')) == 'deviceRecords') {
    if (!(getObjectProperty(___arguments.context.pageData, 'isMapLoading')) && (!(getObjectProperty(___arguments.context.pageData, 'selectedDeviceLocation')) || !(getObjectProperty(___arguments.context.pageData, 'selectedItemDeviceIMEI')) || (getObjectProperty(___arguments.context.pageData, 'locationError')))) {
      (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('27e9d80471e22c3bedae5a7d1eaaf2cd', true);
    } else {
      (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('27e9d80471e22c3bedae5a7d1eaaf2cd', false);
    }
  } else if ((getObjectProperty(___arguments.context.pageData, 'recordSelection')) == 'residentRecords') {
    if (!(getObjectProperty(___arguments.context.pageData, 'isMapLoading')) && (!(getObjectProperty(___arguments.context.pageData, 'selectedDeviceLocation')) || !(getObjectProperty(___arguments.context.pageData, 'selectedItemResident.deviceIMEI')) || (getObjectProperty(___arguments.context.pageData, 'locationError')))) {
      (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('27e9d80471e22c3bedae5a7d1eaaf2cd', true);
    } else {
      (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('27e9d80471e22c3bedae5a7d1eaaf2cd', false);
    }
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./components/reusable/r_ae95a557491cf646d89334f5f43c1d69/logic/2884b49db05bd55c202697565be430d4/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'isMapLoading')) {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('2884b49db05bd55c202697565be430d4', true);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('2884b49db05bd55c202697565be430d4', false);
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./components/reusable/r_ae95a557491cf646d89334f5f43c1d69/logic/80dcf446aab85d6721dc6dbc7f37a19b/bundle.js', [], () => ({
  /* content */
  /* handler:onMapCenterAssignment */
  ['onMapCenterAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'selectedDeviceLocation')) {
    return (getObjectProperty(___arguments.context.pageData, 'selectedDeviceLocation'));
  }

  },
  /* handler:onMapCenterAssignment */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (!(getObjectProperty(___arguments.context.pageData, 'selectedDeviceLocation')) || (getObjectProperty(___arguments.context.pageData, 'isMapLoading')) || (getObjectProperty(___arguments.context.pageData, 'locationError'))) {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('80dcf446aab85d6721dc6dbc7f37a19b', false);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('80dcf446aab85d6721dc6dbc7f37a19b', true);
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./components/reusable/r_ae95a557491cf646d89334f5f43c1d69/logic/5732a1f22f9b1ec1879e7b7c03f11914/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'selectedItemDetailsSelection')) == 'apps') {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('5732a1f22f9b1ec1879e7b7c03f11914', true);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('5732a1f22f9b1ec1879e7b7c03f11914', false);
  }

  },
  /* handler:onStyleAssignment */
  /* handler:onBeforeMount */
  ['onBeforeMount'](___arguments) {
      ___arguments.context.pageData['residentDateFilter'] = 'today';

  },
  /* handler:onBeforeMount */
  /* content */
}))

define('./components/reusable/r_ae95a557491cf646d89334f5f43c1d69/logic/1e3f58db2afd6ab147449b6e3e4d0fb3/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'residentDateFilter')) == 'today') {
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('1e3f58db2afd6ab147449b6e3e4d0fb3'))['color'] = '#3871FF';
  } else {
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('1e3f58db2afd6ab147449b6e3e4d0fb3'))['color'] = '#000000';
  }

  },
  /* handler:onStyleAssignment */
  /* handler:onClick */
  ['onClick'](___arguments) {
      ___arguments.context.pageData['residentDateFilter'] = 'today';

  },
  /* handler:onClick */
  /* content */
}))

define('./components/reusable/r_ae95a557491cf646d89334f5f43c1d69/logic/0357e5ffcc7897da1fbeb0b784253da3/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'residentDateFilter')) == 'week') {
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('0357e5ffcc7897da1fbeb0b784253da3'))['color'] = '#3871FF';
  } else {
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('0357e5ffcc7897da1fbeb0b784253da3'))['color'] = '#000000';
  }

  },
  /* handler:onStyleAssignment */
  /* handler:onClick */
  ['onClick'](___arguments) {
      ___arguments.context.pageData['residentDateFilter'] = 'week';

  },
  /* handler:onClick */
  /* content */
}))

define('./components/reusable/r_ae95a557491cf646d89334f5f43c1d69/logic/6febdf1b8e2550758c183b219ca1a502/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'residentDateFilter')) == 'month') {
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('6febdf1b8e2550758c183b219ca1a502'))['color'] = '#3871FF';
  } else {
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('6febdf1b8e2550758c183b219ca1a502'))['color'] = '#000000';
  }

  },
  /* handler:onStyleAssignment */
  /* handler:onClick */
  ['onClick'](___arguments) {
      ___arguments.context.pageData['residentDateFilter'] = 'month';

  },
  /* handler:onClick */
  /* content */
}))

define('./components/reusable/r_ae95a557491cf646d89334f5f43c1d69/logic/ec69ce74495a100a49224b64811d2ad8/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'selectedItemResident.deviceIMEI')) {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('ec69ce74495a100a49224b64811d2ad8', true);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('ec69ce74495a100a49224b64811d2ad8', false);
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./components/reusable/r_ae95a557491cf646d89334f5f43c1d69/logic/4e19b48ed96b959f170677ba1da7bf9b/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'residentIntakeForm.pillarInfo.pillar')) != 4) {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('4e19b48ed96b959f170677ba1da7bf9b', true);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('4e19b48ed96b959f170677ba1da7bf9b', false);
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./components/reusable/r_ae95a557491cf646d89334f5f43c1d69/logic/da05b65a5c76c363a5ce3b73a51d85a7/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'isLoadingInstalledApps')) || (getObjectProperty(___arguments.context.pageData, 'installedAppsError'))) {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('da05b65a5c76c363a5ce3b73a51d85a7', false);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('da05b65a5c76c363a5ce3b73a51d85a7', true);
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./components/reusable/r_ae95a557491cf646d89334f5f43c1d69/logic/6eee2875172c425e22a1d5d38691db6a/bundle.js', [], () => ({
  /* content */
  /* handler:onSourceUrlAssignment */
  ['onSourceUrlAssignment'](___arguments) {
    var response, item, listOfApplications, application, listOfSystemApps;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.getComponentDataStoreByUid('c7a9230b7186f3120e270fba592c5259'), 'AppIcon')) {
    return (getObjectProperty(___arguments.context.getComponentDataStoreByUid('c7a9230b7186f3120e270fba592c5259'), 'AppIcon'));
  } else {
    return 'https://app.aquax.co/api/files/cyberdive_photos/cd-icon-default-photo.svg';
  }

  },
  /* handler:onSourceUrlAssignment */
  /* content */
}))

define('./components/reusable/r_ae95a557491cf646d89334f5f43c1d69/logic/9fbc9cf8e2a5f02e39e428c599be537d/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'isLoadingInstalledApps')) {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('9fbc9cf8e2a5f02e39e428c599be537d', true);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('9fbc9cf8e2a5f02e39e428c599be537d', false);
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./components/reusable/r_ae95a557491cf646d89334f5f43c1d69/logic/eaf66cf710130a0ada54e3fbe3ba92ff/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (!(getObjectProperty(___arguments.context.pageData, 'isLoadingInstalledApps')) && ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid).dynamicListItems })('da05b65a5c76c363a5ce3b73a51d85a7')).length == 0) {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('eaf66cf710130a0ada54e3fbe3ba92ff', true);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('eaf66cf710130a0ada54e3fbe3ba92ff', false);
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./components/reusable/r_ae95a557491cf646d89334f5f43c1d69/logic/521f2e550186f0732367944fdad935e7/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'residentIntakeForm.pillarInfo.pillar')) == 4) {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('521f2e550186f0732367944fdad935e7', true);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('521f2e550186f0732367944fdad935e7', false);
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./components/reusable/r_ae95a557491cf646d89334f5f43c1d69/logic/43640198378c4d09f01efc736c6c73b8/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'residentIntakeForm.pillarInfo.pillar')) == 4) {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('43640198378c4d09f01efc736c6c73b8', true);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('43640198378c4d09f01efc736c6c73b8', false);
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./components/reusable/r_ae95a557491cf646d89334f5f43c1d69/logic/826d9ff5deeb64ff616b0cebe6b34fd1/bundle.js', [], () => ({
  /* content */
  /* handler:onSourceUrlAssignment */
  ['onSourceUrlAssignment'](___arguments) {
      return 'https://app.aquax.co/api/files/cyberdive_photos/cd-icon-default-photo.svg';

  },
  /* handler:onSourceUrlAssignment */
  /* content */
}))

define('./components/reusable/r_ae95a557491cf646d89334f5f43c1d69/logic/b08a05891a12d62a7357e0a58e4b2475/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'isLoadingUnistalledApps')) {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('b08a05891a12d62a7357e0a58e4b2475', true);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('b08a05891a12d62a7357e0a58e4b2475', false);
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./components/reusable/r_ae95a557491cf646d89334f5f43c1d69/logic/bb1d72a3c53197ecede374efe9c3459e/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (!(getObjectProperty(___arguments.context.pageData, 'isLoadingUninstalledApps')) && ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid).dynamicListItems })('212ff173c315b254f2934463dff4ed0e')).length == 0) {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('bb1d72a3c53197ecede374efe9c3459e', true);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('bb1d72a3c53197ecede374efe9c3459e', false);
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./components/reusable/r_ae95a557491cf646d89334f5f43c1d69/logic/515bf7a8096dbfaae33e395f7403af73/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'residentIntakeForm.pillarInfo.pillar')) != 4) {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('515bf7a8096dbfaae33e395f7403af73', true);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('515bf7a8096dbfaae33e395f7403af73', false);
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./components/reusable/r_ae95a557491cf646d89334f5f43c1d69/logic/26970fbb1d7261268672b2f3865c6353/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (!(getObjectProperty(___arguments.context.pageData, 'selectedItemResident.deviceIMEI'))) {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('26970fbb1d7261268672b2f3865c6353', true);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('26970fbb1d7261268672b2f3865c6353', false);
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./components/reusable/r_ae95a557491cf646d89334f5f43c1d69/logic/6e6f8f28c315d619ccddaf6921f51ea9/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'selectedItemResident.deviceStatus')) != 'ASSIGNED') {
    await BackendlessUI.Functions.Custom['fn_d48a6d3a366b0ef43b6e0b4bab07fa46'](___arguments.context.pageData);
    ___arguments.context.pageData['isNewResident'] = false;
    ___arguments.context.pageData['hasStoppedPolling'] = undefined;
    ___arguments.context.pageData['hasOnboardStarted'] = undefined;
    ___arguments.context.pageData['residentIntakeStep'] = undefined;
    ___arguments.context.pageData['recordTableVariant'] = 'resident';
    ___arguments.context.pageData['residentPillarRadio'] = undefined;
    ___arguments.context.pageData['residentIntakeVariant'] = 'pillar';
    ___arguments.context.pageData['isAddRecordModalOpen'] = true;
  }

  },
  /* handler:onClick */
  /* content */
}))

define('./components/reusable/r_ae95a557491cf646d89334f5f43c1d69/logic/126d089c599dc89713ed2e78933d588c/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'selectedItemDetailsSelection')) == 'contacts') {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('126d089c599dc89713ed2e78933d588c', true);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('126d089c599dc89713ed2e78933d588c', false);
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./components/reusable/r_ae95a557491cf646d89334f5f43c1d69/logic/7135c491a10484395e5eac81db8bbd97/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (!(getObjectProperty(___arguments.context.pageData, 'residentIntakeForm.contactArray')) || (getObjectProperty(___arguments.context.pageData, 'residentIntakeForm.contactArray')).length == 0) {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('7135c491a10484395e5eac81db8bbd97', false);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('7135c491a10484395e5eac81db8bbd97', true);
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./components/reusable/r_ae95a557491cf646d89334f5f43c1d69/logic/abb86f885e4cf8beb29a3392ecd1ff74/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'residentIntakeForm')) {
    (function (componentUid, listItems) { ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('abb86f885e4cf8beb29a3392ecd1ff74', (getObjectProperty(___arguments.context.pageData, 'residentIntakeForm.contactArray')));
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./components/reusable/r_ae95a557491cf646d89334f5f43c1d69/logic/e2b1b0b65dbae0f752464f0b46debc9c/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'selectedItemContact')) == ___arguments.context.dataModel) {
    ___arguments.context.pageData['selectedItemContact'] = undefined;
  } else {
    ___arguments.context.pageData['selectedItemContact'] = ___arguments.context.dataModel;
  }

  },
  /* handler:onClick */
  /* content */
}))

define('./components/reusable/r_ae95a557491cf646d89334f5f43c1d69/logic/35160cf8b5f9b037e93bbc39f636e716/bundle.js', [], () => ({
  /* content */
  /* handler:onCheckedStateAssignment */
  ['onCheckedStateAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'selectedItemContact')) == ___arguments.context.getComponentDataStoreByUid('e2b1b0b65dbae0f752464f0b46debc9c')) {
    return true;
  } else {
    return false;
  }

  },
  /* handler:onCheckedStateAssignment */
  /* content */
}))

define('./components/reusable/r_ae95a557491cf646d89334f5f43c1d69/logic/e66c507c3e815a0ef36157d9a4d27541/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    var contact;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  var contact_list = (getObjectProperty(___arguments.context.pageData, 'residentIntakeForm.contactArray'));
  for (var contact_index in contact_list) {
    contact = contact_list[contact_index];
    if ((getObjectProperty(contact, 'index')) == 1) {
      ___arguments.context.pageData['residentContactFullNameOne'] = (getObjectProperty(contact, 'fullName'));
      ___arguments.context.pageData['residentContactCellPhoneOne'] = (getObjectProperty(contact, 'cellPhone'));
      ___arguments.context.pageData['residentContactHomePhoneOne'] = (getObjectProperty(contact, 'homePhone'));
    } else if ((getObjectProperty(contact, 'index')) == 2) {
      ___arguments.context.pageData['residentContactFullNameTwo'] = (getObjectProperty(contact, 'fullName'));
      ___arguments.context.pageData['residentContactCellPhoneTwo'] = (getObjectProperty(contact, 'cellPhone'));
      ___arguments.context.pageData['residentContactHomePhoneTwo'] = (getObjectProperty(contact, 'homePhone'));
    } else if ((getObjectProperty(contact, 'index')) == 3) {
      ___arguments.context.pageData['residentContactFullNameThree'] = (getObjectProperty(contact, 'fullName'));
      ___arguments.context.pageData['residentContactCellPhoneThree'] = (getObjectProperty(contact, 'cellPhone'));
      ___arguments.context.pageData['residentContactHomePhoneThree'] = (getObjectProperty(contact, 'homePhone'));
    } else if ((getObjectProperty(contact, 'index')) == 4) {
      ___arguments.context.pageData['residentContactFullNameFour'] = (getObjectProperty(contact, 'fullName'));
      ___arguments.context.pageData['residentContactCellPhoneFour'] = (getObjectProperty(contact, 'cellPhone'));
      ___arguments.context.pageData['residentContactHomePhoneFour'] = (getObjectProperty(contact, 'homePhone'));
    } else if ((getObjectProperty(contact, 'index')) == 5) {
      ___arguments.context.pageData['residentContactFullNameFive'] = (getObjectProperty(contact, 'fullName'));
      ___arguments.context.pageData['residentContactCellPhoneFive'] = (getObjectProperty(contact, 'cellPhone'));
      ___arguments.context.pageData['residentContactHomePhoneFive'] = (getObjectProperty(contact, 'homePhone'));
    }
  }
  ___arguments.context.pageData['recordTableVariant'] = 'resident';
  ___arguments.context.pageData['residentIntakeVariant'] = 'updateContacts';
  ___arguments.context.pageData['residentIntakeContactsStep'] = 1;
  ___arguments.context.pageData['residentPillarRadio'] = (Number((getObjectProperty(___arguments.context.pageData, 'selectedItemResident.pillar'))));
  ___arguments.context.pageData['isAddRecordModalOpen'] = true;

  },
  /* handler:onClick */
  /* content */
}))

define('./components/reusable/r_ae95a557491cf646d89334f5f43c1d69/logic/5d9813c61fe7a2714db153121a2967b3/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (!(getObjectProperty(___arguments.context.pageData, 'residentIntakeForm.contactArray')) || (getObjectProperty(___arguments.context.pageData, 'residentIntakeForm.contactArray')).length == 0) {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('5d9813c61fe7a2714db153121a2967b3', true);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('5d9813c61fe7a2714db153121a2967b3', false);
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./components/reusable/r_ae95a557491cf646d89334f5f43c1d69/logic/6768501de35166bcb4e5435779fa0116/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (!(getObjectProperty(___arguments.context.pageData, 'residentIntakeForm.contactArray'))) {
    return 'No Contacts Yet';
  } else if ((getObjectProperty(___arguments.context.pageData, 'residentIntakeForm.contactArray')) && (getObjectProperty(___arguments.context.pageData, 'residentIntakeForm.contactArray')).length == 0) {
    return 'No Contacts';
  }

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./components/reusable/r_ae95a557491cf646d89334f5f43c1d69/logic/c7342965c7ef9d369bb6092ceadb7fe7/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (!(getObjectProperty(___arguments.context.pageData, 'residentIntakeForm.contactArray'))) {
    return 'Please enroll a device for this resident to add contacts!';
  } else if ((getObjectProperty(___arguments.context.pageData, 'residentIntakeForm.contactArray')) && (getObjectProperty(___arguments.context.pageData, 'residentIntakeForm.contactArray')).length == 0) {
    return 'Please add contacts to view and edit!';
  }

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./components/reusable/r_ae95a557491cf646d89334f5f43c1d69/logic/e28340a5320ac57378efb282bd63aee4/bundle.js', [], () => ({
  /* content */
  /* handler:onLabelAssignment */
  ['onLabelAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (!(getObjectProperty(___arguments.context.pageData, 'residentIntakeForm.contactArray'))) {
    return 'Assign Device';
  } else if ((getObjectProperty(___arguments.context.pageData, 'residentIntakeForm.contactArray')) && (getObjectProperty(___arguments.context.pageData, 'residentIntakeForm.contactArray')).length == 0) {
    return 'Add Contacts';
  }

  },
  /* handler:onLabelAssignment */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var contact;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

/**
 * Describe this function...
 */
async function enterContactsForm() {
  var contact_list = (getObjectProperty(___arguments.context.pageData, 'residentIntakeForm.contactArray'));
  for (var contact_index in contact_list) {
    contact = contact_list[contact_index];
    if ((getObjectProperty(contact, 'index')) == 1) {
      ___arguments.context.pageData['residentContactFullNameOne'] = (getObjectProperty(contact, 'fullName'));
      ___arguments.context.pageData['residentContactCellPhoneOne'] = (getObjectProperty(contact, 'cellPhone'));
      ___arguments.context.pageData['residentContactHomePhoneOne'] = (getObjectProperty(contact, 'homePhone'));
    } else if ((getObjectProperty(contact, 'index')) == 2) {
      ___arguments.context.pageData['residentContactFullNameTwo'] = (getObjectProperty(contact, 'fullName'));
      ___arguments.context.pageData['residentContactCellPhoneTwo'] = (getObjectProperty(contact, 'cellPhone'));
      ___arguments.context.pageData['residentContactHomePhoneTwo'] = (getObjectProperty(contact, 'homePhone'));
    } else if ((getObjectProperty(contact, 'index')) == 3) {
      ___arguments.context.pageData['residentContactFullNameThree'] = (getObjectProperty(contact, 'fullName'));
      ___arguments.context.pageData['residentContactCellPhoneThree'] = (getObjectProperty(contact, 'cellPhone'));
      ___arguments.context.pageData['residentContactHomePhoneThree'] = (getObjectProperty(contact, 'homePhone'));
    } else if ((getObjectProperty(contact, 'index')) == 4) {
      ___arguments.context.pageData['residentContactFullNameFour'] = (getObjectProperty(contact, 'fullName'));
      ___arguments.context.pageData['residentContactCellPhoneFour'] = (getObjectProperty(contact, 'cellPhone'));
      ___arguments.context.pageData['residentContactHomePhoneFour'] = (getObjectProperty(contact, 'homePhone'));
    } else if ((getObjectProperty(contact, 'index')) == 5) {
      ___arguments.context.pageData['residentContactFullNameFive'] = (getObjectProperty(contact, 'fullName'));
      ___arguments.context.pageData['residentContactCellPhoneFive'] = (getObjectProperty(contact, 'cellPhone'));
      ___arguments.context.pageData['residentContactHomePhoneFive'] = (getObjectProperty(contact, 'homePhone'));
    }
  }
  ___arguments.context.pageData['recordTableVariant'] = 'resident';
  ___arguments.context.pageData['residentIntakeVariant'] = 'updateContacts';
  ___arguments.context.pageData['residentIntakeContactsStep'] = 1;
  ___arguments.context.pageData['residentPillarRadio'] = (Number((getObjectProperty(___arguments.context.pageData, 'residentIntakeForm.pillarInfo.pillar'))));
  ___arguments.context.pageData['isAddRecordModalOpen'] = true;
}


  if (!(getObjectProperty(___arguments.context.pageData, 'residentIntakeForm.contactArray'))) {
    if ((getObjectProperty(___arguments.context.pageData, 'selectedItemResident.deviceStatus')) != 'ASSIGNED') {
      await BackendlessUI.Functions.Custom['fn_d48a6d3a366b0ef43b6e0b4bab07fa46'](___arguments.context.pageData);
      ___arguments.context.pageData['isNewResident'] = false;
      ___arguments.context.pageData['hasStoppedPolling'] = undefined;
      ___arguments.context.pageData['hasOnboardStarted'] = undefined;
      ___arguments.context.pageData['residentIntakeStep'] = undefined;
      ___arguments.context.pageData['recordTableVariant'] = 'resident';
      ___arguments.context.pageData['residentPillarRadio'] = undefined;
      ___arguments.context.pageData['residentIntakeVariant'] = 'pillar';
      ___arguments.context.pageData['isAddRecordModalOpen'] = true;
    }
  } else if ((getObjectProperty(___arguments.context.pageData, 'residentIntakeForm.contactArray')) && (getObjectProperty(___arguments.context.pageData, 'residentIntakeForm.contactArray')).length == 0) {
    if ((getObjectProperty(___arguments.context.pageData, 'residentIntakeForm.pillarInfo.pillar')) == 1) {
      if ((getObjectProperty(___arguments.context.pageData, 'residentIntakeForm.contactArray')).length != 1) {
        await enterContactsForm();
      }
    } else if ((getObjectProperty(___arguments.context.pageData, 'residentIntakeForm.pillarInfo.pillar')) == 2) {
      if ((getObjectProperty(___arguments.context.pageData, 'residentIntakeForm.contactArray')).length != 3) {
        await enterContactsForm();
      }
    } else if ((getObjectProperty(___arguments.context.pageData, 'residentIntakeForm.pillarInfo.pillar')) == 3) {
      if ((getObjectProperty(___arguments.context.pageData, 'residentIntakeForm.contactArray')).length != 5) {
        await enterContactsForm();
      }
    } else if ((getObjectProperty(___arguments.context.pageData, 'residentIntakeForm.pillarInfo.pillar')) == 4) {
      await enterContactsForm();
    }
  }

  },
  /* handler:onClick */
  /* content */
}))

define('./components/reusable/r_ae95a557491cf646d89334f5f43c1d69/logic/f6e6a7b51bffa296b4711e467f6d8d86/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'navbarSelection')) == 'residentData') {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('f6e6a7b51bffa296b4711e467f6d8d86', true);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('f6e6a7b51bffa296b4711e467f6d8d86', false);
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./components/reusable/r_ae95a557491cf646d89334f5f43c1d69/logic/0adf3d9cad95727be039ba42c923b367/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'navbarSelection')) == 'organizations') {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('0adf3d9cad95727be039ba42c923b367', true);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('0adf3d9cad95727be039ba42c923b367', false);
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./components/reusable/r_ae95a557491cf646d89334f5f43c1d69/logic/cd1560e1fbee94fe697cf7a4d48ad57f/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    var classesList;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'selectedItemOrganization.status')) == 'ACTIVE') {
    classesList = ['active_status_block'];
  } else if ((getObjectProperty(___arguments.context.pageData, 'selectedItemOrganization.status')) == 'DEACTIVATED') {
    classesList = ['deactivated_status_block'];
  }

  return classesList

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./components/reusable/r_ae95a557491cf646d89334f5f43c1d69/logic/630ca103c8e9a6b27deac17f1fe18a8f/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    var classesList;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'selectedItemOrganization.status')) == 'ACTIVE') {
    classesList = ['active_status_block_icon'];
  } else if ((getObjectProperty(___arguments.context.pageData, 'selectedItemOrganization.status')) == 'DEACTIVATED') {
    classesList = ['deactivated_status_block_icon'];
  }

  return classesList

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./components/reusable/r_ae95a557491cf646d89334f5f43c1d69/logic/a006642b4f42b491eea3a70daa1fbeb7/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    var classesList;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'selectedItemOrganization.status')) == 'ACTIVE') {
    return 'Active';
  } else if ((getObjectProperty(___arguments.context.pageData, 'selectedItemOrganization.status')) == 'DEACTIVATED') {
    return 'Deactivated';
  }

  },
  /* handler:onContentAssignment */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    var classesList;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'selectedItemOrganization.status')) == 'ACTIVE') {
    classesList = ['active_status_block_text'];
  } else if ((getObjectProperty(___arguments.context.pageData, 'selectedItemOrganization.status')) == 'DEACTIVATED') {
    classesList = ['deactivated_status_block_text'];
  }

  return classesList

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./components/reusable/r_ae95a557491cf646d89334f5f43c1d69/logic/80e44093d5924e3fdfd9f74ff063e9d9/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((new Date((getObjectProperty(___arguments.context.pageData, 'selectedItemOrganization.created')))).toLocaleDateString())

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./components/reusable/r_ae95a557491cf646d89334f5f43c1d69/logic/43915b08f1ab1df8267ef54df45db25a/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((new Date((getObjectProperty(___arguments.context.pageData, 'selectedItemOrganization.updated')))).toLocaleDateString())

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./components/reusable/r_ae95a557491cf646d89334f5f43c1d69/logic/24339194283b6549c9d3aa9531b84403/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (getObjectProperty(___arguments.context.pageData, 'selectedItemOrganization.address'))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./components/reusable/r_ae95a557491cf646d89334f5f43c1d69/logic/1d09d885d501e2dc38957022ac0ad896/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (await BackendlessUI.Functions.Custom['fn_9807a958b9f47818617415e4438b5e47']((getObjectProperty(___arguments.context.pageData, 'selectedItemOrganization.websiteLink')), 16))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./components/reusable/r_ae95a557491cf646d89334f5f43c1d69/logic/ca55c2522abcc51a0f85d56978ca7fc5/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (getObjectProperty(___arguments.context.pageData, 'selectedItemOrganization.billingName'))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./components/reusable/r_ae95a557491cf646d89334f5f43c1d69/logic/910b24e29ba2dfd7d7b7eaeaffb2e1e7/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function subsequenceFromStartLast(sequence, at1) {
  var start = at1;
  var end = sequence.length - 1 + 1;
  return sequence.slice(start, end);
}


  if (getObjectProperty(___arguments.context.pageData, 'selectedItemOrganization.billingPhone')) {
    return (await BackendlessUI.Functions.Custom['fn_f282814277b3c0aa13f33d3b2cda111b']((subsequenceFromStartLast((getObjectProperty(___arguments.context.pageData, 'selectedItemOrganization.billingPhone')), 2)), 'create'));
  }

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./components/reusable/r_ae95a557491cf646d89334f5f43c1d69/logic/81791aff74723af58c4bbcf20bc2f8c1/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (await BackendlessUI.Functions.Custom['fn_9807a958b9f47818617415e4438b5e47']((getObjectProperty(___arguments.context.pageData, 'selectedItemOrganization.billingEmail')), 16))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./components/reusable/r_ae95a557491cf646d89334f5f43c1d69/logic/40c6e021ca5c21b11ba62ec289599962/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (getObjectProperty(___arguments.context.pageData, 'selectedItemOrganization.taxId'))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./components/reusable/r_ae95a557491cf646d89334f5f43c1d69/logic/1ec9ee95d7e91ad2fbf1e6c3aa750fd9/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var menuOptionSelection, navbarSelection, selectedItemUser, selectedItemOrganization;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function subsequenceFromStartLast(sequence, at1) {
  var start = at1;
  var end = sequence.length - 1 + 1;
  return sequence.slice(start, end);
}


  navbarSelection = (getObjectProperty(___arguments.context.pageData, 'navbarSelection'));
  ___arguments.context.pageData['menuOptionSelection'] = undefined;
  if (navbarSelection == 'organizations') {
    selectedItemOrganization = (getObjectProperty(___arguments.context.pageData, 'selectedItemOrganization'));
    if (selectedItemOrganization) {
      ___arguments.context.pageData['organizationUpdateObject'] = (JSON.parse((JSON.stringify(selectedItemOrganization))));
      (getObjectProperty(___arguments.context.pageData, 'organizationUpdateObject'))['billingPhone'] = (await BackendlessUI.Functions.Custom['fn_f282814277b3c0aa13f33d3b2cda111b']((subsequenceFromStartLast((getObjectProperty(___arguments.context.pageData, 'organizationUpdateObject.billingPhone')), 2)), 'create'));
      ___arguments.context.pageData['scrollToTopSelectedItem'] = true;
      ___arguments.context.pageData['recordTableVariant'] = 'organization';
      ___arguments.context.pageData['isSelectedItemModalOpen'] = true;
      ___arguments.context.pageData['isEditOrgButtonDisabled'] = ({ [`orgName`]: true,[`address`]: true,[`website`]: true,[`taxId`]: true,[`billingName`]: true,[`billingPhone`]: true,[`billingEmail`]: true,[`state`]: true,[`zipCode`]: true,[`city`]: true,[`selectedImage`]: true });
    }
  } else if (navbarSelection == 'userData') {
    selectedItemUser = (getObjectProperty(___arguments.context.pageData, 'selectedItemUser'));
    menuOptionSelection = (getObjectProperty(___arguments.context.pageData, 'menuOptionSelection'));
    if (selectedItemUser) {
      ___arguments.context.pageData['userUpdateObject'] = (JSON.parse((JSON.stringify(selectedItemUser))));
      (getObjectProperty(___arguments.context.pageData, 'userUpdateObject'))['phoneNumber'] = (await BackendlessUI.Functions.Custom['fn_f282814277b3c0aa13f33d3b2cda111b']((subsequenceFromStartLast((getObjectProperty(___arguments.context.pageData, 'userUpdateObject.phoneNumber')), 2)), 'create'));
      ___arguments.context.pageData['scrollToTopSelectedItem'] = true;
      ___arguments.context.pageData['recordTableVariant'] = 'user';
      ___arguments.context.pageData['isSelectedItemModalOpen'] = true;
      ___arguments.context.pageData['emailError'] = null;
      ___arguments.context.pageData['phoneError'] = null;
      ___arguments.context.pageData['isUserDetailsButtonDisabled'] = ({ [`name`]: true,[`phone`]: true,[`email`]: true,[`role`]: true,[`selectedImage`]: true,[`organization`]: true });
    }
  }

  },
  /* handler:onClick */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'navbarSelection')) == 'organizations' || (getObjectProperty(___arguments.context.pageData, 'navbarSelection')) == 'userData') {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('1ec9ee95d7e91ad2fbf1e6c3aa750fd9', true);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('1ec9ee95d7e91ad2fbf1e6c3aa750fd9', false);
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./components/reusable/r_ae95a557491cf646d89334f5f43c1d69/logic/619201d1788f10db513bcc537df30faf/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'navbarSelection')) == 'organizations') {
    return 'Edit Org';
  } else if ((getObjectProperty(___arguments.context.pageData, 'navbarSelection')) == 'userData') {
    return 'Edit User';
  }

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./components/reusable/r_ae95a557491cf646d89334f5f43c1d69/logic/19948d546f7e920b243cc8e5f1fd0181/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'navbarSelection')) == 'residentData') {
    return 'Current Location';
  } else if ((getObjectProperty(___arguments.context.pageData, 'navbarSelection')) == 'organizations') {
    return 'Organization Location';
  }

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./components/reusable/r_ae95a557491cf646d89334f5f43c1d69/logic/fc3f55caa424b87cbc53749769e89ab3/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    var classesList;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'selectedItemUser.status')) == 'ACTIVE') {
    classesList = ['active_status_block'];
  } else if ((getObjectProperty(___arguments.context.pageData, 'selectedItemUser.inviteStatus')) == 'PENDING' || (getObjectProperty(___arguments.context.pageData, 'selectedItemUser.inviteStatus')) == 'RESENT') {
    classesList = ['pending_status_block'];
  } else if ((getObjectProperty(___arguments.context.pageData, 'selectedItemUser.status')) == 'LOCKED') {
    classesList = ['incomplete_status_block'];
  } else if ((getObjectProperty(___arguments.context.pageData, 'selectedItemUser.status')) == 'DEACTIVATED') {
    classesList = ['deactivated_status_block'];
  }

  return classesList

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./components/reusable/r_ae95a557491cf646d89334f5f43c1d69/logic/967a123a33945fe8d872447fa8cff7ee/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    var classesList;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'selectedItemUser.status')) == 'ACTIVE') {
    classesList = ['active_status_block_text'];
  } else if ((getObjectProperty(___arguments.context.pageData, 'selectedItemUser.inviteStatus')) == 'PENDING' || (getObjectProperty(___arguments.context.pageData, 'selectedItemUser.inviteStatus')) == 'RESENT') {
    classesList = ['pending_status_block_text'];
  } else if ((getObjectProperty(___arguments.context.pageData, 'selectedItemUser.status')) == 'DEACTIVATED') {
    classesList = ['deactivated_status_block_text'];
  } else if ((getObjectProperty(___arguments.context.pageData, 'selectedItemUser.status')) == 'LOCKED') {
    classesList = ['incomplete_status_block_text'];
  }

  return classesList

  },
  /* handler:onClassListAssignment */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'selectedItemUser.status')) == 'ACTIVE') {
    return 'Active';
  } else if ((getObjectProperty(___arguments.context.pageData, 'selectedItemUser.inviteStatus')) == 'PENDING') {
    return 'Invite Sent';
  } else if ((getObjectProperty(___arguments.context.pageData, 'selectedItemUser.inviteStatus')) == 'RESENT') {
    return 'Invite Resent';
  } else if ((getObjectProperty(___arguments.context.pageData, 'selectedItemUser.status')) == 'DEACTIVATED') {
    return 'Deactivated';
  } else if ((getObjectProperty(___arguments.context.pageData, 'selectedItemUser.status')) == 'LOCKED') {
    return 'Locked';
  }

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./components/reusable/r_ae95a557491cf646d89334f5f43c1d69/logic/e67d3d92498869872ec4b93b831b1182/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    var classesList;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'selectedItemUser.status')) == 'ACTIVE') {
    classesList = ['active_status_block_icon'];
  } else if ((getObjectProperty(___arguments.context.pageData, 'selectedItemUser.status')) == 'DEACTIVATED') {
    classesList = ['deactivated_status_block_icon'];
  }

  return classesList

  },
  /* handler:onClassListAssignment */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'selectedItemUser.inviteStatus')) == 'PENDING' || (getObjectProperty(___arguments.context.pageData, 'selectedItemUser.inviteStatus')) == 'RESENT' || (getObjectProperty(___arguments.context.pageData, 'selectedItemUser.status')) == 'LOCKED') {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('e67d3d92498869872ec4b93b831b1182', false);
  } else if ((getObjectProperty(___arguments.context.pageData, 'selectedItemUser.status')) == 'ACTIVE' || (getObjectProperty(___arguments.context.pageData, 'selectedItemUser.status')) == 'DEACTIVATED') {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('e67d3d92498869872ec4b93b831b1182', true);
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./components/reusable/r_ae95a557491cf646d89334f5f43c1d69/logic/63b96d4514cce11d27e46f9753f28796/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'selectedItemUser.status')) == 'LOCKED') {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('63b96d4514cce11d27e46f9753f28796', true);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('63b96d4514cce11d27e46f9753f28796', false);
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./components/reusable/r_ae95a557491cf646d89334f5f43c1d69/logic/e24196bcb5eb7dcc8c5ac69324e44233/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'selectedItemUser.userRole')) == 'admin') {
    return 'Admin';
  } else if ((getObjectProperty(___arguments.context.pageData, 'selectedItemUser.userRole')) == 'manager') {
    return 'Manager';
  }

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./components/reusable/r_ae95a557491cf646d89334f5f43c1d69/logic/62f88948a731be2f929d719f15491e06/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (await BackendlessUI.Functions.Custom['fn_9807a958b9f47818617415e4438b5e47']((getObjectProperty(___arguments.context.pageData, 'selectedItemUser.orgName')), 13))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./components/reusable/r_ae95a557491cf646d89334f5f43c1d69/logic/a6a56aadc2692be3e111306382399b83/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function subsequenceFromStartLast(sequence, at1) {
  var start = at1;
  var end = sequence.length - 1 + 1;
  return sequence.slice(start, end);
}


  if (getObjectProperty(___arguments.context.pageData, 'selectedItemUser.phoneNumber')) {
    return (await BackendlessUI.Functions.Custom['fn_f282814277b3c0aa13f33d3b2cda111b']((subsequenceFromStartLast((getObjectProperty(___arguments.context.pageData, 'selectedItemUser.phoneNumber')), 2)), 'create'));
  }

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./components/reusable/r_ae95a557491cf646d89334f5f43c1d69/logic/8f694e94bf614c98f8e15572c206f05b/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((new Date((getObjectProperty(___arguments.context.pageData, 'selectedItemUser.created')))).toLocaleDateString())

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./components/reusable/r_ae95a557491cf646d89334f5f43c1d69/logic/6f0f5249fd4518699d616fe2d1b433ec/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((new Date((getObjectProperty(___arguments.context.pageData, 'selectedItemUser.updated')))).toLocaleDateString())

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./components/reusable/r_ae95a557491cf646d89334f5f43c1d69/logic/ada6e016a1e3c1ecb55e4f5d03a25d43/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (await BackendlessUI.Functions.Custom['fn_9807a958b9f47818617415e4438b5e47']((getObjectProperty(___arguments.context.pageData, 'selectedItemUser.email')), 13))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./components/reusable/r_ae95a557491cf646d89334f5f43c1d69/logic/8d283526ff9fd16adbeac159436d54ea/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    var selectedItemUser;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  selectedItemUser = (getObjectProperty(___arguments.context.pageData, 'selectedItemUser'));
  if (selectedItemUser) {
    if ((getObjectProperty(selectedItemUser, 'inviteStatus')) == 'PENDING' || (getObjectProperty(selectedItemUser, 'inviteStatus')) == 'RESENT') {
      ___arguments.context.pageData['isConfirmationModalOpen'] = true;
      ___arguments.context.pageData['confirmationOption'] = 'resendUser';
    }
  }

  },
  /* handler:onClick */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'navbarSelection')) == 'userData') {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('8d283526ff9fd16adbeac159436d54ea', true);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('8d283526ff9fd16adbeac159436d54ea', false);
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./components/reusable/r_ae95a557491cf646d89334f5f43c1d69/logic/3de517e18c87b87b3ada14a515835feb/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    var classes, navbarSelection, selectedItemUser;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  selectedItemUser = (getObjectProperty(___arguments.context.pageData, 'selectedItemUser'));
  navbarSelection = (getObjectProperty(___arguments.context.pageData, 'navbarSelection'));
  if (navbarSelection == 'userData') {
    if ((getObjectProperty(selectedItemUser, 'inviteStatus')) == 'PENDING' || (getObjectProperty(selectedItemUser, 'inviteStatus')) == 'RESENT') {
      classes = ['active_action', 'actionText'];
    } else {
      classes = ['inactive_action', 'actionText'];
    }
  }

  return classes

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./components/reusable/r_ae95a557491cf646d89334f5f43c1d69/logic/4901a683a71196a4bd37ee0744c79aca/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    var classes, navbarSelection, selectedItemUser;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  selectedItemUser = (getObjectProperty(___arguments.context.pageData, 'selectedItemUser'));
  navbarSelection = (getObjectProperty(___arguments.context.pageData, 'navbarSelection'));
  if (navbarSelection == 'userData') {
    if ((getObjectProperty(selectedItemUser, 'inviteStatus')) == 'PENDING' || (getObjectProperty(selectedItemUser, 'inviteStatus')) == 'RESENT') {
      classes = ['active_action', 'actionIcon'];
    } else {
      classes = ['inactive_action', 'actionIcon'];
    }
  }

  return classes

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./components/reusable/r_ae95a557491cf646d89334f5f43c1d69/logic/6917e4374a5eb9090b54b053ab1b15fc/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'navbarSelection')) == 'userData') {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('6917e4374a5eb9090b54b053ab1b15fc', true);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('6917e4374a5eb9090b54b053ab1b15fc', false);
  }

  },
  /* handler:onStyleAssignment */
  /* handler:onClick */
  ['onClick'](___arguments) {
    var isSame, item, selectedItemArrayUser;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  selectedItemArrayUser = (getObjectProperty(___arguments.context.pageData, 'selectedItemArrayUser'));
  if (selectedItemArrayUser) {
    if ((getObjectProperty(selectedItemArrayUser, 'inviteStatus')) == 'PENDING' && (getObjectProperty(selectedItemArrayUser, 'inviteStatus')) == 'RESENT') {
      ___arguments.context.pageData['isConfirmationModalOpen'] = true;
      ___arguments.context.pageData['confirmationOption'] = 'cancelUser';
    }
  }

  },
  /* handler:onClick */
  /* content */
}))

define('./components/reusable/r_ae95a557491cf646d89334f5f43c1d69/logic/70c7ad7496c753246d98b7a3e319c3da/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    var classes, navbarSelection, selectedItemUser;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  selectedItemUser = (getObjectProperty(___arguments.context.pageData, 'selectedItemUser'));
  navbarSelection = (getObjectProperty(___arguments.context.pageData, 'navbarSelection'));
  if (navbarSelection == 'userData') {
    if ((getObjectProperty(selectedItemUser, 'inviteStatus')) == 'PENDING' || (getObjectProperty(selectedItemUser, 'inviteStatus')) == 'RESENT') {
      classes = ['active_action', 'actionText'];
    } else {
      classes = ['inactive_action', 'actionText'];
    }
  }

  return classes

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./components/reusable/r_ae95a557491cf646d89334f5f43c1d69/logic/78ce383cbe8231939bd273f1c9cbecc8/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    var classes, navbarSelection, selectedItemUser;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  selectedItemUser = (getObjectProperty(___arguments.context.pageData, 'selectedItemUser'));
  navbarSelection = (getObjectProperty(___arguments.context.pageData, 'navbarSelection'));
  if (navbarSelection == 'userData') {
    if ((getObjectProperty(selectedItemUser, 'inviteStatus')) == 'PENDING' || (getObjectProperty(selectedItemUser, 'inviteStatus')) == 'RESENT') {
      classes = ['active_action', 'actionIcon'];
    } else {
      classes = ['inactive_action', 'actionIcon'];
    }
  }

  return classes

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./components/reusable/r_ae95a557491cf646d89334f5f43c1d69/logic/745ee03c6109c9d65776e087f1fcf099/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'navbarSelection')) == 'userData') {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('745ee03c6109c9d65776e087f1fcf099', true);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('745ee03c6109c9d65776e087f1fcf099', false);
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./components/reusable/r_aa3167560c491b09e98c98573d592174/logic/e3b13ed17dbafe5e88742f161093e501/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'deviceTableCount')) {
    return (['Showing ',(getObjectProperty(___arguments.context.pageData, 'deviceTableCount')),' records'].join(''));
  } else if ((getObjectProperty(___arguments.context.pageData, 'deviceTableCount')) == 0) {
    return 'Showing 0 records';
  }

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./components/reusable/r_aa3167560c491b09e98c98573d592174/logic/8c1404b4be23350cdeeac37e2a2b8e45/bundle.js', [], () => ({
  /* content */
  /* handler:PageCountLogic */
  ['PageCountLogic'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'deviceTableCount')) {
    if ((getObjectProperty(___arguments.context.pageData, 'deviceTableCount')) > 10) {
      return (Math.ceil((getObjectProperty(___arguments.context.pageData, 'deviceTableCount')) / 10));
    } else {
      return 1;
    }
  }

  },
  /* handler:PageCountLogic */
  /* handler:onStyleAssignment */
  async ['onStyleAssignment'](___arguments) {
    var deviceTableFilter, changedValue, currentUser;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  deviceTableFilter = (getObjectProperty(___arguments.context.pageData, 'deviceTableFilter'));
  currentUser = (getObjectProperty(___arguments.context.pageData, 'currentUser'));
  changedValue = (getObjectProperty(___arguments.context.pageData, 'changedValueDevice'));
  if (deviceTableFilter && (getObjectProperty(___arguments.context.pageData, 'hasClickedApplyFilter'))) {
    (function (componentUid, listItems) { ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('c8d4456340144c028acebf84b47afdaf', []);
    ___arguments.context.pageData['hasClickedApplyFilter'] = false;
    ___arguments.context.pageData['isTableLoading'] = true;
    if (currentUser) {
      (function (componentUid, listItems) { ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('c8d4456340144c028acebf84b47afdaf', (await Backendless.Data.of('Devices').find(Backendless.DataQueryBuilder.create().setWhereClause((await BackendlessUI.Functions.Custom['fn_5da19a0685ee9e7b97339f9e3dd69c3b']('device', null, (getObjectProperty(currentUser, 'organizationId.orgName')), changedValue, deviceTableFilter, null, null))).setRelationsDepth(2).setSortBy('created asc').setPageSize(10))));
      ___arguments.context.pageData['isTableLoading'] = false;
      ___arguments.context.pageData['deviceTableCount'] = (await Backendless.Data.of('Devices').getObjectCount(Backendless.DataQueryBuilder.create().setWhereClause((await BackendlessUI.Functions.Custom['fn_5da19a0685ee9e7b97339f9e3dd69c3b']('device', null, (getObjectProperty(currentUser, 'organizationId.orgName')), changedValue, deviceTableFilter, null, null)))));
    }
    ___arguments.context.pageData['isTableLoading'] = false;
  }

  },
  /* handler:onStyleAssignment */
  /* handler:onPageChange */
  async ['onPageChange'](___arguments) {
    var deviceTableFilter, changedValue, currentUser;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  ___arguments.context.pageData['isTableLoading'] = true;
  (function (componentUid, listItems) { ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('c8d4456340144c028acebf84b47afdaf', []);
  deviceTableFilter = (getObjectProperty(___arguments.context.pageData, 'deviceTableFilter'));
  currentUser = (getObjectProperty(___arguments.context.pageData, 'currentUser'));
  changedValue = (getObjectProperty(___arguments.context.pageData, 'changedValueDevice'));
  if (currentUser && !(getObjectProperty(___arguments.context.pageData, 'hasClickedApplyFilter'))) {
    (function (componentUid, listItems) { ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('c8d4456340144c028acebf84b47afdaf', (await Backendless.Data.of('Devices').find(Backendless.DataQueryBuilder.create().setWhereClause((await BackendlessUI.Functions.Custom['fn_5da19a0685ee9e7b97339f9e3dd69c3b']('device', null, (getObjectProperty(currentUser, 'organizationId.orgName')), changedValue, deviceTableFilter, null, null))).setRelationsDepth(2).setSortBy('created asc').setPageSize(10).setOffset((10 * ___arguments['currentPage'] - 10)))));
    ___arguments.context.pageData['isTableLoading'] = false;
    if (!(getObjectProperty(___arguments.context.pageData, 'pageEnter'))) {
      ___arguments.context.pageData['deviceTableCount'] = (await Backendless.Data.of('Devices').getObjectCount(Backendless.DataQueryBuilder.create().setWhereClause((await BackendlessUI.Functions.Custom['fn_5da19a0685ee9e7b97339f9e3dd69c3b']('device', null, (getObjectProperty(currentUser, 'organizationId.orgName')), changedValue, deviceTableFilter, null, null)))));
      ___arguments.context.pageData['pageEnter'] = true;
      if ((getObjectProperty(___arguments.context.pageData, 'deviceTableCount')) == 0) {
        ___arguments.context.pageData['noResultsScreen'] = true;
      }
    }
  }
  ___arguments.context.pageData['isTableLoading'] = false;

  },
  /* handler:onPageChange */
  /* content */
}))

define('./components/reusable/r_aa3167560c491b09e98c98573d592174/logic/1a09a691d146abe25932ae6194fa5792/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  async ['onStyleAssignment'](___arguments) {
    var refreshDeviceTable, deviceTableFilter, changedValue, currentUser;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

/**
 * Describe this function...
 */
async function refreshDeviceTable2() {
  ___arguments.context.pageData['isTableLoading'] = true;
  (function (componentUid, listItems) { ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('c8d4456340144c028acebf84b47afdaf', []);
  currentUser = (getObjectProperty(___arguments.context.pageData, 'currentUser'));
  changedValue = (getObjectProperty(___arguments.context.pageData, 'changedValueDevice'));
  deviceTableFilter = (getObjectProperty(___arguments.context.pageData, 'deviceTableFilter'));
  (function (componentUid, listItems) { ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('c8d4456340144c028acebf84b47afdaf', (await Backendless.Data.of('Devices').find(Backendless.DataQueryBuilder.create().setWhereClause((await BackendlessUI.Functions.Custom['fn_5da19a0685ee9e7b97339f9e3dd69c3b']('device', null, (getObjectProperty(currentUser, 'organizationId.orgName')), changedValue, deviceTableFilter, null, null))).setRelationsDepth(2).setSortBy('created asc').setPageSize(10))));
  ___arguments.context.pageData['isTableLoading'] = false;
  ___arguments.context.pageData['deviceTableCount'] = (await Backendless.Data.of('Devices').getObjectCount(Backendless.DataQueryBuilder.create().setWhereClause((await BackendlessUI.Functions.Custom['fn_5da19a0685ee9e7b97339f9e3dd69c3b']('device', null, (getObjectProperty(currentUser, 'organizationId.orgName')), changedValue, deviceTableFilter, null, null)))));
  ___arguments.context.pageData['selectedItemArrayDevice'] = [];
  ___arguments.context.pageData['refreshDeviceTable'] = undefined;
}


  refreshDeviceTable = (getObjectProperty(___arguments.context.pageData, 'refreshDeviceTable'));
  if (refreshDeviceTable) {
    await refreshDeviceTable2();
  }

  },
  /* handler:onStyleAssignment */
  /* handler:onBeforeMount */
  ['onBeforeMount'](___arguments) {
      ___arguments.context.pageData['selectedItemArrayDevice'] = [];
  ___arguments.context.pageData['deviceFilterArray'] = [];
  ___arguments.context.pageData['deviceTableFilter'] = [];

  },
  /* handler:onBeforeMount */
  /* content */
}))

define('./components/reusable/r_aa3167560c491b09e98c98573d592174/logic/6b3f0bc23ceae6dac0e4c92b753a545e/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (!(getObjectProperty(___arguments.context.pageData, 'isViewDetailsModalOpen'))) {
    if ((getObjectProperty(___arguments.context.pageData, 'selectedItemArrayDevice'))[0] == ___arguments.context.dataModel) {
      ___arguments.context.pageData['selectedItemArrayDevice'] = [];
    } else {
      ___arguments.context.pageData['selectedItemArrayDevice'] = [___arguments.context.dataModel];
    }
  }

  },
  /* handler:onClick */
  /* content */
}))

define('./components/reusable/r_aa3167560c491b09e98c98573d592174/logic/f39b23d4d26d7cec995b049ca37e7ff9/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    var isTableLoading;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  isTableLoading = (getObjectProperty(___arguments.context.pageData, 'isTableLoading'));
  if (isTableLoading) {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('f39b23d4d26d7cec995b049ca37e7ff9', true);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('f39b23d4d26d7cec995b049ca37e7ff9', false);
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./components/reusable/r_aa3167560c491b09e98c98573d592174/logic/cd8ce0c2828f8e74beb2302a39ee23ce/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    var isTableLoading, deviceTableCount;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  deviceTableCount = (getObjectProperty(___arguments.context.pageData, 'deviceTableCount'));
  isTableLoading = (getObjectProperty(___arguments.context.pageData, 'isTableLoading'));
  if (deviceTableCount == 0 && !isTableLoading) {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('cd8ce0c2828f8e74beb2302a39ee23ce', true);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('cd8ce0c2828f8e74beb2302a39ee23ce', false);
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./components/reusable/r_aa3167560c491b09e98c98573d592174/logic/c5b845bb76959f0a95915bdecdf4f40a/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    var classesList;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('6b3f0bc23ceae6dac0e4c92b753a545e'), 'deviceStatus')) == 'ASSIGNED') {
    return 'Assigned';
  } else if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('6b3f0bc23ceae6dac0e4c92b753a545e'), 'deviceStatus')) == 'ONBOARDSTARTED') {
    return 'Onboard Started';
  } else if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('6b3f0bc23ceae6dac0e4c92b753a545e'), 'deviceStatus')) == 'ONBOARDPENDING') {
    return 'Onboard Pending';
  } else if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('6b3f0bc23ceae6dac0e4c92b753a545e'), 'deviceStatus')) == 'DEACTIVATED') {
    return 'Deactivated';
  }

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./components/reusable/r_aa3167560c491b09e98c98573d592174/logic/a76b843f44b2c0c2d96de49ee1f38298/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    var isActionDrawerOpen;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  isActionDrawerOpen = (getObjectProperty(___arguments.context.pageData, 'isDeviceRecordActionDrawerOpen'));
  if (isActionDrawerOpen) {
    ___arguments.context.pageData['isDeviceRecordActionDrawerOpen'] = false;
  } else {
    ___arguments.context.pageData['isDeviceRecordActionDrawerOpen'] = true;
  }

  },
  /* handler:onClick */
  /* content */
}))

define('./components/reusable/r_aa3167560c491b09e98c98573d592174/logic/b9e11ed588ad69711db11edc56bdc66b/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    var isActionDrawerOpen;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  isActionDrawerOpen = (getObjectProperty(___arguments.context.pageData, 'isDeviceRecordActionDrawerOpen'));
  if (isActionDrawerOpen) {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('b9e11ed588ad69711db11edc56bdc66b', true);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('b9e11ed588ad69711db11edc56bdc66b', false);
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./components/reusable/r_aa3167560c491b09e98c98573d592174/logic/9418bb35cc2651211f0baffa1756bd8b/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (String('Pillar ') + String(getObjectProperty(___arguments.context.getComponentDataStoreByUid('6b3f0bc23ceae6dac0e4c92b753a545e'), 'residentId.pillar')))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./components/reusable/r_aa3167560c491b09e98c98573d592174/logic/a56ea43892389d119d2cbb0c7b4c6b97/bundle.js', [], () => ({
  /* content */
  /* handler:onSourceUrlAssignment */
  ['onSourceUrlAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.getComponentDataStoreByUid('6b3f0bc23ceae6dac0e4c92b753a545e'), 'residentId.profilePicture')) {
    return (getObjectProperty(___arguments.context.getComponentDataStoreByUid('6b3f0bc23ceae6dac0e4c92b753a545e'), 'residentId.profilePicture'));
  } else {
    return 'https://app.aquax.co/api/files/cyberdive_photos/cd-icon-default-photo.svg';
  }

  },
  /* handler:onSourceUrlAssignment */
  /* content */
}))

define('./components/reusable/r_aa3167560c491b09e98c98573d592174/logic/38ac316743a84f55662cdaeab9c4362d/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  async ['onChange'](___arguments) {
    var deviceTableFilter, changedValue, currentUser;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  changedValue = ___arguments.value.split('\'').join('');
  deviceTableFilter = (getObjectProperty(___arguments.context.pageData, 'deviceTableFilter'));
  currentUser = (getObjectProperty(___arguments.context.pageData, 'currentUser'));
  ___arguments.context.pageData['isTableLoading'] = true;
  (function (componentUid, listItems) { ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('c8d4456340144c028acebf84b47afdaf', []);
  ___arguments.context.pageData['changedValueDevice'] = changedValue;
  (function (componentUid, listItems) { ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('c8d4456340144c028acebf84b47afdaf', (await Backendless.Data.of('Devices').find(Backendless.DataQueryBuilder.create().setWhereClause((await BackendlessUI.Functions.Custom['fn_5da19a0685ee9e7b97339f9e3dd69c3b']('device', null, (getObjectProperty(currentUser, 'organizationId.orgName')), changedValue, deviceTableFilter, null, null))).setRelationsDepth(2).setSortBy('created asc').setPageSize(10))));
  ___arguments.context.pageData['isTableLoading'] = false;
  ___arguments.context.pageData['deviceTableCount'] = (await Backendless.Data.of('Devices').getObjectCount(Backendless.DataQueryBuilder.create().setWhereClause((await BackendlessUI.Functions.Custom['fn_5da19a0685ee9e7b97339f9e3dd69c3b']('device', null, (getObjectProperty(currentUser, 'organizationId.orgName')), changedValue, deviceTableFilter, null, null)))));
  ___arguments.context.pageData['isTableLoading'] = false;

  },
  /* handler:onChange */
  /* content */
}))

define('./components/reusable/r_aa3167560c491b09e98c98573d592174/logic/6c99021fb63346a7ecdc67ea04399829/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var item, isDeviceRecordFilterDrawerOpen;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  isDeviceRecordFilterDrawerOpen = (getObjectProperty(___arguments.context.pageData, 'isDeviceRecordFilterDrawerOpen'));
  if (isDeviceRecordFilterDrawerOpen) {
    ___arguments.context.pageData['deviceFilterArray'] = (await Promise.all((getObjectProperty(___arguments.context.pageData, 'deviceFilterArray')).map(async item => {  if ((getObjectProperty(___arguments.context.pageData, 'deviceTableFilter')).includes(item)) {
        return item;
      }
    ; return ;})));
    ___arguments.context.pageData['isDeviceRecordFilterDrawerOpen'] = false;
    ___arguments.context.pageData['userClickedDeviceFilter'] = false;
  } else {
    ___arguments.context.pageData['deviceFilterArray'] = (await Promise.all((getObjectProperty(___arguments.context.pageData, 'deviceTableFilter')).map(async item => {; return item;})));
    ___arguments.context.pageData['isDeviceRecordFilterDrawerOpen'] = true;
    ___arguments.context.pageData['userClickedDeviceFilter'] = true;
  }

  },
  /* handler:onClick */
  /* content */
}))

define('./components/reusable/r_aa3167560c491b09e98c98573d592174/logic/694ffadbb2f51d0d196b70ee69333c84/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    var isFilterDrawerOpen;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  isFilterDrawerOpen = (getObjectProperty(___arguments.context.pageData, 'isDeviceRecordFilterDrawerOpen'));
  if (isFilterDrawerOpen) {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('694ffadbb2f51d0d196b70ee69333c84', true);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('694ffadbb2f51d0d196b70ee69333c84', false);
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./components/reusable/r_aa3167560c491b09e98c98573d592174/logic/1f4f0d34d2b1019d9d4237f125e62e27/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    var classesList;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('6b3f0bc23ceae6dac0e4c92b753a545e'), 'deviceStatus')) == 'ASSIGNED') {
    classesList = ['active_status'];
  } else {
    classesList = ['deactivated_status'];
  }

  return classesList

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./components/reusable/r_aa3167560c491b09e98c98573d592174/logic/c2078ad1a81caf3e0a6d038f55ae18f3/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  ['onChange'](___arguments) {
    var deviceFilterArray;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}

function removeItemInList(l, i, c) {   const index = c ? l.findIndex(el => el[c] === i[c]) : l.indexOf(i);  if (index >= 0) { l.splice(index, 1); }  return l;}


  deviceFilterArray = (getObjectProperty(___arguments.context.pageData, 'deviceFilterArray'));
  if (___arguments.value) {
    addItemToList(deviceFilterArray, 'deviceStatus = \'ASSIGNED\'');
    ___arguments.context.pageData['deviceFilterArray'] = deviceFilterArray;
  } else {
    removeItemInList(deviceFilterArray, 'deviceStatus = \'ASSIGNED\'', '');
    ___arguments.context.pageData['deviceFilterArray'] = deviceFilterArray;
  }

  },
  /* handler:onChange */
  /* handler:onCheckedStateAssignment */
  ['onCheckedStateAssignment'](___arguments) {
    var deviceFilterArray;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  deviceFilterArray = (getObjectProperty(___arguments.context.pageData, 'deviceFilterArray'));
  if (deviceFilterArray && (deviceFilterArray.includes('deviceStatus = \'ASSIGNED\''))) {
    return true;
  } else {
    return false;
  }

  },
  /* handler:onCheckedStateAssignment */
  /* content */
}))

define('./components/reusable/r_aa3167560c491b09e98c98573d592174/logic/5e3139ef82be6bf1d342d7b4408dfa89/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  ['onChange'](___arguments) {
    var deviceFilterArray;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}

function removeItemInList(l, i, c) {   const index = c ? l.findIndex(el => el[c] === i[c]) : l.indexOf(i);  if (index >= 0) { l.splice(index, 1); }  return l;}


  deviceFilterArray = (getObjectProperty(___arguments.context.pageData, 'deviceFilterArray'));
  if (___arguments.value) {
    addItemToList(deviceFilterArray, 'deviceStatus = \'DEACTIVATED\'');
    ___arguments.context.pageData['deviceFilterArray'] = deviceFilterArray;
  } else {
    removeItemInList(deviceFilterArray, 'deviceStatus = \'DEACTIVATED\'', '');
    ___arguments.context.pageData['deviceFilterArray'] = deviceFilterArray;
  }

  },
  /* handler:onChange */
  /* handler:onCheckedStateAssignment */
  ['onCheckedStateAssignment'](___arguments) {
    var deviceFilterArray;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  deviceFilterArray = (getObjectProperty(___arguments.context.pageData, 'deviceFilterArray'));
  if (deviceFilterArray && (deviceFilterArray.includes('deviceStatus = \'DEACTIVATED\''))) {
    return true;
  } else {
    return false;
  }

  },
  /* handler:onCheckedStateAssignment */
  /* content */
}))

define('./components/reusable/r_aa3167560c491b09e98c98573d592174/logic/5994bad587fc136537c374977fd79ed8/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  ___arguments.context.pageData['hasClickedApplyFilter'] = true;
  if (getObjectProperty(___arguments.context.pageData, 'deviceFilterArray')) {
    if ((getObjectProperty(___arguments.context.pageData, 'deviceFilterArray')).length > 0) {
      ___arguments.context.pageData['deviceTableFilter'] = (getObjectProperty(___arguments.context.pageData, 'deviceFilterArray'));
    } else {
      ___arguments.context.pageData['deviceTableFilter'] = [];
    }
  }

  },
  /* handler:onClick */
  /* content */
}))

define('./components/reusable/r_aa3167560c491b09e98c98573d592174/logic/1af36785ac1f3b068d115012995374f5/bundle.js', [], () => ({
  /* content */
  /* handler:onCheckedStateAssignment */
  ['onCheckedStateAssignment'](___arguments) {
    var isChecked, selectedItem, selectedItemArray;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  selectedItemArray = (getObjectProperty(___arguments.context.pageData, 'selectedItemArrayDevice'));
  isChecked = false;
  if (selectedItemArray) {
    for (var selectedItem_index in selectedItemArray) {
      selectedItem = selectedItemArray[selectedItem_index];
      if ((getObjectProperty(selectedItem, 'objectId')) == (getObjectProperty(___arguments.context.getComponentDataStoreByUid('6b3f0bc23ceae6dac0e4c92b753a545e'), 'objectId'))) {
        isChecked = true;
        break;
      }
    }
  }

  return isChecked

  },
  /* handler:onCheckedStateAssignment */
  /* content */
}))

define('./components/reusable/r_aa3167560c491b09e98c98573d592174/logic/3a697796f553a8862e7c53f4eee3c32f/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      ___arguments.context.pageData['isViewDetailsModalOpen'] = true;
  ___arguments.context.pageData['viewDetailsModalVariant'] = 'device';
  ___arguments.context.pageData['viewDetailsSelection'] = ___arguments.context.getComponentDataStoreByUid('6b3f0bc23ceae6dac0e4c92b753a545e');

  },
  /* handler:onClick */
  /* content */
}))

define('./components/reusable/r_aa3167560c491b09e98c98573d592174/logic/303fb0063948a44b14830d0e260e74ef/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var getDeviceLocationResponse, getDeviceIdResponse;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  ___arguments.context.pageData['selectedItemDevice'] = ___arguments.context.getComponentDataStoreByUid('6b3f0bc23ceae6dac0e4c92b753a545e');
  ___arguments.context.pageData['selectedItemDeviceIMEI'] = (getObjectProperty(___arguments.context.pageData, 'selectedItemDevice'));
  ___arguments.context.pageData['recordSelection'] = 'deviceRecords';
  ___arguments.context.pageData['navbarSelection'] = 'deviceRecords';
  localStorage.setItem('navbarSelection', JSON.stringify('deviceRecords'));
  ___arguments.context.pageData['selectedDeviceLocation'] = undefined;
  ___arguments.context.pageData['locationError'] = false;
  ___arguments.context.pageData['isMapLoading'] = true;
  ___arguments.context.pageData['residentDetailsSelection'] = 'details';
  ___arguments.context.pageData['recordSelection'] = 'residentRecords';
  ___arguments.context.pageData['isViewResidentDetailsOpen'] = true;
  ___arguments.context.pageData['selectedItemResident'] = ((await Backendless.Data.of('ResidentInformation').find(Backendless.DataQueryBuilder.create().setWhereClause((['objectId = \'',(getObjectProperty(___arguments.context.getComponentDataStoreByUid('6b3f0bc23ceae6dac0e4c92b753a545e'), 'residentId.objectId')),'\''].join(''))).setPageSize(10)))[0]);
  ___arguments.context.pageData['residentIntakeForm'] = (JSON.parse((getObjectProperty(___arguments.context.pageData, 'selectedItemResident.residentIntakeForm'))));
  if (getObjectProperty(___arguments.context.pageData, 'selectedItemDeviceIMEI')) {
    try {
      getDeviceIdResponse = (await Backendless.Request.get(`${Backendless.appPath}/services/HeadwindMDM/DeviceId`).set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).query({ 'token': JSON.stringify((getObjectProperty((JSON.parse((getObjectProperty(___arguments.context.pageData, 'currentUser.organizationId.headwindData')))), 'token'))),'deviceNumber': JSON.stringify((getObjectProperty(___arguments.context.pageData, 'selectedItemDevice.deviceId'))) }).send());
      if ((getObjectProperty(getDeviceIdResponse, 'status')) == 'OK') {
        getDeviceLocationResponse = (await Backendless.Request.get(`${Backendless.appPath}/services/HeadwindMDM/DeviceLocation`).set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).query({ 'token': JSON.stringify((getObjectProperty((JSON.parse((getObjectProperty(___arguments.context.pageData, 'currentUser.organizationId.headwindData')))), 'token'))),'deviceId': (getObjectProperty(getDeviceIdResponse, 'data.id')) }).send());
        if ((getObjectProperty(getDeviceLocationResponse, 'status')) == 'OK') {
          ___arguments.context.pageData['selectedDeviceLocation'] = [({ [`lng`]: (getObjectProperty(getDeviceLocationResponse, 'data.lon')),[`lat`]: (getObjectProperty(getDeviceLocationResponse, 'data.lat')) })];
          ___arguments.context.pageData['isMapLoading'] = false;
        } else {
          throw (new Error(''))
        }
      } else {
        throw (new Error(''))
      }

    } catch (error) {
      await BackendlessUI.Functions.Custom['fn_77bfd63e533a9b4c5577e5fe31a4b5ca'](___arguments.context.pageData, 'Error finding device location.', 'failure');
      ___arguments.context.pageData['isMapLoading'] = false;
      ___arguments.context.pageData['locationError'] = true;

    }
  }
  ___arguments.context.pageData['isMapLoading'] = false;

  },
  /* handler:onClick */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (await BackendlessUI.Functions.Custom['fn_9807a958b9f47818617415e4438b5e47']((getObjectProperty(___arguments.context.getComponentDataStoreByUid('6b3f0bc23ceae6dac0e4c92b753a545e'), 'residentId.nameInUse')), 10))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./components/reusable/r_8c0522b9c57b3a5b5c31b90260b6f13b/logic/20c2ca959ddba07fdb27c7258e533055/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    var isActionDrawerOpen;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  isActionDrawerOpen = (getObjectProperty(___arguments.context.pageData, 'isCurfewRecordActionDrawerOpen'));
  if (isActionDrawerOpen) {
    ___arguments.context.pageData['isCurfewRecordActionDrawerOpen'] = false;
    ___arguments.context.pageData['userClickedCurfewAction'] = false;
  } else {
    ___arguments.context.pageData['isCurfewRecordActionDrawerOpen'] = true;
    ___arguments.context.pageData['userClickedCurfewAction'] = true;
  }

  },
  /* handler:onClick */
  /* content */
}))

define('./components/reusable/r_8c0522b9c57b3a5b5c31b90260b6f13b/logic/f25493427346ba13569c746d7a342691/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    var isActionDrawerOpen;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  isActionDrawerOpen = (getObjectProperty(___arguments.context.pageData, 'isCurfewRecordActionDrawerOpen'));
  if (isActionDrawerOpen) {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('f25493427346ba13569c746d7a342691', true);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('f25493427346ba13569c746d7a342691', false);
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./components/reusable/r_8c0522b9c57b3a5b5c31b90260b6f13b/logic/8346b4638a33474b66e4a65a60c2de7c/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    var classes;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  classes = [];
  if ((getObjectProperty(___arguments.context.pageData, 'curfewPillarSelectionBar')) == '1') {
    addItemToList(classes, 'selected_box_bar');
  }

  return classes

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./components/reusable/r_8c0522b9c57b3a5b5c31b90260b6f13b/logic/37dc31b43bbff1835df413f877b5cc0b/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    var classes;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  classes = [];
  if ((getObjectProperty(___arguments.context.pageData, 'curfewPillarSelectionBar')) == '1') {
    addItemToList(classes, 'selected_box_text');
  }

  return classes

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./components/reusable/r_8c0522b9c57b3a5b5c31b90260b6f13b/logic/2b4c901ae4b5e4f687ea8ca6e0196bad/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    var classes;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  classes = [];
  if ((getObjectProperty(___arguments.context.pageData, 'curfewPillarSelectionBar')) == '2') {
    addItemToList(classes, 'selected_box_bar');
  }

  return classes

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./components/reusable/r_8c0522b9c57b3a5b5c31b90260b6f13b/logic/60c46e4b8a75a7debe024b88d32faadd/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    var classes;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  classes = [];
  if ((getObjectProperty(___arguments.context.pageData, 'curfewPillarSelectionBar')) == '2') {
    addItemToList(classes, 'selected_box_text');
  }

  return classes

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./components/reusable/r_8c0522b9c57b3a5b5c31b90260b6f13b/logic/bb8f46685c5aad1e32f846ed27265933/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    var classes;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  classes = [];
  if ((getObjectProperty(___arguments.context.pageData, 'curfewPillarSelectionBar')) == '3') {
    addItemToList(classes, 'selected_box_bar');
  }

  return classes

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./components/reusable/r_8c0522b9c57b3a5b5c31b90260b6f13b/logic/9786265e81ba6a7b0fce0fcc74da95d2/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    var classes;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  classes = [];
  if ((getObjectProperty(___arguments.context.pageData, 'curfewPillarSelectionBar')) == '3') {
    addItemToList(classes, 'selected_box_text');
  }

  return classes

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./components/reusable/r_8c0522b9c57b3a5b5c31b90260b6f13b/logic/893ed7e5ee6aea4b74546026e144e6fc/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    var classes;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  classes = [];
  if ((getObjectProperty(___arguments.context.pageData, 'curfewPillarSelectionBar')) == '4') {
    addItemToList(classes, 'selected_box_bar');
  }

  return classes

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./components/reusable/r_8c0522b9c57b3a5b5c31b90260b6f13b/logic/de4b56a94f2a6737bd7eaf6af2216624/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    var classes;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  classes = [];
  if ((getObjectProperty(___arguments.context.pageData, 'curfewPillarSelectionBar')) == '4') {
    addItemToList(classes, 'selected_box_text');
  }

  return classes

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./components/reusable/r_8c0522b9c57b3a5b5c31b90260b6f13b/logic/51cea94202ab4271bf96c16fcc198b0f/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var currentUser, toast;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

/**
 * Describe this function...
 */
async function setToast(toast) {
}


  ___arguments.context.pageData['curfewPillarSelectionBar'] = '1';
  ___arguments.context.pageData['isTableLoading'] = true;
  (function (componentUid, listItems) { ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('fda8a085242d236490e7423d97e85e07', []);
  currentUser = (getObjectProperty(___arguments.context.pageData, 'currentUser'));
  if (currentUser) {
    try {
      (function (componentUid, listItems) { ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('fda8a085242d236490e7423d97e85e07', (await Backendless.Data.of('Curfews').find(Backendless.DataQueryBuilder.create().setWhereClause((await BackendlessUI.Functions.Custom['fn_5da19a0685ee9e7b97339f9e3dd69c3b']('curfew', null, (getObjectProperty(currentUser, 'organizationId.orgName')), null, null, null, (getObjectProperty(___arguments.context.pageData, 'curfewPillarSelectionBar'))))).setSortBy('created asc').setPageSize(10))));
      ___arguments.context.pageData['curfewTableCount'] = (await Backendless.Data.of('Curfews').getObjectCount(Backendless.DataQueryBuilder.create().setWhereClause((await BackendlessUI.Functions.Custom['fn_5da19a0685ee9e7b97339f9e3dd69c3b']('curfew', null, (getObjectProperty(currentUser, 'organizationId.orgName')), null, null, null, (getObjectProperty(___arguments.context.pageData, 'curfewPillarSelectionBar')))))));

    } catch (error) {
      await setToast(({ [`status`]: 'failure',[`message`]: 'Error loading curfew table' }));

    }
  }
  ___arguments.context.pageData['isTableLoading'] = false;

  },
  /* handler:onClick */
  /* content */
}))

define('./components/reusable/r_8c0522b9c57b3a5b5c31b90260b6f13b/logic/f34f098a05246192c74b914403dbb916/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var currentUser, toast;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

/**
 * Describe this function...
 */
async function setToast(toast) {
}


  ___arguments.context.pageData['curfewPillarSelectionBar'] = '2';
  ___arguments.context.pageData['isTableLoading'] = true;
  (function (componentUid, listItems) { ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('fda8a085242d236490e7423d97e85e07', []);
  currentUser = (getObjectProperty(___arguments.context.pageData, 'currentUser'));
  if (currentUser) {
    try {
      (function (componentUid, listItems) { ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('fda8a085242d236490e7423d97e85e07', (await Backendless.Data.of('Curfews').find(Backendless.DataQueryBuilder.create().setWhereClause((await BackendlessUI.Functions.Custom['fn_5da19a0685ee9e7b97339f9e3dd69c3b']('curfew', null, (getObjectProperty(currentUser, 'organizationId.orgName')), null, null, null, (getObjectProperty(___arguments.context.pageData, 'curfewPillarSelectionBar'))))).setSortBy('created asc').setPageSize(10))));
      ___arguments.context.pageData['curfewTableCount'] = (await Backendless.Data.of('Curfews').getObjectCount(Backendless.DataQueryBuilder.create().setWhereClause((await BackendlessUI.Functions.Custom['fn_5da19a0685ee9e7b97339f9e3dd69c3b']('curfew', null, (getObjectProperty(currentUser, 'organizationId.orgName')), null, null, null, (getObjectProperty(___arguments.context.pageData, 'curfewPillarSelectionBar')))))));

    } catch (error) {
      await setToast(({ [`status`]: 'failure',[`message`]: 'Error loading curfew table' }));

    }
  }
  ___arguments.context.pageData['isTableLoading'] = false;

  },
  /* handler:onClick */
  /* content */
}))

define('./components/reusable/r_8c0522b9c57b3a5b5c31b90260b6f13b/logic/219440be1c8e3c21360bb2e9c6886964/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var currentUser, toast;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

/**
 * Describe this function...
 */
async function setToast(toast) {
}


  ___arguments.context.pageData['curfewPillarSelectionBar'] = '3';
  ___arguments.context.pageData['isTableLoading'] = true;
  (function (componentUid, listItems) { ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('fda8a085242d236490e7423d97e85e07', []);
  currentUser = (getObjectProperty(___arguments.context.pageData, 'currentUser'));
  if (currentUser) {
    try {
      (function (componentUid, listItems) { ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('fda8a085242d236490e7423d97e85e07', (await Backendless.Data.of('Curfews').find(Backendless.DataQueryBuilder.create().setWhereClause((await BackendlessUI.Functions.Custom['fn_5da19a0685ee9e7b97339f9e3dd69c3b']('curfew', null, (getObjectProperty(currentUser, 'organizationId.orgName')), null, null, null, (getObjectProperty(___arguments.context.pageData, 'curfewPillarSelectionBar'))))).setSortBy('created asc').setPageSize(10))));
      ___arguments.context.pageData['curfewTableCount'] = (await Backendless.Data.of('Curfews').getObjectCount(Backendless.DataQueryBuilder.create().setWhereClause((await BackendlessUI.Functions.Custom['fn_5da19a0685ee9e7b97339f9e3dd69c3b']('curfew', null, (getObjectProperty(currentUser, 'organizationId.orgName')), null, null, null, (getObjectProperty(___arguments.context.pageData, 'curfewPillarSelectionBar')))))));

    } catch (error) {
      await setToast(({ [`status`]: 'failure',[`message`]: 'Error loading curfew table' }));

    }
  }
  ___arguments.context.pageData['isTableLoading'] = false;

  },
  /* handler:onClick */
  /* content */
}))

define('./components/reusable/r_8c0522b9c57b3a5b5c31b90260b6f13b/logic/96ff3a1f4f27a88f8e24f2dcf0c3d8e2/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var currentUser, toast;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

/**
 * Describe this function...
 */
async function setToast(toast) {
}


  ___arguments.context.pageData['curfewPillarSelectionBar'] = '4';
  ___arguments.context.pageData['isTableLoading'] = true;
  (function (componentUid, listItems) { ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('fda8a085242d236490e7423d97e85e07', []);
  currentUser = (getObjectProperty(___arguments.context.pageData, 'currentUser'));
  if (currentUser) {
    try {
      (function (componentUid, listItems) { ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('fda8a085242d236490e7423d97e85e07', (await Backendless.Data.of('Curfews').find(Backendless.DataQueryBuilder.create().setWhereClause((await BackendlessUI.Functions.Custom['fn_5da19a0685ee9e7b97339f9e3dd69c3b']('curfew', null, (getObjectProperty(currentUser, 'organizationId.orgName')), null, null, null, (getObjectProperty(___arguments.context.pageData, 'curfewPillarSelectionBar'))))).setSortBy('created asc').setPageSize(10))));
      ___arguments.context.pageData['curfewTableCount'] = (await Backendless.Data.of('Curfews').getObjectCount(Backendless.DataQueryBuilder.create().setWhereClause((await BackendlessUI.Functions.Custom['fn_5da19a0685ee9e7b97339f9e3dd69c3b']('curfew', null, (getObjectProperty(currentUser, 'organizationId.orgName')), null, null, null, (getObjectProperty(___arguments.context.pageData, 'curfewPillarSelectionBar')))))));

    } catch (error) {
      await setToast(({ [`status`]: 'failure',[`message`]: 'Error loading curfew table' }));

    }
  }
  ___arguments.context.pageData['isTableLoading'] = false;

  },
  /* handler:onClick */
  /* content */
}))

define('./components/reusable/r_8c0522b9c57b3a5b5c31b90260b6f13b/logic/acdfee280bb6bb64995f879aed91e5fa/bundle.js', [], () => ({
  /* content */
  /* handler:PageCountLogic */
  ['PageCountLogic'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'curfewTableCount')) {
    if ((getObjectProperty(___arguments.context.pageData, 'curfewTableCount')) > 10) {
      return (Math.ceil((getObjectProperty(___arguments.context.pageData, 'curfewTableCount')) / 10));
    } else {
      return 1;
    }
  }

  },
  /* handler:PageCountLogic */
  /* handler:onPageChange */
  async ['onPageChange'](___arguments) {
    var currentUser, toast;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

/**
 * Describe this function...
 */
async function setToast(toast) {
  await BackendlessUI.Functions.Custom['fn_77bfd63e533a9b4c5577e5fe31a4b5ca'](___arguments.context.pageData, (getObjectProperty(toast, 'message')), (getObjectProperty(toast, 'status')));
}


  ___arguments.context.pageData['isTableLoading'] = true;
  (function (componentUid, listItems) { ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('fda8a085242d236490e7423d97e85e07', []);
  currentUser = (getObjectProperty(___arguments.context.pageData, 'currentUser'));
  if (currentUser) {
    try {
      (function (componentUid, listItems) { ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('fda8a085242d236490e7423d97e85e07', (await Backendless.Data.of('Curfews').find(Backendless.DataQueryBuilder.create().setWhereClause((await BackendlessUI.Functions.Custom['fn_5da19a0685ee9e7b97339f9e3dd69c3b']('curfew', null, (getObjectProperty(currentUser, 'organizationId.orgName')), null, null, null, (getObjectProperty(___arguments.context.pageData, 'curfewPillarSelectionBar'))))).setSortBy('created asc').setPageSize(10).setOffset((10 * ___arguments['currentPage'] - 10)))));
      if (!(getObjectProperty(___arguments.context.pageData, 'pageEnter'))) {
        ___arguments.context.pageData['pageEnter'] = true;
        ___arguments.context.pageData['curfewTableCount'] = (await Backendless.Data.of('Curfews').getObjectCount(Backendless.DataQueryBuilder.create().setWhereClause((await BackendlessUI.Functions.Custom['fn_5da19a0685ee9e7b97339f9e3dd69c3b']('curfew', null, (getObjectProperty(currentUser, 'organizationId.orgName')), null, null, null, (getObjectProperty(___arguments.context.pageData, 'curfewPillarSelectionBar')))))));
      }

    } catch (error) {
      await setToast(({ [`status`]: 'failure',[`message`]: 'Error loading curfew table' }));

    }
  }
  ___arguments.context.pageData['isTableLoading'] = false;

  },
  /* handler:onPageChange */
  /* content */
}))

define('./components/reusable/r_8c0522b9c57b3a5b5c31b90260b6f13b/logic/f598cfffee39e20acd3f1d3831af8597/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'curfewTableCount')) {
    return (['Showing ',(getObjectProperty(___arguments.context.pageData, 'curfewTableCount')),' records'].join(''));
  } else if ((getObjectProperty(___arguments.context.pageData, 'curfewTableCount')) == 0) {
    return 'Showing 0 records';
  }

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./components/reusable/r_8c0522b9c57b3a5b5c31b90260b6f13b/logic/a0bb0fae2fc6ef994b39fdc2b0660422/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    var isTableLoading;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  isTableLoading = (getObjectProperty(___arguments.context.pageData, 'isTableLoading'));
  if (isTableLoading) {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('a0bb0fae2fc6ef994b39fdc2b0660422', true);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('a0bb0fae2fc6ef994b39fdc2b0660422', false);
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./components/reusable/r_8c0522b9c57b3a5b5c31b90260b6f13b/logic/73324fa55fc9a7ee1973d302febfbd63/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    var classesList;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  classesList = [];
  if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('1c89cf08fadc9571a10d99cb825a986c'), 'status')) == 'ACTIVE') {
    classesList = ['active_status'];
  } else if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('1c89cf08fadc9571a10d99cb825a986c'), 'status')) == 'DEACTIVATED') {
    classesList = ['deactivated_status'];
  }

  return classesList

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./components/reusable/r_8c0522b9c57b3a5b5c31b90260b6f13b/logic/8cefc8d2ed6af35f7d1fded6abfd54a4/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('1c89cf08fadc9571a10d99cb825a986c'), 'status')) == 'ACTIVE') {
    return 'Active';
  } else if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('1c89cf08fadc9571a10d99cb825a986c'), 'status')) == 'DEACTIVATED') {
    return 'Deactivated';
  }

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./components/reusable/r_8c0522b9c57b3a5b5c31b90260b6f13b/logic/1c89cf08fadc9571a10d99cb825a986c/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    var list;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'selectedItemArrayCurfew'))[0] == ___arguments.context.dataModel) {
    ___arguments.context.pageData['selectedItemArrayCurfew'] = [];
  } else {
    ___arguments.context.pageData['selectedItemArrayCurfew'] = [___arguments.context.dataModel];
  }

  },
  /* handler:onClick */
  /* content */
}))

define('./components/reusable/r_8c0522b9c57b3a5b5c31b90260b6f13b/logic/3583c716e93722aa5ea251a898080be8/bundle.js', [], () => ({
  /* content */
  /* handler:onCheckedStateAssignment */
  ['onCheckedStateAssignment'](___arguments) {
    var isChecked, selectedItem, selectedItemArray;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  selectedItemArray = (getObjectProperty(___arguments.context.pageData, 'selectedItemArrayCurfew'));
  isChecked = false;
  for (var selectedItem_index in selectedItemArray) {
    selectedItem = selectedItemArray[selectedItem_index];
    if ((getObjectProperty(selectedItem, 'objectId')) == (getObjectProperty(___arguments.context.getComponentDataStoreByUid('1c89cf08fadc9571a10d99cb825a986c'), 'objectId'))) {
      isChecked = true;
      break;
    }
  }

  return isChecked

  },
  /* handler:onCheckedStateAssignment */
  /* content */
}))

define('./components/reusable/r_8c0522b9c57b3a5b5c31b90260b6f13b/logic/9e2137976b4147b923d6b3a8cf4b9714/bundle.js', [], () => ({
  /* content */
  /* handler:onBeforeMount */
  ['onBeforeMount'](___arguments) {
      ___arguments.context.pageData['selectedItemArrayCurfew'] = [];

  },
  /* handler:onBeforeMount */
  /* content */
}))

define('./components/reusable/r_8c0522b9c57b3a5b5c31b90260b6f13b/logic/9ee7171c6194f9ce8fcb5ed585387672/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    var selectedItemArrayCurfew;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  selectedItemArrayCurfew = (getObjectProperty(___arguments.context.pageData, 'selectedItemArrayCurfew'));
  if (selectedItemArrayCurfew && selectedItemArrayCurfew.length == 1) {
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('2f43a7baaedcec52b49ac1a99dd1b3d7'))['color'] = '#000000';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('de725df3527f93f17589f3ba8a7557a7'))['color'] = '#000000';
  } else {
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('2f43a7baaedcec52b49ac1a99dd1b3d7'))['color'] = 'rgb(0,0,0,0.3)';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('de725df3527f93f17589f3ba8a7557a7'))['color'] = 'rgb(0,0,0,0.3)';
  }

  },
  /* handler:onStyleAssignment */
  /* handler:onClick */
  ['onClick'](___arguments) {
    var selectedItem, times, dayArray, toast;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

/**
 * Describe this function...
 */
async function addToast(toast) {
}


  ___arguments.context.pageData['curfewStep'] = 1;
  ___arguments.context.pageData['curfewNameError'] = undefined;
  selectedItem = (getObjectProperty(___arguments.context.pageData, 'selectedItemArrayCurfew'))[0];
  times = (JSON.parse((getObjectProperty(selectedItem, 'times'))));
  dayArray = (getObjectProperty((JSON.parse((getObjectProperty(selectedItem, 'days')))), 'dayArray'));
  ___arguments.context.pageData['curfewDayArray'] = dayArray;
  ___arguments.context.pageData['pillarRadio'] = (getObjectProperty(selectedItem, 'pillar'));
  ___arguments.context.pageData['timeDrawerOneTime'] = (getObjectProperty(times, 'startTime'));
  ___arguments.context.pageData['timeDrawerTwoTime'] = (getObjectProperty(times, 'endTime'));
  ___arguments.context.pageData['curfewName'] = (getObjectProperty(selectedItem, 'name'));
  ___arguments.context.pageData['curfewObjectId'] = (getObjectProperty(selectedItem, 'objectId'));
  ___arguments.context.pageData['curfewPillarArray'] = [(getObjectProperty(selectedItem, 'pillar'))];
  ___arguments.context.pageData['recordTableVariant'] = 'curfew';
  ___arguments.context.pageData['curfewTableVariant'] = 'edit';
  ___arguments.context.pageData['isAddRecordModalOpen'] = true;

  },
  /* handler:onClick */
  /* content */
}))

define('./components/reusable/r_8c0522b9c57b3a5b5c31b90260b6f13b/logic/69cb04b5bb5f491a92caeea62e8918cc/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    var isSame, item, selectedItemArrayCurfew;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  selectedItemArrayCurfew = (getObjectProperty(___arguments.context.pageData, 'selectedItemArrayCurfew'));
  if (selectedItemArrayCurfew && selectedItemArrayCurfew.length == 1) {
    if ((getObjectProperty((selectedItemArrayCurfew[0]), 'status')) == 'ACTIVE') {
      ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('6acfaf118a4fefa325eabbcba45e6e07'))['color'] = '#000000';
      ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('155861ea27da011781db253d5b61b0a2'))['color'] = '#000000';
    } else {
      ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('6acfaf118a4fefa325eabbcba45e6e07'))['color'] = 'rgb(0,0,0,0.3)';
      ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('155861ea27da011781db253d5b61b0a2'))['color'] = 'rgb(0,0,0,0.3)';
    }
  } else if (selectedItemArrayCurfew && selectedItemArrayCurfew.length > 1) {
    isSame = true;
    for (var item_index in selectedItemArrayCurfew) {
      item = selectedItemArrayCurfew[item_index];
      if ((getObjectProperty(item, 'status')) != 'ACTIVE') {
        isSame = false;
      }
    }
    if (isSame) {
      ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('6acfaf118a4fefa325eabbcba45e6e07'))['color'] = '#000000';
      ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('155861ea27da011781db253d5b61b0a2'))['color'] = '#000000';
    } else {
      ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('6acfaf118a4fefa325eabbcba45e6e07'))['color'] = 'rgb(0,0,0,0.3)';
      ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('155861ea27da011781db253d5b61b0a2'))['color'] = 'rgb(0,0,0,0.3)';
    }
  } else {
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('6acfaf118a4fefa325eabbcba45e6e07'))['color'] = 'rgb(0,0,0,0.3)';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('155861ea27da011781db253d5b61b0a2'))['color'] = 'rgb(0,0,0,0.3)';
  }

  },
  /* handler:onStyleAssignment */
  /* handler:onClick */
  ['onClick'](___arguments) {
    var isSame, item, selectedItemArrayCurfew;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  selectedItemArrayCurfew = (getObjectProperty(___arguments.context.pageData, 'selectedItemArrayCurfew'));
  if (selectedItemArrayCurfew && selectedItemArrayCurfew.length == 1) {
    if ((getObjectProperty((selectedItemArrayCurfew[0]), 'status')) == 'ACTIVE') {
      ___arguments.context.pageData['isConfirmationModalOpen'] = true;
      ___arguments.context.pageData['confirmationOption'] = 'deactivateCurfew';
    }
  } else if (selectedItemArrayCurfew && selectedItemArrayCurfew.length > 1) {
    isSame = true;
    for (var item_index in selectedItemArrayCurfew) {
      item = selectedItemArrayCurfew[item_index];
      if ((getObjectProperty(item, 'status')) != 'ACTIVE') {
        isSame = false;
      }
    }
    if (isSame) {
      ___arguments.context.pageData['isConfirmationModalOpen'] = true;
      ___arguments.context.pageData['confirmationOption'] = 'deactivateCurfew';
    }
  }

  },
  /* handler:onClick */
  /* content */
}))

define('./components/reusable/r_8c0522b9c57b3a5b5c31b90260b6f13b/logic/57e65d14f2e8039a61aa182488af7e1e/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    var isSame, item, selectedItemArrayCurfew;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  selectedItemArrayCurfew = (getObjectProperty(___arguments.context.pageData, 'selectedItemArrayCurfew'));
  if (selectedItemArrayCurfew && selectedItemArrayCurfew.length == 1) {
    if ((getObjectProperty((selectedItemArrayCurfew[0]), 'status')) == 'DEACTIVATED') {
      ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('ffcc63fd34a6315d4f75322e437f57df'))['color'] = '#000000';
      ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('687efb1a60951cbfd88a80e771b6c2c2'))['color'] = '#000000';
    } else {
      ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('ffcc63fd34a6315d4f75322e437f57df'))['color'] = 'rgb(0,0,0,0.3)';
      ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('687efb1a60951cbfd88a80e771b6c2c2'))['color'] = 'rgb(0,0,0,0.3)';
    }
  } else if (selectedItemArrayCurfew && selectedItemArrayCurfew.length > 1) {
    isSame = true;
    for (var item_index in selectedItemArrayCurfew) {
      item = selectedItemArrayCurfew[item_index];
      if ((getObjectProperty(item, 'status')) != 'DEACTIVATED') {
        isSame = false;
      }
    }
    if (isSame) {
      ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('ffcc63fd34a6315d4f75322e437f57df'))['color'] = '#000000';
      ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('687efb1a60951cbfd88a80e771b6c2c2'))['color'] = '#000000';
    } else {
      ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('ffcc63fd34a6315d4f75322e437f57df'))['color'] = 'rgb(0,0,0,0.3)';
      ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('687efb1a60951cbfd88a80e771b6c2c2'))['color'] = 'rgb(0,0,0,0.3)';
    }
  } else {
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('ffcc63fd34a6315d4f75322e437f57df'))['color'] = 'rgb(0,0,0,0.3)';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('687efb1a60951cbfd88a80e771b6c2c2'))['color'] = 'rgb(0,0,0,0.3)';
  }

  },
  /* handler:onStyleAssignment */
  /* handler:onClick */
  ['onClick'](___arguments) {
    var isSame, item, selectedItemArrayCurfew;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  selectedItemArrayCurfew = (getObjectProperty(___arguments.context.pageData, 'selectedItemArrayCurfew'));
  if (selectedItemArrayCurfew && selectedItemArrayCurfew.length == 1) {
    if ((getObjectProperty((selectedItemArrayCurfew[0]), 'status')) == 'DEACTIVATED') {
      ___arguments.context.pageData['isConfirmationModalOpen'] = true;
      ___arguments.context.pageData['confirmationOption'] = 'activateCurfew';
    }
  } else if (selectedItemArrayCurfew && selectedItemArrayCurfew.length > 1) {
    isSame = true;
    for (var item_index in selectedItemArrayCurfew) {
      item = selectedItemArrayCurfew[item_index];
      if ((getObjectProperty(item, 'status')) != 'DEACTIVATED') {
        isSame = false;
      }
    }
    if (isSame) {
      ___arguments.context.pageData['isConfirmationModalOpen'] = true;
      ___arguments.context.pageData['confirmationOption'] = 'activateCurfew';
    }
  }

  },
  /* handler:onClick */
  /* content */
}))

define('./components/reusable/r_8c0522b9c57b3a5b5c31b90260b6f13b/logic/503b082906ad78c83ee87fac82c6ee1f/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  async ['onStyleAssignment'](___arguments) {
    var refreshCurfewTable, pillar, currentUser, toast;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

/**
 * Describe this function...
 */
async function refreshCurfewTable2() {
  (function (componentUid, listItems) { ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('fda8a085242d236490e7423d97e85e07', []);
  ___arguments.context.pageData['isTableLoading'] = true;
  currentUser = (getObjectProperty(___arguments.context.pageData, 'currentUser'));
  pillar = (getObjectProperty(refreshCurfewTable, 'pillar'));
  try {
    if (currentUser) {
      (function (componentUid, listItems) { ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('fda8a085242d236490e7423d97e85e07', (await Backendless.Data.of('Curfews').find(Backendless.DataQueryBuilder.create().setWhereClause((await BackendlessUI.Functions.Custom['fn_5da19a0685ee9e7b97339f9e3dd69c3b']('curfew', null, (getObjectProperty(currentUser, 'organizationId.orgName')), null, null, null, pillar))).setSortBy('created asc').setPageSize(10))));
      ___arguments.context.pageData['isTableLoading'] = false;
      ___arguments.context.pageData['curfewTableCount'] = (await Backendless.Data.of('Curfews').getObjectCount(Backendless.DataQueryBuilder.create().setWhereClause((await BackendlessUI.Functions.Custom['fn_5da19a0685ee9e7b97339f9e3dd69c3b']('curfew', null, (getObjectProperty(currentUser, 'organizationId.orgName')), null, null, null, pillar)))));
    }
    ___arguments.context.pageData['selectedItemArrayCurfew'] = [];
    ___arguments.context.pageData['refreshCurfewTable'] = undefined;

  } catch (error) {
    await setToast(({ [`status`]: 'failure',[`message`]: 'Error refreshing curfew table.' }));

  }
}

/**
 * Describe this function...
 */
async function setToast(toast) {
  await BackendlessUI.Functions.Custom['fn_77bfd63e533a9b4c5577e5fe31a4b5ca'](___arguments.context.pageData, (getObjectProperty(toast, 'message')), (getObjectProperty(toast, 'status')));
}


  refreshCurfewTable = (getObjectProperty(___arguments.context.pageData, 'refreshCurfewTable'));
  if (refreshCurfewTable) {
    await refreshCurfewTable2();
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./components/reusable/r_8c0522b9c57b3a5b5c31b90260b6f13b/logic/f207ff585a1b94cdc697a475707861f7/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      ___arguments.context.pageData['curfewStep'] = 1;
  ___arguments.context.pageData['curfewNameError'] = undefined;
  ___arguments.context.pageData['curfewObjectId'] = undefined;
  ___arguments.context.pageData['timeDrawerOneTime'] = undefined;
  ___arguments.context.pageData['timeDrawerTwoTime'] = undefined;
  ___arguments.context.pageData['curfewName'] = undefined;
  ___arguments.context.pageData['pillarRadio'] = undefined;
  ___arguments.context.pageData['curfewDayArray'] = [];
  ___arguments.context.pageData['curfewPillarArray'] = [];
  ___arguments.context.pageData['recordTableVariant'] = 'curfew';
  ___arguments.context.pageData['curfewTableVariant'] = 'add';
  ___arguments.context.pageData['isAddRecordModalOpen'] = true;

  },
  /* handler:onClick */
  /* content */
}))

define('./components/reusable/r_8c0522b9c57b3a5b5c31b90260b6f13b/logic/f3f1a243dce710bafcd8ec185f8a3c91/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
    var timeObject;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  timeObject = (JSON.parse((getObjectProperty(___arguments.context.getComponentDataStoreByUid('1c89cf08fadc9571a10d99cb825a986c'), 'times'))));

  return ([(await BackendlessUI.Functions.Custom['fn_419a5da59f8ecf4150695a570f593a75']((getObjectProperty(timeObject, 'startTime')))),' to ',(await BackendlessUI.Functions.Custom['fn_419a5da59f8ecf4150695a570f593a75']((getObjectProperty(timeObject, 'endTime'))))].join(''))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./components/reusable/r_8c0522b9c57b3a5b5c31b90260b6f13b/logic/eaabad5b91d262d2db3e9ec4c8898895/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty((JSON.parse((getObjectProperty(___arguments.context.getComponentDataStoreByUid('1c89cf08fadc9571a10d99cb825a986c'), 'days')))), 'dayArray')).length == 7) {
    return 'Repeat every day';
  } else {
    return (String((getObjectProperty((JSON.parse((getObjectProperty(___arguments.context.getComponentDataStoreByUid('1c89cf08fadc9571a10d99cb825a986c'), 'days')))), 'dayArray')).join(', ')) + String('!'));
  }

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./components/reusable/r_8c0522b9c57b3a5b5c31b90260b6f13b/logic/237b00d9f0bb3d0f861c40262914e617/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  ___arguments.context.pageData['isConfirmationModalOpen'] = true;
  if ((getObjectProperty(((getObjectProperty(___arguments.context.pageData, 'selectedItemArrayCurfew'))[0]), 'status')) == 'ACTIVE') {
    ___arguments.context.pageData['confirmationOption'] = 'deleteActiveCurfew';
  } else {
    ___arguments.context.pageData['confirmationOption'] = 'deleteCurfew';
  }

  },
  /* handler:onClick */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    var isSame, item, selectedItemArrayCurfew;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  selectedItemArrayCurfew = (getObjectProperty(___arguments.context.pageData, 'selectedItemArrayCurfew'));
  if (selectedItemArrayCurfew && selectedItemArrayCurfew.length == 1) {
    if ((getObjectProperty((selectedItemArrayCurfew[0]), 'status')) == 'DEACTIVATED') {
      ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('1f6ce974c0663bfd4ad4c2afc372596d'))['color'] = '#000000';
      ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('95fabf15731683d1fc54f5e77eb9b484'))['color'] = '#000000';
    } else {
      ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('1f6ce974c0663bfd4ad4c2afc372596d'))['color'] = 'rgb(0,0,0,0.3)';
      ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('95fabf15731683d1fc54f5e77eb9b484'))['color'] = 'rgb(0,0,0,0.3)';
    }
  } else if (selectedItemArrayCurfew && selectedItemArrayCurfew.length > 1) {
    isSame = true;
    for (var item_index in selectedItemArrayCurfew) {
      item = selectedItemArrayCurfew[item_index];
      if ((getObjectProperty(item, 'status')) != 'DEACTIVATED') {
        isSame = false;
      }
    }
    if (isSame) {
      ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('1f6ce974c0663bfd4ad4c2afc372596d'))['color'] = '#000000';
      ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('95fabf15731683d1fc54f5e77eb9b484'))['color'] = '#000000';
    } else {
      ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('1f6ce974c0663bfd4ad4c2afc372596d'))['color'] = 'rgb(0,0,0,0.3)';
      ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('95fabf15731683d1fc54f5e77eb9b484'))['color'] = 'rgb(0,0,0,0.3)';
    }
  } else {
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('1f6ce974c0663bfd4ad4c2afc372596d'))['color'] = 'rgb(0,0,0,0.3)';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('95fabf15731683d1fc54f5e77eb9b484'))['color'] = 'rgb(0,0,0,0.3)';
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./components/reusable/r_8c0522b9c57b3a5b5c31b90260b6f13b/logic/188acd792415339a094be9e73bb77cad/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    var isTableLoading, orgTableCount, navbarSelection, residentTableCount, userTableCount, curfewTableCount;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  curfewTableCount = (getObjectProperty(___arguments.context.pageData, 'curfewTableCount'));
  isTableLoading = (getObjectProperty(___arguments.context.pageData, 'isTableLoading'));
  if (curfewTableCount == 0 && !isTableLoading) {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('188acd792415339a094be9e73bb77cad', true);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('188acd792415339a094be9e73bb77cad', false);
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./components/reusable/r_3c81022b009ca37d201fd0954e7c249c/logic/bbd670ac909779f6736f3655981c8ae1/bundle.js', [], () => ({
  /* content */
  /* handler:PageCountLogic */
  ['PageCountLogic'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'residentTableCount')) {
    if ((getObjectProperty(___arguments.context.pageData, 'residentTableCount')) > 10) {
      return (Math.ceil((getObjectProperty(___arguments.context.pageData, 'residentTableCount')) / 10));
    } else {
      return 1;
    }
  }

  },
  /* handler:PageCountLogic */
  /* handler:onPageChange */
  async ['onPageChange'](___arguments) {
    var currentUser, residentTableFilter, changedValue;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  ___arguments.context.pageData['isTableLoading'] = true;
  (function (componentUid, listItems) { ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('3787794e83ad9d2657e56df6ed2b68ed', []);
  residentTableFilter = (getObjectProperty(___arguments.context.pageData, 'residentTableFilter'));
  currentUser = (getObjectProperty(___arguments.context.pageData, 'currentUser'));
  changedValue = (getObjectProperty(___arguments.context.pageData, 'changedValueResident'));
  if (currentUser && !(getObjectProperty(___arguments.context.pageData, 'hasClickedApplyFilter'))) {
    (function (componentUid, listItems) { ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('3787794e83ad9d2657e56df6ed2b68ed', (await Backendless.Data.of('ResidentInformation').find(Backendless.DataQueryBuilder.create().setWhereClause((await BackendlessUI.Functions.Custom['fn_5da19a0685ee9e7b97339f9e3dd69c3b']('resident', (getObjectProperty(currentUser, 'email')), (getObjectProperty(currentUser, 'organizationId.orgName')), changedValue, residentTableFilter, (getObjectProperty(currentUser, 'userRole')), null))).setSortBy('created asc').setPageSize(10).setOffset((10 * ___arguments['currentPage'] - 10)))));
    ___arguments.context.pageData['isTableLoading'] = false;
    if (!(getObjectProperty(___arguments.context.pageData, 'pageEnter'))) {
      ___arguments.context.pageData['residentTableCount'] = (await Backendless.Data.of('ResidentInformation').getObjectCount(Backendless.DataQueryBuilder.create().setWhereClause((await BackendlessUI.Functions.Custom['fn_5da19a0685ee9e7b97339f9e3dd69c3b']('resident', (getObjectProperty(currentUser, 'email')), (getObjectProperty(currentUser, 'organizationId.orgName')), changedValue, residentTableFilter, (getObjectProperty(currentUser, 'userRole')), null)))));
      ___arguments.context.pageData['pageEnter'] = true;
      if ((getObjectProperty(___arguments.context.pageData, 'residentTableCount')) == 0) {
        ___arguments.context.pageData['noResultsScreen'] = true;
      }
    }
  }
  ___arguments.context.pageData['isTableLoading'] = false;

  },
  /* handler:onPageChange */
  /* handler:onStyleAssignment */
  async ['onStyleAssignment'](___arguments) {
    var currentUser, residentTableFilter, changedValue;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  residentTableFilter = (getObjectProperty(___arguments.context.pageData, 'residentTableFilter'));
  currentUser = (getObjectProperty(___arguments.context.pageData, 'currentUser'));
  changedValue = (getObjectProperty(___arguments.context.pageData, 'changedValueResident'));
  if (getObjectProperty(___arguments.context.pageData, 'hasClickedApplyFilter')) {
    ___arguments.context.pageData['isResidentRecordFilterDrawerOpen'] = false;
    ___arguments.context.pageData['hasClickedApplyFilter'] = false;
    ___arguments.context.pageData['isTableLoading'] = true;
    (function (componentUid, listItems) { ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('3787794e83ad9d2657e56df6ed2b68ed', []);
    if (currentUser) {
      (function (componentUid, listItems) { ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('3787794e83ad9d2657e56df6ed2b68ed', (await Backendless.Data.of('ResidentInformation').find(Backendless.DataQueryBuilder.create().setWhereClause((await BackendlessUI.Functions.Custom['fn_5da19a0685ee9e7b97339f9e3dd69c3b']('resident', (getObjectProperty(currentUser, 'email')), (getObjectProperty(currentUser, 'organizationId.orgName')), changedValue, residentTableFilter, (getObjectProperty(currentUser, 'userRole')), null))).setSortBy('created asc').setPageSize(10))));
      ___arguments.context.pageData['isTableLoading'] = false;
      ___arguments.context.pageData['residentTableCount'] = (await Backendless.Data.of('ResidentInformation').getObjectCount(Backendless.DataQueryBuilder.create().setWhereClause((await BackendlessUI.Functions.Custom['fn_5da19a0685ee9e7b97339f9e3dd69c3b']('resident', (getObjectProperty(currentUser, 'email')), (getObjectProperty(currentUser, 'organizationId.orgName')), changedValue, residentTableFilter, (getObjectProperty(currentUser, 'userRole')), null)))));
    }
    ___arguments.context.pageData['isTableLoading'] = false;
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./components/reusable/r_3c81022b009ca37d201fd0954e7c249c/logic/80c7e9324455f0620ca672e70558689e/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    var isTableLoading;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  isTableLoading = (getObjectProperty(___arguments.context.pageData, 'isTableLoading'));
  if (isTableLoading) {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('80c7e9324455f0620ca672e70558689e', true);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('80c7e9324455f0620ca672e70558689e', false);
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./components/reusable/r_3c81022b009ca37d201fd0954e7c249c/logic/ceedae56aca9857b5cdf3292d47a8094/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'residentTableCount')) {
    return (['Showing ',(getObjectProperty(___arguments.context.pageData, 'residentTableCount')),' records'].join(''));
  } else if ((getObjectProperty(___arguments.context.pageData, 'residentTableCount')) == 0) {
    return 'Showing 0 records';
  }

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./components/reusable/r_3c81022b009ca37d201fd0954e7c249c/logic/cf21deee1441481bf3d3a19f1f8271ce/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  async ['onChange'](___arguments) {
    var currentUser, residentTableFilter, changedValue;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  changedValue = ___arguments.value.split('\'').join('');
  residentTableFilter = (getObjectProperty(___arguments.context.pageData, 'residentTableFilter'));
  currentUser = (getObjectProperty(___arguments.context.pageData, 'currentUser'));
  ___arguments.context.pageData['isTableLoading'] = true;
  (function (componentUid, listItems) { ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('3787794e83ad9d2657e56df6ed2b68ed', []);
  ___arguments.context.pageData['changedValueResident'] = changedValue;
  (function (componentUid, listItems) { ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('3787794e83ad9d2657e56df6ed2b68ed', (await Backendless.Data.of('ResidentInformation').find(Backendless.DataQueryBuilder.create().setWhereClause((await BackendlessUI.Functions.Custom['fn_5da19a0685ee9e7b97339f9e3dd69c3b']('resident', (getObjectProperty(currentUser, 'email')), (getObjectProperty(currentUser, 'organizationId.orgName')), changedValue, residentTableFilter, (getObjectProperty(currentUser, 'userRole')), null))).setSortBy('created asc').setPageSize(10))));
  ___arguments.context.pageData['isTableLoading'] = false;
  ___arguments.context.pageData['residentTableCount'] = (await Backendless.Data.of('ResidentInformation').getObjectCount(Backendless.DataQueryBuilder.create().setWhereClause((await BackendlessUI.Functions.Custom['fn_5da19a0685ee9e7b97339f9e3dd69c3b']('resident', (getObjectProperty(currentUser, 'email')), (getObjectProperty(currentUser, 'organizationId.orgName')), changedValue, residentTableFilter, (getObjectProperty(currentUser, 'userRole')), null)))));
  ___arguments.context.pageData['isTableLoading'] = false;

  },
  /* handler:onChange */
  /* content */
}))

define('./components/reusable/r_3c81022b009ca37d201fd0954e7c249c/logic/9ad8462142dd3943bab636487518c838/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    var isActionDrawerOpen;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  isActionDrawerOpen = (getObjectProperty(___arguments.context.pageData, 'isResidentRecordActionDrawerOpen'));
  if (isActionDrawerOpen) {
    ___arguments.context.pageData['isResidentRecordActionDrawerOpen'] = false;
    ___arguments.context.pageData['userClickedResidentAction'] = false;
  } else {
    ___arguments.context.pageData['isResidentRecordActionDrawerOpen'] = true;
    ___arguments.context.pageData['userClickedResidentAction'] = true;
  }

  },
  /* handler:onClick */
  /* content */
}))

define('./components/reusable/r_3c81022b009ca37d201fd0954e7c249c/logic/4db83ef9ca89b79b5275a4ab68f71a86/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    var isActionDrawerOpen;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  isActionDrawerOpen = (getObjectProperty(___arguments.context.pageData, 'isResidentRecordActionDrawerOpen'));
  if (isActionDrawerOpen) {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('4db83ef9ca89b79b5275a4ab68f71a86', true);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('4db83ef9ca89b79b5275a4ab68f71a86', false);
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./components/reusable/r_3c81022b009ca37d201fd0954e7c249c/logic/02d79d2f7f6b67f6c447e9d7fda5d77c/bundle.js', [], () => ({
  /* content */
  /* handler:onSourceUrlAssignment */
  ['onSourceUrlAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.getComponentDataStoreByUid('478f377cbbc8f1468f7b71ccf77e283c'), 'profilePicture')) {
    return (getObjectProperty(___arguments.context.getComponentDataStoreByUid('478f377cbbc8f1468f7b71ccf77e283c'), 'profilePicture'));
  } else {
    return 'https://app.aquax.co/api/files/cyberdive_photos/cd-icon-default-photo.svg';
  }

  },
  /* handler:onSourceUrlAssignment */
  /* content */
}))

define('./components/reusable/r_3c81022b009ca37d201fd0954e7c249c/logic/478f377cbbc8f1468f7b71ccf77e283c/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      ___arguments.context.pageData['selectedItemResident'] = ___arguments.context.dataModel;

  },
  /* handler:onClick */
  /* content */
}))

define('./components/reusable/r_3c81022b009ca37d201fd0954e7c249c/logic/5a77fb599fd92f9f30d3d44e1733a7c2/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    var selectedArray;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  selectedArray = (getObjectProperty(___arguments.context.pageData, 'selectedItemArrayResident'));
  ___arguments.context.pageData['isCurfewModalOpen'] = true;
  if (selectedArray && (getObjectProperty((selectedArray[0]), 'curfew'))) {
    ___arguments.context.pageData['curfewArray'] = (JSON.parse((getObjectProperty((selectedArray[0]), 'curfew'))));
    ___arguments.context.pageData['curfewModalSteps'] = 4;
  } else {
    ___arguments.context.pageData['curfewModalSteps'] = 1;
    ___arguments.context.pageData['curfewArray'] = [];
  }

  },
  /* handler:onClick */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    var selectedItemArrayResident;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  selectedItemArrayResident = (getObjectProperty(___arguments.context.pageData, 'selectedItemArrayResident'));
  if (selectedItemArrayResident && selectedItemArrayResident.length == 1) {
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('c8d42e7e69c46fdb56b22f25cb3236ea'))['color'] = '#000000';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('c093041f0d2956d1e01921dc1c0a2c49'))['color'] = '#000000';
  } else {
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('c8d42e7e69c46fdb56b22f25cb3236ea'))['color'] = 'rgb(0,0,0,0.3)';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('c093041f0d2956d1e01921dc1c0a2c49'))['color'] = 'rgb(0,0,0,0.3)';
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./components/reusable/r_3c81022b009ca37d201fd0954e7c249c/logic/49b18622d00f56dec3eabe384e5a9a8e/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  async ['onStyleAssignment'](___arguments) {
    var refreshResidentTable, currentUser, residentTableFilter, changedValue;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

/**
 * Describe this function...
 */
async function refreshResidentTable2() {
  ___arguments.context.pageData['refreshResidentTable'] = undefined;
  ___arguments.context.pageData['isTableLoading'] = true;
  (function (componentUid, listItems) { ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('3787794e83ad9d2657e56df6ed2b68ed', []);
  currentUser = (getObjectProperty(___arguments.context.pageData, 'currentUser'));
  changedValue = (getObjectProperty(___arguments.context.pageData, 'changedValueResident'));
  residentTableFilter = (getObjectProperty(___arguments.context.pageData, 'residentTableFilter'));
  (function (componentUid, listItems) { ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('3787794e83ad9d2657e56df6ed2b68ed', (await Backendless.Data.of('ResidentInformation').find(Backendless.DataQueryBuilder.create().setWhereClause((await BackendlessUI.Functions.Custom['fn_5da19a0685ee9e7b97339f9e3dd69c3b']('resident', (getObjectProperty(currentUser, 'email')), (getObjectProperty(currentUser, 'organizationId.orgName')), changedValue, residentTableFilter, (getObjectProperty(currentUser, 'userRole')), null))).setSortBy('created asc').setPageSize(10))));
  ___arguments.context.pageData['isTableLoading'] = false;
  ___arguments.context.pageData['residentTableCount'] = (await Backendless.Data.of('ResidentInformation').getObjectCount(Backendless.DataQueryBuilder.create().setWhereClause((await BackendlessUI.Functions.Custom['fn_5da19a0685ee9e7b97339f9e3dd69c3b']('resident', (getObjectProperty(currentUser, 'email')), (getObjectProperty(currentUser, 'organizationId.orgName')), changedValue, residentTableFilter, (getObjectProperty(currentUser, 'userRole')), null)))));
  ___arguments.context.pageData['refreshResidentGraph'] = true;
  ___arguments.context.pageData['selectedItemArrayResident'] = [];
}


  refreshResidentTable = (getObjectProperty(___arguments.context.pageData, 'refreshResidentTable'));
  if (refreshResidentTable) {
    await refreshResidentTable2();
  }

  },
  /* handler:onStyleAssignment */
  /* handler:onBeforeMount */
  ['onBeforeMount'](___arguments) {
      ___arguments.context.pageData['selectedItemArrayResident'] = [];
  ___arguments.context.pageData['residentFilterArray'] = [];
  ___arguments.context.pageData['residentTableFilter'] = [];

  },
  /* handler:onBeforeMount */
  /* content */
}))

define('./components/reusable/r_3c81022b009ca37d201fd0954e7c249c/logic/d34853610e1b715f8f0fd1bd474b627c/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (await BackendlessUI.Functions.Custom['fn_9807a958b9f47818617415e4438b5e47']((getObjectProperty(___arguments.context.getComponentDataStoreByUid('478f377cbbc8f1468f7b71ccf77e283c'), 'nameInUse')), 20))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./components/reusable/r_3c81022b009ca37d201fd0954e7c249c/logic/40a8c152f26f671d40bef9723be82425/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var item, isFilerDrawerOpen;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  isFilerDrawerOpen = (getObjectProperty(___arguments.context.pageData, 'isResidentRecordFilterDrawerOpen'));
  if (isFilerDrawerOpen) {
    ___arguments.context.pageData['residentFilterArray'] = (await Promise.all((getObjectProperty(___arguments.context.pageData, 'residentFilterArray')).map(async item => {  if ((getObjectProperty(___arguments.context.pageData, 'residentTableFilter')).includes(item)) {
        return item;
      }
    ; return ;})));
    ___arguments.context.pageData['isResidentRecordFilterDrawerOpen'] = false;
  } else {
    ___arguments.context.pageData['residentFilterArray'] = (await Promise.all((getObjectProperty(___arguments.context.pageData, 'residentTableFilter')).map(async item => {; return item;})));
    ___arguments.context.pageData['isResidentRecordFilterDrawerOpen'] = true;
  }

  },
  /* handler:onClick */
  /* content */
}))

define('./components/reusable/r_3c81022b009ca37d201fd0954e7c249c/logic/861fbdccb0b74c8cf36f674da186c486/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    var isFilterDrawerOpen;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  isFilterDrawerOpen = (getObjectProperty(___arguments.context.pageData, 'isResidentRecordFilterDrawerOpen'));
  if (isFilterDrawerOpen) {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('861fbdccb0b74c8cf36f674da186c486', true);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('861fbdccb0b74c8cf36f674da186c486', false);
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./components/reusable/r_3c81022b009ca37d201fd0954e7c249c/logic/889b70b36329145268e9930aeeee61d9/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  ['onChange'](___arguments) {
    var residentFilterArray;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}

function removeItemInList(l, i, c) {   const index = c ? l.findIndex(el => el[c] === i[c]) : l.indexOf(i);  if (index >= 0) { l.splice(index, 1); }  return l;}


  residentFilterArray = (getObjectProperty(___arguments.context.pageData, 'residentFilterArray'));
  if (___arguments.value) {
    addItemToList(residentFilterArray, 'pillar = \'1\'');
    ___arguments.context.pageData['residentFilterArray'] = residentFilterArray;
  } else {
    removeItemInList(residentFilterArray, 'pillar = \'1\'', '');
    ___arguments.context.pageData['residentFilterArray'] = residentFilterArray;
  }

  },
  /* handler:onChange */
  /* handler:onCheckedStateAssignment */
  ['onCheckedStateAssignment'](___arguments) {
    var residentFilterArray;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  residentFilterArray = (getObjectProperty(___arguments.context.pageData, 'residentFilterArray'));
  if (residentFilterArray && (residentFilterArray.includes('pillar = \'1\''))) {
    return true;
  } else {
    return false;
  }

  },
  /* handler:onCheckedStateAssignment */
  /* content */
}))

define('./components/reusable/r_3c81022b009ca37d201fd0954e7c249c/logic/c14e0776464e9179d421dd6c833c3748/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  ['onChange'](___arguments) {
    var residentFilterArray;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}

function removeItemInList(l, i, c) {   const index = c ? l.findIndex(el => el[c] === i[c]) : l.indexOf(i);  if (index >= 0) { l.splice(index, 1); }  return l;}


  residentFilterArray = (getObjectProperty(___arguments.context.pageData, 'residentFilterArray'));
  if (___arguments.value) {
    addItemToList(residentFilterArray, 'pillar = \'2\'');
    ___arguments.context.pageData['residentFilterArray'] = residentFilterArray;
  } else {
    removeItemInList(residentFilterArray, 'pillar = \'2\'', '');
    ___arguments.context.pageData['residentFilterArray'] = residentFilterArray;
  }

  },
  /* handler:onChange */
  /* handler:onCheckedStateAssignment */
  ['onCheckedStateAssignment'](___arguments) {
    var residentFilterArray;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  residentFilterArray = (getObjectProperty(___arguments.context.pageData, 'residentFilterArray'));
  if (residentFilterArray && (residentFilterArray.includes('pillar = \'2\''))) {
    return true;
  } else {
    return false;
  }

  },
  /* handler:onCheckedStateAssignment */
  /* content */
}))

define('./components/reusable/r_3c81022b009ca37d201fd0954e7c249c/logic/cfd5972870c2827598e37a4f774e33ba/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  ['onChange'](___arguments) {
    var residentFilterArray;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}

function removeItemInList(l, i, c) {   const index = c ? l.findIndex(el => el[c] === i[c]) : l.indexOf(i);  if (index >= 0) { l.splice(index, 1); }  return l;}


  residentFilterArray = (getObjectProperty(___arguments.context.pageData, 'residentFilterArray'));
  if (___arguments.value) {
    addItemToList(residentFilterArray, 'pillar = \'3\'');
    ___arguments.context.pageData['residentFilterArray'] = residentFilterArray;
  } else {
    removeItemInList(residentFilterArray, 'pillar = \'3\'', '');
    ___arguments.context.pageData['residentFilterArray'] = residentFilterArray;
  }

  },
  /* handler:onChange */
  /* handler:onCheckedStateAssignment */
  ['onCheckedStateAssignment'](___arguments) {
    var residentFilterArray;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  residentFilterArray = (getObjectProperty(___arguments.context.pageData, 'residentFilterArray'));
  if (residentFilterArray && (residentFilterArray.includes('pillar = \'3\''))) {
    return true;
  } else {
    return false;
  }

  },
  /* handler:onCheckedStateAssignment */
  /* content */
}))

define('./components/reusable/r_3c81022b009ca37d201fd0954e7c249c/logic/857af952d29c4c38896d5a0b8b3f7aa1/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  ['onChange'](___arguments) {
    var residentFilterArray;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}

function removeItemInList(l, i, c) {   const index = c ? l.findIndex(el => el[c] === i[c]) : l.indexOf(i);  if (index >= 0) { l.splice(index, 1); }  return l;}


  residentFilterArray = (getObjectProperty(___arguments.context.pageData, 'residentFilterArray'));
  if (___arguments.value) {
    addItemToList(residentFilterArray, 'pillar = \'4\'');
    ___arguments.context.pageData['residentFilterArray'] = residentFilterArray;
  } else {
    removeItemInList(residentFilterArray, 'pillar = \'4\'', '');
    ___arguments.context.pageData['residentFilterArray'] = residentFilterArray;
  }

  },
  /* handler:onChange */
  /* handler:onCheckedStateAssignment */
  ['onCheckedStateAssignment'](___arguments) {
    var residentFilterArray;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  residentFilterArray = (getObjectProperty(___arguments.context.pageData, 'residentFilterArray'));
  if (residentFilterArray && (residentFilterArray.includes('pillar = \'4\''))) {
    return true;
  } else {
    return false;
  }

  },
  /* handler:onCheckedStateAssignment */
  /* content */
}))

define('./components/reusable/r_3c81022b009ca37d201fd0954e7c249c/logic/2535743c6ea66666763f27f5a18d1b32/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      ___arguments.context.pageData['recordTableVariant'] = 'resident';
  ___arguments.context.pageData['residentIntakeVariant'] = 'intake';
  ___arguments.context.pageData['residentIntakeStep'] = 1;
  ___arguments.context.pageData['isNewResident'] = true;
  ___arguments.context.pageData['selectedResidentImage'] = undefined;
  ___arguments.context.pageData['selectedResidentImageFile'] = undefined;
  ___arguments.context.pageData['residentIntakeForm'] = ({ [`residentObjectId`]: undefined,[`pillarInfo`]: undefined,[`profilePicture`]: undefined,[`residentIntakeStep`]: undefined,[`personalDetails`]: ({ [`race`]: ({ [`answer`]: 'default',[`other`]: undefined }),[`genderIdentity`]: ({ [`answer`]: undefined,[`other`]: undefined }),[`pronouns`]: ({ [`answer`]: undefined,[`other`]: undefined }) }),[`emergencyContact`]: ({ [`relationship`]: ({ [`answer`]: undefined,[`other`]: undefined }) }) });
  ___arguments.context.pageData['isAddRecordModalOpen'] = true;

  },
  /* handler:onClick */
  /* content */
}))

define('./components/reusable/r_3c81022b009ca37d201fd0954e7c249c/logic/de16b2149bf4d8ee51b3767904858d01/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    var isTableLoading, orgTableCount, navbarSelection, residentTableCount, userTableCount, curfewTableCount;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  residentTableCount = (getObjectProperty(___arguments.context.pageData, 'residentTableCount'));
  isTableLoading = (getObjectProperty(___arguments.context.pageData, 'isTableLoading'));
  if (residentTableCount == 0 && !isTableLoading) {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('de16b2149bf4d8ee51b3767904858d01', true);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('de16b2149bf4d8ee51b3767904858d01', false);
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./components/reusable/r_3c81022b009ca37d201fd0954e7c249c/logic/43ef7d12266ba0cd487a51ff8e29152e/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var getDeviceLocationResponse, getDeviceIdResponse;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  ___arguments.context.pageData['selectedDeviceLocation'] = undefined;
  ___arguments.context.pageData['locationError'] = false;
  ___arguments.context.pageData['isMapLoading'] = true;
  ___arguments.context.pageData['selectedItemDeviceIMEI'] = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('478f377cbbc8f1468f7b71ccf77e283c'), 'deviceIMEI'));
  ___arguments.context.pageData['residentIntakeForm'] = (JSON.parse((getObjectProperty(___arguments.context.getComponentDataStoreByUid('478f377cbbc8f1468f7b71ccf77e283c'), 'residentIntakeForm'))));
  ___arguments.context.pageData['detailsSelection'] = 'details';
  ___arguments.context.pageData['recordSelection'] = 'residentRecords';
  ___arguments.context.pageData['selectedItemDetailsSelection'] = 'details';
  ___arguments.context.pageData['isViewDetailsOpen'] = true;
  if (getObjectProperty(___arguments.context.getComponentDataStoreByUid('478f377cbbc8f1468f7b71ccf77e283c'), 'deviceIMEI')) {
    try {
      getDeviceIdResponse = (await Backendless.Request.get(`${Backendless.appPath}/services/HeadwindMDM/DeviceId`).set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).query({ 'token': JSON.stringify((getObjectProperty((JSON.parse((getObjectProperty(___arguments.context.pageData, 'currentUser.organizationId.headwindData')))), 'token'))),'deviceNumber': JSON.stringify((getObjectProperty(___arguments.context.getComponentDataStoreByUid('478f377cbbc8f1468f7b71ccf77e283c'), 'deviceId'))) }).send());
      if ((getObjectProperty(getDeviceIdResponse, 'status')) == 'OK') {
        getDeviceLocationResponse = (await Backendless.Request.get(`${Backendless.appPath}/services/HeadwindMDM/DeviceLocation`).set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).query({ 'token': JSON.stringify((getObjectProperty((JSON.parse((getObjectProperty(___arguments.context.pageData, 'currentUser.organizationId.headwindData')))), 'token'))),'deviceId': (getObjectProperty(getDeviceIdResponse, 'data.id')) }).send());
        if ((getObjectProperty(getDeviceLocationResponse, 'status')) == 'OK') {
          ___arguments.context.pageData['selectedDeviceLocation'] = [({ [`lng`]: (getObjectProperty(getDeviceLocationResponse, 'data.lon')),[`lat`]: (getObjectProperty(getDeviceLocationResponse, 'data.lat')) })];
          await Backendless.Data.of('Residents').save( ({ [`objectId`]: (getObjectProperty(___arguments.context.getComponentDataStoreByUid('478f377cbbc8f1468f7b71ccf77e283c'), 'objectId')),[`lastLocation`]: (await Backendless.Request.get(`${Backendless.appPath}/services/AWSApis/getDeviceLocation`).set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).query({ 'lng': JSON.stringify((getObjectProperty(getDeviceLocationResponse, 'data.lon'))),'lat': JSON.stringify((getObjectProperty(getDeviceLocationResponse, 'data.lat'))) }).send()) }) );
          ___arguments.context.pageData['isMapLoading'] = false;
        } else {
          throw (new Error(''))
        }
      } else {
        throw (new Error(''))
      }

    } catch (error) {
      await BackendlessUI.Functions.Custom['fn_77bfd63e533a9b4c5577e5fe31a4b5ca'](___arguments.context.pageData, 'Error finding device location.', 'failure');
      ___arguments.context.pageData['isMapLoading'] = false;
      ___arguments.context.pageData['locationError'] = true;

    }
  }
  ___arguments.context.pageData['isMapLoading'] = false;

  },
  /* handler:onClick */
  /* content */
}))

define('./components/reusable/r_3c81022b009ca37d201fd0954e7c249c/logic/e3787089737a91825c38bb77f46d5556/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  ['onChange'](___arguments) {
    var residentFilterArray;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}

function removeItemInList(l, i, c) {   const index = c ? l.findIndex(el => el[c] === i[c]) : l.indexOf(i);  if (index >= 0) { l.splice(index, 1); }  return l;}


  residentFilterArray = (getObjectProperty(___arguments.context.pageData, 'residentFilterArray'));
  if (___arguments.value) {
    addItemToList(residentFilterArray, 'status = \'ACTIVE\'');
    ___arguments.context.pageData['residentFilterArray'] = residentFilterArray;
  } else {
    removeItemInList(residentFilterArray, 'status = \'ACTIVE\'', '');
    ___arguments.context.pageData['residentFilterArray'] = residentFilterArray;
  }

  },
  /* handler:onChange */
  /* handler:onCheckedStateAssignment */
  ['onCheckedStateAssignment'](___arguments) {
    var residentFilterArray;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  residentFilterArray = (getObjectProperty(___arguments.context.pageData, 'residentFilterArray'));
  if (residentFilterArray && (residentFilterArray.includes('status = \'ACTIVE\''))) {
    return true;
  } else {
    return false;
  }

  },
  /* handler:onCheckedStateAssignment */
  /* content */
}))

define('./components/reusable/r_3c81022b009ca37d201fd0954e7c249c/logic/279dd6edae63568c8aec736f68e61a99/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  ['onChange'](___arguments) {
    var residentFilterArray;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}

function removeItemInList(l, i, c) {   const index = c ? l.findIndex(el => el[c] === i[c]) : l.indexOf(i);  if (index >= 0) { l.splice(index, 1); }  return l;}


  residentFilterArray = (getObjectProperty(___arguments.context.pageData, 'residentFilterArray'));
  if (___arguments.value) {
    addItemToList(residentFilterArray, 'status = \'DEACTIVED\'');
    ___arguments.context.pageData['residentFilterArray'] = residentFilterArray;
  } else {
    removeItemInList(residentFilterArray, 'status = \'DEACTIVED\'', '');
    ___arguments.context.pageData['residentFilterArray'] = residentFilterArray;
  }

  },
  /* handler:onChange */
  /* handler:onCheckedStateAssignment */
  ['onCheckedStateAssignment'](___arguments) {
    var residentFilterArray;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  residentFilterArray = (getObjectProperty(___arguments.context.pageData, 'residentFilterArray'));
  if (residentFilterArray && (residentFilterArray.includes('status = \'DEACTIVED\''))) {
    return true;
  } else {
    return false;
  }

  },
  /* handler:onCheckedStateAssignment */
  /* content */
}))

define('./components/reusable/r_3c81022b009ca37d201fd0954e7c249c/logic/0088d46d1e9540d9458fcb341b7c9ef7/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  ['onChange'](___arguments) {
    var residentFilterArray;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}

function removeItemInList(l, i, c) {   const index = c ? l.findIndex(el => el[c] === i[c]) : l.indexOf(i);  if (index >= 0) { l.splice(index, 1); }  return l;}


  residentFilterArray = (getObjectProperty(___arguments.context.pageData, 'residentFilterArray'));
  if (___arguments.value) {
    addItemToList(residentFilterArray, 'status = \'ONBOARDSTARTED\'');
    ___arguments.context.pageData['residentFilterArray'] = residentFilterArray;
  } else {
    removeItemInList(residentFilterArray, 'status = \'ONBOARDSTARTED\'', '');
    ___arguments.context.pageData['residentFilterArray'] = residentFilterArray;
  }

  },
  /* handler:onChange */
  /* handler:onCheckedStateAssignment */
  ['onCheckedStateAssignment'](___arguments) {
    var residentFilterArray;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  residentFilterArray = (getObjectProperty(___arguments.context.pageData, 'residentFilterArray'));
  if (residentFilterArray && (residentFilterArray.includes('status = \'ONBOARDSTARTED\''))) {
    return true;
  } else {
    return false;
  }

  },
  /* handler:onCheckedStateAssignment */
  /* content */
}))

define('./components/reusable/r_3c81022b009ca37d201fd0954e7c249c/logic/0aeb2854b7d90687b69e90f29ec4dfeb/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('478f377cbbc8f1468f7b71ccf77e283c'), 'deviceStatus')) == 'ASSIGNED') {
    return (await BackendlessUI.Functions.Custom['fn_9807a958b9f47818617415e4438b5e47']((getObjectProperty(___arguments.context.getComponentDataStoreByUid('478f377cbbc8f1468f7b71ccf77e283c'), 'deviceId')), 10));
  } else {
    return 'Assign Device';
  }

  },
  /* handler:onContentAssignment */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    var classesList;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('478f377cbbc8f1468f7b71ccf77e283c'), 'deviceStatus')) == 'ASSIGNED') {
    classesList = ['resident_device_text'];
  } else {
    classesList = ['resident_assign_device_text'];
  }

  return classesList

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./components/reusable/r_3c81022b009ca37d201fd0954e7c249c/logic/f818188a32db356b0879ed92a389eaab/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    var pillar;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  pillar = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('478f377cbbc8f1468f7b71ccf77e283c'), 'pillar'));
  if (pillar == '1') {
    return 'Pillar 01';
  } else if (pillar == '2') {
    return 'Pillar 02';
  } else if (pillar == '3') {
    return 'Pillar 03';
  } else if (pillar == '4') {
    return 'Pillar 04';
  }

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./components/reusable/r_3c81022b009ca37d201fd0954e7c249c/logic/b9e9ff39776add88908a66b9b8ef3058/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    var classesList;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('478f377cbbc8f1468f7b71ccf77e283c'), 'deviceStatus')) == 'ASSIGNED') {
    classesList = ['resident_device_icon'];
  } else {
    classesList = ['resident_assign_device_icon'];
  }

  return classesList

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./components/reusable/r_3c81022b009ca37d201fd0954e7c249c/logic/9a4f0e9f13b95bf6044ee74367145739/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    var classesList;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('478f377cbbc8f1468f7b71ccf77e283c'), 'deviceStatus')) == 'ASSIGNED') {
    classesList = ['resident_device_block'];
  } else {
    classesList = ['resident_assign_device_block'];
  }

  return classesList

  },
  /* handler:onClassListAssignment */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  ___arguments.context.pageData['residentIntakeForm'] = (JSON.parse((getObjectProperty(___arguments.context.getComponentDataStoreByUid('478f377cbbc8f1468f7b71ccf77e283c'), 'residentIntakeForm'))));
  if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('478f377cbbc8f1468f7b71ccf77e283c'), 'deviceStatus')) == 'ASSIGNED') {
    ___arguments.context.pageData['pageEnter'] = false;
    ___arguments.context.pageData['changedValueDevice'] = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('478f377cbbc8f1468f7b71ccf77e283c'), 'deviceIMEI'));
    ___arguments.context.pageData['navbarSelection'] = 'deviceRecords';
    localStorage.setItem('navbarSelection', JSON.stringify('deviceRecords'));
  } else {
    await BackendlessUI.Functions.Custom['fn_d48a6d3a366b0ef43b6e0b4bab07fa46'](___arguments.context.pageData);
    ___arguments.context.pageData['isNewResident'] = false;
    ___arguments.context.pageData['residentPillarRadio'] = 1;
    ___arguments.context.pageData['hasStoppedPolling'] = undefined;
    ___arguments.context.pageData['hasOnboardStarted'] = undefined;
    ___arguments.context.pageData['residentIntakeStep'] = undefined;
    ___arguments.context.pageData['recordTableVariant'] = 'resident';
    ___arguments.context.pageData['residentIntakeVariant'] = 'pillar';
    ___arguments.context.pageData['isAddRecordModalOpen'] = true;
  }

  },
  /* handler:onClick */
  /* content */
}))

define('./components/reusable/r_3c81022b009ca37d201fd0954e7c249c/logic/c8dd4866a2c82877586beb434508f385/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (!(getObjectProperty(___arguments.context.getComponentDataStoreByUid('478f377cbbc8f1468f7b71ccf77e283c'), 'pillar'))) {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('c8dd4866a2c82877586beb434508f385', true);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('c8dd4866a2c82877586beb434508f385', false);
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./components/reusable/r_3c81022b009ca37d201fd0954e7c249c/logic/7ccef90bd13bc0610c9902dacc1a4d39/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.getComponentDataStoreByUid('478f377cbbc8f1468f7b71ccf77e283c'), 'pillar')) {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('7ccef90bd13bc0610c9902dacc1a4d39', true);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('7ccef90bd13bc0610c9902dacc1a4d39', false);
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./components/reusable/r_3c81022b009ca37d201fd0954e7c249c/logic/01476da76ca22c09742d2ba8b0455751/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  ___arguments.context.pageData['hasClickedApplyFilter'] = true;
  if (getObjectProperty(___arguments.context.pageData, 'residentFilterArray')) {
    if ((getObjectProperty(___arguments.context.pageData, 'residentFilterArray')).length > 0) {
      ___arguments.context.pageData['residentTableFilter'] = (getObjectProperty(___arguments.context.pageData, 'residentFilterArray'));
    } else {
      ___arguments.context.pageData['residentTableFilter'] = [];
    }
  }

  },
  /* handler:onClick */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    var classes;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'residentFilterArray')) {
    if ((getObjectProperty(___arguments.context.pageData, 'residentFilterArray')).length > 0) {
      classes = ['filterButton', 'apply_filter_button'];
    } else {
      classes = ['filterButton', 'apply_filter_button_disabled'];
    }
  }

  return classes

  },
  /* handler:onClassListAssignment */
  /* handler:onDisabledStateAssignment */
  ['onDisabledStateAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'residentFilterArray')) {
    if ((getObjectProperty(___arguments.context.pageData, 'residentFilterArray')).length > 0) {
      return false;
    } else {
      return true;
    }
  }

  },
  /* handler:onDisabledStateAssignment */
  /* content */
}))

define('./components/reusable/r_3c81022b009ca37d201fd0954e7c249c/logic/08c78f5cf781446efb68d0c7e2242345/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    var classes;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'residentFilterArray')) {
    if ((getObjectProperty(___arguments.context.pageData, 'residentFilterArray')).length > 0) {
      ___arguments.context.pageData['hasClickedApplyFilter'] = true;
      ___arguments.context.pageData['residentTableFilter'] = [];
    }
  }

  },
  /* handler:onClick */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    var classes;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'residentFilterArray')) {
    if ((getObjectProperty(___arguments.context.pageData, 'residentFilterArray')).length > 0) {
      classes = ['filterText'];
    } else {
      classes = ['filterText', 'clear_filter_button_disabled'];
    }
  }

  return classes

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./components/reusable/r_3c81022b009ca37d201fd0954e7c249c/logic/b73dcbffa539fb7b1fb26e11c1bbbacb/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    var classesList;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('478f377cbbc8f1468f7b71ccf77e283c'), 'status')) == 'ACTIVE') {
    classesList = ['active_status_block'];
  } else if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('478f377cbbc8f1468f7b71ccf77e283c'), 'status')) == 'INCOMPLETE') {
    classesList = ['incomplete_status_block'];
  } else if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('478f377cbbc8f1468f7b71ccf77e283c'), 'status')) == 'DEACTIVATED') {
    classesList = ['deactivated_status_block'];
  }

  return classesList

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./components/reusable/r_3c81022b009ca37d201fd0954e7c249c/logic/ffb5ee3828e99cbf05469ff368483803/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    var classesList;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('478f377cbbc8f1468f7b71ccf77e283c'), 'status')) == 'ACTIVE') {
    classesList = ['active_status_block_icon'];
  } else if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('478f377cbbc8f1468f7b71ccf77e283c'), 'status')) == 'INCOMPLETE') {
    classesList = ['incomplete_status_block_icon'];
  } else if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('478f377cbbc8f1468f7b71ccf77e283c'), 'status')) == 'DEACTIVATED') {
    classesList = ['deactivated_status_block_icon'];
  }

  return classesList

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./components/reusable/r_3c81022b009ca37d201fd0954e7c249c/logic/b8cd49bf508ca96c4995921668c3a283/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    var classesList;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('478f377cbbc8f1468f7b71ccf77e283c'), 'status')) == 'ACTIVE') {
    classesList = ['active_status_block_text'];
  } else if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('478f377cbbc8f1468f7b71ccf77e283c'), 'status')) == 'ONBOARDSTARTED' || (getObjectProperty(___arguments.context.getComponentDataStoreByUid('478f377cbbc8f1468f7b71ccf77e283c'), 'status')) == 'ONBOARDPENDING') {
    classesList = ['incomplete_status_block_text'];
  } else if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('478f377cbbc8f1468f7b71ccf77e283c'), 'status')) == 'DEACTIVATED') {
    classesList = ['deactivated_status_block_text'];
  }

  return classesList

  },
  /* handler:onClassListAssignment */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    var classesList;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('478f377cbbc8f1468f7b71ccf77e283c'), 'status')) == 'ACTIVE') {
    return 'Active';
  } else if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('478f377cbbc8f1468f7b71ccf77e283c'), 'status')) == 'INCOMPLETE') {
    return 'Intake Incomplete';
  } else if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('478f377cbbc8f1468f7b71ccf77e283c'), 'status')) == 'DEACTIVATED') {
    return 'Deactivated';
  }

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./components/reusable/r_8c5ff28e7186193e5ab42b9570a91bf6/logic/30fd4798ddb99f69309e14b11274af0b/bundle.js', [], () => ({
  /* content */
  /* handler:onBeforeMount */
  ['onBeforeMount'](___arguments) {
      ___arguments.context.pageData['selectedItemArrayUser'] = [];

  },
  /* handler:onBeforeMount */
  /* content */
}))

define('./components/reusable/r_8c5ff28e7186193e5ab42b9570a91bf6/logic/6fd2ae514584121d81a69d1e02d4a4a4/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    var tableCount, response, currentUser, changedValue;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'userTableCount')) {
    return (['Showing ',(getObjectProperty(___arguments.context.pageData, 'userTableCount')),' records'].join(''));
  } else if ((getObjectProperty(___arguments.context.pageData, 'userTableCount')) == 0) {
    return 'Showing 0 records';
  }

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./components/reusable/r_8c5ff28e7186193e5ab42b9570a91bf6/logic/06dc27e50ba5576541ae82e5c1c9fd82/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  async ['onChange'](___arguments) {
    var currentUser, changedValue;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  changedValue = ___arguments.value.split('\'').join('');
  currentUser = (getObjectProperty(___arguments.context.pageData, 'currentUser'));
  ___arguments.context.pageData['changedValueUser'] = ___arguments.value;
  ___arguments.context.pageData['noSearchResultsUser'] = false;
  ___arguments.context.pageData['isTableLoading'] = true;
  (function (componentUid, listItems) { ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('81cfd1bb805749ea6143204291fb8ce3', []);
  if (currentUser) {
    (function (componentUid, listItems) { ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('81cfd1bb805749ea6143204291fb8ce3', (await Backendless.Data.of('ManagementUserRecords').find(Backendless.DataQueryBuilder.create().setWhereClause((await BackendlessUI.Functions.Custom['fn_5da19a0685ee9e7b97339f9e3dd69c3b']('user', (getObjectProperty(currentUser, 'email')), (getObjectProperty(currentUser, 'organizationId.orgName')), changedValue, null, (getObjectProperty(currentUser, 'userRole')), null))).setSortBy('created asc').setPageSize(10))));
    ___arguments.context.pageData['userTableCount'] = (await Backendless.Data.of('ManagementUserRecords').getObjectCount(Backendless.DataQueryBuilder.create().setWhereClause((await BackendlessUI.Functions.Custom['fn_5da19a0685ee9e7b97339f9e3dd69c3b']('user', (getObjectProperty(currentUser, 'email')), (getObjectProperty(currentUser, 'organizationId.orgName')), changedValue, null, (getObjectProperty(currentUser, 'userRole')), null)))));
    ___arguments.context.pageData['isTableLoading'] = false;
  }

  },
  /* handler:onChange */
  /* content */
}))

define('./components/reusable/r_8c5ff28e7186193e5ab42b9570a91bf6/logic/00d97266b70f96b7c5936c7b228c0671/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    var isActionDrawerOpen;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  isActionDrawerOpen = (getObjectProperty(___arguments.context.pageData, 'isUserRecordActionDrawerOpen'));
  if (isActionDrawerOpen) {
    ___arguments.context.pageData['isUserRecordActionDrawerOpen'] = false;
  } else {
    ___arguments.context.pageData['isUserRecordActionDrawerOpen'] = true;
  }

  },
  /* handler:onClick */
  /* content */
}))

define('./components/reusable/r_8c5ff28e7186193e5ab42b9570a91bf6/logic/ea85610d7b851a8df678dec3dc9ab029/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    var isActionDrawerOpen;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  isActionDrawerOpen = (getObjectProperty(___arguments.context.pageData, 'isUserRecordActionDrawerOpen'));
  if (isActionDrawerOpen) {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('ea85610d7b851a8df678dec3dc9ab029', true);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('ea85610d7b851a8df678dec3dc9ab029', false);
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./components/reusable/r_8c5ff28e7186193e5ab42b9570a91bf6/logic/a6c626fa8ec335e86e23f802dfab41c6/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var selectedItemArrayUser, menuOptionSelection;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function subsequenceFromStartLast(sequence, at1) {
  var start = at1;
  var end = sequence.length - 1 + 1;
  return sequence.slice(start, end);
}


  selectedItemArrayUser = (getObjectProperty(___arguments.context.pageData, 'selectedItemArrayUser'));
  menuOptionSelection = (getObjectProperty(___arguments.context.pageData, 'menuOptionSelection'));
  ___arguments.context.pageData['menuOptionSelection'] = undefined;
  if (selectedItemArrayUser && selectedItemArrayUser.length == 1) {
    ___arguments.context.pageData['recordTableVariant'] = 'user';
    ___arguments.context.pageData['isSelectedItemModalOpen'] = true;
    ___arguments.context.pageData['userRecordsOrganizationValue'] = undefined;
    ___arguments.context.pageData['userRecordsUserRoleValue'] = undefined;
    ___arguments.context.pageData['selectedImage'] = undefined;
    ___arguments.context.pageData['selectedItemName'] = (getObjectProperty((selectedItemArrayUser[0]), 'name'));
    ___arguments.context.pageData['selectedItemEmail'] = undefined;
    ___arguments.context.pageData['selectedItemPhone'] = undefined;
    ___arguments.context.pageData['selectedItemPhone'] = (await BackendlessUI.Functions.Custom['fn_f282814277b3c0aa13f33d3b2cda111b']((subsequenceFromStartLast((getObjectProperty((selectedItemArrayUser[0]), 'phoneNumber')), 2)), 'create'));
    ___arguments.context.pageData['selectedItemUserRole'] = undefined;
    ___arguments.context.pageData['userRoleValue'] = undefined;
    ___arguments.context.pageData['emailError'] = null;
    ___arguments.context.pageData['phoneError'] = null;
    ___arguments.context.pageData['isUserDetailsButtonDisabled'] = ({ [`name`]: true,[`phone`]: true,[`email`]: true,[`role`]: true,[`selectedImage`]: true,[`organization`]: true });
  }

  },
  /* handler:onClick */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    var selectedItemArrayUser;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  selectedItemArrayUser = (getObjectProperty(___arguments.context.pageData, 'selectedItemArrayUser'));
  if (selectedItemArrayUser && selectedItemArrayUser.length == 1) {
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('ba19314a0fde4fbfc1ca28b3b587ee16'))['color'] = '#000000';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('aedefdc3233c79ccd1ae3e8555cdf328'))['color'] = '#000000';
  } else {
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('ba19314a0fde4fbfc1ca28b3b587ee16'))['color'] = 'rgb(0,0,0,0.3)';
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('aedefdc3233c79ccd1ae3e8555cdf328'))['color'] = 'rgb(0,0,0,0.3)';
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./components/reusable/r_8c5ff28e7186193e5ab42b9570a91bf6/logic/aa77ba731b3aa605f381bcc36aecbfae/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    var isSame, item, selectedItemArrayUser;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  selectedItemArrayUser = (getObjectProperty(___arguments.context.pageData, 'selectedItemArrayUser'));
  if (selectedItemArrayUser && selectedItemArrayUser.length == 1) {
    if ((getObjectProperty((selectedItemArrayUser[0]), 'inviteStatus')) == 'PENDING' || (getObjectProperty((selectedItemArrayUser[0]), 'inviteStatus')) == 'RESENT') {
      ___arguments.context.pageData['isConfirmationModalOpen'] = true;
      ___arguments.context.pageData['confirmationOption'] = 'resendUser';
    }
  } else if (selectedItemArrayUser && selectedItemArrayUser.length > 1) {
    isSame = true;
    for (var item_index in selectedItemArrayUser) {
      item = selectedItemArrayUser[item_index];
      if ((getObjectProperty(item, 'inviteStatus')) != 'PENDING') {
        isSame = false;
      }
    }
    if (isSame) {
      ___arguments.context.pageData['isConfirmationModalOpen'] = true;
      ___arguments.context.pageData['confirmationOption'] = 'resendUser';
    }
  }

  },
  /* handler:onClick */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    var isSame, item, selectedItemArrayUser;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  selectedItemArrayUser = (getObjectProperty(___arguments.context.pageData, 'selectedItemArrayUser'));
  if (selectedItemArrayUser && selectedItemArrayUser.length == 1) {
    if ((getObjectProperty((selectedItemArrayUser[0]), 'inviteStatus')) == 'PENDING' || (getObjectProperty((selectedItemArrayUser[0]), 'inviteStatus')) == 'RESENT') {
      ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('bae8950a3e23b7becce980eaf2b85b9c'))['color'] = '#000000';
      ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('d785859b380439e5a43ff465a119c087'))['color'] = '#000000';
    } else {
      ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('bae8950a3e23b7becce980eaf2b85b9c'))['color'] = 'rgb(0,0,0,0.3)';
      ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('d785859b380439e5a43ff465a119c087'))['color'] = 'rgb(0,0,0,0.3)';
    }
  } else if (selectedItemArrayUser && selectedItemArrayUser.length > 1) {
    isSame = true;
    for (var item_index in selectedItemArrayUser) {
      item = selectedItemArrayUser[item_index];
      if ((getObjectProperty(item, 'inviteStatus')) != 'PENDING') {
        isSame = false;
      }
    }
    if (isSame) {
      ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('bae8950a3e23b7becce980eaf2b85b9c'))['color'] = '#000000';
      ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('d785859b380439e5a43ff465a119c087'))['color'] = '#000000';
    } else {
      ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('bae8950a3e23b7becce980eaf2b85b9c'))['color'] = 'rgb(0,0,0,0.3)';
      ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('d785859b380439e5a43ff465a119c087'))['color'] = 'rgb(0,0,0,0.3)';
    }
  }
  selectedItemArrayUser = (getObjectProperty(___arguments.context.pageData, 'selectedItemArrayUser'));
  if (selectedItemArrayUser && selectedItemArrayUser.length == 1) {
    if ((getObjectProperty((selectedItemArrayUser[0]), 'inviteStatus')) == 'PENDING' || (getObjectProperty((selectedItemArrayUser[0]), 'inviteStatus')) == 'RESENT') {
      ___arguments.context.pageData['isConfirmationModalOpen'] = true;
      ___arguments.context.pageData['confirmationOption'] = 'resendUser';
    }
  } else if (selectedItemArrayUser && selectedItemArrayUser.length > 1) {
    isSame = true;
    for (var item_index2 in selectedItemArrayUser) {
      item = selectedItemArrayUser[item_index2];
      if ((getObjectProperty(item, 'inviteStatus')) != 'PENDING') {
        isSame = false;
      }
    }
    if (isSame) {
      ___arguments.context.pageData['isConfirmationModalOpen'] = true;
      ___arguments.context.pageData['confirmationOption'] = 'resendUser';
    }
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./components/reusable/r_8c5ff28e7186193e5ab42b9570a91bf6/logic/1c3c09959ddbde6e686d9ab1bfe10814/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    var isSame, item, selectedItemArrayUser;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  selectedItemArrayUser = (getObjectProperty(___arguments.context.pageData, 'selectedItemArrayUser'));
  if (selectedItemArrayUser && selectedItemArrayUser.length == 1) {
    if ((getObjectProperty((selectedItemArrayUser[0]), 'inviteStatus')) != 'PENDING' && (getObjectProperty((selectedItemArrayUser[0]), 'status')) == 'ACTIVE') {
      ___arguments.context.pageData['isConfirmationModalOpen'] = true;
      ___arguments.context.pageData['confirmationOption'] = 'deactivateUser';
    }
  } else if (selectedItemArrayUser && selectedItemArrayUser.length > 1) {
    isSame = true;
    for (var item_index in selectedItemArrayUser) {
      item = selectedItemArrayUser[item_index];
      if ((getObjectProperty(item, 'status')) != 'ACTIVE' || (getObjectProperty(item, 'inviteStatus')) == 'PENDING') {
        isSame = false;
      }
    }
    if (isSame) {
      ___arguments.context.pageData['isConfirmationModalOpen'] = true;
      ___arguments.context.pageData['confirmationOption'] = 'deactivateUser';
    }
  }
  selectedItemArrayUser = (getObjectProperty(___arguments.context.pageData, 'selectedItemArrayUser'));
  if (selectedItemArrayUser && selectedItemArrayUser.length == 1) {
    if ((getObjectProperty((selectedItemArrayUser[0]), 'inviteStatus')) != 'PENDING' && (getObjectProperty((selectedItemArrayUser[0]), 'status')) == 'DEACTIVATED') {
      ___arguments.context.pageData['isConfirmationModalOpen'] = true;
      ___arguments.context.pageData['confirmationOption'] = 'activateUser';
    }
  } else if (selectedItemArrayUser && selectedItemArrayUser.length > 1) {
    isSame = true;
    for (var item_index2 in selectedItemArrayUser) {
      item = selectedItemArrayUser[item_index2];
      if ((getObjectProperty(item, 'status')) != 'DEACTIVATED' || (getObjectProperty(item, 'inviteStatus')) == 'PENDING') {
        isSame = false;
      }
    }
    if (isSame) {
      ___arguments.context.pageData['isConfirmationModalOpen'] = true;
      ___arguments.context.pageData['confirmationOption'] = 'activateUser';
    }
  }

  },
  /* handler:onClick */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    var isSame, item, selectedItemArrayUser;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  selectedItemArrayUser = (getObjectProperty(___arguments.context.pageData, 'selectedItemArrayUser'));
  if (selectedItemArrayUser && selectedItemArrayUser.length == 1) {
    if ((getObjectProperty((selectedItemArrayUser[0]), 'inviteStatus')) != 'PENDING' && (getObjectProperty((selectedItemArrayUser[0]), 'status')) == 'ACTIVE') {
      ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('16d81a38b73e1724709cd89836fad067'))['color'] = '#000000';
      ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('97bedd83451663191b3603f4dfbcec81'))['color'] = '#000000';
    } else {
      ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('16d81a38b73e1724709cd89836fad067'))['color'] = 'rgb(0,0,0,0.3)';
      ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('97bedd83451663191b3603f4dfbcec81'))['color'] = 'rgb(0,0,0,0.3)';
    }
  } else if (selectedItemArrayUser && selectedItemArrayUser.length > 1) {
    isSame = true;
    for (var item_index in selectedItemArrayUser) {
      item = selectedItemArrayUser[item_index];
      if ((getObjectProperty(item, 'status')) != 'ACTIVE' || (getObjectProperty(item, 'inviteStatus')) == 'PENDING') {
        isSame = false;
      }
    }
    if (isSame) {
      ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('16d81a38b73e1724709cd89836fad067'))['color'] = '#000000';
      ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('97bedd83451663191b3603f4dfbcec81'))['color'] = '#000000';
    } else {
      ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('16d81a38b73e1724709cd89836fad067'))['color'] = 'rgb(0,0,0,0.3)';
      ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('97bedd83451663191b3603f4dfbcec81'))['color'] = 'rgb(0,0,0,0.3)';
    }
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./components/reusable/r_8c5ff28e7186193e5ab42b9570a91bf6/logic/5c212542c04bc0db3cc9455337a96177/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    var isSame, item, selectedItemArrayUser;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  selectedItemArrayUser = (getObjectProperty(___arguments.context.pageData, 'selectedItemArrayUser'));
  if (selectedItemArrayUser && selectedItemArrayUser.length == 1) {
    if ((getObjectProperty((selectedItemArrayUser[0]), 'inviteStatus')) != 'PENDING' && (getObjectProperty((selectedItemArrayUser[0]), 'status')) == 'DEACTIVATED') {
      ___arguments.context.pageData['isConfirmationModalOpen'] = true;
      ___arguments.context.pageData['confirmationOption'] = 'activateUser';
    }
  } else if (selectedItemArrayUser && selectedItemArrayUser.length > 1) {
    isSame = true;
    for (var item_index in selectedItemArrayUser) {
      item = selectedItemArrayUser[item_index];
      if ((getObjectProperty(item, 'status')) != 'DEACTIVATED' || (getObjectProperty(item, 'inviteStatus')) == 'PENDING') {
        isSame = false;
      }
    }
    if (isSame) {
      ___arguments.context.pageData['isConfirmationModalOpen'] = true;
      ___arguments.context.pageData['confirmationOption'] = 'activateUser';
    }
  }

  },
  /* handler:onClick */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    var isSame, item, selectedItemArrayUser;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  selectedItemArrayUser = (getObjectProperty(___arguments.context.pageData, 'selectedItemArrayUser'));
  if (selectedItemArrayUser && selectedItemArrayUser.length == 1) {
    if ((getObjectProperty((selectedItemArrayUser[0]), 'inviteStatus')) != 'PENDING' && (getObjectProperty((selectedItemArrayUser[0]), 'status')) == 'DEACTIVATED') {
      ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('16e7a33cfeea9906af9daef13df7374a'))['color'] = '#000000';
      ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('b9aba4ff684e72d38409cb39123559aa'))['color'] = '#000000';
    } else {
      ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('16e7a33cfeea9906af9daef13df7374a'))['color'] = 'rgb(0,0,0,0.3)';
      ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('b9aba4ff684e72d38409cb39123559aa'))['color'] = 'rgb(0,0,0,0.3)';
    }
  } else if (selectedItemArrayUser && selectedItemArrayUser.length > 1) {
    isSame = true;
    for (var item_index in selectedItemArrayUser) {
      item = selectedItemArrayUser[item_index];
      if ((getObjectProperty(item, 'status')) != 'DEACTIVATED' || (getObjectProperty(item, 'inviteStatus')) == 'PENDING') {
        isSame = false;
      }
    }
    if (isSame) {
      ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('16e7a33cfeea9906af9daef13df7374a'))['color'] = '#000000';
      ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('b9aba4ff684e72d38409cb39123559aa'))['color'] = '#000000';
    } else {
      ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('16e7a33cfeea9906af9daef13df7374a'))['color'] = 'rgb(0,0,0,0.3)';
      ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('b9aba4ff684e72d38409cb39123559aa'))['color'] = 'rgb(0,0,0,0.3)';
    }
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./components/reusable/r_8c5ff28e7186193e5ab42b9570a91bf6/logic/b917c7e5cbe9fbdb899831a6db74fd8a/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    var isSame, item, selectedItemArrayUser;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  selectedItemArrayUser = (getObjectProperty(___arguments.context.pageData, 'selectedItemArrayUser'));
  if (selectedItemArrayUser && selectedItemArrayUser.length == 1) {
    if ((getObjectProperty((selectedItemArrayUser[0]), 'inviteStatus')) == 'PENDING') {
      ___arguments.context.pageData['isConfirmationModalOpen'] = true;
      ___arguments.context.pageData['confirmationOption'] = 'cancelUser';
    }
  } else if (selectedItemArrayUser && selectedItemArrayUser.length > 1) {
    isSame = true;
    for (var item_index in selectedItemArrayUser) {
      item = selectedItemArrayUser[item_index];
      if ((getObjectProperty(item, 'inviteStatus')) != 'PENDING') {
        isSame = false;
      }
    }
    if (isSame) {
      ___arguments.context.pageData['isConfirmationModalOpen'] = true;
      ___arguments.context.pageData['confirmationOption'] = 'cancelUser';
    }
  }

  },
  /* handler:onClick */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    var isSame, item, selectedItemArrayUser;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  selectedItemArrayUser = (getObjectProperty(___arguments.context.pageData, 'selectedItemArrayUser'));
  if (selectedItemArrayUser && selectedItemArrayUser.length == 1) {
    if ((getObjectProperty((selectedItemArrayUser[0]), 'inviteStatus')) == 'PENDING') {
      ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('ee85da83ef7545a199acf8163b36af12'))['color'] = '#000000';
      ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('63e5a4822ead2ad6f8c874f35eae72c9'))['color'] = '#000000';
    } else {
      ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('ee85da83ef7545a199acf8163b36af12'))['color'] = 'rgb(0,0,0,0.3)';
      ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('63e5a4822ead2ad6f8c874f35eae72c9'))['color'] = 'rgb(0,0,0,0.3)';
    }
  } else if (selectedItemArrayUser && selectedItemArrayUser.length > 1) {
    isSame = true;
    for (var item_index in selectedItemArrayUser) {
      item = selectedItemArrayUser[item_index];
      if ((getObjectProperty(item, 'inviteStatus')) != 'PENDING') {
        isSame = false;
      }
    }
    if (isSame) {
      ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('ee85da83ef7545a199acf8163b36af12'))['color'] = '#000000';
      ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('63e5a4822ead2ad6f8c874f35eae72c9'))['color'] = '#000000';
    } else {
      ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('ee85da83ef7545a199acf8163b36af12'))['color'] = 'rgb(0,0,0,0.3)';
      ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('63e5a4822ead2ad6f8c874f35eae72c9'))['color'] = 'rgb(0,0,0,0.3)';
    }
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./components/reusable/r_8c5ff28e7186193e5ab42b9570a91bf6/logic/fa69bb2c96e74fe43017e2ba90cbee0e/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    /**
 * Describe this function...
 */
async function clearInputs() {
  ___arguments.context.pageData['email'] = undefined;
  ___arguments.context.pageData['fullName'] = undefined;
  ___arguments.context.pageData['userPhoneNumber'] = undefined;
  ___arguments.context.pageData['userRole'] = undefined;
  ___arguments.context.pageData['organizationChoice'] = undefined;
  ___arguments.context.pageData['phoneError'] = undefined;
  ___arguments.context.pageData['emailError'] = undefined;
  ___arguments.context.pageData['userRoleSelection'] = undefined;
}


  ___arguments.context.pageData['isAddRecordModalOpen'] = true;
  ___arguments.context.pageData['recordTableVariant'] = 'user';
  await clearInputs();

  },
  /* handler:onClick */
  /* content */
}))

define('./components/reusable/r_8c5ff28e7186193e5ab42b9570a91bf6/logic/30670d6722f137f473c124af68976cab/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    var filteredArray, selectedItemArray, item;


  ___arguments.context.pageData['selectedItemUser'] = ___arguments.context.dataModel;

  },
  /* handler:onClick */
  /* content */
}))

define('./components/reusable/r_8c5ff28e7186193e5ab42b9570a91bf6/logic/29a3c4a2eacb179cf15ca70b7f79829b/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (await BackendlessUI.Functions.Custom['fn_9807a958b9f47818617415e4438b5e47']((getObjectProperty(___arguments.context.getComponentDataStoreByUid('30670d6722f137f473c124af68976cab'), 'email')), 20))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./components/reusable/r_8c5ff28e7186193e5ab42b9570a91bf6/logic/50d9e8e500d74bc4f5c7d286596d0ba3/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    var inviteStatus;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  inviteStatus = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('30670d6722f137f473c124af68976cab'), 'inviteStatus'));
  if (inviteStatus == 'ACCEPTED') {
    return 'Accepted';
  } else if (inviteStatus == 'PENDING' || inviteStatus == 'RESENT') {
    return 'Pending';
  }

  },
  /* handler:onContentAssignment */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    var classesList;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('30670d6722f137f473c124af68976cab'), 'inviteStatus')) == 'ACCEPTED') {
    classesList = ['active_status_block_text'];
  } else if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('30670d6722f137f473c124af68976cab'), 'inviteStatus')) == 'PENDING' || (getObjectProperty(___arguments.context.getComponentDataStoreByUid('30670d6722f137f473c124af68976cab'), 'inviteStatus')) == 'RESENT') {
    classesList = ['deactivated_status_block_text'];
  }

  return classesList

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./components/reusable/r_8c5ff28e7186193e5ab42b9570a91bf6/logic/261c8d46b0832dc8f76b74e45bf687cd/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ([((new Date((getObjectProperty(___arguments.context.getComponentDataStoreByUid('30670d6722f137f473c124af68976cab'), 'created')))).toLocaleDateString()),', ',((new Date((getObjectProperty(___arguments.context.getComponentDataStoreByUid('30670d6722f137f473c124af68976cab'), 'created')))).toLocaleTimeString())].join(''))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./components/reusable/r_8c5ff28e7186193e5ab42b9570a91bf6/logic/11bc3ea9a7b67c3227a44b0562f3d144/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    var isTableLoading;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  isTableLoading = (getObjectProperty(___arguments.context.pageData, 'isTableLoading'));
  if (isTableLoading) {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('11bc3ea9a7b67c3227a44b0562f3d144', true);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('11bc3ea9a7b67c3227a44b0562f3d144', false);
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./components/reusable/r_8c5ff28e7186193e5ab42b9570a91bf6/logic/7a7927e52b023c594efe426ff47276c5/bundle.js', [], () => ({
  /* content */
  /* handler:PageCountLogic */
  ['PageCountLogic'](___arguments) {
    var userSearchCount, changedValue, currentUser;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'userTableCount')) {
    if ((getObjectProperty(___arguments.context.pageData, 'userTableCount')) > 10) {
      return (Math.ceil((getObjectProperty(___arguments.context.pageData, 'userTableCount')) / 10));
    } else {
      return 1;
    }
  }

  },
  /* handler:PageCountLogic */
  /* handler:onPageChange */
  async ['onPageChange'](___arguments) {
    var currentUser, changedValue, userResponse;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  ___arguments.context.pageData['isTableLoading'] = true;
  (function (componentUid, listItems) { ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('81cfd1bb805749ea6143204291fb8ce3', []);
  currentUser = (getObjectProperty(___arguments.context.pageData, 'currentUser'));
  changedValue = (getObjectProperty(___arguments.context.pageData, 'changedValueUser'));
  if (currentUser) {
    (function (componentUid, listItems) { ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('81cfd1bb805749ea6143204291fb8ce3', (await Backendless.Data.of('ManagementUserRecords').find(Backendless.DataQueryBuilder.create().setWhereClause((await BackendlessUI.Functions.Custom['fn_5da19a0685ee9e7b97339f9e3dd69c3b']('user', (getObjectProperty(currentUser, 'email')), (getObjectProperty(currentUser, 'organizationId.orgName')), changedValue, null, (getObjectProperty(currentUser, 'userRole'))))).setSortBy('created asc').setPageSize(10).setOffset((10 * ___arguments['currentPage'] - 10)))));
    if (!(getObjectProperty(___arguments.context.pageData, 'pageEnter'))) {
      ___arguments.context.pageData['pageEnter'] = true;
      ___arguments.context.pageData['userTableCount'] = (await Backendless.Data.of('ManagementUserRecords').getObjectCount(Backendless.DataQueryBuilder.create().setWhereClause((await BackendlessUI.Functions.Custom['fn_5da19a0685ee9e7b97339f9e3dd69c3b']('user', (getObjectProperty(currentUser, 'email')), (getObjectProperty(currentUser, 'organizationId.orgName')), changedValue, null, (getObjectProperty(currentUser, 'userRole')))))));
      if ((getObjectProperty(___arguments.context.pageData, 'userTableCount')) == 0) {
        ___arguments.context.pageData['noResultsScreen'] = true;
      }
    }
  }
  ___arguments.context.pageData['isTableLoading'] = false;

  },
  /* handler:onPageChange */
  /* content */
}))

define('./components/reusable/r_8c5ff28e7186193e5ab42b9570a91bf6/logic/43e3e93f566da57b58e4488ca658a911/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    var currentUser;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  currentUser = (getObjectProperty(___arguments.context.pageData, 'currentUser'));
  if (currentUser) {
    if ((getObjectProperty(currentUser, 'userRole')) != 'super') {
      (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('43e3e93f566da57b58e4488ca658a911', false);
    } else {
      (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('43e3e93f566da57b58e4488ca658a911', true);
    }
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./components/reusable/r_8c5ff28e7186193e5ab42b9570a91bf6/logic/9a171c874abf44ed6dd94decf73154f8/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    var currentUser;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  currentUser = (getObjectProperty(___arguments.context.pageData, 'currentUser'));
  if (currentUser) {
    if ((getObjectProperty(currentUser, 'userRole')) != 'super') {
      (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('9a171c874abf44ed6dd94decf73154f8', false);
    } else {
      (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('9a171c874abf44ed6dd94decf73154f8', true);
    }
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./components/reusable/r_8c5ff28e7186193e5ab42b9570a91bf6/logic/b6f38bb44f15f006f1378a84732925aa/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  async ['onStyleAssignment'](___arguments) {
    var currentUser, changedValue, refreshUserTable;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

/**
 * Describe this function...
 */
async function refreshUserTable2() {
  ___arguments.context.pageData['isTableLoading'] = true;
  currentUser = (getObjectProperty(___arguments.context.pageData, 'currentUser'));
  changedValue = (getObjectProperty(___arguments.context.pageData, 'changedValueUser'));
  (function (componentUid, listItems) { ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('81cfd1bb805749ea6143204291fb8ce3', []);
  if (currentUser) {
    (function (componentUid, listItems) { ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('81cfd1bb805749ea6143204291fb8ce3', (await Backendless.Data.of('ManagementUserRecords').find(Backendless.DataQueryBuilder.create().setWhereClause((await BackendlessUI.Functions.Custom['fn_5da19a0685ee9e7b97339f9e3dd69c3b']('user', (getObjectProperty(currentUser, 'email')), (getObjectProperty(currentUser, 'organizationId.orgName')), changedValue, null, (getObjectProperty(currentUser, 'userRole')), null))).setSortBy('created asc').setPageSize(10))));
    ___arguments.context.pageData['userTableCount'] = (await Backendless.Data.of('ManagementUserRecords').getObjectCount(Backendless.DataQueryBuilder.create().setWhereClause((await BackendlessUI.Functions.Custom['fn_5da19a0685ee9e7b97339f9e3dd69c3b']('user', (getObjectProperty(currentUser, 'email')), (getObjectProperty(currentUser, 'organizationId.orgName')), changedValue, null, (getObjectProperty(currentUser, 'userRole')), null)))));
  }
  ___arguments.context.pageData['selectedItemArrayUser'] = [];
  ___arguments.context.pageData['isTableLoading'] = false;
  ___arguments.context.pageData['refreshUserTable'] = undefined;
}


  refreshUserTable = (getObjectProperty(___arguments.context.pageData, 'refreshUserTable'));
  if (refreshUserTable) {
    await refreshUserTable2();
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./components/reusable/r_8c5ff28e7186193e5ab42b9570a91bf6/logic/b77e42abc126fd5335c7fa7123ac4e61/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    var isTableLoading, orgTableCount, navbarSelection, residentTableCount, userTableCount, curfewTableCount;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  userTableCount = (getObjectProperty(___arguments.context.pageData, 'userTableCount'));
  isTableLoading = (getObjectProperty(___arguments.context.pageData, 'isTableLoading'));
  if (userTableCount == 0 && !isTableLoading) {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('b77e42abc126fd5335c7fa7123ac4e61', true);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('b77e42abc126fd5335c7fa7123ac4e61', false);
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./components/reusable/r_8c5ff28e7186193e5ab42b9570a91bf6/logic/9dcf41b401f59ff3646a9c5b29d91654/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
    var text;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function subsequenceFromStartLast(sequence, at1) {
  var start = at1;
  var end = sequence.length - 1 + 1;
  return sequence.slice(start, end);
}



  return (String('+1') + String((await BackendlessUI.Functions.Custom['fn_f282814277b3c0aa13f33d3b2cda111b']((subsequenceFromStartLast((getObjectProperty(___arguments.context.getComponentDataStoreByUid('30670d6722f137f473c124af68976cab'), 'phoneNumber')), 2)), 'create'))))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./components/reusable/r_8c5ff28e7186193e5ab42b9570a91bf6/logic/08ff3935143191fe44a53dc53a28fe33/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ([((new Date((getObjectProperty(___arguments.context.getComponentDataStoreByUid('30670d6722f137f473c124af68976cab'), 'inviteSent')))).toLocaleDateString()),', ',((new Date((getObjectProperty(___arguments.context.getComponentDataStoreByUid('30670d6722f137f473c124af68976cab'), 'inviteSent')))).toLocaleTimeString())].join(''))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./components/reusable/r_8c5ff28e7186193e5ab42b9570a91bf6/logic/fa1c20b2fac9b83e9e6551afbd958ffd/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('30670d6722f137f473c124af68976cab'), 'userRole')) == 'admin') {
    return 'Admin';
  } else if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('30670d6722f137f473c124af68976cab'), 'userRole')) == 'manager') {
    return 'Manager';
  }

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./components/reusable/r_8c5ff28e7186193e5ab42b9570a91bf6/logic/d82cd2ce922de5d95816fb8a30444e8b/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    var classesList;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('30670d6722f137f473c124af68976cab'), 'status')) == 'ACTIVE') {
    classesList = ['active_status_block'];
  } else if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('30670d6722f137f473c124af68976cab'), 'inviteStatus')) == 'PENDING' || (getObjectProperty(___arguments.context.getComponentDataStoreByUid('30670d6722f137f473c124af68976cab'), 'inviteStatus')) == 'RESENT') {
    classesList = ['pending_status_block'];
  } else if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('30670d6722f137f473c124af68976cab'), 'status')) == 'LOCKED') {
    classesList = ['incomplete_status_block'];
  } else if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('30670d6722f137f473c124af68976cab'), 'status')) == 'DEACTIVATED') {
    classesList = ['deactivated_status_block'];
  }

  return classesList

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./components/reusable/r_8c5ff28e7186193e5ab42b9570a91bf6/logic/35931dd7b8a149d9723573ee2cc2977e/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    var classesList;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('30670d6722f137f473c124af68976cab'), 'status')) == 'ACTIVE') {
    classesList = ['active_status_block_text'];
  } else if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('30670d6722f137f473c124af68976cab'), 'inviteStatus')) == 'PENDING' || (getObjectProperty(___arguments.context.getComponentDataStoreByUid('30670d6722f137f473c124af68976cab'), 'inviteStatus')) == 'RESENT') {
    classesList = ['pending_status_block_text'];
  } else if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('30670d6722f137f473c124af68976cab'), 'status')) == 'LOCKED') {
    classesList = ['incomplete_status_text'];
  } else if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('30670d6722f137f473c124af68976cab'), 'status')) == 'DEACTIVATED') {
    classesList = ['deactivated_status_block_text'];
  }

  return classesList

  },
  /* handler:onClassListAssignment */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('30670d6722f137f473c124af68976cab'), 'status')) == 'ACTIVE') {
    return 'Active';
  } else if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('30670d6722f137f473c124af68976cab'), 'inviteStatus')) == 'RESENT') {
    return 'Invite Resent';
  } else if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('30670d6722f137f473c124af68976cab'), 'inviteStatus')) == 'PENDING') {
    return 'Invite Sent';
  } else if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('30670d6722f137f473c124af68976cab'), 'status')) == 'DEACTIVATED') {
    return 'Deactivated';
  } else if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('30670d6722f137f473c124af68976cab'), 'status')) == 'LOCKED') {
    return 'Locked';
  }

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./components/reusable/r_8c5ff28e7186193e5ab42b9570a91bf6/logic/947cb392074a6d82bb8b1a9488543f37/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    var classesList;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('30670d6722f137f473c124af68976cab'), 'status')) == 'ACTIVE') {
    classesList = ['active_status_block_icon'];
  } else if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('30670d6722f137f473c124af68976cab'), 'status')) == 'DEACTIVATED') {
    classesList = ['deactivated_status_block_icon'];
  }

  return classesList

  },
  /* handler:onClassListAssignment */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('30670d6722f137f473c124af68976cab'), 'inviteStatus')) == 'PENDING' || (getObjectProperty(___arguments.context.getComponentDataStoreByUid('30670d6722f137f473c124af68976cab'), 'inviteStatus')) == 'RESENT' || (getObjectProperty(___arguments.context.getComponentDataStoreByUid('30670d6722f137f473c124af68976cab'), 'status')) == 'LOCKED') {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('947cb392074a6d82bb8b1a9488543f37', false);
  } else if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('30670d6722f137f473c124af68976cab'), 'status')) == 'ACTIVE' || (getObjectProperty(___arguments.context.getComponentDataStoreByUid('30670d6722f137f473c124af68976cab'), 'status')) == 'DEACTIVATED') {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('947cb392074a6d82bb8b1a9488543f37', true);
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./components/reusable/r_8c5ff28e7186193e5ab42b9570a91bf6/logic/9335eddd260e709439b74c4577e878a1/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    var classesList;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('30670d6722f137f473c124af68976cab'), 'inviteStatus')) == 'ACCEPTED') {
    classesList = ['active_status_block'];
  } else if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('30670d6722f137f473c124af68976cab'), 'inviteStatus')) == 'PENDING' || (getObjectProperty(___arguments.context.getComponentDataStoreByUid('30670d6722f137f473c124af68976cab'), 'inviteStatus')) == 'RESENT') {
    classesList = ['deactivated_status_block'];
  }

  return classesList

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./components/reusable/r_8c5ff28e7186193e5ab42b9570a91bf6/logic/d6f6f584d05a70052404b3e53ddb9218/bundle.js', [], () => ({
  /* content */
  /* handler:onSourceUrlAssignment */
  ['onSourceUrlAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.getComponentDataStoreByUid('30670d6722f137f473c124af68976cab'), 'profilePicture')) {
    return (getObjectProperty(___arguments.context.getComponentDataStoreByUid('30670d6722f137f473c124af68976cab'), 'profilePicture'));
  } else {
    return 'https://app.aquax.co/api/files/cyberdive_photos/cd-icon-default-photo.svg';
  }

  },
  /* handler:onSourceUrlAssignment */
  /* content */
}))

define('./components/reusable/r_8c5ff28e7186193e5ab42b9570a91bf6/logic/8447c014e21bcca39a6ff786460f89cf/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      ___arguments.context.pageData['selectedItemDetailsSelection'] = 'details';
  ___arguments.context.pageData['isViewDetailsOpen'] = true;

  },
  /* handler:onClick */
  /* content */
}))

define('./components/reusable/r_8c5ff28e7186193e5ab42b9570a91bf6/logic/46058a9560670eb82e3ecce59fa840f3/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.getComponentDataStoreByUid('30670d6722f137f473c124af68976cab'), 'status')) == 'LOCKED') {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('46058a9560670eb82e3ecce59fa840f3', true);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('46058a9560670eb82e3ecce59fa840f3', false);
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./components/reusable/r_698f6b5303f575960cc8c822b8ec44fd/logic/b134b6bf0335848408d17df19247322e/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    var noResultsScreen;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  noResultsScreen = (getObjectProperty(___arguments.context.pageData, 'noResultsScreen'));
  if (noResultsScreen) {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('b134b6bf0335848408d17df19247322e', true);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('b134b6bf0335848408d17df19247322e', false);
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./components/reusable/r_698f6b5303f575960cc8c822b8ec44fd/logic/9ec5c00b4c128954e45dd2e1c37899e3/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    var navabrSelection, currentUser;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  navabrSelection = (getObjectProperty(___arguments.context.pageData, 'navbarSelection'));
  if (navabrSelection == 'organizations') {
    return 'No Organizations Created';
  } else if (navabrSelection == 'residentData') {
    return 'No Residents Added';
  } else if (navabrSelection == 'userData') {
    return 'No Users Added';
  } else if (navabrSelection == 'deviceRecords') {
    return 'No Device Records';
  }

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./components/reusable/r_698f6b5303f575960cc8c822b8ec44fd/logic/59af09a2fa337bfbb89fbccea4044508/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    var navabrSelection, currentUser;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  navabrSelection = (getObjectProperty(___arguments.context.pageData, 'navbarSelection'));
  if (navabrSelection == 'organizations') {
    return 'There are currently no organizations in your account. Please click the \'Create Organization\' button to add your first organization.';
  } else if (navabrSelection == 'residentData') {
    return 'Please add a new resident';
  } else if (navabrSelection == 'userData') {
    return 'There are currently no users in your account. Please click the \'Add User\' button to add your first user.';
  } else if (navabrSelection == 'deviceRecords') {
    return 'No records to display. Please assign the device to a resident to view the device records here.';
  }

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./components/reusable/r_698f6b5303f575960cc8c822b8ec44fd/logic/b7dcc91569b221424f2cc6d63fafd549/bundle.js', [], () => ({
  /* content */
  /* handler:onLabelAssignment */
  ['onLabelAssignment'](___arguments) {
    var navabrSelection, currentUser;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  currentUser = (getObjectProperty(___arguments.context.pageData, 'currentUser'));
  navabrSelection = (getObjectProperty(___arguments.context.pageData, 'navbarSelection'));
  if ((getObjectProperty(currentUser, 'userRole')) == 'super') {
    if (navabrSelection == 'organizations') {
      return 'Create Organization';
    } else if (navabrSelection == 'userData') {
      return 'Add User';
    }
  } else {
    if (navabrSelection == 'residentData') {
      return 'Start Intake';
    } else if (navabrSelection == 'deviceRecords') {
      return 'Go To Resident Page';
    } else if (navabrSelection == 'userData') {
      return 'Add User';
    }
  }

  },
  /* handler:onLabelAssignment */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var navabrSelection, currentUser, recordTableVariant;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

/**
 * Describe this function...
 */
async function clearOrgInputs() {
  ___arguments.context.pageData['organizationName'] = null;
  ___arguments.context.pageData['logoPicture'] = null;
  ___arguments.context.pageData['streetAddress'] = null;
  ___arguments.context.pageData['city'] = null;
  ___arguments.context.pageData['state'] = null;
  ___arguments.context.pageData['zipCode'] = null;
  ___arguments.context.pageData['websiteLink'] = null;
  ___arguments.context.pageData['billingEmail'] = null;
  ___arguments.context.pageData['billingPhone'] = null;
  ___arguments.context.pageData['billingName'] = null;
  ___arguments.context.pageData['taxId'] = null;
  ___arguments.context.pageData['addOrganizationStep'] = 1;
  ___arguments.context.pageData['orgNameError'] = false;
  ___arguments.context.pageData['websiteError'] = false;
  ___arguments.context.pageData['zipCodeError'] = false;
  ___arguments.context.pageData['taxIdError'] = null;
  ___arguments.context.pageData['billingEmailError'] = false;
  ___arguments.context.pageData['billingPhoneError'] = false;
}

/**
 * Describe this function...
 */
async function clearUserInputs() {
  ___arguments.context.pageData['email'] = null;
  ___arguments.context.pageData['fullName'] = null;
  ___arguments.context.pageData['phoneNumber'] = null;
  ___arguments.context.pageData['userRole'] = null;
  ___arguments.context.pageData['organizationChoice'] = null;
  ___arguments.context.pageData['phoneError'] = null;
  ___arguments.context.pageData['emailError'] = null;
}


  recordTableVariant = (getObjectProperty(___arguments.context.pageData, 'recordTableVariant'));
  ___arguments.context.pageData['addOrganizationStep'] = 1;
  currentUser = (getObjectProperty(___arguments.context.pageData, 'currentUser'));
  navabrSelection = (getObjectProperty(___arguments.context.pageData, 'navbarSelection'));
  if ((getObjectProperty(currentUser, 'userRole')) == 'super') {
    if (navabrSelection == 'organizations') {
      ___arguments.context.pageData['isAddRecordModalOpen'] = true;
      ___arguments.context.pageData['recordTableVariant'] = 'organization';
    } else if (navabrSelection == 'userData') {
      ___arguments.context.pageData['isAddRecordModalOpen'] = true;
      ___arguments.context.pageData['recordTableVariant'] = 'user';
    }
  } else {
    if (navabrSelection == 'userData') {
      ___arguments.context.pageData['isAddRecordModalOpen'] = true;
      ___arguments.context.pageData['recordTableVariant'] = 'user';
    } else if (navabrSelection == 'deviceRecords') {
      ___arguments.context.pageData['navbarSelection'] = 'residentData';
      ___arguments.context.pageData['noResultsScreen'] = undefined;
    } else if (navabrSelection == 'residentData') {
      ___arguments.context.pageData['recordTableVariant'] = 'resident';
      ___arguments.context.pageData['residentIntakeVariant'] = 'intake';
      ___arguments.context.pageData['residentIntakeStep'] = 1;
      ___arguments.context.pageData['isAddRecordModalOpen'] = true;
      ___arguments.context.pageData['residentIntakeForm'] = ({ [`residentObjectId`]: undefined,[`residentIntakeStep`]: undefined,[`personalDetails`]: ({ [`race`]: ({ [`answer`]: undefined,[`other`]: undefined }),[`genderIdentity`]: ({ [`answer`]: undefined,[`other`]: undefined }),[`pronouns`]: ({ [`answer`]: undefined,[`other`]: undefined }) }),[`emergencyContact`]: ({ [`relationship`]: ({ [`answer`]: undefined,[`other`]: undefined }) }) });
    }
  }
  await clearOrgInputs();
  await clearUserInputs();

  },
  /* handler:onClick */
  /* content */
}))

define('./components/reusable/r_698f6b5303f575960cc8c822b8ec44fd/logic/a3f49b76991d9971da0897e9c15c357a/bundle.js', [], () => ({
  /* content */
  /* handler:onSourceUrlAssignment */
  ['onSourceUrlAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'navbarSelection')) == 'deviceRecords') {
    return 'https://app.aquax.co/api/files/cyberdive_photos/cd-icon-no-device-found.png';
  } else {
    return 'https://app.aquax.co/api/files/cyberdive_photos/cd-icon-no-results.png';
  }

  },
  /* handler:onSourceUrlAssignment */
  /* content */
}))

define('./components/reusable/r_f6c5a876753dc841c8ca0d0134c3247a/logic/e2491ddd1fe17b2af687920b54ae1188/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    var isUserMenuOpen;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  isUserMenuOpen = (getObjectProperty(___arguments.context.pageData, 'isUserMenuOpen'));
  if (isUserMenuOpen) {
    ___arguments.context.pageData['isUserMenuOpen'] = false;
  } else {
    ___arguments.context.pageData['isUserMenuOpen'] = true;
  }

  },
  /* handler:onClick */
  /* content */
}))

define('./components/reusable/r_f6c5a876753dc841c8ca0d0134c3247a/logic/d29296736c5cce076c660a61ba2b523f/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  async ['onContentAssignment'](___arguments) {
    var currentUser;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  currentUser = (getObjectProperty(___arguments.context.pageData, 'currentUser'));
  if (currentUser) {
    return (await BackendlessUI.Functions.Custom['fn_9807a958b9f47818617415e4438b5e47']((getObjectProperty(currentUser, 'name')), 20));
  }

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./components/reusable/r_f6c5a876753dc841c8ca0d0134c3247a/logic/28d5674ccdfb6d3cea6fcae86e2e49ee/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    var userType, currentUser;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  currentUser = (getObjectProperty(___arguments.context.pageData, 'currentUser'));
  if (currentUser) {
    userType = (getObjectProperty(currentUser, 'userRole'));
    if (userType == 'manager') {
      return 'Case Manager';
    } else if (userType == 'admin') {
      return 'Administrator';
    }
  }

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./components/reusable/r_f6c5a876753dc841c8ca0d0134c3247a/logic/6e244f679043727b2685bbfb4a59d0b4/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'isUserMenuOpen')) {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('b5ffc96e4915d7c9b89100e764c1314b', false);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('b5ffc96e4915d7c9b89100e764c1314b', true);
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./components/reusable/r_f6c5a876753dc841c8ca0d0134c3247a/logic/b5ffc96e4915d7c9b89100e764c1314b/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'isUserMenuOpen')) {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('6e244f679043727b2685bbfb4a59d0b4', true);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('6e244f679043727b2685bbfb4a59d0b4', false);
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./components/reusable/r_f6c5a876753dc841c8ca0d0134c3247a/logic/4a267f32d57867fbdc1eda5e243a84ae/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function subsequenceFromStartLast(sequence, at1) {
  var start = at1;
  var end = sequence.length - 1 + 1;
  return sequence.slice(start, end);
}


  ___arguments.context.pageData['menuOptionSelection'] = 'viewProfile';
  ___arguments.context.pageData['selectedImage'] = undefined;
  ___arguments.context.pageData['selectedItemName'] = (getObjectProperty(___arguments.context.pageData, 'currentUser.name'));
  ___arguments.context.pageData['selectedItemEmail'] = undefined;
  ___arguments.context.pageData['selectedItemPhone'] = (await BackendlessUI.Functions.Custom['fn_f282814277b3c0aa13f33d3b2cda111b']((subsequenceFromStartLast((getObjectProperty(___arguments.context.pageData, 'currentUser.phoneNumber')), 2)), 'create'));
  ___arguments.context.pageData['emailError'] = undefined;
  ___arguments.context.pageData['phoneError'] = undefined;
  ___arguments.context.pageData['isUserDetailsButtonDisabled'] = ({ [`name`]: true,[`phone`]: true,[`email`]: true,[`role`]: true,[`selectedImage`]: true,[`organization`]: true });
  ___arguments.context.pageData['isSelectedItemModalOpen'] = true;

  },
  /* handler:onClick */
  /* content */
}))

define('./components/reusable/r_f6c5a876753dc841c8ca0d0134c3247a/logic/682e361bae28513beaa3123751045f8e/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var currentUser;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function subsequenceFromStartLast(sequence, at1) {
  var start = at1;
  var end = sequence.length - 1 + 1;
  return sequence.slice(start, end);
}


  currentUser = (getObjectProperty(___arguments.context.pageData, 'currentUser'));
  if (currentUser) {
    if ((getObjectProperty(currentUser, 'userRole')) == 'admin') {
      ___arguments.context.pageData['isSelectedItemModalOpen'] = true;
      ___arguments.context.pageData['menuOptionSelection'] = 'viewOrganization';
      ___arguments.context.pageData['selectedItemOrgName'] = (getObjectProperty(currentUser, 'organizationId.orgName'));
      ___arguments.context.pageData['selectedImage'] = undefined;
      ___arguments.context.pageData['selectedItemOrgBillingName'] = (getObjectProperty(currentUser, 'organizationId.billingName'));
      ___arguments.context.pageData['selectedItemOrgBillingEmail'] = undefined;
      ___arguments.context.pageData['selectedItemOrgBillingPhone'] = (await BackendlessUI.Functions.Custom['fn_f282814277b3c0aa13f33d3b2cda111b']((subsequenceFromStartLast((getObjectProperty(currentUser, 'organizationId.billingPhone')), 2)), 'create'));
      ___arguments.context.pageData['selectedItemOrgWebsite'] = undefined;
      ___arguments.context.pageData['selectedItemOrgTaxId'] = (getObjectProperty(currentUser, 'organizationId.taxId'));
      ___arguments.context.pageData['selectedItemOrgAddress'] = undefined;
      ___arguments.context.pageData['selectedItemOrgCity'] = undefined;
      ___arguments.context.pageData['orgStateValue'] = undefined;
      ___arguments.context.pageData['selectedItemOrgZipCode'] = (getObjectProperty(currentUser, 'organizationId.zipCode'));
      ___arguments.context.pageData['isEditOrgButtonDisabled'] = ({ [`orgName`]: true,[`address`]: true,[`website`]: true,[`taxId`]: true,[`billingName`]: true,[`billingPhone`]: true,[`billingEmail`]: true,[`state`]: true,[`zipCode`]: true,[`city`]: true,[`selectedImage`]: true });
    } else {
      ___arguments.context.pageData['viewDetailsSelection'] = (getObjectProperty(currentUser, 'organizationId'));
      ___arguments.context.pageData['isViewDetailsModalOpen'] = true;
      ___arguments.context.pageData['viewDetailsModalVariant'] = 'organization';
    }
  }

  },
  /* handler:onClick */
  /* content */
}))

define('./components/reusable/r_f6c5a876753dc841c8ca0d0134c3247a/logic/eedf98a4d0ec1824de22b3a34123cad6/bundle.js', [], () => ({
  /* content */
  /* handler:onSourceUrlAssignment */
  ['onSourceUrlAssignment'](___arguments) {
    var currentUser;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  currentUser = (getObjectProperty(___arguments.context.pageData, 'currentUser'));
  if (currentUser) {
    if (getObjectProperty(currentUser, 'profilePicture')) {
      return (getObjectProperty(currentUser, 'profilePicture'));
    } else {
      return 'https://app.aquax.co/api/files/cyberdive_photos/cd-icon-default-photo.svg';
    }
  }

  },
  /* handler:onSourceUrlAssignment */
  /* content */
}))

define('./components/reusable/r_f6c5a876753dc841c8ca0d0134c3247a/logic/116ceca14d0adf6456f5fc2b73b41b5d/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'isUserMenuOpen')) {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('116ceca14d0adf6456f5fc2b73b41b5d', true);
  } else {
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('116ceca14d0adf6456f5fc2b73b41b5d', false);
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./components/reusable/r_f6c5a876753dc841c8ca0d0134c3247a/logic/d1280ec811ebe74f7b0e6a8154c56d67/bundle.js', [], () => ({
  /* content */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    var currentUser;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  currentUser = (getObjectProperty(___arguments.context.pageData, 'currentUser'));
  if (currentUser) {
    if ((getObjectProperty(currentUser, 'userRole')) == 'super') {
      (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('7f3cdd46f5c3a31f344b7aac7bb7a2d7', true);
      (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('d21c3875569cd125f40480fdbd4865ce', false);
    } else {
      (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('7f3cdd46f5c3a31f344b7aac7bb7a2d7', false);
      (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('d21c3875569cd125f40480fdbd4865ce', true);
    }
  }

  },
  /* handler:onStyleAssignment */
  /* handler:onClassListAssignment */
  ['onClassListAssignment'](___arguments) {
    var classes;


  if ((function () { return BackendlessUI.isProd })()) {
    classes = ['header', 'header_display_none'];
  } else {
    classes = ['header'];
  }

  return classes

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./components/reusable/r_f6c5a876753dc841c8ca0d0134c3247a/logic/dac204aac16b4a4680eb1ec763a9d5ba/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
      ___arguments.context.pageData['navbarSelection'] = 'dashboard';
  ___arguments.context.pageData['refreshResidentGraph'] = true;
  localStorage.setItem('navbarSelection', JSON.stringify('dashboard'));
  await BackendlessUI.Functions.Custom['fn_86e80235f72a49fc4fa8cc92d83c4b9e'](___arguments.context.pageData);

  },
  /* handler:onClick */
  /* content */
}))

